
import { Component, Vue } from 'vue-property-decorator';
import { translate } from '@/filters/translate';

@Component({
    methods: {
        translate,
    },
})
export default class GameletQA extends Vue {
    tabs = translate('gameletQA.tabs', { raw: true }) as any;

    mounted(): void {
        document.title = translate('localedTitle') + ':' + translate('gameletQA.banner.title');
    }
}
