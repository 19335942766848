
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { serverService } from '@/services/serverService';
import { timestampToDate } from '@/filters/timestampToString';
import CssPreloader from '@/components/CssPreloader.vue';
import { translate } from '@/filters/translate';
import { errorToString } from '@/filters/content-filters';
import { dialogService } from '@/services/dialogService';
import { NotificationType } from '@/datas/NotificationType';
import { FriendshipStatus, TeamshipSetting } from '@/datas/FriendshipStatus';
import { IFriednship, ITeam, ITeamship, IUser } from '@/vo/User';

@Component({
    components: {
        CssPreloader,
    },
    methods: {
        translate,
    },
})
export default class Teamship extends Vue {
    teamship: ITeamship | null = null;
    friendshipTime = 0;

    showBtnRemove = false;

    @Prop()
    user?: IUser;

    @Prop()
    pageTeam?: ITeam;

    @Prop()
    me?: IUser;

    message = '';

    myTeamship?: ITeamship | null;
    myTeam?: ITeam | null;

    error = '';

    get friendship(): IFriednship | null {
        return this.teamship && this.teamship.ship;
    }

    get team(): ITeam | null | undefined {
        return this.teamship && this.teamship.team;
    }

    setMyTeamship(teamship?: ITeamship | null): void {
        if (teamship && teamship.ship.status == FriendshipStatus.ACCEPTED.code) {
            this.myTeamship = teamship;
        } else {
            this.myTeamship = null;
        }
        this.myTeam = teamship && teamship.team;
    }

    isMyTeam(): boolean {
        return !!this.myTeam && this.myTeam.teamname == this.team?.teamname;
    }
    isMyTeamship(): boolean {
        if (this.isMyTeam()) {
            return this.pageTeam ? true : serverService.isMe(this.user);
        }
        return false;
    }
    isMyPage(): boolean {
        return serverService.isMe(this.user);
    }

    isRequester(): boolean {
        if (this.friendship) {
            if (this.pageTeam) {
                return this.friendship.requester.username != this.pageTeam.username;
            } else {
                return this.isMyTeam() && this.friendship.requester.username == this.myTeam?.username;
            }
        }
        return false;
    }

    isBlackedByMe(): boolean {
        if (this.friendship!.status == FriendshipStatus.BLACKED.code) {
            return serverService.isMe(this.friendship!.requester);
        } else if (this.friendship!.status == FriendshipStatus.BLACKED_BOTH.code) {
            return true;
        } else {
            return false;
        }
    }
    isBlackedByUser(): boolean {
        if (this.friendship!.status == FriendshipStatus.BLACKED.code) {
            return serverService.isMe(this.friendship!.acceptor);
        } else {
            return false;
        }
    }
    isRejectedByMe(): boolean {
        if (this.friendship!.status == FriendshipStatus.REJECTED.code) {
            return serverService.isMe(this.friendship!.acceptor);
        } else {
            return false;
        }
    }

    canInvite(): boolean {
        return TeamshipSetting.checkAuth(this.myTeamship, TeamshipSetting.INVITE);
    }

    refresh(): void {
        this.showBtnRemove = false;
        const forceUpdate = Date.now() > this.friendshipTime;
        if (!this.me) {
            this.onTeamshipChanged();
            return;
        }
        serverService.team.getMyOwnTeamship(this.me, forceUpdate).then((myteamship) => {
            this.setMyTeamship(myteamship);
            if (this.user) {
                const user = this.user;
                if (
                    !this.friendship ||
                    forceUpdate ||
                    ![this.friendship.requester.username, this.friendship.acceptor.username].includes(user.username)
                ) {
                    this.friendshipTime = Date.now() + 60000;
                    const promise: Promise<ITeamship | null> = serverService.isMe(user)
                        ? Promise.resolve(null)
                        : serverService.team.getUserTeamship(user);
                    promise
                        .then((teamship) => {
                            if (serverService.isMe(user)) {
                                teamship = this.myTeamship || null;
                            }
                            if (!teamship && this.canInvite()) {
                                const myTeam = myteamship!.team!;
                                teamship = {
                                    ship: {
                                        requester: myTeam,
                                        acceptor: user,
                                        status: FriendshipStatus.NONE.code,
                                        createTime: 0,
                                        confirmTime: 0,
                                    },
                                    team: myTeam,
                                };
                            }
                            this.updateTeamship(teamship);
                            serverService.messaging.markRead([this.user!.username], [NotificationType.TO_TEAM_REQUEST]);
                        })
                        .catch((error) => {
                            this.teamship = null;
                            dialogService.error({ body: errorToString(error) });
                        });
                }
            } else if (this.pageTeam) {
                const pageTeam = this.pageTeam;
                if (
                    !this.friendship ||
                    forceUpdate ||
                    ![this.friendship.requester.username, this.friendship.acceptor.username].includes(pageTeam.username)
                ) {
                    this.friendshipTime = Date.now() + 60000;
                    const promise = serverService.team.isMyTeam(pageTeam)
                        ? Promise.resolve(null)
                        : this.loadTeam(pageTeam.teamname);
                    promise
                        .then((teamship) => {
                            if (serverService.team.isMyTeam(pageTeam)) {
                                teamship =
                                    myteamship && myteamship.ship.status != FriendshipStatus.NONE.code ? myteamship : null;
                            }
                            this.updateTeamship(teamship);

                            serverService.messaging.markRead(
                                [pageTeam.username],
                                [
                                    NotificationType.TO_TEAM_REQUEST,
                                    NotificationType.TO_TEAM_GRANTED,
                                    NotificationType.TO_TEAM_REJECTED,
                                    NotificationType.TEAM_INVITE_REQUEST,
                                    NotificationType.TEAM_INVITE_GRANTED,
                                    NotificationType.TEAM_INVITE_REQUEST,
                                ]
                            );
                        })
                        .catch((error) => {
                            this.teamship = null;
                            dialogService.error({ body: errorToString(error) });
                        });
                }
            } else {
                this.teamship = null;
            }
        });
    }

    private loadTeam(teamname: string): Promise<ITeamship | null> {
        const me = this.me;
        if (!me) {
            return Promise.resolve(null);
        }
        return serverService.team.getMyTeamship(teamname).then((result) => {
            let teamship = result.teamship;
            if (!teamship) {
                teamship = {
                    ship: {
                        acceptor: result.team,
                        requester: me,
                        status: FriendshipStatus.NONE.code,
                        createTime: 0,
                        confirmTime: 0,
                    },
                    team: result.team,
                };
            } else if (teamship.team?.teamname != result.team.teamname) {
                return null;
            }
            return teamship;
        });
    }

    @Watch('user', { immediate: true })
    onUserChange(): void {
        this.refresh();
    }

    @Emit('teamshipStatus')
    onTeamshipChanged(): void {}

    btnRemoveVisible(value: boolean): void {
        this.showBtnRemove = value;
    }

    get createDate(): string {
        return timestampToDate(this.friendship!.createTime * 1000);
    }

    get confirmDate(): string {
        return timestampToDate(this.friendship!.confirmTime * 1000);
    }

    updateTeamship(teamship: ITeamship | null): void {
        this.teamship = teamship;
        this.friendshipTime = Date.now() + 60000;
        this.onTeamshipChanged();
    }

    wasMyLeaderTeam(): boolean {
        const teamship = this.teamship;
        return (
            !this.myTeamship &&
            !!teamship &&
            teamship.ship.status == FriendshipStatus.NONE.code &&
            teamship.ship.settings == TeamshipSetting.LEADER
        );
    }

    private setFriendship(newStatus: FriendshipStatus): Promise<IFriednship | null> {
        const myTeam = this.pageTeam || this.myTeam;
        if (!myTeam) {
            return Promise.reject('You are not in a team.');
        }
        this.error = '';
        let friendship: IFriednship = this.friendship!;
        let status = friendship.status;
        friendship.status = 'loading';
        this.teamship!.user = this.pageTeam ? this.me! : this.user!;
        return serverService.team
            .setTeamship(this.teamship!, newStatus)
            .then(() => {
                this.friendshipTime = 0;
                this.refresh();
                return friendship;
            })
            .catch((err) => {
                friendship.status = status;
                if (status == FriendshipStatus.ACCEPTED.code) {
                    dialogService.error({
                        body: errorToString(err),
                    });
                } else {
                    this.error = err.response.data;
                }
                return null;
            });
    }

    /**
     * send invitation to user to join your team
     */
    btnSendRequest(): void {
        let body: string;
        let action = 'btn.send-team-apply';
        let retake = false;
        if (this.pageTeam) {
            if (this.wasMyLeaderTeam()) {
                retake = true;
                body = translate('confirm-send-team-retake-request', {
                    target: this.pageTeam.nickname,
                });
                action = 'btn.retake-myteam-short';
            } else {
                body = translate('confirm-send-team-apply-request', {
                    target: this.pageTeam.nickname,
                });
            }
        } else {
            body = translate('confirm-send-team-invite-request', {
                target: this.user!.nickname,
            });
            action = 'btn.send-team-invite';
        }
        dialogService.alert(
            {
                //title: 'Make friend?',
                body: body,
            },
            {
                okText: translate(action),
                cancelText: translate('btn.cancel'),
                ok: (controller) => {
                    controller.dismiss();
                    if (this.pageTeam) {
                        this.friendship!.requester = this.me!;
                        this.friendship!.acceptor = this.pageTeam;
                    } else {
                        let users = [this.friendship!.requester, this.friendship!.acceptor];
                        this.friendship!.requester = users.find((u) => u.username != this.user!.username)!;
                        this.friendship!.acceptor = this.user!;
                    }
                    this.setFriendship(FriendshipStatus.REQUESTING);
                },
            }
        );
    }

    btnAccept(): void {
        this.setFriendship(FriendshipStatus.ACCEPTED);
    }
    btnReject(): void {
        this.setFriendship(FriendshipStatus.REJECTED);
    }

    /** leave team */
    btnRemove(): void {
        dialogService.alert(
            {
                //title: 'Make friend?',
                body: translate('confirm-leave-team'),
            },
            {
                customClass: 'danger',
                okText: translate('btn.leave-team'),
                cancelText: translate('btn.cancel'),
                ok: (controller) => {
                    controller.dismiss();
                    this.setFriendship(FriendshipStatus.NONE);
                },
            }
        );
    }

    btnCancelRequest(): void {
        this.setFriendship(FriendshipStatus.NONE);
    }

    btnBlack(): void {
        this.setFriendship(FriendshipStatus.BLACKED);
    }
    btnUnblack(): void {
        this.setFriendship(FriendshipStatus.NONE);
    }
}
