import { importTranslation } from '../translate';

importTranslation('en-US', {
    blog: {
        btn: {
            submitBlog: 'I want to contribute',
            aboutEditors: 'About the Editors',
            newer: 'Newer',
            older: 'Older',
        },
        label: {
            read: 'Read',
            moreArticles: 'More Articles',
        },
        intro: {
            title: 'Gamelet Players Blog',
            desc: [
                'This Blog is built for the players to share ideas, feelings, and memories in the gamelet community. We sincerely invite you to tell us your stories and inspire all players on Gamelet.',
                'The E-Publication is produced by the official Gamelet Editors. The team looks for fun stories, interviews interesting players, and writes original essays and contes.',
            ],
        },
        blogs: {
            title: 'Latest Articles',
        },
        epub: {
            title: 'E-Publication',
            volTitle: 'E-Pub Vol.{vol}',
        },
    },
});

export const blog_translation_en = 1;
