
import { Component, Vue, Prop } from 'vue-property-decorator';
import { translate } from '@/filters/translate';
import { serverService } from '@/services/serverService';
import { FileUtil } from '@/utils/FileUtil';
import { ResourceCategory } from '@/datas/ResourceCategory';
import { dialogService } from '@/services/dialogService';
import { errorToString } from '@/filters/content-filters';
import { IUser } from '@/vo/User';

@Component({
    methods: {
        translate,
    },
    components: {},
})
export default class UploadResources extends Vue {
    public $refs!: Vue['$refs'] & {
        canvas: HTMLCanvasElement;
    };

    @Prop()
    category;

    resourceUrl = '';

    preferSize = {
        width: 1000,
        height: 240,
    };

    categoryTitle = {
        [ResourceCategory.CHAT_COVER.code]: translate('forum.board.GameletChat.tab'),
        [ResourceCategory.GAMES_COVER.code]: translate('forum.board.games.tab'),
        [ResourceCategory.GALLERY_COVER.code]: translate('forum.board.GameletGallery.tab'),
    };

    mounted(): void {}

    uploadFile(): void {
        serverService.getInitData().then((initData) => {
            let user = initData.me;

            dialogService.alert(
                {
                    titleIcon: 'fas fa-question-circle',
                    title: translate('forum.alert.uploadDialog.title', {
                        category: this.categoryTitle[this.category.code],
                    }),
                },
                {
                    okText: translate('btn.confirm'),
                    cancelText: translate('btn.cancel'),
                    ok: (controller) => {
                        controller.showLoading(translate('forum.alert.loadingDialog.text'));
                        serverService.resource
                            .addResource(user, this.category, this.resourceUrl)
                            .then(() => {
                                controller.dismiss();
                                window.location.reload();
                            })
                            .catch((err) => {
                                controller.dismiss();
                                dialogService.error({
                                    title: errorToString(err),
                                });
                            });
                    },
                }
            );
        });
    }

    addFile(): void {
        let input = document.createElement('input');
        input.type = 'file';
        input.accept = '.gif,.png,.jpg';
        input.click();

        input.onchange = (e) => {
            const el = e.target as HTMLInputElement;
            if (el.files && el.files.length) {
                FileUtil.convertFileToBase64(el.files[0])
                    .then((dataUrl) => FileUtil.resizeDataUrl(dataUrl, this.preferSize))
                    .then((dataUrl) => {
                        this.resourceUrl = dataUrl;
                    });
            }
        };
    }

    searchUser(): Promise<IUser | null> {
        return dialogService.searchUser(
            {
                title: translate('gm.alert.searchResourceUser.title', {
                    category: translate(`gm.category.${this.category.code}`),
                }),
                titleIcon: 'fas fa-search',
            },
            {
                okText: translate('btn.confirm'),
                cancelText: translate('btn.cancel'),
            }
        );
    }
}
