
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { serverService } from '@/services/serverService';
import { DISCUSS_CATEGORY, DISCUSS_ORDER, DISCUSS_STATE, DISCUSS_STATUS } from '@/services/discussService';
import ForumArticleBox from './ForumArticleBox.vue';
import InputBox from './InputBox.vue';
import Pagination from '@/components/Pagination.vue';
import CssPreloader from '@/components/CssPreloader.vue';
import { Location, Route } from 'vue-router';
import { translate } from '@/filters/translate';
import { errorToString } from '@/filters/content-filters';
import { clientCodeToName, isUserTitleClient } from '@/filters/game-filters';
import { getClientUpRoute, RouteResolver, setClientLastViewId, UpRouteData } from '@/utils/RouteResolver';
import { dialogService } from '@/services/dialogService';
import { ForumCat, PostInputData } from '@/datas/ForumCat';
import { DomUtil } from '@/utils/DomUtil';
import { NotificationType } from '@/datas/NotificationType';
import { ArticleUsersMeta } from '@/datas/ArticleUsersMeta';
import { Constant } from '@/datas/Constant';
import AreaTitle from '@/components/AreaTitle.vue';
import AdsResponsive from '@/components/ads/AdsResponsive.vue';
import { showSideAds } from '@/filters/mobile';
import { AlertDialogController } from '../../components/dialogs/DialogProps';
import { IClient } from '@/vo/Client';
import { IArticle, IArticleAndReplies, IArticleUsersMeta } from '@/vo/Discuss';
import { IUser } from '@/vo/User';

declare const $: any;

@Component({
    components: {
        ForumArticleBox,
        InputBox,
        Pagination,
        CssPreloader,
        AreaTitle,
        AdsResponsive,
    },
    methods: {
        translate,
        showSideAds,
    },
})
export default class ForumArticle extends Vue implements RouteResolver {
    public $refs!: Vue['$refs'] & {
        listTop: HTMLDivElement;
        btnPostToggle: HTMLDivElement;
    };

    articleId = 0;
    client?: IClient;

    data: IArticleAndReplies | null = null;
    floor = '';
    showInputBox = false;
    preloadStart = 0;
    start = 0;
    length = 50;
    maxPage = 0;
    paramName = 'page';
    loading = false;
    error = false;
    page = 1;
    showStaticBtnReply = false;
    isManager = false;
    category!: DISCUSS_CATEGORY;
    webMasterList: IUser[] = [];
    resetInput = 0;

    statusOptions = [DISCUSS_STATUS.OPEN, DISCUSS_STATUS.DONE, DISCUSS_STATUS.PASSOVER];

    usersMeta: ArticleUsersMeta = new ArticleUsersMeta();

    @Prop()
    fixedForum?: ForumCat;

    mounted(): void {
        this.onScroll = this.onScroll.bind(this);
        window.addEventListener('scroll', this.onScroll);
        this.listGM();
        this.refreshPageTitle();
    }
    refreshPageTitle(): void {
        let title = translate('localedTitle');
        if (this.client && this.client.name) {
            title = this.client.name;
        }
        title += ':' + this.getSubtitle();
        document.title = title;
    }
    beforeDestroy(): void {
        window.removeEventListener('scroll', this.onScroll);
    }

    getSubtitle(): string {
        const category = this.category;
        if (this.isOfficialForum()) {
            return translate(`game.${category.code}.officialTitle`);
        }
        if (isUserTitleClient(this.client)) {
            return translate(`game.sysTitle.forumTitle`);
        }
        return translate(`game.${category.code}.title`);
    }

    get titleIcon(): string {
        switch (this.category) {
            case DISCUSS_CATEGORY.NEWS:
                return 'far fa-newspaper';
            case DISCUSS_CATEGORY.FORUM:
                return 'fas fa-comments';
            case DISCUSS_CATEGORY.REPORT:
                return 'fas fa-question-circle';
            case DISCUSS_CATEGORY.CONTRIBUTE:
                return 'fas fa-feather';
        }
        return '';
    }

    isReport(): boolean {
        return this.category == DISCUSS_CATEGORY.REPORT;
    }

    getBellClass(): string {
        return this.usersMeta.isWatching() ? 'fas fa-bell' : 'far fa-bell';
    }

    get isFollowing(): boolean {
        return this.usersMeta.isWatching();
    }

    get isNeverFollowed(): boolean {
        return !this.usersMeta.isBanWatching();
    }

    onArticleStateChange(article: IArticle): void {
        if (this.isNews()) {
            if (article.id != this.articleId) {
                // a reply state has changed, we need to refresh rootArticle content
                serverService.discuss.getArticleById(this.articleId).then((a) => {
                    if (a.id == this.data?.article.id) {
                        this.data!.article = Object.assign(this.data!.article, a);
                        this.$forceUpdate();
                    }
                });
            }
        } else if (this.data && article.id == this.articleId) {
            this.data.parent = article;
            this.$forceUpdate();
        }
    }

    @Watch('$route', { immediate: true })
    onRouteChanged(to: Route, from?: Route): void {
        this.parseParams(to);
        if (!from) {
            /**
             * if from is not null, means the page is redirected because article has a parent article.
             */
            setClientLastViewId(this.articleId + '');
        }
        if (from && from.path == to.path && to.hash) {
            let search = to.hash.match(/^#([0-9]+)$/);
            if (search) {
                let floor = Number(search[1]);
                let anchor = $(`a.hashtag[name="${floor}"]`);
                if (anchor.length) {
                    // smooth scroll is handled in router.vue scrollBehavior and content-filter.ts
                    //this.moveToFloor(floor, true);
                    return;
                }
            }
        }
        this.isManager = false;
        this.loadReplies(this.page).then(() => {
            // check if everything is good before checking GM
            if (!this.loading && this.data) {
                this.checkIsManager(this.client!.code).then((value) => {
                    this.isManager = value;
                });
            }
        });
    }

    listGM(): void {
        serverService.client.listGameMasters(Constant.SITE_CLIENT.code).then((data) => {
            this.webMasterList = data;
        });
    }

    isRemoved(): boolean | null {
        return this.error || (this.data && this.data.article.state == DISCUSS_STATE.REMOVED.code);
    }

    canReply(): boolean {
        if (this.isNews()) {
            return false;
        }
        return (
            this.data!.article.state == DISCUSS_STATE.OPEN.code ||
            (this.data!.article.state == DISCUSS_STATE.DIALOG.code &&
                (this.isManager || serverService.isMe(this.data!.article.author)))
        );
    }

    isOfficialForum(): boolean {
        return !!ForumCat.listByCode(this.client!.code).length;
    }

    isNews(): boolean {
        return this.category == DISCUSS_CATEGORY.NEWS;
    }

    isRooter(article: IArticle): boolean {
        return this.data!.article.author.username == article.author.username;
    }

    get parentRoute(): Location {
        if (this.fixedForum) {
            return this.fixedForum.route;
        }
        return {
            name: 'forum_client',
            params: {
                category: (this.category || DISCUSS_CATEGORY.FORUM).code,
                clientCode: this.client!.code,
            },
        };
    }

    checkIsManager(clientCode: string): Promise<boolean> {
        return Promise.all([serverService.client.listGameMasters(clientCode), serverService.getInitData()]).then(
            () => serverService.isGM() || serverService.isClientGM(clientCode)
        );
    }

    parseParams(to: Route): void {
        this.articleId = Number(to.params.articleId);
        this.category = DISCUSS_CATEGORY.getByCode(this.fixedForum ? this.fixedForum.category! : to.params['category']);
        const clientCode: string = this.fixedForum ? this.fixedForum.clientCode! : to.params['clientCode'];

        if (this.client && this.client.code == clientCode) {
            // we've got this
        } else {
            this.client = {
                code: clientCode,
                name: clientCodeToName(clientCode, this.category.code),
            };
        }
        if (!this.client.name) {
            this.queryClient();
        }

        if (to.params['page'] == 'last') {
            if (this.maxPage) {
                this.page = this.maxPage;
                this.$router.replace(this.resolveRoute({ page: this.maxPage }));
            } else {
                this.page = 99999;
            }
        } else {
            this.page = Number(to.params['page']) || 1;
        }
    }

    private queryClient(): void {
        serverService.client.queryClients([this.client!.code]).then((clients) => {
            if (clients.length) {
                let client = clients[0];
                if (this.client && this.client.code == client.code) {
                    this.client = client;
                    this.refreshPageTitle();
                    this.$forceUpdate();
                }
            }
        });
    }

    get routeResolver(): RouteResolver {
        return this;
    }
    getUpRoute(): UpRouteData | null {
        let data: UpRouteData = {
            name: '',
            route: {},
        };
        if (this.fixedForum) {
            data.route = {
                name: this.fixedForum.route.name,
                params: Object.assign(
                    {
                        page: '1',
                    },
                    this.fixedForum.route.params
                ),
            };
            data.name = this.fixedForum.name;
        } else {
            const client = this.client!;
            const category = this.category || DISCUSS_CATEGORY.FORUM;
            data.route = getClientUpRoute(client.code) || {
                name: 'forum_client',
                params: Object.assign({
                    category: category.code,
                    clientCode: this.client!.code,
                }),
            };
            if (data.route.name == 'forum_games_page' || data.route.name == 'forum_games') {
                data.name = ForumCat.GAMES.name;
            } else {
                if (this.isOfficialForum()) {
                    data.name = client.name!;
                } else {
                    data.name = translate(`game.${category.code}.title`);
                }
            }
        }
        return data;
    }

    resolveRoute(params: { [key: string]: any }): Location {
        if (this.fixedForum) {
            return {
                name: this.fixedForum.route.name + '_post_page',
                params: Object.assign(
                    {
                        articleId: this.articleId + '',
                        page: params.page + '',
                    },
                    this.fixedForum.route.params
                ),
                hash: params.hash,
            };
        }
        return {
            name: 'forum_client_post_page',
            params: {
                category: (this.category || DISCUSS_CATEGORY.FORUM).code,
                clientCode: this.client!.code,
                articleId: this.articleId + '',
                page: params.page + '',
            },
            hash: params.hash,
        };
    }

    floorInputKey(e: KeyboardEvent): void {
        if (e.key == 'Enter' && this.data) {
            let id = Math.floor(Number(this.floor));
            id = Math.max(1, Math.min(this.data.article.replies, id));
            this.moveToFloor(id, true);
        }
    }

    moveToFloor(id: number, force?: boolean): void {
        let myElement: ForumArticleBox | ForumArticleBox[] = this.$refs[`article_${id}`] as ForumArticleBox[];

        if (myElement && Array.isArray(myElement)) {
            myElement = myElement[0];
        }

        if (myElement && myElement.$refs) {
            // 如果是 isRoot, 代表很有可能他不是想點進來看單獨這篇文章
            // 而是想正常進來看這整篇討論串，因此不要再去滑動，影響讀者
            if (!myElement.isRoot || force) {
                DomUtil.moveToElement(myElement.$refs.hashTag);
            }
        } else {
            let page = Math.ceil((id - 1) / this.length);
            this.$router.push(this.resolveRoute({ page: page, hash: '#' + id }));
        }
    }

    private loadPageKey(page: number): string {
        return [this.client?.code, this.category.code, page].join('/');
    }

    watchForum(watch: boolean): void {
        let loading = dialogService.loading();
        let promise = watch
            ? serverService.discuss.watchArticle(this.data!.article!)
            : serverService.discuss.stopWatchArticle(this.data!.article!);
        promise
            .then((meta) => {
                this.usersMeta = new ArticleUsersMeta(meta, serverService.myUserId);
            })
            .catch((err) => {
                dialogService.error({ title: errorToString(err) });
            })
            .finally(() => {
                loading.close();
            });
    }

    loadReplies(page: number): Promise<void> {
        this.page = page;
        this.loading = true;
        this.preloadStart = this.start;
        this.start = this.length * (page - 1);
        let loadPageKey = this.loadPageKey(page);

        if (this.data && this.$route.name?.endsWith('_page'))
            this.$nextTick(() => {
                DomUtil.moveToElement(this.$refs.listTop);
            });

        this.usersMeta = new ArticleUsersMeta();
        return serverService.discuss
            .getArticleAndReplies(
                this.client ? this.client.code : '',
                this.category,
                this.articleId,
                DISCUSS_ORDER.TIME_ASC,
                this.start,
                this.length
            )
            .then((data) => {
                if (this.loadPageKey(this.page) != loadPageKey) {
                    return;
                }

                if (data.parent || data.client || data.category) {
                    // needs redirect
                    let page = data.floor === undefined ? this.page : Math.ceil((data.floor! - 1) / this.length);
                    this.articleId = data.parent ? data.parent.id : this.articleId;
                    this.client = data.client || this.client;
                    this.category = data.category
                        ? DISCUSS_CATEGORY.getByCode(data.category)
                        : this.category || DISCUSS_CATEGORY.FORUM;

                    this.$router.replace(
                        this.resolveRoute({
                            page: page,
                            hash: data.floor === undefined ? this.$route.hash : '#' + data.floor!,
                        })
                    );
                } else {
                    // Line 447 have the same function(move to listTop)
                    this.loading = false;
                    this.preloadStart = this.start;
                    this.data = data;
                    this.usersMeta = new ArticleUsersMeta(this.data!.article.meta as IArticleUsersMeta, serverService.myUserId);

                    this.maxPage = Math.ceil((data.article.replies - 1) / this.length);
                    if (this.page > Math.max(1, this.maxPage)) {
                        this.$router.replace(this.resolveRoute({ page: this.maxPage }));
                    } else {
                        if (this.$route.hash) {
                            let searchHash = searchFloorHash(this.$route.hash);
                            if (searchHash) {
                                this.$nextTick(() => this.moveToFloor(Number(searchHash![1])));
                            }
                        }

                        serverService.messaging.markArticlesRead(
                            [this.data!.article],
                            [NotificationType.USER_REPORT, NotificationType.USER_JOINED_DISCUSS]
                        );
                    }
                }
            })
            .catch((err) => {
                this.error = true;
                this.loading = false;
                errorToString(err);
            });
    }

    displayInput(): void {
        if (!serverService.hasLoggedIn()) {
            dialogService.notLoginError(this.$route.fullPath);
        } else {
            this.showInputBox = !this.showInputBox;
            if (this.showInputBox) {
                DomUtil.moveToElement(this.$refs.btnPostToggle);
            }
        }
    }

    onScroll(): void {
        if (this.$refs.btnPostToggle) {
            let minOffset = this.$refs.btnPostToggle.offsetTop - 50;
            let show = window.scrollY > minOffset;
            if (show != this.showStaticBtnReply) {
                this.showStaticBtnReply = show;
            }
        }
    }

    onBtnArticleStatus(status: DISCUSS_STATUS): void {
        if (this.isManager && this.isReport() && this.data) {
            this.openGMDialog(
                translate('forum.gmReportStatus', {
                    status: status.getOptionName(this.category.code),
                }),
                'fas fa-edit',
                (note) => {
                    return serverService.discuss.setArticleStatus(this.data!.article, status, note).then(() => {
                        // done
                    });
                }
            );
        }
    }

    onPost(post: PostInputData): void {
        if (!post) {
            return;
        }
        dialogService.alert(
            {
                title: translate('forum.replyConfirm.title'),
                body: '',
                titleIcon: 'fas fa-question-circle',
            },
            {
                okText: translate('forum.replyConfirm.btnConfirm'),
                cancelText: translate('forum.replyConfirm.btnCancel'),
                ok: (controller) => {
                    const articleId = this.articleId!;
                    submitReply(this.data!, articleId, controller, post).then((result) => {
                        this.showInputBox = false;
                        this.resetInput++;
                        if (result) {
                            this.$router.replace(
                                this.resolveRoute({
                                    page: Math.ceil(result.article.replies / this.length),
                                    hash: '#' + result.article.replies,
                                })
                            );
                        }
                    });
                },
            }
        );
    }

    private openGMDialog(title: string, titleIcon: string, action: (note: string) => Promise<void>): void {
        dialogService.input(
            {
                titleIcon: titleIcon,
                title: title,
            },
            {
                placeholder: translate('forum.gmNote'),
                okText: translate('btn.confirm'),
                cancelText: translate('btn.cancel'),
                ok: (controller) => {
                    controller.showLoading(translate('processing'));
                    action(controller.getInput())
                        .then(() => {
                            controller.dismiss();
                        })
                        .catch((err) => {
                            controller.hideLoading();
                            dialogService.error({ body: errorToString(err) });
                        });
                },
            }
        );
    }
}
function searchFloorHash(hash: string) {
    return hash.match(/^#([0-9]+)$/);
}
function submitReply(data: IArticleAndReplies, articleId: number, controller: AlertDialogController, post: PostInputData) {
    controller.showLoading(translate('forum.replyConfirm.posting'));
    const title = 'RE:' + data.article.title;
    return serverService.discuss
        .addReply(articleId, title, post.content)
        .then((result) => {
            controller.dismiss();
            return result;
        })
        .catch((err) => {
            controller.dismiss();
            dialogService.error({ title: errorToString(err) });
        });
}
