import { importTranslation } from '../translate';

importTranslation('en-US', {
    links: {
        games: 'Games',
        forums: 'Forums',
        recharge: 'Deposit',
        gameletBlog: {
            fullName: 'Gamelet Blog',
            tab: 'Blog',
        },
        codeGamelet: {
            fullName: 'Code Gamelet',
            tab: 'C.G.',
        },
        aboutUs: 'About Us',
        memorabilia: 'Memorabilia',
        problemReport: 'Report',
        userTerms: 'Terms of Service',
        privacyPolicy: 'Privacy Policy',
    },
});

export const links_translation_en = 1;
