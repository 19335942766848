import { IArticleUsersMeta } from '@/vo/Discuss';

export class ArticleUsersMeta {
    private meta: IArticleUsersMeta = {
        users: {
            aids: [],
            bids: [],
            wids: [],
        },
    };

    constructor(meta?: IArticleUsersMeta, private meId: number = 0) {
        if (meta) {
            this.meta = Object.assign(this.meta, meta);
        }
    }

    isWatching(): boolean {
        return !this.isBanWatching() && (this.meta.users.aids.includes(this.meId) || this.meta.users.wids.includes(this.meId));
    }

    isBanWatching(): boolean {
        return this.meta.users.bids.includes(this.meId);
    }
}
