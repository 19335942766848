
import { Component } from 'vue-property-decorator';
import { serverService } from '@/services/serverService';
import CssPreloader from '@/components/CssPreloader.vue';
import BaseTabComp from './BaseTabComp';
import { getBadgePageRoute, getClientPlayUrl } from '@/filters/game-filters';
import { translate } from '@/filters/translate';
import { timestampToDate } from '@/filters/timestampToString';
import { IUserBadge, IUserBadgePack } from '@/vo/Badge';

@Component({
    components: {
        CssPreloader,
    },
    methods: {
        getBadgePageRoute,
        getClientPlayUrl,
        translate,
    },
})
export default class Badges extends BaseTabComp {
    loading = false;

    packs: IUserBadgePack[] = null!;

    mounted(): void {
        this.loading = true;
        serverService.user.listRecentBadgesByUser(this.user!, 100).then((list) => {
            this.loading = false;
            this.packs = list;
        });
    }

    get noBadgeText(): string {
        return this.isMe ? 'i-have-no-badge' : 'he-has-no-badge';
    }

    getBadgeTooltip(badge: IUserBadge): string {
        let lines: string[] = [
            badge.gameBadge.name,
            translate(badge.count > 1 ? 'badge.firstGetDate' : 'badge.getDate', {
                date: timestampToDate(badge.createTime * 1000),
            }),
        ];
        if (badge.count > 1) {
            lines.push(
                translate('badge.lastGetDate', {
                    date: timestampToDate(badge.modifyTime * 1000),
                }),
                translate('badge.getCount', { count: badge.count })
            );
        }
        return lines.join('\n');
    }

    loadMore(pack: IUserBadgePack): void {
        pack.loading = true;
        serverService.user
            .listUserBadgesByGame(this.user!, pack.game!, pack.fresh ? pack.badges.length : 0, 100)
            .then((list) => {
                pack.loading = false;
                if (pack.fresh) {
                    pack.badges = pack.badges.concat(list);
                } else {
                    pack.badges = list;
                    pack.fresh = true;
                }
            });
    }

    hasMore(pack: IUserBadgePack): boolean {
        return pack.badges.length < pack.total;
    }
}
