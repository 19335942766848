import { importTranslation } from '../translate';

importTranslation('zh-Hans', {
    time: {
        aMinute: '一分钟',
        minutes: '{minutes}分钟',
        hours: '{hours}小时',
        days: '{days}天',
        years: '{years}年',
    },
    timeShort: {
        aMinute: '一分钟',
        minutes: '{minutes}分钟',
        hours: '{hours}小时',
        days: '{days}天',
        years: '{years}年',
    },
    timeAgo: {
        sec: '一秒前',
        secs: '{sec}秒前',
        min: '一分钟前',
        mins: '{min}分钟前',
        hour: '一小时前',
        hours: '{hour}小时前',
        day: '昨天',
        days: '{day}天前',
        month: '上个月',
        months: '{month}个月前',
        year: '去年',
        years: '{year}年前',
    },
    reply: '{reply}回覆',
    replies: '{reply}回覆',
    update: '{count}则更新',
    updates: '{count}则更新',
    to: '至',
    btn: {
        login: '登入',
        logout: '登出',
        myProfile: '我的档案',
        myTeam: '我的盟会',
        more: '更多',
        confirm: '确定',
        cancel: '取消',
        close: '关闭',
        gotit: '知道了',
        depositBeforeBuy: '充值(资金不足)',
    },
    googleSearch: {
        input: 'Google搜寻',
        result: '搜寻结果',
    },
    twitter: {
        title: '推特消息',
    },
    leftMenu: {
        gameletQA: '嘎姆2.0<br />Q&A专区',
        gameletChannel: '官方频道',
        moreFunction: 'COMING<br />SOON',
    },
    localedTitle: '嘎姆擂台',
    notLogin: '未登入',
    processing: '处理中...',
    gmHome: '管理员的家',
    loading: '读取资料中...',
    updating: '更新资料中...',
    error: {
        notLogin: '请先登入帐号！ ',
        fileOverSized: '档案太大了!',
    },
    locked: {
        reasonTitle: '冻结原因',
        showHowLong: '您的帐号暂时处于冻结状态，还有 <span style="color: #fff09e">{time}</span> 解锁。 ',
    },
    gm: {
        alert: {
            searchResourceUser: {
                selected: '已选择',
                btnSelect: '选择',
            },
        },
    },
});

export const common_translation_cn = 1;