import { TeamshipSetting } from '../datas/FriendshipStatus';
import { translate } from './translate';

export class SettingData {
    constructor(public setting: number, public code: string) {}

    get label(): string {
        return translate('label.team.setting.' + this.code);
    }
    get description(): string {
        return translate('label.team.settingTip.' + this.code);
    }
}

export const allTeamshipSettings = [
    new SettingData(TeamshipSetting.INVITE, 'invite'),
    new SettingData(TeamshipSetting.REMOVE, 'remove'),
    new SettingData(TeamshipSetting.EDITOR, 'editor'),
    new SettingData(TeamshipSetting.INVEST, 'invest'),
    new SettingData(TeamshipSetting.MANAGE, 'manage'),
];

const all: TeamAction[] = [];

export class TeamAction {
    public static CREATE_TEAM = new TeamAction('createTeam');

    public static RETAKE = new TeamAction('retake');
    public static ACCEPT_INVITE = new TeamAction('acceptInvite');
    public static APPLY = new TeamAction('apply');
    public static ACCEPT_APPLY = new TeamAction('acceptApply');
    public static INVITE = new TeamAction('invite');

    public static REJECT_INVITE = new TeamAction('rejectInvite');
    public static REJECT_APPLY = new TeamAction('rejectApply');

    public static LEAVE_TEAM = new TeamAction('leaveTeam');
    public static CANCEL_APPLY = new TeamAction('cancelApply');
    public static REMOVE_MEMBER = new TeamAction('removeMember');
    public static CANCEL_INVITE = new TeamAction('cancelInvite');

    public static SAVE_TEAM = new TeamAction('saveTeam');
    public static SET_TEAMSIZE = new TeamAction('setTeamSize');

    public static UPDATE_TEAMSHIP = new TeamAction('updateTeamship');

    public static SET_BOARD_AUTHTYPE = new TeamAction('setBoardAuthType');
    public static REMOVE_ARTICLE = new TeamAction('removeArticle');
    public static SET_ARTICLE_STATUS = new TeamAction('setArticleStatus');
    public static SET_ARTICLE_STATE = new TeamAction('setArticleState');
    public static SET_ARTICLE_LOCKED = new TeamAction('setArticleLocked');
    public static SET_ARTICLE_ORDER = new TeamAction('setArticleOrder');

    public static getByCode(code: string): TeamAction | undefined {
        return all.find((action) => action.code == code);
    }

    public static listAll(): TeamAction[] {
        return all.slice();
    }

    constructor(public code: string) {
        if (!TeamAction.getByCode(code)) {
            all.push(this);
        }
    }

    get name(): string {
        return translate('team.action.' + this.code);
    }
}

export class TeamActionSet {
    constructor(public code: string, public actions: TeamAction[]) {}
    get name(): string {
        return translate('team.actionSet.' + this.code);
    }
}
export const ALL_TEAMACTION_SET: TeamActionSet[] = [
    new TeamActionSet('all', all),
    new TeamActionSet('team', [
        TeamAction.CREATE_TEAM,
        TeamAction.SET_TEAMSIZE,
        TeamAction.SAVE_TEAM,
        TeamAction.SET_BOARD_AUTHTYPE,
    ]),
    new TeamActionSet('invite', [
        TeamAction.RETAKE,
        TeamAction.ACCEPT_INVITE,
        TeamAction.APPLY,
        TeamAction.ACCEPT_APPLY,
        TeamAction.INVITE,
        TeamAction.CANCEL_APPLY,
        TeamAction.CANCEL_INVITE,
        TeamAction.REJECT_INVITE,
        TeamAction.REJECT_APPLY,
    ]),
    new TeamActionSet('remove', [TeamAction.UPDATE_TEAMSHIP, TeamAction.REMOVE_MEMBER, TeamAction.LEAVE_TEAM]),
    new TeamActionSet('board', [
        TeamAction.SET_BOARD_AUTHTYPE,
        TeamAction.SET_ARTICLE_ORDER,
        TeamAction.SET_ARTICLE_STATUS,
        TeamAction.SET_ARTICLE_STATE,
        TeamAction.SET_ARTICLE_LOCKED,
        TeamAction.REMOVE_ARTICLE,
    ]),
];
