import { importTranslation } from '../translate';

importTranslation('zh-Hans', {
    blog: {
        btn: {
            submitBlog: '我要投稿',
            aboutEditors: '关于编辑群',
            newer: '较新文章',
            older: '较旧文章',
        },
        label: {
            read: '阅读',
            moreArticles: '更多文章',
        },
        intro: {
            title: '嘎姆游刊',
            desc: [
                '嘎姆游刊是玩家们分享点子、抒发心情、回忆考古的专属园地。 我们期待您将平常在嘎姆得到的灵感化成文字，发表在这儿感动与启发所有的玩家们。 ',
                '「嘎姆擂台电子期刊」则是由官方组成的「嘎姆编辑群」以搜文、访谈、创作等方式制作的电子刊物，里头刊登了许多原创的文章与小说，定期出刊与读者们 分享。 ',
            ],
        },
        blogs: {
            title: '最新文章',
        },
        epub: {
            title: '嘎姆期刊电子书',
            volTitle: '嘎姆期刊 Vol.{vol}',
        },
    },
});

export const blog_translation_cn = 1;