
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { translate } from '@/filters/translate';
import { DISCUSS_CATEGORY, DISCUSS_STATE } from '../services/discussService';
import BoardMessage, { BoardMessageData } from '../app-home/users/components/BoardMessage.vue';
import { DomUtil } from '../utils/DomUtil';
import { errorToString } from '../filters/content-filters';
import { dialogService } from '../services/dialogService';
import { serverService } from '../services/serverService';
import { Location, Route } from 'vue-router';
import { RouteResolver, UpRouteData } from '../utils/RouteResolver';
import { Constant } from '../datas/Constant';
import CssPreloader from '@/components/CssPreloader.vue';
import InputBox from '@/forum/components/InputBox.vue';
import Pagination from '@/components/Pagination.vue';
import { getBlogReadRoute } from '../filters/blog-filters';
import { IArticle, IArticleAndReplies, ISubjectFull } from '@/vo/Discuss';
import { IFeedEntry } from '@/vo/Blog';

@Component({
    components: {
        CssPreloader,
        InputBox,
        Pagination,
        BoardMessage,
    },
    methods: {
        translate,
    },
})
export default class BlogBoard extends Vue {
    public $refs!: Vue['$refs'] & {
        comments: HTMLDivElement;
    };

    loading = false;
    showInputBox = false;

    data?: BoardMessageData[];

    resetInput = 0;

    page = 1;

    length = 20;

    maxPage = 0;

    allowPost = true;

    needLoadPage = false;

    category = DISCUSS_CATEGORY.BLOG_BOARD;

    states = [DISCUSS_STATE.OPEN];

    subject: ISubjectFull | null = null;

    showBlacklist = false;

    @Prop()
    entry!: IFeedEntry;

    @Watch('$route', { immediate: true })
    onRouteChanged(to: Route): void {
        this.data = [];
        this.loading = false;
        this.showBlacklist = false;

        if (to.params['messageID']) {
            this.loading = true;
            let messageId = to.params['messageID'];
            serverService.discuss
                .getRootArticleIndex(Number(messageId), this.states)
                .then((index) => {
                    this.$router.replace(
                        this.resolveRoute({
                            page: Math.floor(index.index / this.length) + 1,
                            hash: '#' + index.parent,
                        })
                    );
                })
                .catch((err) => {
                    console.error(err);
                });
        } else {
            this.page = Number(to.params['page']) || 1;

            if (!this.subject) {
                this.loading = true;
                this.needLoadPage = true;
                this.loadTotalPages().then(() => {
                    this.loading = false;
                    if (this.isViewable()) {
                        this.loadPage(this.page);
                    }
                });
            } else if (this.allowPost) {
                this.loadPage(this.page);
            }
        }
    }

    isViewable(): boolean {
        return this.allowPost || this.isPublicBoard();
    }

    resolveRoute(params: { [key: string]: any }): Location {
        return getBlogReadRoute(this.entry, params.page, params.hash);
    }

    mounted(): void {
        this.refreshAllowPost();
    }

    get routeResolver(): RouteResolver {
        return this;
    }
    getUpRoute(): UpRouteData | null {
        return null;
    }

    isAdminMessage(article: IArticle): boolean {
        return article.author.username == Constant.ADMIN_USERNAME;
    }

    onStateChange(event: IArticleAndReplies, index: number): void {
        this.data![index].article = event.article;
        this.data![index].replies = event.replies;
        this.$forceUpdate();
    }

    onRead(event: IArticleAndReplies, index: number): void {
        this.data![index].article = event.article;
        this.data![index].replies = event.replies;
        this.$forceUpdate();
    }

    displayInput(): void {
        if (this.allowPost) {
            if (!serverService.hasLoggedIn()) {
                dialogService.notLoginError(this.$route.fullPath);
            } else {
                this.showInputBox = !this.showInputBox;
            }
        }
    }

    isPublicBoard(): boolean {
        return !!this.subject && !this.subject.authType;
    }

    private loadPageKey(page: number): string {
        return [this.entry.id, page].join('/');
    }

    private setSubject(subject: ISubjectFull | null): void {
        this.subject = subject;
        this.$forceUpdate();
    }

    private loadTotalPages(): Promise<void> {
        this.setSubject(null);
        this.allowPost = false;
        this.showInputBox = false;
        if (this.category) {
            let loadKey = this.loadPageKey(-1);
            return serverService.discuss
                .getBlogRootArticlesTotal(this.entry, this.states)
                .then((data) => {
                    if (loadKey == this.loadPageKey(-1)) {
                        this.maxPage = Math.ceil(data.total / this.length);
                        this.setSubject(data.subject);
                        this.refreshAllowPost();
                    }
                })
                .catch((err) => {
                    dialogService.error({ body: errorToString(err) });
                });
        } else {
            return Promise.resolve();
        }
    }

    private refreshAllowPost(): void {
        this.allowPost = true;
    }

    loadPage(page: number): void {
        this.loading = true;
        this.needLoadPage = false;
        this.page = page;
        const start = this.length * (page - 1);
        const loadPageKey = this.loadPageKey(page);

        serverService.discuss
            .listBlogRootArticles(this.entry, this.states, start, this.length)
            .then((articles) => {
                if (loadPageKey == this.loadPageKey(this.page)) {
                    this.data = articles.map((article) => new BoardMessageData(article));
                    if (this.$route.hash) {
                        let searchHash = this.$route.hash.match(/^#([0-9]+)$/);
                        if (searchHash) {
                            this.$nextTick()
                                .then(() => this.$nextTick())
                                .then(() => this.moveToArticle(Number(searchHash![1])));
                        }
                    }
                }
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                this.loading = false;
            });
    }

    onPost(post: { title: string; content: string; state: DISCUSS_STATE }): void {
        if (!post) {
            return;
        }
        let loading = dialogService.loading();
        serverService.discuss
            .addBlogArticle(this.entry, post.content)
            .then((article) => {
                loading.close();
                this.resetInput += 1;
                this.showInputBox = false;
                if (this.$route.name?.startsWith('blog_read')) {
                    this.$router.push({
                        name: 'blog_read',
                        params: {
                            id: this.entry.id,
                        },
                    });
                } else {
                    this.data?.unshift(new BoardMessageData(article));
                    this.$forceUpdate();
                }
            })
            .catch((err) => {
                loading.close();
                dialogService.error({ title: errorToString(err) });
            });
    }

    moveToArticle(id: number): void {
        let refs = this.$refs[`article_${id}`] as BoardMessage[];
        if (refs && refs.length) {
            DomUtil.moveToElement(refs[0].$refs.hashTag);
        }
    }
}
