
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Location, Route } from 'vue-router';
import { errorToString } from '@/filters/content-filters';
import { translate } from '@/filters/translate';
import { durationToHours } from '@/filters/timestampToString';
import { dialogService } from '@/services/dialogService';
import { DISCUSS_CATEGORY, DISCUSS_STATE, DISCUSS_STATUS } from '@/services/discussService';
import { serverService } from '@/services/serverService';
import { DomUtil } from '@/utils/DomUtil';
import { RouteResolver, UpRouteData } from '@/utils/RouteResolver';
import { Constant } from '@/datas/Constant';
import InputBox from '@/forum/components/InputBox.vue';
import ReviewBox from '@/forum/reviews/ReviewBox.vue';
import RateStars from '@/components/RateStars.vue';
import Pagination from '@/components/Pagination.vue';
import CssPreloader from '@/components/CssPreloader.vue';
import GameInfor from '@/game/GameInfor.vue';
import GameNews from '@/forum/news/GameNews.vue';
import GameProgress from '../progress/GameProgress.vue';
import { PostInputData } from '../../datas/ForumCat';
import { AlertDialogController } from '../../components/dialogs/DialogProps';
import AreaTitle from '@/components/AreaTitle.vue';
import GameLinksButton from '@/components/GameLinksButton.vue';
import { IArticle, IReviewMeta } from '@/vo/Discuss';
import { IClient } from '@/vo/Client';
import TitleClient from '@/game/TitleClient.vue';
import { isUserTitleClient } from '../../filters/game-filters';

@Component({
    components: {
        InputBox,
        ReviewBox,
        Pagination,
        GameInfor,
        RateStars,
        GameNews,
        GameProgress,
        CssPreloader,
        AreaTitle,
        GameLinksButton,
        TitleClient,
    },
    methods: {
        translate,
        durationToHours,
        isUserTitleClient,
    },
})
export default class ReviewList extends Vue implements RouteResolver {
    public $refs!: Vue['$refs'] & {
        listTop: HTMLDivElement;
    };

    client: IClient = { code: '', name: '' };

    category = DISCUSS_CATEGORY.REVIEW;

    isManager = false;
    isClientManager = false;

    resetInput = 0;
    initInputBox = false;
    showInputBox = false;
    data: IArticle[] | null = null;
    maxPage = 0;
    length = 10;
    page = 1;
    reviewMeta?: IReviewMeta;
    allowPost = false;
    myLastReview?: IArticle;
    thisRate = 0;

    needPlayToReviewMessage = translate('review.message.needPlayToReview', {
        mins: Constant.REVIEW_MIN_PLAY_MINS,
    });

    @Watch('$route', { immediate: true })
    onRouteChange(to: Route): void {
        const clientCode = to.params.clientCode;
        const sameClient = this.client.code == clientCode;

        if (sameClient) {
            this.$nextTick(() => {
                DomUtil.moveToElement(this.$refs.listTop);
            });
        } else {
            this.client = {
                code: clientCode,
                name: '',
            };
            this.checkIsManager();
            this.checkAllowPost();
        }
        if (!sameClient) {
            this.setInputBoxVisible(false);
            this.loadTotalPages();
        }

        if (!this.client.name) {
            this.queryClient(this.client.code);
        }
        this.refreshPageTitle();

        this.page = Number(to.params['page']) || 1;

        this.loadPage(this.page);
    }

    private refreshPageTitle(): void {
        if (this.client && this.client.name) {
            document.title = this.client.name + ':' + translate('game.btn.review');
        } else {
            document.title = translate('localedTitle');
        }
    }

    get loading(): boolean {
        return !this.data;
    }

    getHavePlayedMessage(): string {
        const secs = this.reviewMeta ? this.reviewMeta.duration : 0;
        if (secs > 0) {
            const mins = Math.round(secs / 60);
            if (mins <= 1) {
                return translate('review.message.havePlayedOneMinute');
            } else if (mins < Constant.REVIEW_MIN_PLAY_MINS) {
                return translate('review.message.havePlayedMinutes', {
                    mins: mins,
                });
            } else {
                return translate('review.message.havePlayedAlmostMinutes', {
                    mins: mins,
                });
            }
        }
        return '';
    }

    setThiRate(rate: number): void {
        this.thisRate = rate;
    }

    checkAllowPost(): void {
        this.reviewMeta = undefined;
        this.allowPost = false;
        let clientCode = this.client.code;
        serverService.getInitData().then((initData) => {
            if (initData.me && clientCode == this.client.code) {
                serverService.discuss.getReviewMeta(this.client).then((result) => {
                    if (clientCode == this.client.code) {
                        this.reviewMeta = result.meta;
                        this.myLastReview = result.review;
                        this.thisRate = (this.myLastReview && this.myLastReview.rate) || 0;
                        this.allowPost = this.reviewMeta!.duration > Constant.REVIEW_MIN_PLAY_MINS * 60;
                        if (this.$route.hash == '#write') {
                            this.setInputBoxVisible(true);
                            DomUtil.moveToElement(this.$refs.listTop);
                        }
                        this.$forceUpdate();
                    }
                });
            }
        });
    }

    checkIsManager(): void {
        let clientCode = this.client.code;
        this.isManager = false;
        Promise.all([serverService.client.listGameMasters(clientCode), serverService.getInitData()]).then(() => {
            if (this.client.code == clientCode) {
                this.isManager = serverService.isGM() || serverService.isClientGM(clientCode);
                this.isClientManager = serverService.isClientGM(clientCode);
            }
        });
    }

    private queryClient(clientCode: string): void {
        serverService.client.queryClients([clientCode]).then((clients) => {
            // check if we are still in this client page
            if (this.client.code == clientCode) {
                if (clients.length) {
                    let client = clients[0];
                    this.client = client;
                    this.refreshPageTitle();
                } else {
                    dialogService.error({ body: 'No such game' }).then(() => {
                        this.$router.replace({ name: 'forums' });
                    });
                }
                this.$forceUpdate();
            }
        });
    }

    onStageChange(): void {
        this.loadPage(this.page);
    }

    private loadTotalPages(): void {
        let loadKey = this.loadPageKey(-1);
        this.maxPage = 0;
        serverService.discuss
            .getRootArticlesTotal(this.client, this.category, [DISCUSS_STATE.OPEN, DISCUSS_STATE.DIALOG, DISCUSS_STATE.CLOSE])
            .then((data) => {
                if (loadKey == this.loadPageKey(-1)) {
                    this.maxPage = Math.ceil(data.total / this.length);
                    if (this.page > Math.max(1, this.maxPage)) {
                        this.$router.replace(this.resolveRoute({ page: this.maxPage }));
                    }
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    loadPage(page: number): void {
        this.data = null;
        this.page = page;
        const start = this.length * (page - 1);
        const loadPageKey = this.loadPageKey(page);

        serverService.discuss
            .listRootArticles(
                this.client,
                this.category,
                [DISCUSS_STATE.OPEN, DISCUSS_STATE.DIALOG, DISCUSS_STATE.CLOSE],
                [DISCUSS_STATUS.OPEN],
                false,
                true,
                false,
                false,
                false,
                start,
                this.length
            )
            .then((data) => {
                if (loadPageKey == this.loadPageKey(this.page)) {
                    this.data = data;
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    private loadPageKey(page: number): string {
        return [this.client.code, this.category.code, page].join('/');
    }

    get routeResolver(): RouteResolver {
        return this;
    }
    getUpRoute(): UpRouteData | null {
        return null;
    }

    resolveRoute(params: { [key: string]: any }): Location {
        return {
            name: 'review_client_page',
            params: {
                page: params.page + '',
                clientCode: this.client.code,
                category: (this.category || DISCUSS_CATEGORY.FORUM).code,
            },
        };
    }

    toggleInputBox(): void {
        if (!serverService.hasLoggedIn()) {
            this.setInputBoxVisible(false);
            dialogService.notLoginError(this.$route.fullPath);
        } else {
            this.setInputBoxVisible(!this.showInputBox);
            this.showInputBox && this.allowPost && DomUtil.moveToElement(this.$refs.listTop);
        }
    }

    setInputBoxVisible(vis: boolean): void {
        this.showInputBox = vis;
        this.initInputBox = this.initInputBox || vis;
        this.resetInput += 1;
    }

    onPost(post: PostInputData): void {
        if (!post || !this.reviewMeta) {
            return;
        }
        if (!this.thisRate) {
            dialogService.error({
                body: translate('review.alert.emptyRate'),
            });
            return;
        }
        this.askEditOrNot(post, (edit, controller) => {
            controller.showLoading(translate('forum.postConfirm.posting'));
            serverService.discuss
                .addReview(this.client, post.title, post.content, this.thisRate, this.reviewMeta!, true)
                .then((article) => {
                    controller.dismiss();
                    this.myLastReview = article;
                    this.setInputBoxVisible(false);
                    if (this.page == 1) {
                        this.loadPage(this.page);
                    } else {
                        this.$router.push(this.resolveRoute({ page: 1 }));
                    }
                })
                .catch((err) => {
                    controller.dismiss();
                    dialogService.error({ title: errorToString(err) });
                });
        });
    }

    private askEditOrNot(post: PostInputData, cb: (edit: boolean, controller: AlertDialogController) => void): void {
        let lastReview = this.myLastReview;
        if (!lastReview) {
            this.forceEditChoise(false, 'any', cb);
        } else {
            let oneDay = 60000 * 60 * 24;

            let lastTime = lastReview.modifyTime || lastReview.createTime;
            let daysDiff = (serverService.serverTime - lastTime * 1000) / oneDay;

            if (daysDiff < 1) {
                // less than one day, overwrite it
                this.forceEditChoise(true, 'lessOneDay', cb);
            } else if (post.title == lastReview.title && post.content == lastReview.content) {
                // same content, overwrite it
                this.forceEditChoise(true, 'sameContent', cb);
            } else if (lastReview.rate != this.thisRate) {
                // rate is different, (and content is different), create new
                this.forceEditChoise(false, 'any', cb);
            } else {
                dialogService.choose(
                    {
                        title: translate('review.edit.title'),
                        body: translate('review.edit.message'),
                        titleIcon: 'fas fa-question-circle',
                    },
                    {
                        choices: [
                            {
                                value: 'new',
                                label: translate('review.edit.btn.new'),
                                color: 'primary',
                            },
                            {
                                value: 'edit',
                                label: translate('review.edit.btn.edit'),
                                color: 'success',
                            },
                        ],
                        ok: (controller) => {
                            cb(controller.getChoice().value == 'edit', controller);
                        },
                    }
                );
            }
        }
    }

    private forceEditChoise(
        edit: boolean,
        reason: string,
        cb: (edit: boolean, controller: AlertDialogController) => void
    ): void {
        let prefix = edit ? 'forceEditTrue' : 'forceEditFalse';
        dialogService.alert(
            {
                title: translate(`review.${prefix}.title`),
                body: translate(`review.${prefix}.message.${reason}`),
                titleIcon: 'fas fa-question-circle',
            },
            {
                okText: translate(`review.${prefix}.btnConfirm`),
                cancelText: translate('forum.postConfirm.btnCancel'),
                ok: (controller) => {
                    cb(edit, controller);
                },
            }
        );
    }
}
