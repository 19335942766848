
import { Component, Vue } from 'vue-property-decorator';
import { translate } from '@/filters/translate';
import gameDesignTitleIcon from '../assets/cg_design.svg';
import codingTitleIcon from '../assets/cg_code.svg';
import TwitterInfor from '@/components/TwitterInfor.vue';
import AreaTitle from '@/components/AreaTitle.vue';

@Component({
    components: {
        TwitterInfor,
        AreaTitle,
    },
    methods: {
        translate,
    },
})
export default class Coding extends Vue {
    compareUser = translate('cg.compareUser', { raw: true });
    shareFeatures = translate('cg.shareFeatures', { raw: true });
    compareVideo = translate('cg.compareVideo', { raw: true });

    titleIcons = [gameDesignTitleIcon, codingTitleIcon];

    mounted(): void {
        document.title = translate('localedTitle') + ':' + translate('cg.title');
    }
}
