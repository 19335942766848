
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { translate } from '@/filters/translate';
import { contentStripHTML } from '@/filters/content-filters';
import { getBlogRoute, getBlogReadRoute, convertEntryToFeedEntry } from '@/filters/blog-filters';
import { isMobile } from '@/filters/mobile';
import { Constant } from '@/datas/Constant';
import AreaTitle from '@/components/AreaTitle.vue';
import CssPreloader from '@/components/CssPreloader.vue';
import { serverService } from '../services/serverService';
import { ArrayUtil } from '../utils/ArrayUtil';
import { IFeedEntry } from '@/vo/Blog';
import { IClient } from '@/vo/Client';

let loadPage = 1 + Math.floor(Math.random() * 10);

@Component({
    methods: {
        translate,
        contentStripHTML,
        getBlogReadRoute,
        getBlogRoute,
        isMobile,
    },
    components: {
        CssPreloader,
        AreaTitle,
    },
})
export default class BlogFeed extends Vue {
    loading = true;

    feed: IFeedEntry[] = null!;

    commentTotals: { [key: string]: number } = null!;

    client: IClient = Constant.GAMELET_PRESS;

    @Prop({ default: 3 })
    size!: number;

    @Prop()
    title!: string;

    @Watch('$route', { immediate: true })
    onQueryChange(): void {
        this.getFeed(loadPage++).then((entries) => {
            if (entries.length < 10) {
                loadPage = 1;
            }
            let usedFeeds: IFeedEntry[] = [];
            while (entries.length && usedFeeds.length < this.size) {
                const feed = ArrayUtil.getRandomElement<IFeedEntry>(entries, true);
                feed && usedFeeds.push(feed);
            }
            this.feed = usedFeeds;
        });
    }

    private getFeed(page: number): Promise<IFeedEntry[]> {
        this.loading = true;
        return serverService.getBlogFeed(page).then((entryList) => {
            this.loading = false;
            return entryList.map((entry) => convertEntryToFeedEntry(entry));
        });
    }
}
