
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { serverService } from '@/services/serverService';
import { timestampToDate } from '@/filters/timestampToString';
import CssPreloader from '@/components/CssPreloader.vue';
import { translate } from '@/filters/translate';
import { errorToString } from '@/filters/content-filters';
import { dialogService } from '@/services/dialogService';
import { NotificationType } from '@/datas/NotificationType';
import { FriendshipStatus } from '@/datas/FriendshipStatus';
import { IFriednship, IUser } from '@/vo/User';

@Component({
    components: {
        CssPreloader,
    },
    methods: {
        translate,
    },
})
export default class Friendship extends Vue {
    friendship: IFriednship | null = null;
    friendshipTime = 0;

    showBtnRemove = false;

    @Prop()
    user!: IUser;

    @Prop()
    me?: IUser;

    @Emit('friendshipStatus')
    onFriendshipChanged(friendship: IFriednship | null): FriendshipStatus {
        this.friendship = friendship;
        return FriendshipStatus.getByCode(this.friendship?.status);
    }

    message = '';

    error = '';

    isBlackedByMe(): boolean {
        if (this.friendship!.status == FriendshipStatus.BLACKED.code) {
            return serverService.isMe(this.friendship!.requester);
        } else if (this.friendship!.status == FriendshipStatus.BLACKED_BOTH.code) {
            return true;
        } else {
            return false;
        }
    }
    isBlackedByUser(): boolean {
        if (this.friendship!.status == FriendshipStatus.BLACKED.code) {
            return serverService.isMe(this.friendship!.acceptor);
        } else {
            return false;
        }
    }
    isRejectedByMe(): boolean {
        if (this.friendship!.status == FriendshipStatus.REJECTED.code) {
            return serverService.isMe(this.friendship!.acceptor);
        } else {
            return false;
        }
    }

    refresh(): void {
        this.showBtnRemove = false;
        if (this.me && this.user && this.me.username != this.user.username) {
            if (
                !this.friendship ||
                Date.now() > this.friendshipTime ||
                this.friendship.requester.username != this.me.username ||
                this.friendship.acceptor.username != this.user.username
            ) {
                this.friendshipTime = Date.now() + 60000;
                serverService.user
                    .getFriendship(this.me, this.user)
                    .then((friendship) => {
                        this.onFriendshipChanged(friendship);
                        this.friendshipTime = Date.now() + 60000;
                        serverService.messaging.markRead(
                            [this.user!.username],
                            [NotificationType.FRIEND_REQUEST, NotificationType.FRIEND_GRANTED]
                        );
                    })
                    .catch((error) => {
                        this.onFriendshipChanged(null);
                        dialogService.error({ body: errorToString(error) });
                    });
            }
        } else {
            this.onFriendshipChanged(null);
        }
    }
    @Watch('user', { immediate: true })
    onUserChange(): void {
        this.refresh();
    }

    btnRemoveVisible(value: boolean): void {
        this.showBtnRemove = value;
    }

    get createDate(): string {
        return timestampToDate(this.friendship!.createTime * 1000);
    }

    get confirmDate(): string {
        return timestampToDate(this.friendship!.confirmTime * 1000);
    }

    private setFriendship(newStatus: FriendshipStatus): Promise<IFriednship | null> {
        this.error = '';
        let friendship: IFriednship = this.friendship!;
        let status = friendship.status;
        friendship.status = 'loading';
        return serverService.user
            .setFriendship(friendship, newStatus.code)
            .then(() => {
                this.onFriendshipChanged(friendship);
                return friendship;
            })
            .catch((err) => {
                this.error = err.response.statusText;
                friendship.status = status;
                this.onFriendshipChanged(friendship);
                return null;
            });
    }

    btnSendRequest(): void {
        dialogService.alert(
            {
                //title: 'Make friend?',
                body: translate('confirm-send-friend-request', {
                    target: this.user!.nickname,
                }),
            },
            {
                okText: translate('btn.send-friend-request'),
                cancelText: translate('btn.cancel'),
                ok: (controller) => {
                    controller.dismiss();
                    let users = [this.friendship!.requester, this.friendship!.acceptor];
                    this.friendship!.requester = users.find((u) => u.username != this.user!.username)!;
                    this.friendship!.acceptor = this.user!;
                    this.setFriendship(FriendshipStatus.REQUESTING);
                },
            }
        );
    }

    btnAccept(): void {
        this.setFriendship(FriendshipStatus.ACCEPTED);
    }
    btnReject(): void {
        this.setFriendship(FriendshipStatus.REJECTED);
    }

    btnRemove(): void {
        dialogService.alert(
            {
                //title: 'Make friend?',
                body: translate('confirm-remove-friend', {
                    target: this.user!.nickname,
                }),
            },
            {
                okText: translate('btn.remove-friend'),
                cancelText: translate('btn.cancel'),
                ok: (controller) => {
                    controller.dismiss();
                    this.setFriendship(FriendshipStatus.NONE);
                },
            }
        );
    }

    btnCancelRequest(): void {
        this.setFriendship(FriendshipStatus.NONE);
    }

    btnBlack(): void {
        this.setFriendship(FriendshipStatus.BLACKED);
    }
    btnUnblack(): void {
        this.setFriendship(FriendshipStatus.NONE);
    }
}
