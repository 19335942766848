
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { RouteResolver, UpRouteData } from '@/utils/RouteResolver';
import { translate } from '../filters/translate';

@Component({})
export default class Pagination extends Vue {
    @Prop({ default: 1 })
    page!: number;

    @Prop({ default: 1 })
    maxPage!: number;

    @Prop()
    routeResolver!: RouteResolver;

    pageList: number[] = [];
    pageListKey = '';

    @Watch('page', { immediate: true })
    onPageChange(): void {
        this.refreshPageList();
    }
    @Watch('maxPage', { immediate: true })
    onMaxPageChange(): void {
        this.refreshPageList();
    }

    upRouteData: UpRouteData | null = null;

    mounted(): void {
        this.upRouteData = this.routeResolver.getUpRoute();
    }

    private refreshPageList(): void {
        const page = this.page;
        const maxPage = this.maxPage;
        const key = `${page}/${maxPage}`;
        if (key != this.pageListKey) {
            this.pageListKey = key;
            if (this.maxPage) {
                let start = Math.max(1, page - 2);
                const end = Math.min(maxPage, start + 5);
                start = Math.max(1, end - 5);
                const list: number[] = [];
                for (let i = start; i <= end; ++i) {
                    list.push(i);
                }
                this.pageList = list;
            } else {
                this.pageList = [1];
            }
        }
    }

    get firstPage(): number {
        return this.pageList[0];
    }

    get lastPage(): number {
        return this.pageList[this.pageList.length - 1];
    }

    getUpTooltip(): string {
        return translate('forum.btn.backTo', { name: this.upRouteData!.name! });
    }
}
