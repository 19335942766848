import { serverService } from '../services/serverService';

const localeMessages: any = {
    'en-US': {},
    'zh-Hant': {},
    'zh-Hans': {},
};

function importTransJson(messages: any, json: { [key: string]: any }, prefix: string): void {
    for (const key in json) {
        const value = json[key];
        if (typeof value === 'string' || Array.isArray(value)) {
            messages[prefix + key] = value;
        } else {
            importTransJson(messages, value, prefix + key + '.');
        }
    }
}

export function importTranslation(locale: string, json: { [key: string]: any }): void {
    const messages = localeMessages[locale];
    if (messages) {
        importTransJson(messages, json, '');
    }
}

export function translate(value: string, params?: { [key: string]: any }): string {
    let pack = localeMessages[serverService.locale];
    let text: string | string[] = pack && pack[value];
    if (text === undefined && fallbackLocales[serverService.locale]) {
        for (let fallbackLocale of fallbackLocales[serverService.locale]) {
            pack = localeMessages[fallbackLocale];
            text = pack && pack[value];
            if (text !== undefined) {
                break;
            }
        }
    }
    if (text === undefined && serverService.locale != 'en') {
        pack = localeMessages['en'];
        text = pack && pack[value];
    }
    if (text === undefined) {
        if (params && params.default) {
            return params.default;
        }
        return value;
    }

    if (Array.isArray(text)) {
        if (params && params.raw) {
            return text as any;
        } else if (params && params.wrap) {
            text = text.map((line) => `<${params.wrap}>${line}</${params.wrap}>`).join('');
        } else {
            text = text.join((params && params.join) || '\n');
        }
    }

    if (params) {
        for (const key in params) {
            text = text.replace(new RegExp(`{${key}}`, 'g'), params[key]);
        }
    }
    return text;
}

const fallbackLocales = {
    'zh-Hans': ['zh-Hant'],
};
