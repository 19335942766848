
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { translate } from '@/filters/translate';
import { numberWithCommas } from '@/filters/number-filters';
import { getClientReviewRoute } from '../filters/game-filters';
import { Location } from 'vue-router';
import { IClient } from '@/vo/Client';

//declare const $: any;

@Component({
    methods: {
        translate,
        numberWithCommas,
    },
})
export default class RateStars extends Vue {
    @Prop({ default: 0 })
    rate!: number;

    @Prop({ default: 0 })
    votes!: number;

    @Prop({ default: 16 })
    size!: number;

    @Prop({ default: false })
    interactive!: boolean;

    @Prop({ default: false })
    showTips!: boolean;

    @Prop({ default: false })
    simple!: boolean;

    @Emit('select')
    emitSelect(): number {
        return this.displayRate;
    }

    @Prop()
    client?: IClient;

    stars: string[] = [];

    displayRate = 0;

    @Watch('rate', { immediate: true })
    onRateChange(): void {
        this.resetRate(this.rate);
    }

    get toRoute(): Location | null {
        if (this.client) {
            return getClientReviewRoute(this.client);
        } else {
            return {};
        }
    }

    private resetRate(rate: number): void {
        this.displayRate = rate;
        let stars: string[] = [];
        let maxRate = 5;
        let proxStar = Math.round(rate * 2) / 2;

        for (let i = 1; i <= maxRate; ++i) {
            if (proxStar >= i) {
                stars.push('fas fa-star');
            } else if (proxStar > i - 0.999) {
                stars.push('fas fa-star-half-alt');
            } else {
                stars.push('far fa-star');
            }
        }
        this.stars = stars;
    }

    get starsTip(): string {
        let rate = Math.round(this.rate * 100) / 100;
        if (this.votes) {
            return translate('review.label.rateAvg', {
                rate: rate,
                votes: this.votes,
            });
        } else {
            return translate('review.label.rate', { rate: rate });
        }
    }

    onPointerEnter(rate: number): void {
        if (this.interactive) {
            this.resetRate(rate);
        }
    }

    onPointerLeave(): void {
        this.resetRate(this.rate);
    }
}
