
import { Component, Prop } from 'vue-property-decorator';
import { serverService } from '@/services/serverService';
import BaseTabComp from './BaseTabComp';
import CssPreloader from '@/components/CssPreloader.vue';
import { getUserTooltip, getUserRoute } from '@/filters/user-filters';
import { translate } from '@/filters/translate';
import { NotificationType } from '../../../datas/NotificationType';
import { IUser } from '@/vo/User';

@Component({
    components: {
        CssPreloader,
    },
    methods: {
        getUserRoute,
        getUserTooltip,
        translate,
    },
})
export default class Friends extends BaseTabComp {
    @Prop({ default: 'friends' })
    listType?: string;

    @Prop({ default: 0 })
    total?: number;

    friends: IUser[] = [];

    loading = true;

    hasMore = false;

    countPerLoad = 30;

    friendsTotal = 0;

    mounted(): void {
        if (this.total) {
            this.friendsTotal = this.total;
            this.loadMore();
        } else {
            this.loading = true;
            this.getTotal().then((total) => {
                this.loading = false;
                this.friendsTotal = total;
                if (this.friendsTotal) {
                    this.loadMore();
                }
            });
        }
    }

    isTeam(user: IUser): boolean {
        return !!user['teamname'];
    }

    private getTotal(): Promise<number> {
        if (this.listType == 'invites') {
            return serverService.user.getFriendInvitesTotal(this.user!);
        } else if (this.listType == 'blacklist') {
            return serverService.user.getBlacklistTotal(this.user!);
        } else {
            return serverService.user.getFriendsTotal(this.user!);
        }
    }

    loadMore(): void {
        this.loading = true;
        this.hasMore = false;
        let promise: Promise<IUser[]>;
        if (this.listType == 'invites') {
            promise = serverService.user.listFriendInvites(this.user!, this.friends.length, this.countPerLoad);
        } else if (this.listType == 'blacklist') {
            promise = serverService.user.listBlacklist(this.user!, this.friends.length, this.countPerLoad);
        } else {
            promise = serverService.user.listFriends(this.user!, this.friends.length, this.countPerLoad);
        }
        promise.then((friends) => {
            this.friends = this.friends.concat(friends);
            this.loading = false;
            this.hasMore = friends.length >= this.countPerLoad;

            if (this.isMe && this.listType != 'invites') {
                serverService.messaging.markRead(
                    friends.map((f) => f.username),
                    [NotificationType.FRIEND_GRANTED]
                );
            }
        });
    }

    get noFriendsText(): string {
        return this.isMe ? `i-have-no-${this.listType}` : `he-has-no-${this.listType}`;
    }

    get friendsTotalText(): string {
        return this.isMe ? `my-${this.listType}-total` : `his-${this.listType}-total`;
    }
}
