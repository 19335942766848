declare const $: any;

type IPDATA = { v4: string; v6: string } | null;
let cachedIp: IPDATA = null;

export class NetUtil {
    /**
     * Get the value from the current page's url by the query key.
     * @param {string} key
     * @return {string}
     */
    public static getQueryStringValue(key: string): string {
        // eslint-disable-next-line
        return decodeURIComponent(
            window.location.search.replace(
                new RegExp('^(?:.*[&\\?]' + encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$', 'i'),
                '$1'
            )
        );
    }

    public static getIPs(): Promise<IPDATA> {
        if (cachedIp) {
            return Promise.resolve(cachedIp);
        }
        return getRTCPeerConnection(window)
            .then((conn) => _getIPs(conn))
            .then((ips) => {
                if (ips.length) {
                    cachedIp = {
                        v4: ips.find((ip) => ip.includes('.')) || '',
                        v6: ips.find((ip) => ip.includes(':')) || '',
                    };
                    if (!cachedIp.v4 && !cachedIp.v6) {
                        cachedIp = null;
                    }
                }
                return cachedIp;
            })
            .catch(() => {
                return null;
            });
    }

    public static encryptJson(json: unknown): string {
        try {
            let str = json ? btoa(JSON.stringify(json)) : '';
            if (str) {
                str = easyEncryptBase64(str);
            }
            return str;
        } catch (err) {
            return '';
        }
    }
}

function swapChars(str, chars) {
    str = str.replace(new RegExp(chars[0], 'g'), '#');
    str = str.replace(new RegExp(chars[1], 'g'), chars[0]);
    str = str.replace(/#/g, chars[1]);
    return str;
}

function easyEncryptBase64(str) {
    str = swapChars(str, ['0', '9']);
    str = swapChars(str, ['1', '2']);
    str = swapChars(str, ['a', 'Z']);
    str = swapChars(str, ['z', 'A']);
    str = swapChars(str, ['i', 'I']);
    return str;
}

let iframeForIP: HTMLIFrameElement | null = null;

function getRTCPeerConnectionFromWindow(window: any): any {
    //compatibility for firefox and chrome
    return window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
}

function getRTCPeerConnection(window: any): Promise<any> {
    //compatibility for firefox and chrome
    let RTCPeerConnection = getRTCPeerConnectionFromWindow(window);

    if (RTCPeerConnection) {
        return Promise.resolve(RTCPeerConnection);
    }
    //bypass naive webrtc blocking using an iframe
    //NOTE: you need to have an iframe in the page right above the script tag
    //
    //<iframe id="iframe" sandbox="allow-same-origin" style="display: none"></iframe>
    //<script>...getIPs called in here...
    //

    if (iframeForIP) {
        const win: any = iframeForIP.contentWindow;
        RTCPeerConnection = getRTCPeerConnectionFromWindow(win);
        return Promise.resolve(RTCPeerConnection);
    } else {
        return new Promise<any>((resolve) => {
            const iframeId = 'iframe' + Math.ceil(1000 + Math.random() * 100000).toString(36);
            const iframe = $(`<iframe id="${iframeId}" sandbox="allow-same-origin" style="display: none"></iframe>`);
            iframe.appendTo('body');
            iframe.ready(() => {
                iframeForIP = document.getElementById(iframeId) as HTMLIFrameElement;
                const win: any = iframeForIP.contentWindow;
                resolve(getRTCPeerConnectionFromWindow(win));
            });
        });
    }
}

function wait(duration: number): Promise<void> {
    return new Promise<void>((resolve) => {
        setTimeout(resolve, duration);
    });
}

function emptyFunc() {}

function isValidV4Address(ip_addr: string): boolean {
    const local_ip_prefix = ['192.168.', '172.', '10.', '127.'];
    if (ip_addr && ip_addr.indexOf('.') != -1) {
        return !local_ip_prefix.find((prefix) => ip_addr.indexOf(prefix) == 0);
    } else {
        return false;
    }
}

function _getIPs(RTCPeerConnection): Promise<string[]> {
    const ip_dups = {};

    //minimal requirements for data connection
    const mediaConstraints = {
        optional: [{ RtpDataChannels: true }],
    };

    const servers = {
        iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
    };

    //construct a new RTCPeerConnection
    let pc: any = null;
    try {
        pc = new RTCPeerConnection(servers, mediaConstraints);
    } catch (err) {
        return useCloudflare(ip_dups);
    }
    let foundV4 = false;

    function handleCandidate(candidate: string): void {
        //match just the IP address
        const ip_regex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{0,4}(:[a-f0-9]{0,4}){7})/;
        const ip_result = ip_regex.exec(candidate) as RegExpExecArray;
        if (ip_result) {
            const ip_addr = ip_result[1];
            if (ip_addr.includes(':')) {
                const ip6result = ip_addr.match(/::/g);
                if (ip6result && ip6result.length > 1) {
                    return;
                }
                if (ip_addr.match(/:::/)) {
                    return;
                }
            } else if (isValidV4Address(ip_addr)) {
                foundV4 = true;
            } else {
                return;
            }
            ip_dups[ip_addr] = true;
        }
    }

    //listen for candidate events
    pc.onicecandidate = function (ice) {
        //skip non-candidate events
        if (ice.candidate) handleCandidate(ice.candidate.candidate);
    };

    //create a bogus data channel
    pc.createDataChannel('rtc');

    //create an offer sdp
    pc.createOffer(function (result) {
        //trigger the stun server request
        pc.setLocalDescription(result, emptyFunc, emptyFunc);
    }, emptyFunc);

    return wait(1000).then(() => {
        //read candidate info from local description
        try {
            const lines = pc.localDescription.sdp.split('\n');

            lines.forEach(function (line) {
                if (line.indexOf('a=candidate:') === 0) handleCandidate(line);
            });
        } catch (err) {
            console.error(err);
        }

        if (iframeForIP) {
            $(iframeForIP).remove();
            iframeForIP = null;
        }

        if (foundV4) {
            return Object.keys(ip_dups);
        } else {
            return useCloudflare(ip_dups);
        }
    });
}

function useCloudflare(ip_dups) {
    return getIP_cloudflare()
        .then((ipv4) => {
            ip_dups[ipv4] = true;
            return Object.keys(ip_dups);
        })
        .catch(() => {
            return Object.keys(ip_dups);
        });
}

function getIP_cloudflare(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        $.get('https://www.cloudflare.com/cdn-cgi/trace')
            .done((data: string) => {
                const search = data.match(/ip=([^\n]+)(\n|$)/);
                if (search) {
                    resolve(search[1]);
                } else {
                    reject('not found');
                }
            })
            .fail((err) => {
                reject(err);
            });
    });
}
