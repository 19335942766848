import { IArticle } from '@/vo/Discuss';

export interface PageData {
    index: number;
    articles: IArticle[];
    loading: boolean;
    loaded: boolean;
    fading: 'out' | 'in';
}

interface PageDataCache {
    list: PageData[];
    expire: number;
}

const pageDataListCache: { [key: string]: PageDataCache } = {};

export function expireNewsPageData(clientCode: string): void {
    const cacheKey = clientCode || '';
    delete pageDataListCache[cacheKey];
}

export function getNewsPageData(clientCode: string, totalPages: number): PageData[] {
    const cacheKey = clientCode || '';
    let pageDataList = pageDataListCache[cacheKey];
    if (!pageDataList || pageDataList.expire < Date.now()) {
        pageDataList = pageDataListCache[cacheKey] = {
            list: [],
            expire: Date.now() + 60000 * 10, // 10 minutes
        };
    }
    const list = pageDataList.list;
    list.forEach((pageData) => {
        pageData.fading = 'out';
    });

    while (list.length < totalPages) {
        list.push(newPageData(list.length));
    }
    return list.slice(0, totalPages);
}

function newPageData(page: number): PageData {
    return {
        index: page,
        articles: [],
        loading: false,
        loaded: false,
        fading: 'out',
    };
}
