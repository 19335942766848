
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { translate } from '@/filters/translate';
import CssPreloader from '@/components/CssPreloader.vue';
import { serverService } from '../../services/serverService';
import { DISCUSS_CATEGORY, DISCUSS_STATE, DISCUSS_STATUS } from '../../services/discussService';
import { ArrayUtil } from '../../utils/ArrayUtil';
import {
    getClientPlayUrl,
    getClientNewsRoute,
    getClientRouteByCategory,
    getClientCoverBgImg,
    clientCodeToName,
    isUserTitleClient,
} from '@/filters/game-filters';
import { timestampToTimeAgo, newsWeeklyTitle } from '@/filters/timestampToString';
import { Location } from 'vue-router';
import { PageData, getNewsPageData } from '@/datas/NewsPageData';
import { isMobile } from '@/filters/mobile';
import AreaTitle from '@/components/AreaTitle.vue';
import { IClient } from '@/vo/Client';
import { IArticle } from '@/vo/Discuss';

declare const $: any;

@Component({
    components: {
        CssPreloader,
        AreaTitle,
    },
    methods: {
        getClientNewsRoute,
        getClientPlayUrl,
        getClientRouteByCategory,
        getClientCoverBgImg,
        timestampToTimeAgo,
        newsWeeklyTitle,
        translate,
        isMobile,
        isUserTitleClient,
    },
})
export default class GameNews extends Vue {
    // 0 based index
    currPageData: PageData = {
        index: -1,
        articles: [],
        loading: false,
        loaded: true,
        fading: 'out',
    };

    allCats = [DISCUSS_CATEGORY.NEWS, DISCUSS_CATEGORY.FORUM, DISCUSS_CATEGORY.REVIEW, DISCUSS_CATEGORY.REPORT];

    pageDataList: PageData[] = [];

    pageSize = 5;

    clientCode = '-';

    hover = false;

    isMounted = false;

    noAdvUntil = 0;

    @Prop()
    client?: IClient;

    @Prop({ default: 5 })
    totalPages!: number;

    mounted(): void {
        this.isMounted = true;
        if (this.totalPages > 1) {
            setTimeout(() => this.advancePageUpdate(), 1000 * 32);
        }
    }

    beforeDestroy(): void {
        this.isMounted = false;
    }

    get title(): string {
        if (this.client) {
            let name = clientCodeToName(this.client.code, DISCUSS_CATEGORY.NEWS.code);
            if (name) {
                return name;
            }
            if (isUserTitleClient(this.client)) {
                return translate('game.sysTitle.newsTitle');
            }
        }

        return translate('news.title.aGame');
    }

    getClientLinkName(client: IClient, cat: DISCUSS_CATEGORY): string {
        if (isUserTitleClient(client)) {
            if (cat == DISCUSS_CATEGORY.FORUM) {
                return translate('game.sysTitle.forumTitle');
            } else if (cat == DISCUSS_CATEGORY.NEWS) {
                return translate('game.sysTitle.newsTitle');
            }
        }
        return cat.name;
    }

    resetPages(): void {
        this.pageDataList = getNewsPageData(this.clientCode, this.totalPages);
    }

    private advancePageUpdate(): void {
        if (this.isMounted) {
            if (this.hover || Date.now() < this.noAdvUntil) {
                setTimeout(() => this.advancePageUpdate(), 8000);
            } else {
                let currPage = this.currPageData;
                let page = (currPage.index + 1) % this.pageDataList.length;
                let pageData = this.getPageData(page);
                this.loadPage(pageData).then((len) => {
                    if (currPage == this.currPageData && !this.hover) {
                        this.setPage(len ? page : 0);
                    }
                });
                setTimeout(() => this.advancePageUpdate(), Math.max(8000, 32000 - currPage.index * 4000));
            }
        }
    }

    @Watch('client', { immediate: true })
    onClientChange(): void {
        let clientCode = this.client ? this.client.code : '';
        if (this.clientCode != clientCode) {
            this.clientCode = clientCode;
            this.resetPages();
            this.setPage(0);
        }
    }

    getNewsLink(news: IArticle): Location {
        return {
            name: 'forum_client_post',
            params: {
                category: DISCUSS_CATEGORY.NEWS.code,
                clientCode: news.client!.code,
                articleId: news.id + '',
            },
        };
    }

    getPageData(page: number): PageData {
        return this.pageDataList[page];
    }

    setPageByUser(page: number): void {
        this.noAdvUntil = Date.now() + 20000;
        this.setPage(page);
    }

    setPage(page: number): void {
        let pageData = this.getPageData(page);
        this.loadPage(pageData);

        if (this.currPageData.index != -1) {
            $('.news-list .dropdown-toggle').dropdown('hide');
            this.currPageData.fading = 'out';

            serverService.wait(200).then(() => {
                this.currPageData = pageData;
                pageData.fading = 'in';
            });
        } else {
            this.currPageData = pageData;
            pageData.fading = 'in';
        }
    }

    loadPage(pageData: PageData): Promise<number> {
        if (pageData.articles.length || pageData.loading || pageData.loaded) {
            return this.$nextTick().then(() => pageData.articles.length);
        }
        let length = this.pageSize;
        let start = pageData.index * length;
        pageData.loading = true;
        return listLatestNews(this.client, start, length).then((list) => {
            pageData.loading = false;
            pageData.loaded = true;
            pageData.articles = list;
            return list.length;
        });
    }
}

function listLatestNews(client: IClient | undefined, start: number, length: number): Promise<IArticle[]> {
    if (client) {
        return serverService.discuss
            .listRootArticles(
                client,
                DISCUSS_CATEGORY.NEWS,
                [DISCUSS_STATE.OPEN],
                [DISCUSS_STATUS.OPEN],
                false,
                true,
                false,
                false,
                false,
                start,
                length
            )
            .then((list) => {
                list.forEach((article) => {
                    article.client = client;
                });
                return list;
            });
    } else {
        return serverService.discuss
            .listLatestClientsRootArticles(DISCUSS_CATEGORY.NEWS, [DISCUSS_STATE.OPEN], start, length)
            .then((list) => {
                let clientCodes: string[] = [];
                list.forEach((article) => {
                    ArrayUtil.addUniqueElement(clientCodes, article.clientCode);
                });
                return serverService.client.queryClients(clientCodes).then((clients) => {
                    let clientMap: { [key: string]: IClient } = {};
                    clients.forEach((client) => {
                        clientMap[client.code] = client;
                    });
                    return list.filter((article) => {
                        let client = clientMap[article.clientCode!];
                        if (client) {
                            article.client = client;
                            return true;
                        } else {
                            return false;
                        }
                    });
                });
            });
    }
}
