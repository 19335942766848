
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { serverService } from '@/services/serverService';
import { DISCUSS_CATEGORY, DISCUSS_ORDER, DISCUSS_STATE } from '@/services/discussService';
import { timestampToTimeAgo, timestampToDateTime, durationToHours } from '@/filters/timestampToString';
import { translate } from '@/filters/translate';
import { contentLinksToBlankTarget, errorToString } from '@/filters/content-filters';
import { getUserRoute, getUserTooltip } from '@/filters/user-filters';
import { dialogService } from '@/services/dialogService';
import CssPreloader from '@/components/CssPreloader.vue';
import RateStars from '@/components/RateStars.vue';
import { IArticle, IReviewMeta } from '@/vo/Discuss';
import { IClient } from '@/vo/Client';

@Component({
    components: {
        CssPreloader,
        RateStars,
    },
    methods: {
        timestampToTimeAgo,
        timestampToDateTime,
        durationToHours,
        translate,
        getUserRoute,
        getUserTooltip,
    },
})
export default class ReviewBox extends Vue {
    @Prop()
    client!: IClient;

    @Prop()
    data!: IArticle;

    @Prop()
    isManager!: boolean;

    @Prop({ default: 'open' })
    rootState;

    @Emit('stateChange')
    onStateChange(): IArticle {
        return this.data;
    }

    loading = false;

    historyList: IArticle[] = [];
    hasMore = true;
    countPerLoad = 10;

    public $refs!: Vue['$refs'] & {
        hashTag: HTMLAnchorElement;
    };

    get closed(): boolean {
        return DISCUSS_STATE.isCloseOrRemoved(this.data.state) || DISCUSS_STATE.isCloseOrRemoved(this.rootState);
    }
    get isViewable(): boolean {
        if (this.closed) {
            return false;
        } else if (this.isDialog) {
            return this.isManager || serverService.isMe(this.data.author);
        } else {
            return true;
        }
    }

    get isDialog(): boolean {
        return this.data.state == DISCUSS_STATE.DIALOG.code;
    }

    get thisClosed(): boolean {
        return DISCUSS_STATE.isCloseOrRemoved(this.data.state);
    }

    get closedByRoot(): boolean {
        return !DISCUSS_STATE.isCloseOrRemoved(this.data.state) && DISCUSS_STATE.isCloseOrRemoved(this.rootState);
    }

    getPlayDuration(article: IArticle): number {
        let meta = article.meta as IReviewMeta;
        return Math.ceil((meta.duration || 0) * 1000);
    }

    get title(): string {
        let title = this.data.title;
        if (this.closed) {
            if (this.isManager || serverService.isMe(this.data.author)) {
                title = `[${translate('forum.closed')}] ` + title;
            } else {
                title = `[${translate('forum.closed')}]`;
            }
        }
        return title;
    }

    get content(): string {
        let content = contentLinksToBlankTarget(this.data.content);
        if (this.closed) {
            if (this.isManager || serverService.isMe(this.data.author)) {
                return content;
            }
            return '';
        }
        return content;
    }

    loadHistory(): void {
        this.loading = true;
        serverService.discuss
            .getArticleAndReplies(
                this.client.code,
                DISCUSS_CATEGORY.REVIEW,
                this.data.id,
                DISCUSS_ORDER.TIME_DESC,
                this.historyList.length,
                this.countPerLoad
            )
            .then((result) => {
                this.historyList = this.historyList.concat(result.replies);
                this.hasMore = result.replies.length >= this.countPerLoad;
                this.loading = false;
            });
    }

    closeArticle(): void {
        this.openGMDialog(translate('forum.gmCloseArticle'), 'fas fa-times', (note) => {
            return serverService.discuss.closeArticle(this.data, note).then(() => {
                this.onStateChange();
            });
        });
    }
    openArticle(): void {
        this.openGMDialog(translate('forum.gmOpenArticle'), 'fas fa-undo', (note) => {
            return serverService.discuss.openArticle(this.data, note).then(() => {
                this.onStateChange();
            });
        });
    }

    private openGMDialog(title: string, titleIcon: string, action: (note: string) => Promise<void>): void {
        dialogService.input(
            {
                titleIcon: titleIcon,
                title: title,
            },
            {
                placeholder: translate('forum.gmNote'),
                okText: translate('btn.confirm'),
                cancelText: translate('btn.cancel'),
                ok: (controller) => {
                    controller.showLoading(translate('processing'));
                    action(controller.getInput())
                        .then(() => {
                            controller.dismiss();
                        })
                        .catch((err) => {
                            controller.hideLoading();
                            dialogService.error({ body: errorToString(err) });
                        });
                },
            }
        );
    }

    private openAlertDialog(title: string, titleIcon: string, action: () => Promise<void>): void {
        dialogService.alert(
            {
                titleIcon: titleIcon,
                title: title,
            },
            {
                placeholder: translate('forum.gmNote'),
                okText: translate('btn.confirm'),
                cancelText: translate('btn.cancel'),
                ok: (controller) => {
                    controller.showLoading(translate('processing'));
                    action()
                        .then(() => {
                            controller.dismiss();
                        })
                        .catch((err) => {
                            controller.hideLoading();
                            dialogService.error({ body: errorToString(err) });
                        });
                },
            }
        );
    }
}
