var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.isMobile())?_c('div',[_c('div',{staticClass:"title-bar"},[_c('AreaTitle',{staticClass:"title long",attrs:{"text":_vm.translate('forum.board.root.latestArticles')}}),_c('AreaTitle',{staticClass:"title short",attrs:{"text":_vm.translate('forum.board.root.latestArticlesShort')}}),_c('ul',{staticClass:"nav nav-pills"},_vm._l((_vm.navItems),function(item,index){return _c('li',{key:item.code,staticClass:"nav-item"},[_c('router-link',{class:{ 'nav-link': true, active: index == _vm.navIndex },attrs:{"to":{ name: 'home', query: { forum: item.code } }}},[_c('div',{staticClass:"nav-active"}),_c('div',{staticClass:"nav-label"},[_vm._v(" "+_vm._s(_vm.translate('forum.board.' + item.code + '.tab'))+" ")])])],1)}),0)],1),_c('div',{staticClass:"main-box"},[_c('div',{staticClass:"block-gap"}),_c('div',{key:_vm.updateKey,staticClass:"tab-content",attrs:{"id":"pills-tabContent"}},_vm._l((_vm.navItems),function(item,index){return _c('div',{key:item.code,class:{
                    'tab-pane': true,
                    fade: true,
                    show: index == _vm.navIndex,
                    active: index == _vm.navIndex,
                }},[_c('router-link',{staticClass:"btn-more btn-enter-forum",attrs:{"to":item.route}},[_vm._v(_vm._s(_vm.translate('forum.btn.enterForum')))]),(_vm.postsMap[item.code])?_c('div',{class:{
                        'post-list': 1,
                        'post-loading': _vm.postsMap[item.code].loading,
                    }},[(_vm.postsMap[item.code].loading)?_c('CssPreloader',{staticClass:"loading"}):_vm._e(),_vm._l((_vm.postsMap[item.code].articles),function(post,pi){return _c('PostListItem',{key:item.code + pi,attrs:{"article":post,"client":post.client,"showClient":item.isGamesForum(),"category":_vm.category,"unshowTop":true}})}),_c('router-link',{staticClass:"btn-more",attrs:{"to":item.route}},[_vm._v(" "+_vm._s(_vm.translate('btn.more'))+" "),_c('i',{staticClass:"fas fa-arrow-right"})])],2):_vm._e()],1)}),0)])]):_c('div',{staticClass:"mobile-root"},[_c('ul',{staticClass:"nav nav-pills"},_vm._l((_vm.navItems),function(item,index){return _c('li',{key:item.code,staticClass:"nav-item"},[_c('router-link',{class:{ 'nav-link': true, active: index == _vm.navIndex },attrs:{"to":{ name: 'home', query: { forum: item.code } }}},[_c('div',{staticClass:"nav-active"}),_c('div',{staticClass:"nav-label"},[_vm._v(" "+_vm._s(_vm.translate('forum.board.' + item.code + '.tab'))+" ")])])],1)}),0),_c('div',{staticClass:"main-box"},[_c('div',{key:_vm.updateKey,staticClass:"tab-content",attrs:{"id":"pills-tabContent"}},_vm._l((_vm.navItems),function(item,index){return _c('div',{key:item.code,class:{
                    'tab-pane': true,
                    fade: true,
                    show: index == _vm.navIndex,
                    active: index == _vm.navIndex,
                }},[(_vm.postsMap[item.code])?_c('div',{class:{
                        'post-list': 1,
                        'post-loading': _vm.postsMap[item.code].loading,
                    }},[(_vm.postsMap[item.code].loading)?_c('CssPreloader',{staticClass:"loading"}):_vm._e(),_vm._l((_vm.postsMap[item.code].articles),function(post,pi){return _c('PostListItem',{key:item.code + pi,attrs:{"article":post,"client":post.client,"showClient":item.isGamesForum(),"category":_vm.category,"unshowTop":true}})}),_c('router-link',{staticClass:"btn-more",attrs:{"to":item.route}},[_vm._v(" "+_vm._s(_vm.translate('forum.btn.enterForum'))+" "),_c('i',{staticClass:"fas fa-arrow-right"})])],2):_vm._e()])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }