
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Editor from '@tinymce/tinymce-vue';
import { dialogService } from '@/services/dialogService';
import { translate } from '@/filters/translate';
import { isContentEmpty } from '@/filters/content-filters';
import { DISCUSS_CATEGORY, DISCUSS_STATE } from '@/services/discussService';
import { ReportFormat } from '@/datas/ReportFormat';
import { serverService } from '../../services/serverService';
import { PostInputData } from '../../datas/ForumCat';
import { Constant } from '../../datas/Constant';
import { DomUtil } from '../../utils/DomUtil';
import { isDarkMode } from '@/filters/mobile';
import { getEditorContentStyles, getEditorLanguageUrl } from '@/filters/editors';
import { IArticle } from '@/vo/Discuss';
import { IClient } from '@/vo/Client';
import { ITeam } from '@/vo/User';

@Component({
    components: {
        Editor,
    },
    methods: {
        translate,
        getEditorContentStyles,
        getEditorLanguageUrl,
    },
})
export default class InputBox extends Vue {
    content = '';

    title = '';

    state: DISCUSS_STATE = DISCUSS_STATE.OPEN;

    @Prop({ default: '' })
    extraFunctionName?: string;

    @Prop()
    reportFormats?: ReportFormat[];

    selectedReportFormat?: ReportFormat;

    @Prop()
    defaultContent?: IArticle;

    @Prop({ default: 0 })
    resetInput!: number;

    @Prop({ default: true })
    titleRequired!: boolean;

    @Prop({})
    action!: string;

    @Prop({ default: 0 })
    parentArticleId!: number;

    @Prop()
    client?: IClient;

    @Prop()
    category!: DISCUSS_CATEGORY;

    @Prop()
    stateOptions?: DISCUSS_STATE[];

    @Prop()
    team?: ITeam;

    language = serverService.tinymceLocale;

    dark = false;

    rules = {
        [Constant.GAMELET_CHAT.code]: '/forum/GameletChat/post/317',
        [Constant.GAMELET_CHAT2.code]: '/forum/GameletChat/post/317',
        [Constant.GAMELET_GALLERY.code]: '/forum/GameletGallery/post/318',
    };

    mounted(): void {
        this.dark = isDarkMode();
    }

    @Watch('resetInput', { immediate: true })
    onResetInput(): void {
        this.dark = isDarkMode();

        if (this.defaultContent) {
            this.content = this.content || this.defaultContent.content;
            this.title = this.title || this.defaultContent.title;
        } else {
            this.content = '';
            this.title = '';
        }

        this.selectedReportFormat = undefined;
        if (this.stateOptions && this.stateOptions.length) {
            this.state = this.stateOptions[0];
        } else {
            this.state = DISCUSS_STATE.OPEN;
        }

        this.language = serverService.tinymceLocale;
    }

    @Emit('onPost')
    onPost(): PostInputData | void {
        let titleState = this.title.trim();
        let contentState = this.content.trim();
        if (this.isRoot) {
            if (isContentEmpty(titleState) && this.titleRequired) {
                dialogService.error({
                    body: translate('forum.alert.emptyTitle'),
                });
                return;
            }
        }
        if (isContentEmpty(contentState)) {
            dialogService.error({
                body: translate('forum.alert.emptyContent'),
            });
            return;
        }

        return {
            title: titleState,
            content: contentState,
            state: this.state,
        };
    }

    get showPostReminder(): boolean {
        return (
            !!this.client &&
            (this.client!.code == Constant.GAMELET_CHAT.code ||
                this.client!.code == Constant.GAMELET_CHAT2.code ||
                this.client!.code == Constant.GAMELET_GALLERY.code)
        );
    }

    get isRoot(): boolean {
        return !this.parentArticleId;
    }

    get isNews(): boolean {
        return this.category == DISCUSS_CATEGORY.NEWS;
    }
    get isReport(): boolean {
        return this.category == DISCUSS_CATEGORY.REPORT;
    }
    get isContribute(): boolean {
        return this.category == DISCUSS_CATEGORY.CONTRIBUTE;
    }

    getStateName(state: DISCUSS_STATE): string {
        return state.getOptionName(this.team ? 'teamBoard' : this.category.code);
    }
    getStateDesc(state: DISCUSS_STATE): string {
        return state.getDescription(this.team ? 'teamBoard' : this.category.code);
    }

    selectState(option: DISCUSS_STATE): void {
        this.state = option;
    }

    setReportFormat(format: ReportFormat): void {
        this.selectedReportFormat = format;
        let contents: { [key: string]: string } = {};
        if (this.client && this.client.code != Constant.SITE_CLIENT.code) {
            contents['client'] = `${this.client.name} (${this.client.code})`;
        }
        this.content = format.generateHtmlTemplate(contents);
    }

    markButton(editor: IEditor): void {
        editor.ui.registry.addIcon('sharp', DomUtil.SharpSvg);
        editor.ui.registry.addButton('markFloor', {
            icon: 'sharp',
            tooltip: translate('forum.markFloor'),
            onAction: () => {
                dialogService.input(
                    {
                        titleIcon: 'fas fa-hashtag',
                        title: translate('forum.markFloor'),
                    },
                    {
                        inputTypeIsNumber: true,
                        placeholder: translate('forum.floor'),
                        okText: translate('btn.confirm'),
                        cancelText: translate('btn.cancel'),
                        ok: (controller) => {
                            let controllerInput = controller.getInput();
                            if (controllerInput)
                                editor.insertContent(
                                    `<a href="#${controllerInput}" class="mceNonEditable">#${controllerInput}</a>`
                                );
                        },
                    }
                );
            },
        });
    }
}

interface IEditor {
    ui: any;
    insertContent: (content: string) => void;
}
