
import { Component, Vue } from 'vue-property-decorator';
import { translate } from '@/filters/translate';
import { numberWithCommas } from '@/filters/number-filters';
import { serverService } from '@/services/serverService';
import { Currency, PackageContentType } from '@/datas/PackageContentCode';
import CssPreloader from '@/components/CssPreloader.vue';
import MyDeposit from './MyDeposit.vue';
import { dialogService } from '@/services/dialogService';
import AreaTitle from '@/components/AreaTitle.vue';
import { IPaymentPackage } from '@/vo/PayProduct';
@Component({
    methods: {
        translate,
        numberWithCommas,
    },
    components: {
        CssPreloader,
        MyDeposit,
        AreaTitle,
    },
})
export default class Deposit extends Vue {
    packages: IPaymentPackage[] | null = null;

    currency: Currency = Currency.TWD;

    currencyList: Currency[] = Currency.listAll();

    gltDollarIcon = serverService.generateServerUrl('/img/store/glt_dollar.png');

    available = true;

    showContactUs = false;

    windowTarget = 'gltbuypoints';

    mounted(): void {
        if (this.available) {
            serverService.getInitData().then(() => {
                this.currency = serverService.isInTW() ? Currency.TWD : Currency.USD;
            });
            serverService.pay.listProductPackages(PackageContentType.POINTS).then((list) => {
                this.packages = list;
            });
        }
        if (window.opener) {
            this.windowTarget = '_self';
        }
        document.title = translate('localedTitle') + ':' + translate('pay.pageTitle');
    }
    getCompanyDisabledMessage(currency: Currency): string {
        return translate('pay.paymentDisabled', {
            company: translate('pay.paymentCompany.' + currency.paymentCompany[0] + '.name'),
        });
    }

    getPackageValue(paymentPackage: IPaymentPackage): number {
        return paymentPackage.contents[0].value;
    }

    getPackagePrice(paymentPackage: IPaymentPackage): number {
        return paymentPackage.price[this.currency.code];
    }

    getPackageUrl(paymentPackage: IPaymentPackage): string {
        let currency = this.currency;
        let payBy = currency.paymentCompany[0];
        return serverService.generateServerUrl(payBy.getPayUri(paymentPackage));
    }

    setCurrency(cur: Currency): void {
        this.currency = cur;
    }

    hasLoggedIn(): boolean {
        return serverService.hasLoggedIn();
    }

    onBtnBuy(event: MouseEvent, pkg: IPaymentPackage): void {
        if (!this.hasLoggedIn()) {
            event.preventDefault();
            dialogService.alert(
                {
                    title: translate('error.notLogin'),
                },
                {
                    okText: '',
                    cancelText: translate('btn.close'),
                }
            );
        } else if (this.currency.paymentCompany.length > 1) {
            event.preventDefault();
            dialogService.choosePayBy(pkg, this.currency.paymentCompany);
        }
    }

    toggleContactUs(): void {
        this.showContactUs = true;
    }
}
