import { serverService } from '../services/serverService';
import { IArticle } from '@/vo/Discuss';
import { numberWithCommas } from './number-filters';
import { translate } from './translate';

export function timestampToDate(timestamp: number, short?: boolean): string {
    const date = new Date(timestamp);
    let options: any = undefined;
    if (short) {
        options = { month: 'short', day: 'numeric' };
    }
    return date.toLocaleDateString(undefined, options);
}
export function timestampToDateTime(timestamp: number): string {
    const date = new Date(timestamp);
    return date.toLocaleString();
}

const maxSecond = 60;
const maxMinute = 60;
const maxHour = 24;
const yearToDays = 365.242199;
const monthToDays = 30.5;

export function timestampToTimeMore(timestamp: number): string {
    const diffTime = Math.max(0, timestamp - serverService.serverTime);
    const minutes = diffTime / 60000;
    if (minutes < 2) {
        return translate('time.aMinute');
    } else if (minutes < 100) {
        return translate('time.minutes', { minutes: Math.round(minutes) });
    } else {
        const hours = minutes / 60;
        if (hours < 36) {
            return translate('time.hours', { hours: Math.round(hours) });
        } else {
            const days = hours / 24;
            if (days < 720) {
                return translate('time.days', { days: Math.round(days) });
            } else {
                const years = days / yearToDays;
                return translate('time.years', { years: Math.round(years) });
            }
        }
    }
}

export function durationToHours(duration: number): string {
    const minutes = duration / 60000;
    if (minutes < 2) {
        return translate('time.aMinute');
    } else if (minutes < 100) {
        return translate('time.minutes', { minutes: Math.round(minutes) });
    } else {
        const hours = minutes / 60;
        if (hours < 10000) {
            return translate('time.hours', { hours: numberWithCommas(Math.round(hours)) });
        } else {
            const khours = Math.floor(hours / 1000);
            return translate('time.hours', { hours: numberWithCommas(khours) + 'k' });
        }
    }
}

export function timestampToTimeAgo(timestamp: number): string {
    let difference = Math.floor(Math.max(0, (serverService.serverTime - timestamp) / 1000));

    if (difference < maxSecond) {
        return translate('timeAgo.' + (difference > 1 ? 'secs' : 'sec'), {
            sec: difference,
        });
    }

    difference = Math.floor(difference / maxSecond);

    if (difference < maxMinute) {
        return translate('timeAgo.' + (difference > 1 ? 'mins' : 'min'), {
            min: difference,
        });
    }

    difference = Math.floor(difference / maxMinute);

    if (difference < maxHour) {
        return translate('timeAgo.' + (difference > 1 ? 'hours' : 'hour'), {
            hour: difference,
        });
    }

    difference = Math.floor(difference / maxHour);

    if (difference < yearToDays) {
        if (difference <= Math.ceil(monthToDays)) {
            return translate('timeAgo.' + (difference > 1 ? 'days' : 'day'), {
                day: difference,
            });
        }
        difference = Math.floor(difference / monthToDays);
        return translate('timeAgo.' + (difference > 1 ? 'months' : 'month'), {
            month: difference,
        });
    }

    difference = Math.floor(difference / yearToDays);

    return translate('timeAgo.' + (difference > 1 ? 'years' : 'year'), {
        year: difference,
    });
}

export function newsWeeklyTitle(article: IArticle): string {
    const start = timestampToDate(article.createTime * 1000);
    const end = article.modifyTime ? timestampToDate(article.modifyTime * 1000) : start;

    if (start == end) {
        return start;
    } else {
        return start + ' ' + translate('to') + ' ' + end;
    }
}

export function timestampToInputDate(timestamp: number): string {
    const time = new Date(timestamp);
    const yyyy = time.getFullYear();
    const mm = (time.getMonth() + 1 < 10 ? '0' : '') + (time.getMonth() + 1);
    const dd = (time.getDate() < 10 ? '0' : '') + time.getDate();
    return `${yyyy}-${mm}-${dd}`;
}
export function inputDateToDate(inputDate: string): Date {
    const search = inputDate.match(/(\d{4})-(\d{2})-(\d{2})/);
    const date = new Date();
    if (search) {
        date.setFullYear(Number(search[1]));
        date.setMonth(Number(search[2]) - 1);
        date.setDate(Number(search[3]));
    }
    return date;
}
