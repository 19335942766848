
import { Component, Vue, Watch } from 'vue-property-decorator';
import { translate } from '@/filters/translate';
import { showSideAds } from '@/filters/mobile';
import { getBlogRoute, getBlogReadRoute, convertEntryToFeedEntry } from '@/filters/blog-filters';
import { Constant } from '@/datas/Constant';
import GameNews from '@/forum/news/GameNews.vue';
import AreaTitle from '@/components/AreaTitle.vue';
import Epub from './Epub.vue';
import CssPreloader from '@/components/CssPreloader.vue';
import AdsResponsive from '@/components/ads/AdsResponsive.vue';
import { serverService } from '../services/serverService';
import { Route } from 'vue-router';
import { DISCUSS_STATE } from '../services/discussService';
import { IFeedEntry } from '@/vo/Blog';
import { IClient } from '@/vo/Client';

let feedCache: { feed: IFeedEntry[]; expire: number } = null!;

@Component({
    methods: {
        translate,
        getBlogReadRoute,
        getBlogRoute,
        showSideAds,
    },
    components: {
        CssPreloader,
        GameNews,
        AreaTitle,
        Epub,
        AdsResponsive,
    },
})
export default class Blog extends Vue {
    loading = false;

    feed: IFeedEntry[] = null!;

    commentTotals: { [key: string]: number } = null!;

    client: IClient = Constant.GAMELET_PRESS;

    page = 1;

    hasMore = false;

    mounted(): void {
        document.title = translate('localedTitle') + ':' + translate('blog.intro.title');
    }

    @Watch('$route', { immediate: true })
    onQueryChange(newVal: Route): void {
        this.page = Number(newVal.params.page || 1);
        this.getFeed(this.page).then((entries) => {
            this.hasMore = entries.length >= 10;
            this.feed = entries;
            this.loadCommentsTotal(entries);
        });
    }

    private loadCommentsTotal(entries: IFeedEntry[]): Promise<void> {
        this.commentTotals = null!;
        return serverService.discuss.listBlogRootArticlesTotal(entries, [DISCUSS_STATE.OPEN]).then((data) => {
            if (this.feed == entries) {
                this.commentTotals = data;
            }
        });
    }

    public isCommentsLoading(): boolean {
        return !this.commentTotals;
    }
    public getCommentsTotal(blogId: string): number {
        return (this.commentTotals && this.commentTotals[blogId]) || 0;
    }

    private getFeed(page: number): Promise<IFeedEntry[]> {
        if (feedCache && Date.now() > feedCache.expire) {
            return Promise.resolve(feedCache.feed);
        }
        this.loading = true;
        return serverService.getBlogFeed(page).then((entryList) => {
            this.loading = false;
            feedCache = {
                feed: entryList.map((entry) => convertEntryToFeedEntry(entry)),
                expire: Date.now() + 60000 * 10, // 10 minutes
            };
            return feedCache.feed;
        });
    }
}
