import { importTranslation } from '../translate';

importTranslation('zh-Hans', {
    game: {
        recommendGames: '推荐游戏',
        allGames: '所有游戏',
        betaGames: 'Beta游戏区',
        onlinePpl: '目前 {ppl} 人在线',
        trailer: '游戏预告片',
        trailer2: '预告片',
        seeMore: {
            title: '看更多游戏!',
            subtitle: '看看设计师们发表的作品',
        },
        btn: {
            play: '马上玩',
            votes: '{votes}则评论',
            report: '回报问题',
            forum: '游戏讨论',
            viewClientGM: '查看游戏管理员',
            news: '更新与公告',
            review: '分析评论',
        },
        forum: {
            title: '聊聊这个游戏',
            officialTitle: '讨论串',
        },
        report: {
            title: '问题回报',
            officialTitle: '问题回报',
        },
        contribute: {
            title: '投稿专区',
            officialTitle: '稿件',
        },
        review: {
            title: '分析评论',
            officialTitle: '评论',
        },
        manager: {
            title: '管理员名单',
            nobody: '这个游戏还没有管理员',
        },
        news: {
            title: '更新与公告',
            officialTitle: '公告',
        },
        order: {
            new: '最新',
            rate: '好评',
            ppl: '线上人数',
        },
        betaMessages: [
            '欢迎来到Beta游戏区，这里是尚未完成测试的游戏集散地。 ',
            '嘎姆的游戏需要拥有足够的玩家评论才能正式上架。 如果在这儿找到值得一提的游戏，请留下你的评论让更多人可以看见这些游戏。 ',
            '在这一页点击游戏接着按下星星就可以前往游戏的评论区（您需要游玩游戏{min}分钟以上才能发表评论）。 ',
        ],
        sysTitle: {
            btn: {
                toggleSys: '切换成赛事',
                toggleGame: '切换回游戏',
            },
            eventsTitle: '嘎姆赛事',
            forumTitle: '讨论赛事',
            newsTitle: '赛事公告',
            labelOrganizer: '主办成员',
            labelMembers: '主办成员',
            title: '玩家头衔设定',
            gameName: '赛事名称',
            gameTime: '比赛日期',
            gameDesc: '赛事介绍',
            gamePpls: '参赛人数',
            addItem: '增加头衔',
            itemName: '头衔称号',
            addUserItem: '赋予玩家头衔',
            gameResult: '赛事结果',
            addVideo: '增加实况',
            videoUrl: 'Youtube网址',
            status: {
                pub: '公开',
                hidden: '隐藏',
                sponsor: '主办',
            },
        },
    },
    gameBadges: {
        title: '游戏勋章',
        owned: '已获得勋章',
        missing: '还没得到的勋章',
    },
    gameBadge: {
        srcGame: '勋章出自',
        timeGet: '你在 {firstTime} 获得了这枚勋章',
        firstGet: '你 {firstTime} 第一次获得这枚勋章',
        lastGet: '截至 {lastTime} 共取得 {count} 次',
        awardPercent: '约有 {percentage}% 的玩家拥有这枚勋章',
        notOwned: '你尚未获得这枚勋章',
        rarity: {
            common: '标准配备',
            uncommon: '非同寻常',
            rare: '稀有勋章',
            epic: '史诗勋章',
            legendary: '传奇勋章',
        },
    },
});

export const game_translation_cn = 1;
