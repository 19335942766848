import { Location } from 'vue-router';
import { translate } from '../filters/translate';
import { DISCUSS_CATEGORY, DISCUSS_STATE, DISCUSS_STATUS } from '../services/discussService';
import { serverService } from '../services/serverService';
import { ArrayUtil } from '../utils/ArrayUtil';
import { Constant } from './Constant';
import { ReportFormat } from './ReportFormat';
import { ResourceCategory } from './ResourceCategory';
import { IUser } from '@/vo/User';
import { IClient } from '@/vo/Client';
import { IArticle } from '@/vo/Discuss';

const all: ForumCat[] = [];
interface ICoverImage {
    url: string;
    user?: IUser;
    expire: number;
}

export class ForumCatTarget {
    static PLAYER = new ForumCatTarget('player', true, true);
    static REPORTER = new ForumCatTarget('reporter', true, true);
    static GM = new ForumCatTarget('gm', false, false);
    static GEDITOR = new ForumCatTarget('geditor', false, false);
    static VIEWER = new ForumCatTarget('write', true, false);

    constructor(public code: string, public publicView: boolean, public publicPost: boolean) {
        //
    }
}

export class ForumCat {
    static GOSSIP = new ForumCat(
        Constant.GAMELET_CHAT.code,
        ForumCatTarget.PLAYER,
        {
            name: 'forum_client',
            params: {
                clientCode: Constant.GAMELET_CHAT.code,
                category: DISCUSS_CATEGORY.FORUM.code,
            },
        },
        {
            coverResourceCategory: ResourceCategory.CHAT_COVER,
            icon: 'fas fa-comments',
        }
    );
    static GOSSIP2 = new ForumCat(
        Constant.GAMELET_CHAT2.code,
        ForumCatTarget.PLAYER,
        {
            name: 'forum_client',
            params: {
                clientCode: Constant.GAMELET_CHAT2.code,
                category: DISCUSS_CATEGORY.FORUM.code,
            },
        },
        {
            coverResourceCategory: ResourceCategory.CHAT_COVER,
            notPublic: true,
            icon: 'fas fa-comments',
        }
    );
    static GAMES = new ForumCat(
        'games',
        ForumCatTarget.PLAYER,
        {
            name: 'forum_games',
        },
        {
            coverResourceCategory: ResourceCategory.GAMES_COVER,
            icon: 'fas fa-gamepad',
        }
    );
    static GALLERY = new ForumCat(
        Constant.GAMELET_GALLERY.code,
        ForumCatTarget.PLAYER,
        {
            name: 'forum_client',
            params: {
                clientCode: Constant.GAMELET_GALLERY.code,
                category: DISCUSS_CATEGORY.FORUM.code,
            },
        },
        {
            coverResourceCategory: ResourceCategory.GALLERY_COVER,
            icon: 'fas fa-image',
        }
    );
    static REPORT = new ForumCat(
        Constant.SITE_CLIENT.code,
        ForumCatTarget.REPORTER,
        {
            name: 'forum_client',
            params: {
                clientCode: Constant.SITE_CLIENT.code,
                category: DISCUSS_CATEGORY.REPORT.code,
            },
        },
        {
            formats: [ReportFormat.ARTICLE_ABUSE, ReportFormat.GAME_ABUSE, ReportFormat.SITE_BUG],
        }
    );
    static SITE_NEWS = new ForumCat(
        Constant.SITE_CLIENT.code,
        ForumCatTarget.VIEWER,
        {
            name: 'forum_client',
            params: {
                clientCode: Constant.SITE_CLIENT.code,
                category: DISCUSS_CATEGORY.NEWS.code,
            },
        },
        {}
    );
    static GM_DISCUSS = new ForumCat(
        Constant.SITE_CLIENT.code,
        ForumCatTarget.GM,
        {
            name: 'gm_discuss',
            params: {
                clientCode: Constant.SITE_CLIENT.code,
                category: DISCUSS_CATEGORY.FORUM.code,
            },
        },
        {}
    );
    static GAMELET_PRESS = new ForumCat(
        Constant.GAMELET_PRESS.code,
        ForumCatTarget.GEDITOR,
        {
            name: 'forum_client',
            params: {
                clientCode: Constant.GAMELET_PRESS.code,
                category: DISCUSS_CATEGORY.FORUM.code,
            },
        },
        {}
    );
    static GAMELET_PRESS_CONTRIBUTE = new ForumCat(
        Constant.GAMELET_PRESS.code,
        ForumCatTarget.PLAYER,
        {
            name: 'forum_client',
            params: {
                clientCode: Constant.GAMELET_PRESS.code,
                category: DISCUSS_CATEGORY.CONTRIBUTE.code,
            },
        },
        {}
    );
    static GAMELET_PRESS_NEWS = new ForumCat(
        Constant.GAMELET_PRESS.code,
        ForumCatTarget.VIEWER,
        {
            name: 'forum_client',
            params: {
                clientCode: Constant.GAMELET_PRESS.code,
                category: DISCUSS_CATEGORY.NEWS.code,
            },
        },
        {}
    );

    static listPublic(): ForumCat[] {
        return all.filter(
            (f) => f.category == DISCUSS_CATEGORY.FORUM.code && f.target == ForumCatTarget.PLAYER && !f.options.notPublic
        );
    }

    static listByCode(clientCode: string): ForumCat[] {
        return all.filter((f) => f.code == clientCode && f.target != ForumCatTarget.GM);
    }
    static getByCode(code: string): ForumCat | undefined {
        return all.find((f) => f.code == code);
    }

    static getByCoverCategory(category: ResourceCategory): ForumCat | undefined {
        return all.find((f) => f.options.coverResourceCategory == category && !f.options.notPublic);
    }

    private coverImage: ICoverImage = {
        url: '/img/resource-default.png',
        expire: 0,
    };

    constructor(
        public code: string,
        public target: ForumCatTarget,
        public route: Location,
        public options: {
            postBtnLabel?: string;
            coverResourceCategory?: ResourceCategory;
            formats?: ReportFormat[];
            notPublic?: boolean;
            icon?: string;
        }
    ) {
        all.push(this);
    }

    get name(): string {
        return translate(`forum.board.${this.code}.title.${this.category}`);
    }

    get category(): string {
        return (this.route.params && this.route.params.category) || DISCUSS_CATEGORY.FORUM.code;
    }

    // get coverImageUri(): string {
    //     const cat =
    //         this.options.coverResourceCategory || ResourceCategory.GAMES_COVER;
    //     return '/gassets/rsrc/' + Constant.SITE_CLIENT.code + '/' + cat.code;
    // }

    private getCoverImage(): ICoverImage {
        const cat = this.options.coverResourceCategory || ResourceCategory.GAMES_COVER;
        const key = 'cimg_' + Constant.SITE_CLIENT.code + '/' + cat.code;

        let coverImage = this.coverImage;
        if (!coverImage.expire) {
            let needReload = true;
            try {
                const json = localStorage.getItem(key);
                if (json) {
                    coverImage = JSON.parse(json);
                    needReload = Date.now() > coverImage.expire;
                }
            } catch (err) {
                console.error(err);
            }

            if (needReload) {
                coverImage.expire = Date.now() + 60000;
                serverService.resource.getCoverImageResource(Constant.SITE_CLIENT.code, cat).then((resource) => {
                    if (resource) {
                        coverImage.expire = Date.now() + 60000 * 10;
                        coverImage.url = '/gassets/' + resource.url;
                        coverImage.user = resource.user;
                        localStorage.setItem(key, JSON.stringify(coverImage));
                    }
                });
            }
        }
        return coverImage;
    }

    get coverImageUrl(): string {
        return serverService.generateServerUrl(this.getCoverImage().url);
    }
    get coverImageAuthor(): IUser | undefined {
        return this.getCoverImage().user;
    }

    get clientCode(): string | undefined {
        return this.route.params && this.route.params.clientCode;
    }

    getPostRoute(articleId: number, page?: number): Location {
        const location = {
            name: 'forum_client_post',
            params: {
                category: this.category,
                clientCode: this.clientCode!,
                articleId: articleId + '',
            },
        };
        if (page) {
            location.name = 'forum_client_post_page';
            Object.assign(location.params, {
                page: page,
            });
        }
        return location;
    }

    public isGamesForum(): boolean {
        return this == ForumCat.GAMES;
    }

    listRootArticles(states: DISCUSS_STATE[], start: number, length: number): Promise<IArticle[]> {
        if (this == ForumCat.GAMES) {
            return serverService.discuss
                .listLatestClientsArticles(DISCUSS_CATEGORY.FORUM, states, start, length)
                .then((list) => {
                    const clientCodes: string[] = [];
                    list.forEach((article) => ArrayUtil.addUniqueElement(clientCodes, article.clientCode));
                    return serverService.client.queryClients(clientCodes).then((clients) => {
                        const clientsMap: { [key: string]: IClient } = {};
                        clients.forEach((c) => (clientsMap[c.code] = c));
                        list.forEach((article) => {
                            article.client = clientsMap[article.clientCode!];
                        });
                        return list;
                    });
                });
        } else {
            return serverService.discuss
                .listRootArticles(
                    this,
                    DISCUSS_CATEGORY.FORUM,
                    states,
                    [DISCUSS_STATUS.OPEN],
                    true,
                    false,
                    false,
                    true,
                    true,
                    start,
                    length
                )
                .then((list) => {
                    list.forEach((article) => {
                        article.client = this;
                    });
                    return list;
                });
        }
    }
}

export interface PostInputData {
    title: string;
    content: string;
    state: DISCUSS_STATE;
}
