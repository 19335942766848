
import { Component, Prop, Vue } from 'vue-property-decorator';
import { translate } from '../../filters/translate';
import { ITitleUserItem } from '@/vo/PayProduct';
import { timestampToInputDate } from '../../filters/timestampToString';
import { getClientForumRoute } from '../../filters/game-filters';
import { numberWithCommas } from '../../filters/number-filters';

@Component({
    methods: {
        getClientForumRoute,
    },
})
export default class UserTitleBox extends Vue {
    @Prop()
    titleItem!: ITitleUserItem;

    getTitleItemTooltip(): string {
        const titleItem = this.titleItem;
        return [
            translate('game.sysTitle.gameName') + ': ' + titleItem.game.name,
            translate('game.sysTitle.gameTime') + ': ' + timestampToInputDate(titleItem.game.rate!.start),
            translate('game.sysTitle.gamePpls') + ': ' + numberWithCommas(titleItem.game.rate!.votes),
        ].join('\n');
    }
}
