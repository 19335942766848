
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { serverService } from '@/services/serverService';
import { DISCUSS_STATE } from '@/services/discussService';
import { timestampToTimeAgo, timestampToDate, timestampToDateTime, newsWeeklyTitle } from '@/filters/timestampToString';
import { translate } from '@/filters/translate';
import { isMobile } from '@/filters/mobile';
import { contentLinksToBlankTarget } from '@/filters/content-filters';
import { getUserRoute, getUserTooltip } from '@/filters/user-filters';
import CssPreloader from '@/components/CssPreloader.vue';
import ManageButtons from './ManageButtons.vue';
import { IArticle } from '@/vo/Discuss';
import { IClient } from '@/vo/Client';
import { IUser } from '@/vo/User';

@Component({
    components: {
        CssPreloader,
        ManageButtons,
    },
    methods: {
        timestampToTimeAgo,
        timestampToDate,
        timestampToDateTime,
        isMobile,
        translate,
        getUserRoute,
        getUserTooltip,
        newsWeeklyTitle,
    },
})
export default class ForumArticleBox extends Vue {
    @Prop()
    data!: IArticle;

    @Prop()
    client!: IClient;

    @Prop()
    rootData!: IArticle;

    @Prop()
    isRoot!: boolean;

    @Prop()
    isRooter!: boolean;

    @Prop()
    isNews!: boolean;

    @Prop()
    idx!: number;

    @Prop()
    isManager!: boolean;

    @Prop()
    webMasterList!: IUser[];

    contentMaxHeight = 1000;
    canHide = false;
    isHidden = false;

    @Emit('stateChange')
    onStateChange(): IArticle {
        return this.data;
    }

    public $refs!: Vue['$refs'] & {
        hashTag: HTMLAnchorElement;
        content: HTMLAnchorElement;
    };

    mounted(): void {
        this.matchHeight();
        this.matchHeight = this.matchHeight.bind(this);
        window.addEventListener('scroll', this.matchHeight);
    }

    destroyed(): void {
        window.removeEventListener('scroll', this.matchHeight);
    }

    matchHeight(): void {
        let height: number = this.$refs.content.clientHeight;
        if (height > this.contentMaxHeight && !this.canHide) {
            this.canHide = true;
            this.isHidden = true;
            window.removeEventListener('scroll', this.matchHeight);
        }
    }

    showContent(show: boolean): void {
        this.isHidden = !show;
    }

    get closed(): boolean {
        return DISCUSS_STATE.isCloseOrRemoved(this.data.state) || DISCUSS_STATE.isCloseOrRemoved(this.rootData.state);
    }
    get isViewable(): boolean {
        if (this.closed) {
            return false;
        } else if (this.isDialog) {
            return this.isManager || serverService.isMe(this.rootData.author) || serverService.isMe(this.data.author);
        } else {
            return true;
        }
    }

    get isDialog(): boolean {
        return this.data.state == DISCUSS_STATE.DIALOG.code || this.rootData.state == DISCUSS_STATE.DIALOG.code;
    }

    get closedByRoot(): boolean {
        return !DISCUSS_STATE.isCloseOrRemoved(this.data.state) && DISCUSS_STATE.isCloseOrRemoved(this.rootData.state);
    }

    checkIsWebMaster(whichManager: IUser): boolean {
        /* if webMasterList not loaded yet, show webMaster closed */
        if (!this.webMasterList.length) return true;

        for (let i = 0; i < this.webMasterList.length; i++) {
            if (this.webMasterList[i].username == whichManager.username) return true;
        }
        return false;
    }

    get title(): string {
        let title = this.data.title;
        if (this.closed) {
            if (this.isManager || serverService.isMe(this.data.author)) {
                title = `[${translate('forum.closed')}] ` + title;
            } else {
                title = `[${translate('forum.closed')}]`;
            }
        }
        return title;
    }

    get content(): string {
        let content = contentLinksToBlankTarget(this.data.content);
        if (this.closed) {
            if (this.isManager || serverService.isMe(this.data.author)) {
                return content;
            }
            return '';
        }
        return content;
    }
}
