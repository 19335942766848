
import { Component, Watch } from 'vue-property-decorator';
import { Location, Route } from 'vue-router';
import { RouteResolver, UpRouteData } from '@/utils/RouteResolver';
import { serverService } from '@/services/serverService';
import { translate } from '@/filters/translate';
import BaseTabComp from './BaseTabComp';
import CssPreloader from '@/components/CssPreloader.vue';
import { ArrayUtil } from '../../../utils/ArrayUtil';
import { DISCUSS_CATEGORY } from '../../../services/discussService';
import UserArticlesMsg from './UserArticlesMsg.vue';
import Pagination from '@/components/Pagination.vue';
import { IArticlesWithParent } from '@/vo/Discuss';
import { IUser } from '@/vo/User';
import { IClient } from '@/vo/Client';

@Component({
    components: {
        CssPreloader,
        Pagination,
        UserArticlesMsg,
    },
    methods: {
        translate,
    },
})
export default class UserArticles extends BaseTabComp implements RouteResolver {
    categorys: DISCUSS_CATEGORY[] = [
        DISCUSS_CATEGORY.FORUM,
        DISCUSS_CATEGORY.USER_BOARD,
        DISCUSS_CATEGORY.REPORT,
        DISCUSS_CATEGORY.CONTRIBUTE,
    ];
    listCategory: DISCUSS_CATEGORY = this.categorys[0];

    page = 1;
    length = 20;
    maxPage = 0;
    loadingKey = '';
    list: IArticlesWithParent[] = [];
    userCatKey = '';

    get loading(): boolean {
        return !!this.loadingKey;
    }

    @Watch('$route', { immediate: true })
    onRouteChanged(to: Route): void {
        this.list = [];
        this.listCategory = DISCUSS_CATEGORY.getByCode(to.params['category']) || this.categorys[0];

        if ((serverService.isMe(this.user) && !this.user?.guest) || serverService.isGM()) {
            this.page = Number(to.params['page']) || 1;
            this.loadTotalPages();
            this.loadPage(this.page);
        } else {
            this.$router.replace({ name: 'user_about' });
            this.maxPage = 0;
            this.page = 1;
        }
    }

    getLastReplyRoute(data: IArticlesWithParent): Location {
        if (this.listCategory == DISCUSS_CATEGORY.USER_BOARD) {
            return {
                name: 'user_board_message',
                params: {
                    username: data.username!,
                    messageID: data.parent.id + '',
                },
            };
        }

        if (this.listCategory.isForClient()) {
            return {
                name: 'forum_client_post_page',
                params: {
                    category: this.listCategory.code,
                    clientCode: data.clientCode!,
                    articleId: data.parent.id + '',
                    page: 'last',
                },
            };
        }

        return {
            name: 'home',
        };
    }

    getCategoryRoute(categoryCode: string, page?: number): Location {
        return {
            name: page ? 'user_posts_category_page' : 'user_posts_category',
            params: {
                category: categoryCode,
                username: this.user!.username,
                page: page + '',
            },
        };
    }

    resolveRoute(params: { [key: string]: any }): Location {
        return this.getCategoryRoute(params.category || this.listCategory.code, params.page);
    }
    get routeResolver(): RouteResolver {
        return this;
    }
    getUpRoute(): UpRouteData | null {
        return null;
    }

    rootHasNext(item: IArticlesWithParent): boolean {
        return !!((item.articles[0] && item.parent.id != item.articles[0].id) || item.articles[1] || !item.isLast);
    }

    private loadPageKey(page: number): string {
        return [this.user?.username, this.listCategory.code, page].join('/');
    }

    loadTotalPages(): void {
        if (this.user) {
            const loadKey = this.loadPageKey(-1);
            if (this.userCatKey != loadKey) {
                this.userCatKey = loadKey;
                this.maxPage = 0;
                serverService.discuss
                    .getArticlesByUserTotal(this.user!, this.listCategory)
                    .then((total) => {
                        if (loadKey == this.loadPageKey(-1)) {
                            this.maxPage = Math.ceil(total / this.length);
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }
        }
    }

    loadPage(page: number): void {
        const loadPageKey = this.loadPageKey(page);
        if (this.loadingKey == loadPageKey) {
            // already loading this page
            return;
        }
        this.loadingKey = loadPageKey;
        this.page = page;
        const start = this.length * (page - 1);

        listUserArticles(this.user!, this.listCategory, start, this.length)
            .then((list) => {
                if (this.loadPageKey(this.page) == loadPageKey) {
                    this.list = list;
                    this.loadingKey = '';
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }
}

function listUserArticles(
    user: IUser,
    category: DISCUSS_CATEGORY,
    start: number,
    length: number
): Promise<IArticlesWithParent[]> {
    return serverService.discuss.listArticlesByUser(user, category, start, length).then((articles) => {
        let clientsToQuery: string[] = [];
        let usernamesToQuery: string[] = [];
        articles.forEach((article) => {
            if (article.username) {
                ArrayUtil.addUniqueElement(usernamesToQuery, article.username);
            } else if (article.clientCode) {
                ArrayUtil.addUniqueElement(clientsToQuery, article.clientCode);
            }
        });
        let clientsMap: { [key: string]: IClient } = {};
        let usersMap: { [key: string]: IUser } = {};
        return Promise.all([
            serverService.client
                .queryClients(clientsToQuery)
                .then((clients) => clients.forEach((client) => (clientsMap[client.code] = client))),
            serverService.user
                .listByUsernames(usernamesToQuery)
                .then((users) => users.forEach((user) => (usersMap[user.username] = user))),
        ]).then(() => {
            let result: IArticlesWithParent[] = [];
            articles.forEach((article) => {
                if (article.clientCode) {
                    if ((article.client = clientsMap[article.clientCode])) {
                        result.push(article);
                    }
                } else if (article.username) {
                    if ((article.user = usersMap[article.username])) {
                        result.push(article);
                    }
                }
            });
            return result;
        });
    });
}
