let tempCanvas: HTMLCanvasElement;
function getCanvas(width: number, height: number): HTMLCanvasElement {
    if (!tempCanvas) {
        tempCanvas = document.createElement('canvas');
    }
    tempCanvas.width = width;
    tempCanvas.height = height;
    return tempCanvas;
}

export class FileUtil {
    public static convertFileToBase64(file: File): Promise<string> {
        return new Promise<string>((resolve) => {
            // setting up the reader
            const reader = new FileReader();
            reader.readAsDataURL(file);

            // here we tell the reader what to do when it's done reading...
            reader.onload = (readerEvent) => {
                const content = readerEvent.target!.result as string; // this is the content!
                resolve(content);
            };
        });
    }

    public static squareDataUrl(dataUrl: string, maxSize: number, imageType = 'image/jpeg'): Promise<string> {
        return new Promise<string>((resolve) => {
            const img = new Image();
            img.src = dataUrl;
            img.onload = () => {
                const size = Math.min(img.width, img.height);
                const finalSize = Math.min(maxSize, size);
                const canvas = getCanvas(finalSize, finalSize);
                const ctx = canvas.getContext('2d')!;
                ctx.drawImage(img, (img.width - size) / 2, (img.height - size) / 2, size, size, 0, 0, finalSize, finalSize);
                ctx.save();
                resolve(canvas.toDataURL(imageType, 0.92));
            };
        });
    }
    public static ratioDataUrl(dataUrl: string, maxWidth: number, ratio: number, imageType = 'image/jpeg'): Promise<string> {
        return new Promise<string>((resolve) => {
            const img = new Image();
            img.src = dataUrl;
            img.onload = () => {
                const imgRatio = img.height / img.width;
                const cropWidth = imgRatio >= ratio ? img.width : img.height / ratio;
                const cropHeight = imgRatio > ratio ? img.width * ratio : img.height;
                const scale =
                    imgRatio > ratio ? Math.min(1, maxWidth / img.width) : Math.min(1, (maxWidth * ratio) / img.height);
                const finalWidth = Math.round(scale * cropWidth);
                const finalHeight = Math.round(finalWidth * ratio);
                const canvas = getCanvas(finalWidth, finalHeight);
                const ctx = canvas.getContext('2d')!;
                ctx.drawImage(
                    img,
                    (img.width - cropWidth) / 2,
                    (img.height - cropHeight) / 2,
                    cropWidth,
                    cropHeight,
                    0,
                    0,
                    finalWidth,
                    finalHeight
                );
                ctx.save();
                resolve(canvas.toDataURL(imageType, 0.92));
            };
        });
    }

    public static resizeDataUrl(
        dataUrl: string,
        maxSize: { width: number; height: number },
        imageType = 'image/jpeg'
    ): Promise<string> {
        return new Promise<string>((resolve) => {
            const img = new Image();
            img.src = dataUrl;
            img.onload = () => {
                let scale = Math.max(maxSize.width / img.width, maxSize.height / img.height);
                const finalSize = Object.assign({}, maxSize);
                if (scale > 1) {
                    finalSize.width /= scale;
                    finalSize.height /= scale;
                    scale = 1;
                }

                const sourceSize = { width: finalSize.width / scale, height: finalSize.height / scale };
                const canvas = getCanvas(finalSize.width, finalSize.height);
                const ctx = canvas.getContext('2d')!;
                ctx.drawImage(
                    img,
                    (img.width - sourceSize.width) / 2,
                    (img.height - sourceSize.height) / 2,
                    sourceSize.width,
                    sourceSize.height,
                    0,
                    0,
                    finalSize.width,
                    finalSize.height
                );
                ctx.save();
                resolve(canvas.toDataURL(imageType, 0.92));
            };
        });
    }
}
