export function getEditorContentStyles(dark: boolean): string {
    return dark ? getDarkStyles() : '';
}

function getDarkStyles(): string {
    return (
        '.mce-content-body {background:#1f1f1f;color:#ccc} ' +
        '.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {color:#ccc5} ' +
        '.mce-content-body [data-mce-selected=inline-boundary] {background:#27f5} '
    );
}

export function getEditorLanguageUrl(language: string): string | undefined {
    if (language && !language.startsWith('en')) {
        return `/js/tinymce/${language}.js`;
    }
    return undefined;
}
