var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"root"},[_c('div',{staticClass:"intro-root"},[_c('AreaTitle',{attrs:{"filters":"brightness(.75) hue-rotate(12deg)","color":"#337ab7","text":_vm.translate('pay.intro.title')}}),_c('div',{staticClass:"box-body intro-body"},[_c('div',{staticClass:"contents"},[_vm._m(0),_c('div',{staticClass:"lines",domProps:{"innerHTML":_vm._s(_vm.translate('pay.intro.lines', { wrap: 'p' }))}})])]),(!_vm.available)?_c('div',[_vm._v("即將開放")]):(!_vm.packages)?_c('CssPreloader',{staticClass:"loading"}):_c('div',{staticClass:"pkg-list-root"},[(_vm.currency.disabled)?_c('div',{staticClass:"company-disabled"},[_vm._v(" "+_vm._s(_vm.getCompanyDisabledMessage(_vm.currency))+" ")]):_c('div',{staticClass:"pkg-list"},_vm._l((_vm.packages),function(pkg){return _c('div',{key:pkg.code,staticClass:"package-root"},[_c('div',{staticClass:"package"},[_c('div',{staticClass:"package-title",domProps:{"innerHTML":_vm._s(
                                _vm.translate(`pay.product.points.title`, {
                                    icon: _vm.gltDollarIcon,
                                    points: _vm.numberWithCommas(_vm.getPackageValue(pkg)),
                                })
                            )}}),_c('div',{staticClass:"package-description"},[_c('div',{staticClass:"package-content",domProps:{"innerHTML":_vm._s(
                                    _vm.translate(`pay.product.points.desc`, {
                                        icon: _vm.gltDollarIcon,
                                        points: _vm.numberWithCommas(_vm.getPackageValue(pkg)),
                                    })
                                )}})]),_c('div',{staticClass:"package-actions"},[_c('div',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm.translate(`pay.price.${_vm.currency.code}`, { price: _vm.numberWithCommas(_vm.getPackagePrice(pkg)), }))+" ")]),_c('a',{staticClass:"btn btn-primary btn-buy",attrs:{"target":_vm.windowTarget,"href":_vm.getPackageUrl(pkg)},on:{"click":function($event){return _vm.onBtnBuy($event, pkg)}}},[_c('i',{staticClass:"fas fa-shopping-cart"}),_vm._v(" "+_vm._s(_vm.translate('pay.btn.buy'))+" ")])])])])}),0),_c('ul',{staticClass:"nav nav-tabs",attrs:{"role":"tablist"}},_vm._l((_vm.currencyList),function(cur){return _c('li',{key:cur.code,staticClass:"nav-item"},[_c('a',{class:{
                            'nav-link': true,
                            active: cur == _vm.currency,
                        },attrs:{"href":"#","role":"tab","aria-selected":"true"},on:{"click":function($event){$event.preventDefault();return _vm.setCurrency(cur)}}},[_vm._v(" "+_vm._s(_vm.translate('pay.currency.' + cur.code))+" ")])])}),0)]),_c('div',{staticClass:"aboutUs"},[_c('button',{staticClass:"btn btn-info",attrs:{"data-bs-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"},on:{"click":function($event){return _vm.toggleContactUs()}}},[_vm._v(" "+_vm._s(_vm.translate(`aboutUs.title.contact`))+" ")]),_c('div',{staticClass:"dropdown-menu dropdown-menu-right",attrs:{"aria-labelledby":"menuTrigger"}},[(_vm.showContactUs)?_c('table',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.translate('aboutUs.title.email')))]),_vm._m(1)]),_c('tr',[_c('th',[_vm._v(" "+_vm._s(_vm.translate('aboutUs.title.adminFacebook'))+" ")]),_vm._m(2)]),_c('tr',[_c('th',[_vm._v(" "+_vm._s(_vm.translate('aboutUs.title.teamFacebook'))+" ")]),_vm._m(3)])]):_vm._e()])])],1),(_vm.available && _vm.hasLoggedIn())?_c('MyDeposit',{staticClass:"my-deposit"}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-bolt"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('a',{attrs:{"target":"_blank","href":"mailto:haskasu@gmail.com"}},[_vm._v("haskasu@gmail.com")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('a',{attrs:{"target":"_blank","href":"https://www.facebook.com/haska"}},[_vm._v("https://www.facebook.com/haska")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('a',{attrs:{"target":"_blank","href":"https://www.facebook.com/twilightwars.tw"}},[_vm._v("https://www.facebook.com/twilightwars.tw")])])
}]

export { render, staticRenderFns }