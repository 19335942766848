import { Constant } from '../datas/Constant';
import { clientCodeToName } from '../filters/game-filters';
import { translate } from '../filters/translate';
import { ArrayUtil } from '../utils/ArrayUtil';
import { IGameBadgeWithUser } from '@/vo/Badge';
import { IClient, IClientProgress, IMsgClient } from '@/vo/Client';
import { IGameItem, IUserItem } from '@/vo/PayProduct';
import { IUser } from '@/vo/User';
import { AbstractSubService } from './abstractSubService';

const clientsCache: { [key: string]: IClient } = {};

function clientCodesToClients(clientCodes: string[]): IClient[] {
    const list: IClient[] = [];
    clientCodes.forEach((code) => {
        const client = clientsCache[code];
        if (client) {
            list.push(client);
        } else {
            siteClientCodes.includes(code);
            list.push({
                code: code,
                name: clientCodeToName(code),
            });
        }
    });
    return list;
}

function cacheClients(clients: IClient[]): IClient[] {
    clients.forEach((client) => {
        clientsCache[client.code] = client;
    });
    return clients;
}

const gmListCache: {
    [key: string]: {
        gms: IUser[];
        expire: number;
    };
} = {};

interface IGmPromise {
    resolve: (gms: IUser[]) => void;
    reject: (error: string) => void;
}
const gmPromiseList: { [key: string]: IGmPromise[] } = {};

function cacheClientGMs(clientCode: string, gms: IUser[]): IUser[] {
    gmListCache[clientCode] = {
        gms: gms,
        expire: Date.now() + 60000 * 5, // 5 minutes
    };
    return gms;
}
function getCachedClientGMs(clientCode: string): IUser[] | null {
    const cache = gmListCache[clientCode];
    if (cache && cache.expire > Date.now()) {
        return cache.gms;
    }
    return null;
}

const progressListCache: {
    [key: string]: {
        list: IClientProgress[];
        expire: number;
    };
} = {};

function cacheClientProgressList(clientCode: string, list: IClientProgress[]): IClientProgress[] {
    progressListCache[clientCode] = {
        list: list,
        expire: Date.now() + 60000 * 5, // 5 minutes
    };
    return list;
}
function getCachedClientProgressList(clientCode: string): IClientProgress[] | null {
    const cache = progressListCache[clientCode];
    if (cache && cache.expire > Date.now()) {
        return cache.list;
    }
    return null;
}

const siteClientCodes = [
    Constant.SITE_CLIENT.code,
    Constant.GAMELET_PRESS.code,
    Constant.GAMELET_CHAT.code,
    Constant.GAMELET_GALLERY.code,
];

export class ClientService extends AbstractSubService {
    public queryClients(clientCodes: string[]): Promise<IClient[]> {
        const missingCodes = clientCodes.filter((code) => !clientsCache[code] && !siteClientCodes.includes(code));
        if (missingCodes.length) {
            return this.api('/list/clients/codes', {
                codes: clientCodes,
            }).then((json) => {
                cacheClients(json.clients);
                return clientCodesToClients(clientCodes);
            });
        } else {
            return Promise.resolve(clientCodesToClients(clientCodes));
        }
    }

    public searchClients(by: string, term: string, start: number, length: number): Promise<IClient[]> {
        return this.api('/search/clients', {
            by: by,
            term: term,
            start: start,
            length: length,
        }).then((json) => {
            return cacheClients(json.clients);
        });
    }

    public listFeaturedClients(count: number, autoSelect?: boolean): Promise<IClient[]> {
        return this.api(`/list/clients/featured/${count}` + (autoSelect ? '?autoSelect=true' : '')).then((json) =>
            cacheClients(json.clients)
        );
    }

    public setFeaturedClient(clientCode: string, index = 0): Promise<IClient> {
        return this.api(`/set/featured_client/${clientCode}/${index}`).then((json) => json.client);
    }

    public removeFeaturedClient(clientCode: string): Promise<IClient> {
        return this.api('/remove/featured_client/' + clientCode).then((json) => json.client);
    }

    public listGameMasters(clientCode: string): Promise<IUser[]> {
        const cachedGms = getCachedClientGMs(clientCode);
        if (cachedGms) {
            return Promise.resolve(cachedGms);
        }
        if (gmPromiseList[clientCode]) {
            return new Promise<IUser[]>((resolve, reject) => {
                gmPromiseList[clientCode].push({
                    resolve: resolve,
                    reject: reject,
                });
            });
        } else {
            gmPromiseList[clientCode] = [];
        }
        return this.api('/list/gm/' + clientCode)
            .then((json) => {
                const list = cacheClientGMs(clientCode, json.list);
                if (gmPromiseList[clientCode]) {
                    gmPromiseList[clientCode].forEach((promise) => promise.resolve(list));
                    delete gmPromiseList[clientCode];
                }
                return list;
            })
            .catch((err) => {
                if (gmPromiseList[clientCode]) {
                    gmPromiseList[clientCode].forEach((promise) => promise.reject(err));
                    delete gmPromiseList[clientCode];
                }
                throw err;
            });
    }

    public isGameMaster(clientCode: string, user?: IUser): Promise<boolean> {
        if (!user || user.guest) {
            return Promise.resolve(false);
        }
        return this.listGameMasters(clientCode).then(() => this.isGameMasterCached(clientCode, user));
    }

    public isGameMasterCached(clientCode: string, user: IUser): boolean {
        const cachedGms = getCachedClientGMs(clientCode);
        return !!(user && cachedGms && cachedGms.find((gm) => gm.username == user.username));
    }

    public setClientBlocked(clientCode: string, blocked: boolean, note: string): Promise<IClient> {
        return this.api('/set/client/blocked/' + clientCode, {
            blocked: !!blocked,
            note: note,
        }).then((json) => json.client);
    }

    public setClientCgOwnerBlocked(
        clientCode: string,
        ownername: string,
        blocked: boolean,
        note: string,
        dry: boolean
    ): Promise<{ owner: string; clients: number }> {
        return this.api('/set/client/cg_owner/blocked', {
            client: clientCode || '',
            owner: ownername || '',
            blocked: !!blocked,
            note: note,
            dry: !!dry,
        }).then((json) => json.result);
    }

    //------------------

    public listClientsByOrder(beta: boolean, order: CLIENT_ORDER, start: number, length: number): Promise<IClient[]> {
        return this.api('/list/clients/order', {
            beta: beta ? 1 : 0,
            order: order.code,
            start: start,
            length: length,
        }).then((json) => cacheClients(json.clients));
    }

    public getClientsTotal(beta: boolean): Promise<number> {
        return this.api('/get/clients_total', {
            beta: beta ? 1 : 0,
        }).then((json) => json.total);
    }

    public listClientsWithBetaByOrder(beta: number, order: CLIENT_ORDER, start: number, length: number): Promise<IClient[]> {
        return this.api('/list/clients/pub_with_beta/order', {
            beta: beta,
            order: order.code,
            start: start,
            length: length,
        }).then((json) => cacheClients(json.clients));
    }

    // msgserver
    public listMsgPlayersCount(clients: IClient[]): Promise<{ [key: string]: number }> {
        return this.api('/list/msg_players_count', {
            clients: clients.map((c) => c.code),
        }).then((json) => json);
    }

    public listMsgClients(start: number, length: number): Promise<IMsgClient[]> {
        return this.api('/list/msg_clients', {
            start: start,
            length: length,
        }).then((json) => {
            const list: IMsgClient[] = json ? json : [];
            ArrayUtil.sortNumericOn(list, 'ppl', false);
            return list;
        });
    }

    // progress api
    public listProgress(client: IClient, refreshCache?: boolean): Promise<IClientProgress[]> {
        const cachedList = !refreshCache && getCachedClientProgressList(client.code);
        if (cachedList) {
            return Promise.resolve(cachedList);
        }
        return this.api('/list/client_progress/' + client.code, {}).then((json) => {
            cacheClientProgressList(client.code, json.list);
            return json.list;
        });
    }
    public setProgress(client: IClient, progress: IClientProgress): Promise<IClientProgress> {
        delete progressListCache[client.code];
        return this.api('/set/client_progress/' + client.code, {
            id: progress.id,
            title: progress.title,
            desc: progress.desc,
            progress: progress.progress,
            total: progress.total,
        }).then((json) => json.progress);
    }
    public removeProgress(client: IClient, progress: IClientProgress): Promise<number> {
        delete progressListCache[client.code];
        return this.api('/remove/client_progress/' + client.code, {
            id: progress.id,
        }).then((json) => json.id);
    }
    public setProgressOrder(client: IClient, progressList: IClientProgress[]): Promise<IClientProgress[]> {
        return this.api('/set/client_progress_order/' + client.code, {
            idsInOrder: progressList.map((p) => p.id),
        }).then((json) => {
            cacheClientProgressList(client.code, json.list);
            return json.list;
        });
    }

    // title api
    public setClientSysTitle(client: IClient, setTitle: boolean): Promise<IClient> {
        return this.api('/set/sys/title/' + client.code, {
            sys: setTitle ? 1 : 0,
        }).then((json) => {
            client.sys = json.client.sys;
            cacheClients([json.client]);
            return json.client;
        });
    }
    public setSysTitleData(client: IClient, data: { [key: string]: any }): Promise<IClient> {
        return this.api('/set/sys_title/data/' + client.code, {
            data: data,
        }).then((json) => {
            Object.assign(client, json.client);
            cacheClients([json.client]);
            return json.client;
        });
    }
    public listSysTitleItems(client: IClient, category: string, withUsers: boolean, useCache: boolean): Promise<IGameItem[]> {
        const key = [client.code, category, withUsers ? 1 : 0].join('/');
        if (useCache) {
            const cache = listSysTitleItemsCache[key];
            if (cache) {
                return Promise.resolve(cache);
            }
        }
        return this.api('/list/sys_title/items/' + client.code, {
            category: category,
            withUsers: withUsers,
        }).then((json) => {
            listSysTitleItemsCache[key] = json.items;
            return json.items;
        });
    }
    public addSysTitleItem(client: IClient, category: string, data: { [key: string]: any }): Promise<IGameItem> {
        return this.api('/add/sys_title/item/' + client.code, {
            category: category,
            data: data,
        }).then((json) => json.item);
    }
    public setSysTitleItem(client: IClient, item: IGameItem): Promise<IGameItem> {
        return this.api('/set/sys_title/item/' + client.code, {
            code: item.code,
            data: {
                name: item.name,
                order: item.order,
                status: item.status,
                sponsor: item.priceInGameDollar,
            },
        }).then((json) => {
            Object.assign(item, json.item);
            return json.item;
        });
    }
    public addSysTitleUserItem(client: IClient, item: IGameItem, user: IUser): Promise<IUserItem> {
        return this.api('/add/sys_title/user_item/' + client.code, {
            code: item.code,
            user: user.username,
        }).then((json) => {
            return json.userItem;
        });
    }
    public removeSysTitleUserItem(client: IClient, item: IGameItem, user: IUser): Promise<IUserItem> {
        return this.api('/remove/sys_title/user_item/' + client.code, {
            code: item.code,
            user: user.username,
        }).then((json) => {
            return json.userItem;
        });
    }
    public listSysTitleClients(start: number, length: number): Promise<IClient[]> {
        return this.api('/list/sys_title/clients', {
            start: start,
            length: length,
        }).then((json) => json.list);
    }
    public getSysTitleClientsTotal(): Promise<number> {
        return this.api('/get/sys_title/clients_total', {}).then((json) => json.total);
    }
    /** badges */
    public listGameBadges(client: IClient, user?: IUser): Promise<IGameBadgeWithUser[]> {
        const url = `/list/game_badges/${client.code}` + (user ? `/user/${user.username}` : `/guest`);
        return this.api(url, {}).then((json) => json.list);
    }
    public getGameBadge(
        clientCode: string,
        badgeCode: string
    ): Promise<{
        client: IClient;
        gameBadge: IGameBadgeWithUser;
        totalPpl: number;
    }> {
        const url = `/get/game_badge/${clientCode}/${badgeCode}`;
        return this.api(url, {}).then((json) => json);
    }
}
const listSysTitleItemsCache: { [key: string]: IGameItem[] } = {};

class PrivateConstructorLocker {
    static instance = new PrivateConstructorLocker();
}

const allOrders: { [key: string]: CLIENT_ORDER } = {};
export class CLIENT_ORDER {
    static NEW = new CLIENT_ORDER(PrivateConstructorLocker.instance, 'new', 'fas fa-rss', false);
    static RATE = new CLIENT_ORDER(PrivateConstructorLocker.instance, 'rate', 'fas fa-star', false);
    static PPL = new CLIENT_ORDER(PrivateConstructorLocker.instance, 'ppl', 'fas fa-users', true);
    static RANDOM = new CLIENT_ORDER(PrivateConstructorLocker.instance, 'random', 'fas fa-random', false);

    static getByCode(code: string): CLIENT_ORDER {
        return allOrders[code];
    }
    constructor(locker: PrivateConstructorLocker, public code: string, public icon: string, public mixBeta: boolean) {
        allOrders[code] = this;
    }

    get name(): string {
        return translate('game.order.' + this.code);
    }
}
