
import { Component, Vue, Watch } from 'vue-property-decorator';
import { translate } from '@/filters/translate';
import { showSideAds } from '@/filters/mobile';
import { contentStripHTML } from '@/filters/content-filters';
import { Constant } from '@/datas/Constant';
import { Location, Route } from 'vue-router';
import { DomUtil } from '../utils/DomUtil';
import AreaTitle from '@/components/AreaTitle.vue';
import Epub from './Epub.vue';
import BlogBoard from './BlogBoard.vue';
import BlogFeed from './BlogFeed.vue';
import AdsResponsive from '@/components/ads/AdsResponsive.vue';
import { serverService } from '../services/serverService';
import { getBlogRoute } from '../filters/blog-filters';
import { IFeedEntry } from '@/vo/Blog';
import { IClient } from '@/vo/Client';

let feedCache: { [key: string]: IFeedEntry } = {};

@Component({
    methods: {
        translate,
        contentStripHTML,
        showSideAds,
    },
    components: {
        BlogFeed,
        AreaTitle,
        Epub,
        BlogBoard,
        AdsResponsive,
    },
})
export default class BlogRead extends Vue {
    public $refs!: Vue['$refs'] & {
        comments: HTMLDivElement;
    };

    client: IClient = Constant.GAMELET_PRESS;

    entry: IFeedEntry | null = null;

    entryId: string | null = null;

    @Watch('$route', { immediate: true })
    onQueryChange(newVal: Route): void {
        this.refreshPageTitle();
        let id = newVal.params.id;
        let search = id.match(/^([a-z])([0-9]+)$/);
        if (search) {
            let entryId = (this.entryId = search[2]);
            if (search[1] == 'b') {
                // blog
                this.getBlogFeed(entryId).then((entry) => {
                    if (this.entryId == entryId) {
                        this.entry = entry;
                        this.refreshPageTitle();
                        if (newVal.hash == '#comments') {
                            this.$nextTick(() => {
                                this.gotoComments();
                            });
                        }
                    }
                });
            }
        }
    }

    private refreshPageTitle(): void {
        let title = translate('blog.intro.title');
        if (this.entry) {
            title += ':' + this.entry.title;
        }
        document.title = title;
    }

    getBackRoute(): Location {
        return getBlogRoute(this.entry ? serverService.getBlogEntryPage(this.entry.id) : 1);
    }

    private getBlogFeed(id: string): Promise<IFeedEntry> {
        if (feedCache[id]) {
            return Promise.resolve(feedCache[id]);
        }
        return serverService.getBlogEntry(id).then((json) => {
            feedCache[id] = convertEntryToFeedEntry(id, json);
            return feedCache[id];
        });
    }

    gotoComments(): void {
        DomUtil.moveToElement(this.$refs.comments);
    }
}

function convertEntryToFeedEntry(id: string, entry: any): IFeedEntry {
    let content = entry.content.$t;
    let thumb = '';
    let searchThumb = content.match(/<img\s[^>]*src="([^"]+)"/);
    if (searchThumb) {
        thumb = searchThumb[1];
    } else if (entry.media$thumbnail) {
        thumb = entry.media$thumbnail.url;
    }
    let searchAuthor = content.match(/(《.*?》)/);
    content = content.replace(/(《.*?》)/, '') + getContentStyles();

    return {
        id: id,
        author: searchAuthor ? contentStripHTML(searchAuthor[1]) : '',
        content: content,
        title: entry.title.$t,
        tags: entry.category.map((cat) => cat.term),
        link: entry.link.find((link) => link.rel == 'alternate').href,
        thumb: thumb,
        published: new Date(entry.published.$t),
    };
}

function getContentStyles(): string {
    return `
<style>
.blog-root .imageWithComment {
    font-size: smaller;
    text-align: center;
}
.blog-root p {
    margin: 1em 0;
    text-indent: 2em;
}
</style>
    `;
}
