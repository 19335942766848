
import { Component, Vue, Watch } from 'vue-property-decorator';
import { translate } from '@/filters/translate';
import { showSideAds } from '@/filters/mobile';
import { Route } from 'vue-router';
import { ResourceCategory } from '../datas/ResourceCategory';
import ResourcesList from './ResourcesList.vue';
import AdsResponsive from '@/components/ads/AdsResponsive.vue';
import { ForumCat } from '../datas/ForumCat';

@Component({
    methods: {
        translate,
        showSideAds,
    },
    components: {
        ResourcesList,
        AdsResponsive,
    },
})
export default class ContributeArtList extends Vue {
    category?: ResourceCategory;

    forumCat?: ForumCat;

    @Watch('$route', { immediate: true })
    onRouteChanged(to: Route): void {
        this.category = ResourceCategory.getByCode(to.params.category)!;
        this.forumCat = ForumCat.getByCoverCategory(this.category);
        if (!this.category) {
            this.$router.replace({
                name: 'home',
            });
            return;
        }
        document.title = translate('localedTitle') + ':' + translate('contribute.label.postImg');
    }
}
