import { IPaymentPackage } from '@/vo/PayProduct';

export class PackageContentType {
    static POINTS = new PackageContentType('points');

    constructor(public code: string) {}
}

const allSites: PaySite[] = [];
export class PaySite {
    static CG = new PaySite('CG');
    static GLT = new PaySite('GLT');
    static STEAM = new PaySite('STEAM');

    static listAll(): PaySite[] {
        return allSites;
    }

    constructor(public code: string) {
        allSites.push(this);
    }
}

export class PayBy {
    static NEWEBPAY = new PayBy('newebpay', 'newebpay_logo.png', ['cvs', 'webATM', 'ATM']).disable();
    static PAYPAL = new PayBy('paypal', 'paypal_logo.png', ['creditcard', 'bank', 'paypal']);
    static PAYPAL_TW = new PayBy('paypal-tw', 'paypal_logo.png', ['creditcard', 'bank', 'paypal']);
    static STEAMPAY = new PayBy('steampay', 'steampay_logo.png', ['creditcard', 'linepay', 'paypal', 'cvs', 'ATM']);

    disabled = false;

    constructor(public code: string, public image: string, public supports: string[]) {}

    disable(): this {
        this.disabled = true;
        return this;
    }

    getPayUri(pkg: IPaymentPackage): string {
        return `/payment/pay/${this.code}/${pkg.product}`;
    }
}

const allCurrency: Currency[] = [];
export class Currency {
    static TWD = new Currency('TWD', [PayBy.NEWEBPAY, PayBy.PAYPAL_TW]);
    static USD = new Currency('USD', [PayBy.PAYPAL]);

    static listAll(): Currency[] {
        return allCurrency;
    }

    constructor(public code: string, public paymentCompany: PayBy[]) {
        allCurrency.push(this);
    }
}

const AllTradeResults: TradeResult[] = [];
export class TradeResult {
    static SUCCESS = new TradeResult('success');
    static WAITING = new TradeResult('waiting');
    static CANCELED = new TradeResult('canceled');

    static listAll(): TradeResult[] {
        return AllTradeResults;
    }
    static getByValue(value: number): TradeResult {
        if (value == 1) {
            return TradeResult.SUCCESS;
        } else if (value) {
            return TradeResult.WAITING;
        } else {
            return TradeResult.CANCELED;
        }
    }

    constructor(public code: string) {
        AllTradeResults.push(this);
    }
}
