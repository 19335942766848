
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import InputBox from '@/forum/components/InputBox.vue';
import PostListItem from '@/forum/components/PostListItem.vue';
import { serverService } from '@/services/serverService';
import { DISCUSS_CATEGORY, DISCUSS_STATE, DISCUSS_STATUS } from '@/services/discussService';
import Pagination from '@/components/Pagination.vue';
import { Location, Route } from 'vue-router';
import { translate } from '@/filters/translate';
import { clientCodeToName, isSiteClient, isUserTitleClient } from '@/filters/game-filters';
import { containsBadWords, errorToString, getYoutubeThumb } from '@/filters/content-filters';
import { getClientLastViewId, RouteResolver, setClientUpRoute, UpRouteData } from '@/utils/RouteResolver';
import { dialogService } from '@/services/dialogService';
import GameInfor from '@/game/GameInfor.vue';
import GameNews from '@/forum/news/GameNews.vue';
import ForumCatCover from '@/contributeArts/ForumCatCover.vue';
import ContributeTime from '@/forum/components/ContributeTime.vue';
import { ForumCat, PostInputData } from '@/datas/ForumCat';
import { DomUtil } from '@/utils/DomUtil';
import { expireNewsPageData } from '@/datas/NewsPageData';
import { ReportFormat } from '@/datas/ReportFormat';
import { Constant } from '@/datas/Constant';
import GameProgress from '../progress/GameProgress.vue';
import GameBadges from '../../game/GameBadges.vue';
import AreaTitle from '@/components/AreaTitle.vue';
import GameLinksButton from '@/components/GameLinksButton.vue';
import BlogFeed from '@/views/BlogFeed.vue';
import AdsResponsive from '@/components/ads/AdsResponsive.vue';
import UserTitleList from '@/forum/components/UserTitleList.vue';
import { showSideAds } from '@/filters/mobile';
import { ArrayUtil } from '../../utils/ArrayUtil';
import { AlertDialogController } from '../../components/dialogs/DialogProps';
import { IClient } from '@/vo/Client';
import { IArticle, ISubjectFull } from '@/vo/Discuss';
import { IGameItem } from '@/vo/PayProduct';

@Component({
    components: {
        InputBox,
        PostListItem,
        Pagination,
        GameInfor,
        GameNews,
        GameProgress,
        GameBadges,
        ForumCatCover,
        ContributeTime,
        AreaTitle,
        BlogFeed,
        AdsResponsive,
        GameLinksButton,
        UserTitleList,
    },
    methods: {
        translate,
        showSideAds,
        isUserTitleClient,
        getYoutubeThumb,
    },
})
export default class RootArticleList extends Vue implements RouteResolver {
    public $refs!: Vue['$refs'] & {
        listTop: HTMLDivElement;
    };

    showInputBox = false;
    data: IArticle[] | null = null;
    maxPage = 0;
    length = 20;
    page = 1;
    client?: IClient;
    category!: DISCUSS_CATEGORY;
    siteClient = false;
    allowPost = false;
    allowView = true;
    isManager = false;
    isClientManager = false;
    initInputBox = false;
    resetInput = 0;
    statusOptions = DISCUSS_STATUS.listAll();
    managerStatusList: DISCUSS_STATUS[] = [DISCUSS_STATUS.OPEN];
    forumCats: ForumCat[] = [];
    videos?: IGameItem[];

    inputBtnAction = '';
    postBtnLabel = '';
    contributeSubject: ISubjectFull | null = null;
    checkCreateTime = true;
    scrollTo?: string;

    isNews(): boolean {
        return this.category == DISCUSS_CATEGORY.NEWS;
    }
    isReport(): boolean {
        return this.category == DISCUSS_CATEGORY.REPORT;
    }
    isContribute(): boolean {
        return this.category == DISCUSS_CATEGORY.CONTRIBUTE;
    }
    isGossip(): boolean {
        return this.client!.code == Constant.GAMELET_CHAT.code;
    }
    isGossip2(): boolean {
        return this.client!.code == Constant.GAMELET_CHAT2.code;
    }
    get loading(): boolean {
        return !this.data;
    }

    get gossipRoute(): Location {
        return ForumCat.GOSSIP.route;
    }
    get gossip2Route(): Location {
        return ForumCat.GOSSIP2.route;
    }

    /**
     * this is used when RootArticleList is embed in a parent page
     * so we dont depend on route to understand what contents to show
     */
    @Prop()
    fixedForum?: ForumCat;

    forumCat?: ForumCat;

    currRoute?: Route;

    @Watch('$route', { immediate: true })
    onRouteChanged(to: Route, from?: Route): void {
        this.currRoute = to;
        this.scrollTo = undefined;
        this.parseParams(to).then(() => {
            if (this.currRoute == to) {
                this.allowView && this.loadPage(this.page);
            }
        });
        if (!from) {
            const focus = getClientLastViewId();
            if (focus) {
                this.scrollTo = 'article_' + focus;
            }
        }
    }
    getForumTitle(): string {
        if (isUserTitleClient(this.client)) {
            return translate('game.sysTitle.forumTitle');
        }
        return translate('game.' + this.category.code + '.title');
    }
    canToggleClientSys(): boolean {
        if (this.client?.pub || this.fixedForum || this.siteClient) {
            return false;
        }
        return serverService.isGM();
    }
    refreshPageTitle(): void {
        let title = translate('localedTitle');
        if (this.client) {
            if (this.siteClient) {
                if (this.isReport()) {
                    title += ':' + translate(`forum.board.GameletOnline.title.report`);
                } else if (this.forumCat) {
                    title += ':' + translate(`forum.board.${this.forumCat.code}.tab`);
                }
            } else if (this.client.name) {
                title = this.client.name;
                if (this.isNews()) {
                    title += ':' + translate(`game.btn.news`);
                } else if (this.isReport()) {
                    title += ':' + translate(`game.btn.report`);
                }
            }
        }
        document.title = title;
    }
    parseParams(to: Route): Promise<void> {
        let promise: Promise<void> = Promise.resolve();

        const clientCode: string = this.fixedForum ? this.fixedForum.clientCode! : to.params['clientCode'];
        const category: string = this.fixedForum ? this.fixedForum.category! : to.params['category'];
        this.siteClient = isSiteClient(clientCode, category); // not a site-client (chat/gallery)
        const sameCategory = this.category && this.category.code == category;
        const sameClient = this.client && this.client.code == clientCode;
        const siteClientName = clientCodeToName(clientCode, category);
        this.category = DISCUSS_CATEGORY.getByCode(category);
        this.allowPost = true;
        this.forumCat = this.fixedForum;

        if (!this.fixedForum && this.siteClient) {
            let forumcats = ForumCat.listByCode(clientCode);

            if (forumcats.length) {
                this.forumCat = forumcats.find((fc) => fc.category == this.category.code);
                if (!this.forumCat) {
                    this.client = {
                        code: '',
                        name: '',
                    };
                    this.$router.replace(forumcats[0].route);
                    return promise;
                }

                if (this.forumCat.target.publicPost) {
                    this.allowView = this.allowPost = true;
                } else {
                    this.allowPost = false;
                    this.allowView = this.forumCat.target.publicView;
                    Promise.all([
                        serverService.getInitData(),
                        serverService.client.listGameMasters(this.forumCat.clientCode!),
                    ]).then(() => {
                        if (this.client && this.client.code == this.forumCat?.clientCode) {
                            this.allowPost = serverService.isClientGM(this.client.code);
                            if (!this.allowView && this.allowPost) {
                                this.allowView = true;
                                this.loadPage(this.page);
                            }
                        }
                    });
                }
            }
        } else if (this.isNews()) {
            this.allowPost = false;
            Promise.all([serverService.getInitData(), serverService.client.listGameMasters(clientCode)]).then(() => {
                if (this.client && this.client.code == clientCode) {
                    this.allowPost = serverService.isClientGM(clientCode);
                }
            });
        }

        if (!sameClient) {
            this.client = {
                code: clientCode,
                name: siteClientName,
            };
            promise = promise.then(() => this.checkIsManager());
            this.checkContributeTime();
        }
        if (sameCategory && sameClient) {
            this.$nextTick(() => {
                DomUtil.moveToElement(this.$refs.listTop);
            });
        } else {
            this.setInputBoxVisible(false);
            this.loadTotalPages();
        }

        if (!this.client!.name) {
            this.queryClient(this.client!.code);
        }

        if (to.params['page'] == 'last') {
            if (this.maxPage) {
                this.page = this.maxPage;
                this.$router.replace(this.resolveRoute({ page: this.maxPage }));
            } else {
                this.page = 9999999;
            }
        } else {
            this.page = Number(to.params['page']) || 1;
        }

        // update props

        if (this.forumCat && this.forumCat.options.postBtnLabel) {
            this.inputBtnAction = translate(this.forumCat.options.postBtnLabel);
        } else {
            this.inputBtnAction = translate(this.category.code + '.btn.submitPost');
        }
        if (this.forumCat && this.forumCat.options.postBtnLabel) {
            this.postBtnLabel = translate(this.forumCat.options.postBtnLabel);
        } else {
            this.postBtnLabel = translate(this.category.code + '.btn.post');
        }
        this.forumCats = ForumCat.listPublic();
        this.refreshPageTitle();
        return promise;
    }

    private checkContributeTime(): void {
        if (this.allowPost && this.isContribute()) {
            this.allowPost = false;
            this.contributeSubject = null;
            serverService.discuss.getContributeSubject(this.client!).then((subject) => {
                this.contributeSubject = subject;
                this.allowPost = !subject.endTime;
            });
        }
    }

    isStatusSelected(option: DISCUSS_STATUS): boolean {
        return this.managerStatusList.includes(option);
    }
    onStatusChange(event: Event, option: DISCUSS_STATUS): void {
        let checked = (event.currentTarget as HTMLInputElement).checked;
        this.setStatusSelected(option, checked);
        if (this.page == 1) {
            this.allowView && this.loadPage(1);
        } else {
            this.$router.push(this.resolveRoute({ page: 1 }));
        }
    }
    setStatusSelected(option: DISCUSS_STATUS, select: boolean): void {
        if (select) {
            ArrayUtil.addUniqueElement(this.managerStatusList, option);
        } else {
            ArrayUtil.removeElement(this.managerStatusList, option);
        }
    }

    private queryClient(clientCode: string): void {
        this.videos = undefined;
        serverService.client.queryClients([clientCode]).then((clients) => {
            // check if we are still in this client page
            if (this.client && this.client.code == clientCode) {
                if (clients.length) {
                    let client = clients[0];
                    this.client = client;
                    this.loadVideos(client);
                    this.refreshPageTitle();
                } else {
                    dialogService.error({ body: 'No such game' }).then(() => {
                        this.$router.replace({ name: 'forums' });
                    });
                }
                this.$forceUpdate();
            }
        });
    }

    private loadVideos(client: IClient): void {
        if (isUserTitleClient(client)) {
            serverService.client.listSysTitleItems(client, Constant.SYS_ITEM_CATEGORY.VIDEO, false, true).then((items) => {
                this.videos = items;
                this.$forceUpdate();
            });
        }
    }

    get routeResolver(): RouteResolver {
        return this;
    }

    getUpRoute(): UpRouteData | null {
        return null;
    }

    resolveRoute(params: { [key: string]: any }): Location {
        if (this.fixedForum) {
            return {
                name: this.fixedForum.route.name + '_page',
                params: Object.assign({ page: params.page + '' }, this.fixedForum.route.params),
            };
        }
        return {
            name: 'forum_client_page',
            params: {
                page: params.page + '',
                clientCode: this.client!.code,
                category: (this.category || DISCUSS_CATEGORY.FORUM).code,
            },
        };
    }

    getReportFormats(): ReportFormat[] | undefined {
        if (this.forumCat) {
            return this.forumCat.options.formats;
        } else if (this.isReport()) {
            return [ReportFormat.ARTICLE_ABUSE, ReportFormat.PLAYER_ABUSE, ReportFormat.GAME_BUG, ReportFormat.GM_ABUSE];
        } else {
            return undefined;
        }
    }

    getStateOptions(): DISCUSS_STATE[] | undefined {
        if (this.isContribute()) {
            if (this.isManager) {
                return [DISCUSS_STATE.DIALOG, DISCUSS_STATE.OPEN];
            } else {
                return [DISCUSS_STATE.DIALOG];
            }
        } else if (this.isReport()) {
            return [DISCUSS_STATE.DIALOG, DISCUSS_STATE.OPEN];
        } else {
            return undefined;
        }
    }

    private loadTotalPages(): void {
        if (this.client && this.category) {
            let loadKey = this.loadPageKey(-1);
            this.maxPage = 0;
            serverService.discuss
                .getRootArticlesTotal(this.client!, this.category, [
                    DISCUSS_STATE.OPEN,
                    DISCUSS_STATE.DIALOG,
                    DISCUSS_STATE.CLOSE,
                ])
                .then((data) => {
                    if (loadKey == this.loadPageKey(-1)) {
                        this.maxPage = Math.ceil(data.total / this.length);
                        if (this.page > Math.max(1, this.maxPage)) {
                            this.$router.replace(this.resolveRoute({ page: this.maxPage }));
                        }
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }

    loadPage(page: number): void {
        if (!this.category) {
            this.$router.replace({
                name: 'forum_client_page',
                params: {
                    category: DISCUSS_CATEGORY.FORUM.code,
                    clientCode: this.client!.code,
                    page: this.page + '',
                },
            });
            return;
        }

        this.data = null;
        this.page = page;
        const isNews = this.isNews();
        const start = this.length * (page - 1);
        const loadPageKey = this.loadPageKey(page);
        const statusList = this.isReport() && this.isManager ? this.managerStatusList : DISCUSS_STATUS.listAll();

        serverService.discuss
            .listRootArticles(
                this.client!,
                this.category,
                [DISCUSS_STATE.OPEN, DISCUSS_STATE.DIALOG, DISCUSS_STATE.CLOSE],
                statusList,
                false,
                isNews,
                this.isContribute() && this.checkCreateTime,
                false,
                !isNews,
                start,
                this.length
            )
            .then((data) => {
                if (loadPageKey == this.loadPageKey(this.page)) {
                    this.data = data;
                    setClientUpRoute(this.resolveRoute({ page: this.page + '' }));
                    const scrollTo = this.scrollTo;
                    this.scrollTo = undefined;
                    if (scrollTo) {
                        this.$nextTick(() => {
                            this.scrollToRefId(scrollTo);
                        });
                    }
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    private loadPageKey(page: number): string {
        return [this.client?.code, this.category.code, page].join('/');
    }

    toggleInputBox(): void {
        if (!serverService.hasLoggedIn()) {
            this.setInputBoxVisible(false);
            dialogService.notLoginError(this.$route.fullPath);
        } else {
            this.setInputBoxVisible(!this.showInputBox);
            this.showInputBox && DomUtil.moveToElement(this.$refs.listTop);
        }
    }

    setInputBoxVisible(vis: boolean): void {
        this.showInputBox = vis;
        this.initInputBox = this.initInputBox || vis;
        this.resetInput += 1;
    }

    onPost(post: PostInputData): void {
        if (!post) {
            return;
        }
        dialogService.alert(
            {
                title: translate('forum.postConfirm.title'),
                body: '',
                titleIcon: 'fas fa-question-circle',
            },
            {
                okText: translate('forum.postConfirm.btnConfirm'),
                cancelText: translate('forum.postConfirm.btnCancel'),
                ok: (controller) => {
                    let category = this.category;
                    let client = this.client!;
                    let postQuota: number | undefined = undefined;
                    if (containsBadWords([post.title, post.content])) {
                        const cat = ForumCat.GOSSIP2;
                        category = DISCUSS_CATEGORY.getByCode(cat.category);
                        client.code = cat.clientCode!;
                        postQuota = 1;
                    }
                    submitPost(client, category, controller, post, postQuota).then((article) => {
                        if (article) {
                            this.$router.push({
                                name: this.fixedForum ? `${this.fixedForum.route.name}_post` : 'forum_client_post',
                                params: {
                                    category: (category || DISCUSS_CATEGORY.FORUM).code,
                                    clientCode: this.client!.code,
                                    articleId: article.id + '',
                                },
                            });
                        }
                    });
                },
            }
        );
    }

    checkIsManager(): Promise<void> {
        let clientCode = this.client!.code;
        this.isManager = false;
        return Promise.all([serverService.client.listGameMasters(clientCode), serverService.getInitData()]).then(() => {
            if (this.client?.code == clientCode) {
                this.isManager = serverService.isGM() || serverService.isClientGM(clientCode);
                this.isClientManager = serverService.isClientGM(clientCode);
            }
        });
    }

    onStageChange(): void {
        this.loadPage(this.page);
    }

    private operateSubject(promise: Promise<ISubjectFull>): void {
        const loading = dialogService.loading();
        promise
            .then((subject) => {
                this.contributeSubject = subject;
                this.allowPost = !subject || !subject.endTime;
            })
            .catch((err) => {
                dialogService.error({ body: errorToString(err) });
            })
            .then(() => loading.close());
    }

    openSubject(): void {
        this.operateSubject(serverService.discuss.openContributeSubject(this.client!));
    }
    closeSubject(): void {
        this.operateSubject(serverService.discuss.closeContributeSubject(this.client!));
    }
    changeView(view: string): void {
        let checkCreateTime = view != 'all';
        if (this.checkCreateTime != checkCreateTime) {
            this.checkCreateTime = checkCreateTime;
            this.loadPage(1);
        }
    }
    private scrollToRefId(id: string) {
        let refs = this.$refs[id] as PostListItem[];
        if (refs && refs.length) {
            DomUtil.moveToElement(refs[0].$refs.hashTag);
        }
    }

    private toggleTitleClient() {
        const client = this.client;
        if (client) {
            serverService.client.setClientSysTitle(client, !client.sys).then((c) => {
                this.client = c;
                this.$forceUpdate();
            });
        }
    }
}

function submitPost(
    client: IClient,
    category: DISCUSS_CATEGORY,
    controller: AlertDialogController,
    post: PostInputData,
    postQuota?: number
): Promise<IArticle | void> {
    controller.showLoading(translate('forum.postConfirm.posting'));
    return serverService.discuss
        .addRootArticle(client, category, post.title, post.content, post.state, undefined, postQuota)
        .then((article) => {
            controller.dismiss();
            if (category == DISCUSS_CATEGORY.NEWS) {
                expireNewsPageData(client.code);
            }
            return article;
        })
        .catch((err) => {
            controller.dismiss();
            dialogService.error({ title: errorToString(err) });
        });
}
