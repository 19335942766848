import { importTranslation } from '../translate';

importTranslation('en-US', {
    aboutUs: {
        gmTeam: {
            tab: 'Webmasters',
            content: [
                'The webmasters are in charge of developing new features of the gamelet site, helping creators to publish games here, and overwatch the communities.',
                'The team members are also the GM of some Gamelet official games such as Twilight Wars and Renju.',
            ],
        },
        editors: {
            tab: 'Editors',
            content: [
                '"Gamelet Editors" are formed by a group of gamelet players who love writing and editing. They collect ideas in the forums, interview cool people, and write inspiring stories to inspire the community. E-Publications are published periodically.',
                'In the lead of the editor in chief who took over the job in 2019, Gamelet Editors is now well organized, and is always watching the community and turning the history of Gamelet into words.',
            ],
        },
        title: {
            aboutUs: 'About Us',
            teamMember: 'Team Members',
            teamBoard: 'Team Board',
            contact: 'Contact Us',
            email: 'Email',
            facebook: 'Facebook',
            website: 'Website',
            adminFacebook: 'Admin FB',
            teamFacebook: 'Team FB',
        },
    },
    history: {
        events: [
            {
                year: 2007,
                month: 'March',
                text: ['Liquable teamed up'],
            },
            {
                year: 2008,
                month: 'August',
                text: ['Gamelet website published (tw.gamelet.com)'],
            },
            {
                month: 'September',
                text: [
                    'New game released "Black Forest Jack"',
                    'New game released "Into the Atmosphere"',
                    'New game released "Renju"',
                    'New game released "Hammers Smash"',
                ],
            },
            {
                month: 'October',
                text: [
                    'New game released "Slashlet"',
                    'New game released "Battle City Online"',
                    'New game released "Gamelet Pets Connect"',
                ],
            },
            {
                year: 2009,
                month: 'January',
                text: [
                    'New game released "Twilight Wars"',
                    'New game released "Banqi Worriors"',
                    'New game released "Guess the Numbers"',
                    '"Hammers Smash" off shelf',
                ],
            },
            {
                month: 'April',
                text: [
                    'New game released "Crystal Digger"',
                    'New game released "Tank Assult"',
                    'New game released "Snack Coming"',
                ],
            },
            {
                month: 'July',
                text: ['New game released "Fairy Tale 99"'],
            },
            {
                month: 'October',
                text: ['New game released "Pirates Big 2"', 'New game released "Mahjong Place"'],
            },
            {
                year: 2010,
                month: 'February',
                text: ['New game released "Monfriends"'],
            },
            {
                month: 'August',
                text: ['Facebook game released「Pizza World」'],
            },
            {
                year: 2011,
                month: 'January',
                text: ['New game released "Heir to the Glory"'],
            },
            {
                month: 'October',
                text: ['Twilight Wars released "Player Costume Editor"'],
            },
            {
                year: 2012,
                month: 'January',
                text: ['Twilight Wars released season 2 missions'],
            },
            {
                month: 'November',
                text: ['New game released "Witch Battle"'],
            },
            {
                year: 2013,
                month: 'February',
                text: ['Twilight Wars released "Mission Editor"'],
            },
            {
                month: 'August',
                text: ['Voting event of player made missions in Twilight Wars'],
            },
            {
                month: 'October',
                text: ['Twilight Wars released "Zombies Assult"'],
            },
            {
                month: 'November',
                text: ['Twilight Wars released "Dollar Sale" system'],
            },
            {
                year: 2014,
                month: 'January',
                text: ['Twilight Wars events: Spring couplets'],
            },
            {
                month: 'February',
                text: ['New game released "Princess Grown Up Yet"'],
            },
            {
                month: 'June',
                text: ['Gamelet ran out of fund, first generation team dismissed'],
            },
            {
                year: 2015,
                month: 'February',
                text: ['Twilight Wars release "Emergency Events" system'],
            },
            {
                month: 'August',
                text: ['"Gamelet Points Hide and Seek" system released'],
            },
            {
                month: 'September',
                text: ['Gamelet server moved to new private location', 'Twilight Wars released "E-Sports" system'],
            },
            {
                month: 'October',
                text: ['Twilight Wars released "LUA AI" system', 'Twilight Wars Event: Holloween Costume Voting Event'],
            },
            {
                year: 2016,
                month: 'January',
                text: ['"Gamelet Manage Team" formed with player members'],
            },
            {
                month: 'March',
                text: ['First Gamelet publication (Vol.1) published'],
            },
            {
                month: 'June',
                text: ['"E-Sport Committee" formed'],
            },
            {
                month: 'July',
                text: [
                    'First E-Sport by E-Sport Committee was successfully held',
                    'Gamelet server moved to a new private location',
                    'The database of Gamelet server was damaged and caused the site down for 7 days',
                ],
            },
            {
                month: 'August',
                text: [
                    'Twilight Wars E-Sport: Survivors',
                    'Twilight Wars E-Sport: Battle Basketball',
                    'Twilight Wars released new mode: MMO RPG',
                ],
            },
            {
                year: 2017,
                month: 'January',
                text: ['「<a href="//blog.gamelet.com" target="_blank">Gamelet Blog</a>」 opened'],
            },
            {
                month: 'July',
                text: ['Twilight Wars competition: Lua AI design'],
            },
            {
                month: 'August',
                text: ['Gamelet Publication Vol.2 published'],
            },
            {
                year: 2018,
                month: 'April',
                text: ['<a href="//code.gamelet.com" target="_blank">Code.Gamelet (C.G.)</a> published'],
            },
            {
                month: 'August',
                text: ['Gamelet Publication Vol.3 published'],
            },
            {
                year: 2019,
                month: 'January',
                text: ['Code.Gamelet started a three-month free online course'],
            },
            {
                month: 'June',
                text: ['Gamelet Publication Vol.4 published'],
            },
            {
                month: 'November',
                text: ['Gamelet server died. The whole site stopped.'],
            },
            {
                month: 'December',
                text: ['Official Discard opened for community', 'Started to build new gamelet site'],
            },
            {
                year: 2020,
                month: 'January',
                text: [
                    '"Twilight Wars Remake" released alpha on Code.Gamelet.com',
                    '"Twilight Wars Remake" event: "New Year Monster"',
                ],
            },
            {
                month: 'February',
                text: ['Simple version of Gamelet`s new site is up'],
            },
            {
                month: 'March',
                text: [
                    'Gamelet Team developed "COVID-19 CommandCentre" on Code.Gamelet.com',
                    'And joined COVID-19 Global Hackathon 1.0',
                ],
            },
            {
                month: 'June',
                text: [
                    'Gamelet Team started officla gamelet site construction',
                    'Twilight Wars official missions rebuild started',
                    'Gamelet Publication Vol.5 published',
                ],
            },
            {
                month: 'July',
                text: ['Twilight Wars Remake released new "CG Mission Editor"'],
            },
            {
                month: 'October',
                text: ['Gamelet.online opened for registering new games on Code.Gamelet.com'],
            },
            {
                month: 'December',
                text: ['Gamelet new site (gamelet.online) is up and running'],
            },
            {
                year: 2021,
                month: 'February',
                text: ['Gamelet Official Discord closed'],
            },
        ],
    },
    cg: {
        title: 'Code dot Gamelet',
        btn: {
            goCG: 'Check it out',
        },
        intro: [
            '<a href="//code.gamelet.com">Code.Gamelet.com</a> (CG) is a game making platform that suits developer of any level who dreams to make games.',
            'Sharing images, sounds, and even game engines, you can turns your game idea into a real game with or without coding skills. Your game can then published right here to be played by our community.',
        ],
        compareUser: [
            {
                title: 'Game Design',
                features: [
                    'Who wants to <b>make games</b> but has no idea where to start.',
                    'Who wants to prototype a cool idea in the <b>shortest</b> time.',
                    'CG-<b>builtin</b> Game Editor',
                ],
            },
            {
                title: 'Coding',
                features: [
                    "Who wants to <b>learn coding</b> but can't decide what language or tool to pickup.",
                    'Who knows how to code, but wants to learn from <b>masters</b>.',
                    'CG-<b>buildin</b> Typescript IDE',
                ],
            },
        ],
        shareFeatures: [
            '<b>Share</b> picture/sound/assets/editors',
            'CG-builtin Database/<b>Reamtime</b> Server/Website of your game',
            'CG-builtin tool that turns your game into <b>mobile</b> app',
            '<b>Grow</b> with Gamelet community',
        ],
        compareVideo: [
            {
                title: 'Use Case',
                video: 'https://www.youtube.com/embed/KusQPfflrys',
            },
            {
                title: 'Use Case',
                video: 'https://www.youtube.com/embed/Z3HhDTHtntM?start=2640',
            },
        ],
    },
});

export const aboutUs_translation_en = 1;
