import { importTranslation } from '../translate';

importTranslation('en-US', {
    gameletQA: {
        banner: {
            title: 'Gamelet 2.0 Q&A',
        },
        tabs: [
            {
                title: 'Frequently Asked Questions',
                content: [
                    {
                        q: 'What is "WebGL is not supported or not enabled on your browser"？',
                        a:
                            'Most webgames require the support of WebGL. To enable WebGL may invole two actions(or one of them):\n1. Turn "Hardware acceleration" on\n2. Enable WebGL',
                    },
                    {
                        q:
                            'My account is suspended by one of the games that I played. Will it limit what I can do in other games?',
                        a:
                            'Every game on gamelet.online is managed by its developer. Therefore, an account that is suspended by a game does not affect how it plays on other games.',
                    },
                    {
                        q: 'What to make an appeal when my account is suspended by a game?',
                        a:
                            'You can find a dropdown menu that contains a link to "Game Support". You can make an appeal in the Game Support, and ask the management team of the game to reconsider your situation.',
                    },
                ],
            },
            {
                title: 'Game Publishing',
                content: [
                    {
                        q: 'Can I publish my game here?',
                        a:
                            'All games that are developed on code.gamelet.com(C.G.) are able to be published on gamelet.online for free. Gamelet also provide free forum for you to manage the community of your game.',
                    },
                    {
                        q: 'Will a published game be off shelf?',
                        a:
                            "Gamelet will try not to interfere the communities of the games. But please keep in mind that it is the game developer's responsibility to manage the community. If there are too many inappropriate contents exposed on the site, we may be forced to set your game off shelf.",
                    },
                    {
                        q: 'How do I manage the forum of my game?',
                        a:
                            '1. Open the project of your game on CG.\n2. Open "Database" of the sidebar on the right.\n3. Open "Game Masters" dialog.\n4. Assign your gamelet.onlien account as a game master.\n5. You can then login the GM account and open/close articles in the forum.',
                    },
                ],
            },
        ],
    },
});

export const gameletQA_translation_en = 1;
