
import { Component, Vue, Watch } from 'vue-property-decorator';
import { serverService } from '@/services/serverService';
import CssPreloader from '@/components/CssPreloader.vue';
import InputBox from '@/forum/components/InputBox.vue';
import BaseTabComp from './BaseTabComp';
import BoardMessage, { BoardMessageData } from './BoardMessage.vue';
import Pagination from '@/components/Pagination.vue';
import { translate } from '@/filters/translate';
import { DISCUSS_CATEGORY, DISCUSS_STATE } from '@/services/discussService';
import { Location, Route } from 'vue-router';
import { RouteResolver, UpRouteData } from '@/utils/RouteResolver';
import { dialogService } from '@/services/dialogService';
import { errorToString } from '@/filters/content-filters';
import { DomUtil } from '@/utils/DomUtil';
import { NotificationType } from '../../../datas/NotificationType';
import { Constant } from '@/datas/Constant';
import { AuthType } from '@/datas/AuthType';
import BoardAlertMessage from './BoardAlertMessage.vue';
import Friends from './Friends.vue';
import { FriendshipStatus } from '../../../datas/FriendshipStatus';
import { IArticle, IArticleAndReplies, ISubjectFull } from '@/vo/Discuss';

//declare const tinymce: any;

@Component({
    components: {
        CssPreloader,
        InputBox,
        Pagination,
        BoardMessage,
        BoardAlertMessage,
        Friends,
    },
    methods: {
        translate,
    },
})
export default class Board extends BaseTabComp implements RouteResolver {
    @Watch('friendshipStatus')
    onFriendshipStatusChange(): void {
        this.refreshAllowPost();
        if (this.needLoadPage && this.isViewable()) {
            this.loadPage(this.page);
        }
    }
    public $refs!: Vue['$refs'] & {
        listTop: HTMLDivElement;
    };

    loading = false;
    showInputBox = false;

    data: BoardMessageData[] = [];

    resetInput = 0;

    username = '';

    page = 1;

    length = 20;

    maxPage = 0;

    allowPost = false;

    needLoadPage = false;

    category = DISCUSS_CATEGORY.USER_BOARD;

    authTypes = [AuthType.PLAYER, AuthType.FRIEND, AuthType.ME];

    subject: ISubjectFull | null = null;

    boardStatus?: AuthType;

    showBlacklist = false;

    stateOptions = [DISCUSS_STATE.DIALOG, DISCUSS_STATE.OPEN, DISCUSS_STATE.CLOSE];

    @Watch('$route', { immediate: true })
    onRouteChanged(to: Route): void {
        this.data = [];
        this.loading = false;
        this.showBlacklist = false;

        if (this.user!.guest) {
            this.maxPage = 0;
            this.page = 1;
            this.allowPost = false;
        } else if (to.params['messageID']) {
            this.loading = true;
            let messageId = to.params['messageID'];
            serverService.discuss
                .getRootArticleIndex(Number(messageId), this.stateOptions)
                .then((index) => {
                    this.$router.replace(
                        this.resolveRoute({
                            page: Math.floor(index.index / this.length) + 1,
                            hash: '#' + index.parent,
                        })
                    );
                })
                .catch((err) => {
                    console.error(err);
                });
        } else {
            this.page = Number(to.params['page']) || 1;
            let sameUser = this.user?.username == this.username;

            if (!sameUser || !this.subject) {
                this.loading = true;
                this.needLoadPage = true;
                this.loadTotalPages().then(() => {
                    this.loading = false;
                    if (this.isViewable()) {
                        this.loadPage(this.page);
                    }
                });
            } else if (this.allowPost) {
                this.loadPage(this.page, true);
            }
        }

        this.username = this.user!.username;
    }

    get transPrefix(): string {
        return this.team ? 'teamBoard' : 'userBoard';
    }

    isTeamMember(): boolean {
        return !!this.teamship;
    }

    isViewable(): boolean {
        return this.allowPost || this.isPublicBoard();
    }

    resolveRoute(params: { [key: string]: any }): Location {
        if (this.team) {
            return {
                name: 'team_board_page',
                params: {
                    teamname: this.team.teamname,
                    page: params.page,
                },
                hash: params.hash,
            };
        } else {
            return {
                name: 'user_board_page',
                params: {
                    username: this.user!.username,
                    page: params.page,
                },
                hash: params.hash,
            };
        }
    }

    mounted(): void {
        this.refreshAllowPost();
    }

    get routeResolver(): RouteResolver {
        return this;
    }
    getUpRoute(): UpRouteData | null {
        return null;
    }

    isAdminMessage(article: IArticle): boolean {
        return article.author.username == Constant.ADMIN_USERNAME;
    }

    onRefresh(): void {
        if (this.page == 1) {
            if (this.isViewable()) {
                this.loadPage(this.page);
            }
        } else {
            this.$router.push(this.resolveRoute({ page: 1 }));
        }
    }

    onStateChange(event: IArticleAndReplies, index: number): void {
        this.data![index].article = event.article;
        this.data![index].replies = event.replies;
        this.$forceUpdate();
    }

    onRead(event: IArticleAndReplies, index: number): void {
        this.data![index].article = event.article;
        this.data![index].replies = event.replies;
        this.$forceUpdate();
    }

    displayInput(): void {
        if (this.allowPost)
            if (!serverService.hasLoggedIn()) {
                dialogService.notLoginError(this.$route.fullPath);
            } else {
                this.showInputBox = !this.showInputBox;
            }
    }

    showAuthTypeInfo(): void {
        let status = this.boardStatus;
        if (status) {
            dialogService.alert(
                {
                    title: translate(`${this.transPrefix}.label.boardSetting`) + ': ' + status.getBtnLabel(this.transPrefix),
                    titleIcon: status.icon,
                },
                {
                    okText: translate('btn.close'),
                    cancelText: '',
                }
            );
        }
    }
    isPublicBoard(): boolean {
        return !!this.subject && !this.subject.authType;
    }

    setBoardStatus(authType: AuthType): void {
        let loading = dialogService.loading();
        serverService.discuss
            .setUserBoardAuthType(this.team || this.me!, this.category, authType)
            .then((data: ISubjectFull) => {
                this.setSubject(data);
                this.refreshAllowPost();
            })
            .catch((err) => {
                dialogService.error({ body: errorToString(err) });
            })
            .finally(() => {
                loading.close();
            });
    }

    private loadPageKey(page: number): string {
        return [this.user?.username, this.category.code, page].join('/');
    }

    private setSubject(subject: ISubjectFull | null): void {
        this.subject = subject;
        if (this.subject) {
            this.boardStatus = AuthType.getByCode(this.subject.authType) || AuthType.PLAYER;
        } else {
            this.boardStatus = undefined;
        }
        this.$forceUpdate();
    }

    private loadTotalPages(): Promise<void> {
        this.setSubject(null);
        this.allowPost = false;
        this.showInputBox = false;
        if (this.user && this.category) {
            let loadKey = this.loadPageKey(-1);
            return serverService.discuss
                .getUserRootArticlesTotal(this.user!, this.category, this.stateOptions)
                .then((data) => {
                    if (loadKey == this.loadPageKey(-1)) {
                        this.maxPage = Math.ceil(data.total / this.length);
                        this.setSubject(data.subject);
                        this.refreshAllowPost();
                    }
                })
                .catch((err) => {
                    dialogService.error({ body: errorToString(err) });
                });
        } else {
            return Promise.resolve();
        }
    }

    private refreshAllowPost(): void {
        if (serverService.isMe(this.user) || serverService.isGM() || this.isTeamMember()) {
            this.allowPost = true;
        } else if (this.isPublicBoard()) {
            this.allowPost =
                this.friendshipStatus != FriendshipStatus.BLACKED && this.friendshipStatus != FriendshipStatus.BLACKED_BOTH;
        } else if (this.subject?.authType == AuthType.ME.code) {
            this.allowPost = false;
        } else if (this.subject?.authType == AuthType.FRIEND.code) {
            this.allowPost = this.friendshipStatus == FriendshipStatus.ACCEPTED;
        } else {
            this.allowPost = false;
        }
    }

    loadPage(page: number, autoScroll?: boolean): void {
        this.loading = true;
        this.needLoadPage = false;
        this.page = page;
        const start = this.length * (page - 1);
        const loadPageKey = this.loadPageKey(page);

        serverService.discuss
            .listUserRootArticles(this.user!, this.category, this.stateOptions, start, this.length)
            .then((articles) => {
                if (loadPageKey == this.loadPageKey(this.page)) {
                    this.data = articles.map((article) => new BoardMessageData(article));
                    if (this.$route.hash) {
                        let searchHash = this.$route.hash.match(/^#([0-9]+)$/);
                        if (searchHash) {
                            this.$nextTick()
                                .then(() => this.$nextTick())
                                .then(() => this.moveToArticle(Number(searchHash![1])));
                        }
                    } else if (autoScroll) {
                        this.$nextTick()
                            .then(() => this.$nextTick())
                            .then(() => DomUtil.moveToElement(this.$refs.listTop));
                    }

                    serverService.messaging.markArticlesRead(articles, [NotificationType.USER_MESSAGE]);
                }
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                this.loading = false;
            });
    }

    onPost(post: { title: string; content: string; state: DISCUSS_STATE }): void {
        if (!post) {
            return;
        }
        let category = this.team ? 'teamBoard' : this.category.code;
        dialogService.alert(
            {
                title: post.state.getTitle(category),
                body: post.state.getDescription(category),
                titleIcon: 'fas fa-question-circle',
            },
            {
                okText: translate('forum.postConfirm.btnConfirm'),
                cancelText: translate('forum.postConfirm.btnCancel'),
                ok: (controller) => {
                    controller.showLoading(translate('forum.postConfirm.posting'));
                    serverService.discuss
                        .addUserArticle(this.user!, this.category, post.title, post.content, post.state)
                        .then((article) => {
                            controller.dismiss();
                            this.resetInput += 1;
                            this.showInputBox = false;
                            const boardRoute = this.team ? 'team_board' : 'user_board';
                            if (this.$route.name != boardRoute) {
                                this.$router.push({
                                    name: boardRoute,
                                    params: {
                                        username: this.user!.username,
                                    },
                                });
                            } else {
                                this.data?.unshift(new BoardMessageData(article));
                                this.$forceUpdate();
                            }
                        })
                        .catch((err) => {
                            controller.dismiss();
                            dialogService.error({ title: errorToString(err) });
                        });
                },
            }
        );
    }

    moveToArticle(id: number): void {
        let refs = this.$refs[`article_${id}`] as BoardMessage[];
        if (refs && refs.length) {
            DomUtil.moveToElement(refs[0].$refs.hashTag);
        }
    }

    btnBlacklist(show: boolean): void {
        this.showBlacklist = show;
    }
}
