
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ForumCat } from '@/datas/ForumCat';
import { translate } from '@/filters/translate';
import { serverService } from '@/services/serverService';
import { DISCUSS_CATEGORY, DISCUSS_STATE } from '../services/discussService';
import { Constant } from '@/datas/Constant';
import { Location } from 'vue-router';
import { getUserRoute } from '@/filters/user-filters';
import { IArticle } from '@/vo/Discuss';

@Component({
    methods: {
        translate,
        getUserRoute,
    },
})
export default class ForumCatCover extends Vue {
    @Prop()
    forumCat!: ForumCat;

    @Prop({ default: '0 30px' })
    padding!: string | number;

    newsList: {
        content: string;
        route: Location;
    }[] = [];

    mounted(): void {
        getLatestAnnouncement().then((list) => {
            // dont show news that is three month old
            let expireDuration = 60 * 60 * 24 * 30 * 3;
            let expireTime = Date.now() / 1000 - expireDuration;
            list.forEach((news) => {
                if ((news.modifyTime || news.createTime) > expireTime) {
                    let search = news.content.matchAll(/<div>(.*?)<\/div>/g);
                    if (search) {
                        let route: Location = {
                            name: 'forum_client_post',
                            params: {
                                category: DISCUSS_CATEGORY.NEWS.code,
                                clientCode: news.clientCode!,
                                articleId: news.id + '',
                            },
                        };
                        this.newsList = this.newsList.concat(
                            Array.from(search, (result) => {
                                return {
                                    content: result[0],
                                    route: route,
                                };
                            })
                        );
                    }
                }
            });
        });
    }
}

function getLatestAnnouncement(): Promise<IArticle[]> {
    return serverService.discuss.listRootArticlesByClients(
        [Constant.SITE_CLIENT, Constant.GAMELET_PRESS],
        DISCUSS_CATEGORY.NEWS,
        [DISCUSS_STATE.OPEN],
        0,
        5
    );
}
