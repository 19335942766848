
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { serverService } from '@/services/serverService';
import { DISCUSS_STATE } from '@/services/discussService';
import { translate } from '@/filters/translate';
import { contentLinksToBlankTarget } from '@/filters/content-filters';
import { getUserRoute, getUserTooltip } from '@/filters/user-filters';
import BoardFooter from './BoardFooter.vue';
import Editor from '@tinymce/tinymce-vue';
import { IArticle } from '@/vo/Discuss';
import { ITeam, ITeamship, IUser } from '@/vo/User';
import { TeamshipSetting } from '../../../datas/FriendshipStatus';

@Component({
    methods: {
        translate,
        getUserRoute,
        getUserTooltip,
    },
    components: {
        Editor,
        BoardFooter,
    },
})
export default class BoardReply extends Vue {
    @Prop()
    article!: IArticle;

    @Prop()
    user!: IUser;

    @Prop()
    team?: ITeam;

    @Prop()
    teamship?: ITeamship;

    @Emit('stateChange')
    onStateChange(article: IArticle): IArticle {
        return article;
    }

    get removed(): boolean {
        return this.article.state != DISCUSS_STATE.OPEN.code;
    }

    get isManager(): boolean {
        if (this.team) {
            return TeamshipSetting.checkAuth(this.teamship, TeamshipSetting.MANAGE);
        }
        return serverService.isMe(this.user);
    }

    get content(): string {
        if (this.removed) {
            return '';
        }
        return contentLinksToBlankTarget(this.article.content);
    }
}
