
import axios from 'axios';
import { Component, Vue } from 'vue-property-decorator';
import { translate } from '@/filters/translate';
import { Constant } from '@/datas/Constant';
import AreaTitle from '@/components/AreaTitle.vue';
import { serverService } from '../services/serverService';
import { IEpubEntry } from '@/vo/Blog';
import { IClient } from '@/vo/Client';

let epubCache: { feed: IEpubEntry[]; expire: number } = null!;

function convertHTMLToEpubEntry(html: string): IEpubEntry {
    let search = html.match(/id="epub-([0-9]+)".*href="(.+?)"/);
    let coverSearch = html.match(/<img\s.*?src="(.+?)"/);
    if (search) {
        return {
            vol: Number(search[1]),
            link: search[2],
            cover: coverSearch && coverSearch[1],
        };
    } else {
        return {
            vol: 0,
            link: '',
            cover: null,
        };
    }
}

@Component({
    methods: {
        translate,
    },
    components: {
        AreaTitle,
    },
})
export default class Epub extends Vue {
    epubs: IEpubEntry[] = null!;

    client: IClient = Constant.GAMELET_PRESS;

    mounted(): void {
        this.getEpubs().then((epubs) => {
            this.epubs = epubs;
        });
    }

    private getEpubs(): Promise<IEpubEntry[]> {
        if (epubCache && Date.now() > epubCache.expire) {
            return Promise.resolve(epubCache.feed);
        }
        return axios.get<string>(serverService.generateServerUrl(Constant.EPUB_URL)).then((html) => {
            let search = html.data.replace(/[\n\r]/g, '').match(/<(a|div) id="epub-[0-9]+"\s.*?<\/a>/g);
            let feed = search ? search.map((line) => convertHTMLToEpubEntry(line)) : [];
            let seperatorIndex = feed.findIndex((f) => !f.cover);
            if (seperatorIndex != -1) {
                // show max 5 covers
                seperatorIndex = Math.min(5, seperatorIndex);
                for (let i = seperatorIndex; i < feed.length; ++i) {
                    feed[i].cover = null;
                }
                feed.splice(seperatorIndex, 0, {
                    link: '',
                    vol: 0,
                    cover: null,
                });
            }
            epubCache = {
                feed: feed,
                expire: Date.now() + 60000 * 100, // 100 minutes
            };
            return epubCache.feed;
        });
    }
}
