import axios from 'axios';
import { DiscussService } from './discussService';
import { ClientService } from './clientService';
import { UserService } from './userService';
import { NetUtil } from '../utils/NetUtil';
import { GMService } from './gmService';
import { ResourceService } from './resourceService';
import { MessagingService } from './messagingService';
import { PayService } from './PayService';
import { TeamService } from './teamService';
import { IUser, InitData } from '@/vo/User';

declare const $: any;

class ServerService {
    private cgUrl = 'https://code.gamelet.com';

    private serverUrl = 'https://gamelet.online';

    public isLocalTesting = false;

    public isLocalServer = false;

    private initData: InitData = null!;

    private initDataListeners: Array<(data: InitData) => void> = [];

    private installedScripts: string[] = [];

    private localStartTime = 0;

    private defaultLocale = 'zh-Hant';

    private gdevLogin: 'GM' | 'ClientGM' | 'GUser' | '' = 'GUser';

    public discuss: DiscussService = new DiscussService('/discuss_api');
    public client: ClientService = new ClientService('/client_api');
    public user: UserService = new UserService('/user_api');
    public team: TeamService = new TeamService('/team_api');
    public gm: GMService = new GMService('/gm_api');
    public resource: ResourceService = new ResourceService('/resource_api');
    public messaging: MessagingService = new MessagingService('/user_api');
    public pay: PayService = new PayService('/pay_api');

    constructor() {
        const forceServer = true;
        this.initData = (window as any)['appData'];
        this.localStartTime = Date.now();

        this.defaultLocale = NetUtil.getQueryStringValue('locale') || 'zh-Hant' || 'en-US';

        if (window.location.href.indexOf('localhost') != -1) {
            if (forceServer) {
                this.serverUrl = 'https://gdev.gamelet.online';
            } else {
                this.serverUrl = 'http://localhost:8090/gltserver';
                this.cgUrl = 'http://localhost:8090/codegames';
                this.isLocalServer = true;
            }
            this.isLocalTesting = true;
        } else {
            if (this.initData && this.initData.home) {
                this.team.setMyOwnTeamship(this.initData.teamship);
                this.serverUrl = this.initData.home.replace(/\/$/, '');
            }
        }
        // wait for 15~30 seconds to record ip
        this.wait(Math.round(15 + Math.random() * 15) * 1000).then(() => this.recordMe());
    }

    get serverTime(): number {
        return this.initData && this.initData.time ? this.initData.time * 1000 + Date.now() - this.localStartTime : Date.now();
    }
    public generateServerUrl(uri: string): string {
        return this.serverUrl + uri;
    }
    public generateLoginUrl(routePath: string): string {
        return this.generateServerUrl('/login') + '?redirectUri=' + encodeURIComponent(routePath);
    }

    get locale(): string {
        return (this.initData && this.initData.locale) || this.defaultLocale;
    }

    get tinymceLocale(): string {
        switch (this.locale) {
            case 'zh-Hant':
                return 'zh_TW';
            case 'zh-Hans':
                return 'zh_CN';
        }
        return 'en_US';
    }

    public updateMe(user?: IUser): boolean {
        if (this.isMe(user)) {
            Object.assign(this.initData.me, user);
            return true;
        }
        return false;
    }

    public get myUserId(): number {
        return this.initData ? this.initData.meId : 0;
    }

    public isSuperAdmin(): boolean {
        return !!(this.initData && this.initData.me && this.initData.me.admin);
        //return true;
    }

    public isMe(user?: IUser): boolean {
        return !!(user && this.initData && this.initData.me && this.initData.me.username == user.username);
    }

    public isGM(): boolean {
        return !!(this.initData && this.initData.me && this.initData.gm);
    }

    public isClientGM(clientCode: string): boolean {
        return !!(this.initData && this.initData.me && this.client.isGameMasterCached(clientCode, this.initData.me));
    }

    public onSteam(): boolean {
        return this.initData && this.initData.me && this.initData && this.initData.me.username.endsWith('@steam');
    }

    public hasLoggedIn(): boolean {
        return !!(this.initData && this.initData.me && !this.initData.me.guest);
    }

    public getInitData(): Promise<InitData> {
        if (this.initData) {
            return Promise.resolve(this.initData);
        }

        return new Promise<InitData>((resolve) => {
            if (!this.initDataListeners.length) {
                this.api('/user_api/get/init_data', {
                    gdevLogin: this.gdevLogin,
                }).then((data) => {
                    this.initData = data;
                    this.team.setMyOwnTeamship(this.initData.teamship);
                    this.serverUrl = data.home.replace(/\/$/, '');
                    this.localStartTime = Date.now();
                    this.initDataListeners.forEach((cb) => cb(data));
                    this.initDataListeners = [];
                });
            }
            this.initDataListeners.push(resolve);
        });
    }

    public isInTW(): boolean {
        return this.initData.geo.country == 'TW';
    }

    private recordMe(): void {
        // wait for 10~20 seconds to record ip
        this.getInitData().then((initData) => {
            if (initData.me) {
                NetUtil.getIPs().then((ips) => {
                    if (ips) {
                        let platform = navigator.platform;
                        const device = window['device'];
                        if (device && device.platform) {
                            platform = device.platform;
                        }
                        ips['platform'] = platform;
                        this.api('/user_api/record/me', {
                            record: NetUtil.encryptJson(ips),
                        });
                    }
                });
            }
        });
    }

    private cgApi(uri: string, data?: any): Promise<any> {
        const options: any = {
            responseType: '',
        };
        uri += (uri.includes('?') ? '&' : '?') + 'locale=' + this.locale;
        let promise: Promise<any>;
        if (data) {
            promise = axios.post(this.cgUrl + uri, data, options);
        } else {
            promise = axios.get(this.cgUrl + uri, options);
        }
        return promise.then((response) => {
            return response.data;
        });
    }

    private api(uri: string, data?: any): Promise<any> {
        const options: any = {
            withCredentials: true,
            responseType: '',
        };
        let url = this.generateServerUrl(uri);
        url += (url.includes('?') ? '&' : '?') + 'locale=' + this.locale;
        let promise: Promise<any>;
        if (this.isLocalTesting && this.gdevLogin) {
            data = Object.assign(
                {
                    gdevLogin: this.gdevLogin,
                },
                data || {}
            );
        }
        if (data) {
            promise = axios.post(url, data, options);
        } else {
            promise = axios.get(url, options);
        }
        return promise.then((response) => {
            return response.data;
        });
    }

    installScript(url: string): Promise<void> {
        if (this.installedScripts.includes(url)) {
            return Promise.resolve();
        }
        return new Promise<void>((resolve) => {
            this.waitJqueryReady().then(() => {
                $.getScript(url, () => {
                    this.installedScripts.push(url);
                    resolve();
                });
            });
        });
    }

    installTinymce(): Promise<void> {
        return this.installScript(
            // 'https://cdn.tiny.cloud/1/470s0r8tu7obyjyr7b7tytrysph5ud6zafgr2gdhmesvhtqn/tinymce/5/tinymce.min.js'
            'https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.10.9/tinymce.min.js'
        );
    }

    waitJqueryReady(): Promise<void> {
        if (typeof $.getScript === 'function') {
            return Promise.resolve();
        } else {
            return this.wait(30).then(() => this.waitJqueryReady());
        }
    }

    wait(duration: number): Promise<void> {
        return new Promise<void>((resolve) => {
            setTimeout(resolve, duration);
        });
    }

    // blog
    getBlogFeed(page: number): Promise<any[]> {
        const key = page + '';
        if (blogFeedCache[key]) {
            return Promise.resolve(blogFeedCache[key]);
        } else {
            return axios.get(this.generateServerUrl('/gltblog/rss/json/' + page)).then((json) => {
                const entryList = json.data.feed.entry || [];
                blogFeedCache[key] = entryList;
                entryList.forEach((entry) => {
                    const id = getBlogId(entry);
                    entry.blogId = id;
                    blogEntryCache[id] = entry;
                });
                return entryList;
            });
        }
    }
    getBlogEntry(blogId: string): Promise<any> {
        if (blogEntryCache[blogId]) {
            return Promise.resolve(blogEntryCache[blogId]);
        } else {
            return axios.get(this.generateServerUrl('/gltblog/entry/json/' + blogId)).then((json) => {
                const entry = json.data.entry;
                entry.blogId = getBlogId(entry);
                blogEntryCache[blogId] = entry;
                return entry;
            });
        }
    }
    getBlogEntryPage(blogId: string): number {
        for (const key in blogFeedCache) {
            const entryList = blogFeedCache[key];
            if (entryList.find((entry) => entry.blogId == blogId)) {
                return Number(key);
            }
        }
        return 1;
    }
}

function getBlogId(entry: any): string {
    return entry.id.$t.match(/post-([0-9]+)$/)[1];
}
const blogFeedCache: { [key: string]: any[] } = {};
const blogEntryCache: { [key: string]: any } = {};

export const serverService = new ServerService();
