
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { translate } from '@/filters/translate';
import { dialogService } from '@/services/dialogService';
import { errorToString } from '@/filters/content-filters';
import { serverService } from '@/services/serverService';
import { DISCUSS_TOP_MOVEMENT, DISCUSS_STATE } from '@/services/discussService';
import { ForumCat } from '@/datas/ForumCat';
import { IArticle } from '@/vo/Discuss';
import { IClient } from '@/vo/Client';

@Component({
    methods: {
        translate,
    },
})
export default class ManageButtons extends Vue {
    @Prop()
    article!: IArticle;

    @Prop()
    client!: IClient;

    @Prop({ default: true })
    isArticleRoot!: boolean;

    @Prop({ default: 0 })
    order!: number;

    @Emit('stateChange')
    onStateChange(): IArticle {
        return this.article;
    }

    moveMentObj = {
        markTop: DISCUSS_TOP_MOVEMENT.TOP,
        cancelTop: DISCUSS_TOP_MOVEMENT.REMOVE,
        moveDown: DISCUSS_TOP_MOVEMENT.DOWN,
        moveUp: DISCUSS_TOP_MOVEMENT.UP,
    };
    moveMentIconObj = {
        markTop: 'fas fa-thumbtack',
        cancelTop: 'fas fa-minus-circle',
        moveDown: 'fas fa-arrow-down',
        moveUp: 'fas fa-arrow-up',
    };

    isGossip(): boolean {
        return this.client && this.client.code == ForumCat.GOSSIP.clientCode;
    }

    isGossip2(): boolean {
        return this.client && this.client.code == ForumCat.GOSSIP2.clientCode;
    }

    canMoveToGossip2(): boolean {
        return !this.isGossip2() && serverService.isGM();
    }

    get thisClosed(): boolean {
        return DISCUSS_STATE.isCloseOrRemoved(this.article.state);
    }

    coolArticle(cool: boolean): void {
        this.openAlertDialog(
            cool ? translate('forum.btn.to_cool') : translate('forum.btn.to_warm'),
            cool ? 'fas fa-snowflake' : 'fas fa-sun',
            () => {
                return serverService.discuss.setArticleCooled(this.article, cool, '').then(() => {
                    this.onStateChange();
                });
            }
        );
    }

    setArticleLock(): void {
        this.openAlertDialog(translate('forum.btn.to_markLock'), 'fas fa-lock', () => {
            return serverService.discuss.setArticleLocked(this.article, true, '').then(() => {
                this.onStateChange();
            });
        });
    }
    removeArticleLock(): void {
        this.openAlertDialog(translate('forum.btn.to_cancelLock'), 'fas fa-unlock', () => {
            return serverService.discuss.setArticleLocked(this.article, false, '').then(() => {
                this.onStateChange();
            });
        });
    }

    setArticleTop(moveMent: DISCUSS_TOP_MOVEMENT, title: string): void {
        this.openAlertDialog(translate(`forum.${title}`), this.moveMentIconObj[title], () => {
            return serverService.discuss.setArticleTop(this.article, moveMent).then(() => {
                this.onStateChange();
            });
        });
    }

    closeArticle(): void {
        this.openGMDialog(translate('forum.gmCloseArticle'), 'fas fa-times', (note) => {
            return serverService.discuss.closeArticle(this.article, note).then(() => {
                this.onStateChange();
            });
        });
    }
    openArticle(): void {
        this.openGMDialog(translate('forum.gmOpenArticle'), 'fas fa-undo', (note) => {
            return serverService.discuss.openArticle(this.article, note).then(() => {
                this.onStateChange();
            });
        });
    }

    private _moveToGossip(forumCat: ForumCat): void {
        this.openGMDialog(translate('forum.gmMoveTo_' + forumCat.clientCode), 'fas fa-plane-departure', (note) => {
            return serverService.discuss
                .setArticleSubject(
                    this.article,
                    {
                        category: forumCat.category,
                        key: forumCat.clientCode!,
                    },
                    note
                )
                .then(() => {
                    this.$router.replace(forumCat.getPostRoute(this.article.id));
                });
        });
    }

    moveToGossip(): void {
        this._moveToGossip(ForumCat.GOSSIP);
    }

    moveToGossip2(): void {
        this._moveToGossip(ForumCat.GOSSIP2);
    }

    private openGMDialog(title: string, titleIcon: string, action: (note: string) => Promise<void>): void {
        dialogService.input(
            {
                titleIcon: titleIcon,
                title: title,
            },
            {
                placeholder: translate('forum.gmNote'),
                okText: translate('btn.confirm'),
                cancelText: translate('btn.cancel'),
                ok: (controller) => {
                    controller.showLoading(translate('processing'));
                    action(controller.getInput())
                        .then(() => {
                            controller.dismiss();
                        })
                        .catch((err) => {
                            controller.hideLoading();
                            dialogService.error({ body: errorToString(err) });
                        });
                },
            }
        );
    }

    private openAlertDialog(title: string, titleIcon: string, action: () => Promise<void>): void {
        dialogService.alert(
            {
                titleIcon: titleIcon,
                title: title,
            },
            {
                placeholder: translate('forum.gmNote'),
                okText: translate('btn.confirm'),
                cancelText: translate('btn.cancel'),
                ok: (controller) => {
                    controller.showLoading(translate('processing'));
                    action()
                        .then(() => {
                            controller.dismiss();
                        })
                        .catch((err) => {
                            controller.hideLoading();
                            dialogService.error({ body: errorToString(err) });
                        });
                },
            }
        );
    }
}
