
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { translate } from '@/filters/translate';

@Component({
    methods: {
        translate,
    },
})
export default class GoogleSearch extends Vue {
    @Prop({ default: '' })
    defaultSearchTerm?: string;

    @Prop({ default: false })
    addSearchLabel?: boolean;

    searchTerm = '';

    @Emit('change')
    onChange(): string {
        return this.searchTerm;
    }

    mounted(): void {
        this.searchTerm = this.defaultSearchTerm || '';
    }

    onSearchSubmit(): void {
        if (this.$route.name != 'search') {
            this.$router.push({
                name: 'search',
                query: { q: this.searchTerm },
            });
        } else {
            this.onChange();
        }
    }
}
