import { Location } from 'vue-router';
import { IUser } from '@/vo/User';

function isTeamUser(user: IUser): boolean {
    return !!user['teamname'];
}

export function getUserRoute(user: IUser, routeName?: string): Location {
    if (!user) {
        return { name: 'profile' };
    } else if (isTeamUser(user)) {
        return { name: routeName || 'team', params: { teamname: user['teamname'] } };
    } else {
        return { name: routeName || 'user', params: { username: user.username } };
    }
}
export function getUserTooltip(user: IUser): string {
    if (isTeamUser(user)) {
        return user.nickname + '\n( ' + user['teamname'] + ' )';
    } else {
        return user.nickname + '\n( ' + user.username + ' )';
    }
}

export function getUserUrl(user: IUser, tab?: string): string {
    if (isCgUser(user)) {
        return 'https://code.gamelet.com/profile/' + user.nickname;
    }
    let url = '/user/' + user.username;
    if (user['teamname']) {
        url = '/team/' + user['teamname'];
    }
    if (tab) {
        url += '/' + tab;
    }
    return url;
}

export function isCgUser(user: IUser): boolean {
    return !!user.username.match(/@.*\.code\.gamelet\.com$/);
}
