import { FriendshipStatus } from '../datas/FriendshipStatus';
import { IGameItem, IPurchaseTeamItem } from '@/vo/PayProduct';
import { ITeam, ITeamRecord, ITeamship, IUser } from '@/vo/User';
import { AbstractSubService } from './abstractSubService';
import { serverService } from './serverService';

export class TeamService extends AbstractSubService {
    private myTeamship?: ITeamship;
    private myTeamshipExpire = 0;

    setMyOwnTeamship(teamship?: ITeamship): void {
        this.myTeamship = teamship;
        this.myTeamshipExpire = Date.now() + 600000;
    }
    getMyOwnTeamship(me?: IUser, forceUpdate?: boolean): Promise<ITeamship | undefined | null> {
        if (Date.now() < this.myTeamshipExpire && !forceUpdate) {
            return Promise.resolve(this.myTeamship);
        } else if (me) {
            return this.api('/get/my_teamship', {}).then((json: any) => {
                this.setMyOwnTeamship(json.teamship);
                return json.teamship;
            });
        } else {
            return Promise.resolve(null);
        }
    }

    updateMyTeam(team?: ITeam): boolean {
        if (this.myTeamship && team && this.myTeamship.team?.teamname == team.teamname) {
            Object.assign(this.myTeamship.team, team);
            return true;
        }
        return false;
    }
    updateMyTeamship(teamship?: ITeamship): boolean {
        if (teamship && this.updateMyTeam(teamship.team)) {
            const myTeamship = this.myTeamship!;
            if (!myTeamship && this.isMyTeamship(teamship)) {
                this.setMyOwnTeamship(teamship);
            } else if (
                teamship.ship.acceptor.username == myTeamship.ship.acceptor.username &&
                teamship.ship.requester.username == myTeamship.ship.requester.username
            ) {
                Object.assign(this.myTeamship!.ship, teamship?.ship);
                return true;
            }
        }
        return false;
    }

    isMyTeamship(teamship?: ITeamship): boolean {
        return !!(
            teamship &&
            teamship.ship.status == FriendshipStatus.ACCEPTED.code &&
            (serverService.isMe(teamship.ship.requester) || serverService.isMe(teamship.ship.acceptor))
        );
    }

    isMyTeam(team: ITeam): boolean {
        const myTeam = this.myTeamship && this.myTeamship.team;
        return !!(team && myTeam && team.teamname == myTeam.teamname);
    }

    searchTeams(term: string, start: number, length: number): Promise<ITeam[]> {
        return this.api('/search/teams', {
            term: term,
            start: start,
            length: length,
        }).then((json: any) => json.teams);
    }

    listMembers(team: ITeam): Promise<ITeamship[]> {
        return this.api('/list/members/' + team.teamname, {}).then((json: any) => json.list);
    }

    getMemberInvitesTotal(team: ITeam): Promise<number> {
        return this.api('/get/member_invites_total/' + team.teamname, {}).then((json: any) => json.total);
    }
    listMemberInvites(team: ITeam, start: number, length: number): Promise<ITeamship[]> {
        return this.api('/list/member_invites/' + team.teamname, {
            start: start,
            length: length,
        }).then((json: any) => json.list);
    }
    // getTeamship(teamname: string): Promise<vo.Teamship> {
    //     return this.api(`/get/teamship/}/${teamname}`, {})
    //         .then((json: any) => json.teamship);
    // }

    setTeamship(teamship: ITeamship, status: FriendshipStatus): Promise<ITeamship> {
        return this.api(`/set/teamship/${teamship.user!.username}/${teamship.team!.teamname}/${status.code}`, {}).then(
            (json: any) => {
                onTeamshipUpdate(teamship, json.teamship);
                return json.teamship;
            }
        );
    }
    getUserTeamship(user: IUser): Promise<ITeamship> {
        const myTeamship = this.myTeamship;
        return this.api(`/get/user_teamship/${user.username}`, {
            teamname: (myTeamship && myTeamship.team?.teamname) || '',
        }).then((json: any) => {
            onTeamshipUpdate(null, json.teamship);
            return json.teamship;
        });
    }

    getMyTeamship(teamname: string): Promise<{ teamship: ITeamship; team: ITeam }> {
        return this.api(`/get/my_teamship/${teamname}`, {}).then((json: any) => {
            onTeamshipUpdate(null, json.teamship);
            onTeamUpdate(null, json.team);
            return json;
        });
    }
    getCreateTeamItem(): Promise<IPurchaseTeamItem> {
        return this.api('/get/create_team_item', {});
    }
    createTeam(teamname: string, pays: { dollars: number; points: number }): Promise<ITeamship> {
        return this.api('/create/team', {
            teamname: teamname,
            pays: pays,
        }).then((json: any) => {
            this.setMyOwnTeamship(json.teamship);
            return json.teamship;
        });
    }
    saveTeam(
        team: ITeam,
        data: {
            nickname?: string;
            mood?: string;
            icon?: string;
        }
    ): Promise<ITeam> {
        return this.api(`/save/team/${team.teamname}`, {
            data: data,
        }).then((json: any) => {
            team.mood = json.team.mood;
            onTeamUpdate(team, json.team);
            return team;
        });
    }

    setTeamSizeDry(team: ITeam): Promise<IPurchaseTeamItem> {
        return this.api(`/set/team_size/${team.teamname}`, {
            dry: 1,
        }).then((json: any) => {
            onTeamUpdate(team, json.team);
            return json;
        });
    }

    setTeamSize(
        team: ITeam,
        size: number,
        pays: { dollars: number; points: number }
    ): Promise<{
        item: IGameItem;
        team: ITeam;
    }> {
        return this.api(`/set/team_size/${team.teamname}`, {
            size: size,
            pays: pays,
        }).then((json: any) => {
            onTeamUpdate(team, json.team);
            return json;
        });
    }

    updateTeamship(
        teamship: ITeamship,
        options: {
            title: string;
            settings: number;
        }
    ): Promise<ITeamship> {
        return this.api(`/update/teamship/${teamship.team!.teamname}/${teamship.user!.username}`, options).then((json: any) => {
            const newteamship = json.teamship;
            newteamship.team = teamship.team;
            newteamship.user = teamship.user;
            onTeamshipUpdate(teamship, newteamship);
            return newteamship;
        });
    }

    listRecords(
        team: ITeam,
        options: {
            action?: string;
            operator?: string;
            user?: string;
            time?: number;
        },
        start: number,
        length: number
    ): Promise<ITeamRecord[]> {
        return this.api(`/list/records/${team.teamname}`, {
            options: options,
            start: start,
            length: length,
        }).then((json: any) => json.list);
    }
}
function onTeamshipUpdate(teamship: ITeamship | null, updated: ITeamship): void {
    if (teamship && updated && teamship.team?.teamname == updated.team?.teamname) {
        teamship.team && updated.team && Object.assign(teamship.team, updated.team);
        Object.assign(teamship.ship, updated.ship);
    }
    serverService.team.updateMyTeamship(updated);
}

function onTeamUpdate(team: ITeam | null, updated: ITeam): void {
    if (team && updated && team.teamname == updated.teamname) {
        Object.assign(team, updated);
    }
    serverService.team.updateMyTeam(updated);
}
