declare const $: any;

const mobileMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

export function isMobile(): boolean {
    return mobileMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}

export function isDarkMode(): boolean {
    return $(document.body).hasClass('theme-dark');
}

export function showSideAds(): boolean {
    return window.innerWidth > 999;
}
