
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { timestampToTimeAgo, timestampToDateTime } from '@/filters/timestampToString';
import { getClientCoverBgImg, getClientPlayUrl, getClientForumRoute } from '@/filters/game-filters';
import { getUserRoute, getUserTooltip } from '@/filters/user-filters';
import { isMobile } from '@/filters/mobile';
import { translate } from '@/filters/translate';
import { Location } from 'vue-router';
import { DISCUSS_CATEGORY, DISCUSS_STATE } from '@/services/discussService';
import { ForumCat } from '../../datas/ForumCat';
import { serverService } from '@/services/serverService';
import { dialogService } from '@/services/dialogService';
import { errorToString } from '@/filters/content-filters';
import ManageButtons from './ManageButtons.vue';
import { IArticle } from '@/vo/Discuss';
import { IClient } from '@/vo/Client';

@Component({
    components: {
        ManageButtons,
    },
    methods: {
        timestampToTimeAgo,
        getUserRoute,
        getUserTooltip,
        getClientCoverBgImg,
        getClientPlayUrl,
        getClientForumRoute,
        isMobile,
        translate,
    },
})
export default class PostListItem extends Vue {
    @Prop()
    category!: DISCUSS_CATEGORY;

    @Prop()
    article!: IArticle;

    @Prop()
    client!: IClient;

    @Prop({ default: false })
    showClient?: boolean;

    @Prop()
    fixedForum?: ForumCat;

    @Prop({ default: false })
    isManager!: boolean;

    @Prop({ default: 0 })
    order!: number;

    @Prop({ default: false })
    unshowTop!: boolean;

    @Emit('stateChange')
    onStateChange(): IArticle {
        return this.article;
    }

    get isNews(): boolean {
        return this.category == DISCUSS_CATEGORY.NEWS;
    }

    toRoute(): Location {
        if (this.fixedForum) {
            return {
                name: this.fixedForum.route.name + '_post',
                params: Object.assign(
                    {
                        articleId: this.article.id + '',
                    },
                    this.fixedForum.route.params
                ),
            };
        }
        return {
            name: 'forum_client_post',
            params: {
                category: (this.category || DISCUSS_CATEGORY.FORUM).code,
                clientCode: this.client.code,
                articleId: this.article.id + '',
            },
        };
    }

    public $refs!: Vue['$refs'] & {
        hashTag: HTMLDivElement;
    };

    get showCreateTime(): boolean {
        return timestampToTimeAgo(this.article.createTime! * 1000) != timestampToTimeAgo(this.article.modifyTime! * 1000);
    }
    getArticleTime(article: IArticle): number {
        return (article.modifyTime || article.createTime) * 1000;
    }
    getTimeTitle(article: IArticle): string {
        return timestampToDateTime(this.getArticleTime(article));
    }

    get isOpen(): boolean {
        return !this.isClose;
    }
    get isClose(): boolean {
        return DISCUSS_STATE.isCloseOrRemoved(this.article.state);
    }
    get isDialog(): boolean {
        return this.article.state == DISCUSS_STATE.DIALOG.code;
    }
    isArticleClose(article: IArticle): boolean {
        return DISCUSS_STATE.isCloseOrRemoved(article.state);
    }
    get isViewable(): boolean {
        if (this.isOpen) {
            if (this.isDialog) {
                return this.isManager || serverService.isMe(this.article.author);
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    get isTop(): boolean {
        return !!this.article.top && !this.unshowTop;
    }

    getContent(article: IArticle): string {
        return article.content
            .replace(/<img.*?>/gi, '<i class="fas fa-image"></i> ')
            .replace(/<a\s.*?>/gi, '<a>')
            .replace(/<(table|tr|td)\s.*?>/gi, '<span>')
            .replace(/<\/(table|tr|td)>/gi, '</span>')
            .replace(
                /(<div.*?>|<\/div>|<p.*?>|<\/p>|<ul.*?>|<\/ul>|<ol.*?>|<\/ol>|<\/li>|<h\d.*?>|<\/h\d>|<br.*?>|<hr.*?>)/gi,
                ' '
            )
            .replace(/<li.*?>/gi, '•')
            .replace(/<(.*?)\sstyle=['"].*?['"](.*?)>/gi, '<$1$2>');
    }

    private openAlertDialog(title: string, titleIcon: string, action: () => Promise<void>): void {
        dialogService.alert(
            {
                titleIcon: titleIcon,
                title: title,
            },
            {
                placeholder: translate('forum.gmNote'),
                okText: translate('btn.confirm'),
                cancelText: translate('btn.cancel'),
                ok: (controller) => {
                    controller.showLoading(translate('processing'));
                    action()
                        .then(() => {
                            controller.dismiss();
                        })
                        .catch((err) => {
                            controller.hideLoading();
                            dialogService.error({ body: errorToString(err) });
                        });
                },
            }
        );
    }
}
