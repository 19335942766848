import { importTranslation } from '../translate';

importTranslation('zh-Hant', {
    game: {
        recommendGames: '推薦遊戲',
        allGames: '所有遊戲',
        betaGames: 'Beta遊戲區',
        onlinePpl: '目前 {ppl} 人在線',
        trailer: '遊戲預告片',
        trailer2: '預告片',
        seeMore: {
            title: '看更多遊戲!',
            subtitle: '看看設計師們發表的作品',
        },
        btn: {
            play: '馬上玩',
            votes: '{votes}則評論',
            report: '回報問題',
            forum: '遊戲討論',
            viewClientGM: '查看遊戲管理員',
            news: '更新與公告',
            review: '分析評論',
        },
        forum: {
            title: '聊聊這個遊戲',
            officialTitle: '討論串',
        },
        report: {
            title: '問題回報',
            officialTitle: '問題回報',
        },
        contribute: {
            title: '投稿專區',
            officialTitle: '稿件',
        },
        review: {
            title: '分析評論',
            officialTitle: '評論',
        },
        manager: {
            title: '管理員名單',
            nobody: '這個遊戲還沒有管理員',
        },
        news: {
            title: '更新與公告',
            officialTitle: '公告',
        },
        order: {
            new: '最新',
            rate: '好評',
            ppl: '線上人數',
        },
        betaMessages: [
            '歡迎來到Beta遊戲區，這裏是尚未完成測試的遊戲集散地。',
            '嘎姆的遊戲需要擁有足夠的玩家評論才能正式上架。如果在這兒找到值得一提的遊戲，請留下你的評論讓更多人可以看見這些遊戲。',
            '在這一頁點擊遊戲接著按下星星就可以前往遊戲的評論區（您需要遊玩遊戲{min}分鐘以上才能發表評論）。',
        ],
        sysTitle: {
            btn: {
                toggleSys: '切換成賽事',
                toggleGame: '切換回遊戲',
            },
            eventsTitle: '嘎姆賽事',
            forumTitle: '討論賽事',
            newsTitle: '賽事公告',
            labelOrganizer: '主辦成員',
            labelMembers: '主辦成員',
            title: '玩家頭銜設定',
            gameName: '賽事名稱',
            gameTime: '比賽日期',
            gameDesc: '賽事介紹',
            gamePpls: '參賽人數',
            addItem: '增加頭銜',
            itemName: '頭銜稱號',
            addUserItem: '賦予玩家頭銜',
            gameResult: '賽事結果',
            addVideo: '增加實況',
            videoUrl: 'Youtube網址',
            status: {
                pub: '公開',
                hidden: '隱藏',
                sponsor: '主辦',
            },
        },
    },
    gameBadges: {
        title: '遊戲勳章',
        owned: '已獲得勳章',
        missing: '還沒得到的勳章',
    },
    gameBadge: {
        srcGame: '勳章出自',
        timeGet: '你在 {firstTime} 獲得了這枚勳章',
        firstGet: '你 {firstTime} 第一次獲得這枚勳章',
        lastGet: '截至 {lastTime} 共取得 {count} 次',
        awardPercent: '約有 {percentage}% 的玩家擁有這枚勳章',
        notOwned: '你尚未獲得這枚勳章',
        rarity: {
            common: '標準配備',
            uncommon: '非同尋常',
            rare: '稀有勳章',
            epic: '史詩勳章',
            legendary: '傳奇勳章',
        },
    },
});

export const game_translation_zh = 1;
