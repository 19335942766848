import Vue from 'vue';
import VueRouter from 'vue-router';

export function global_init(appName: string, app: never, router: VueRouter): void {
    Vue.config.productionTip = false;
    setupGlobalProps();

    const element = (document.getElementById(`app-${appName}`) || document.getElementById(`app`)) as HTMLDivElement;

    new Vue({
        router,
        render: (h) => h(app),
    }).$mount('#' + element.id);
}

function setupGlobalProps(): void {
    Vue.mixin({
        data: function () {
            return {
                get tinymceApiKey() {
                    // return '470s0r8tu7obyjyr7b7tytrysph5ud6zafgr2gdhmesvhtqn';
                    return '';
                },
            };
        },
    });
}
