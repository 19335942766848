
import { Component, Prop, Vue } from 'vue-property-decorator';
import { translate } from '@/filters/translate';
import { Location } from 'vue-router';
import { ForumCat } from '../datas/ForumCat';
import { isMobile } from '@/filters/mobile';

@Component({
    methods: {
        isMobile,
        translate,
    },
})
export default class Footer extends Vue {
    @Prop({ default: false })
    flat?: boolean;

    getSiteReportForumRoute(): Location {
        return ForumCat.REPORT.route;
    }
}
