import { importTranslation } from '../translate';

importTranslation('zh-Hant', {
    aboutUs: {
        gmTeam: {
            tab: '網站管理團隊',
            content: [
                '我們是嘎姆團隊，負責嘎姆官網的建置、協助創作者將遊戲在官網上架，並監督各遊戲的社群管理。',
                '嘎姆團隊始於2015年，由站長親自邀請在嘎姆社群間較有名望的玩家組成，旨在調解玩家之間的紛爭，幫助站長維持嘎姆的善良風氣。',
                '2019年，網站管理團隊的成員在長期合作的過程中建立了互信與強大的羈絆，並參與了嘎姆的重建。',
            ],
        },
        editors: {
            tab: '刊物編輯群',
            content: [
                '「嘎姆編輯群」成立於2016年，由一群喜愛編輯與寫作的嘎姆玩家組成的愛好會，以搜文、訪談、創作等方式定期在社群間發行電子刊物，分享嘎姆趣事及小說共賞。',
                '2019年在新任總編的帶領下，為編輯群建立了完整的分工制度，定期審查與召募新血，幫助動盪的嘎姆留下歷史的文字。',
            ],
        },
        title: {
            aboutUs: '關於我們',
            teamMember: '團隊成員',
            teamBoard: '團隊討論區',
            contact: '聯絡我們',
            email: 'Email',
            facebook: '臉書',
            website: '網站',
            adminFacebook: '站長臉書',
            teamFacebook: '團隊臉書',
        },
    },
    history: {
        events: [
            {
                year: 2007,
                month: '三月',
                text: ['力可股份有限公司成立'],
            },
            {
                year: 2008,
                month: '八月',
                text: ['嘎姆擂台遊戲網站問世(tw.gamelet.com)'],
            },
            {
                month: '九月',
                text: [
                    '推出新遊戲「黑森林傑克」',
                    '推出新遊戲「衝出大氣層」',
                    '推出新遊戲「無差別五子棋」',
                    '推出新遊戲「槌哥大亂鬥」',
                ],
            },
            {
                month: '十月',
                text: ['推出新遊戲「物換星移」', '推出新遊戲「坦克大決戰Online」', '推出新遊戲「嘎姆寵物連連看」'],
            },
            {
                year: 2009,
                month: '一月',
                text: ['推出新遊戲「光暈戰記」', '推出新遊戲「暗棋無雙」', '推出新遊戲「猜數字」', '「槌哥大亂鬥」下架'],
            },
            {
                month: '四月',
                text: ['推出新遊戲「水晶挖挖哇」', '推出新遊戲「坦克戰隊」', '推出新遊戲「蛇來也」'],
            },
            {
                month: '七月',
                text: ['推出新遊戲「童話99」', '「水晶挖挖哇」於臉書上架', '「暗棋無雙」於臉書上架', '「蛇來也」於臉書上架'],
            },
            {
                month: '八月',
                text: ['「衝出大氣層」於臉書上架', '「童話99」於臉書上架', '「光暈戰記」於臉書上架'],
            },
            {
                month: '十月',
                text: ['推出臉書限定「海賊大老二」', '推出臉書限定「麻將小窩」'],
            },
            {
                year: 2010,
                month: '二月',
                text: ['推出嘎姆臉書雙平台遊戲「怪朋友」'],
            },
            {
                month: '五月',
                text: ['「怪朋友」改名為「寵物秘境」'],
            },
            {
                month: '八月',
                text: ['推出臉書獨占遊戲「Pizza世界」'],
            },
            {
                year: 2011,
                month: '一月',
                text: ['推出新遊戲「光之繼承」'],
            },
            {
                month: '八月',
                text: ['「寵物秘境」改名為「怪朋友Online」'],
            },
            {
                month: '十月',
                text: ['光暈戰記推出「玩家人物造型」及「愛恨情仇」系統'],
            },
            {
                year: 2012,
                month: '一月',
                text: ['光暈戰記推出第二季內容「屠魔英雄」', '嘎姆擂台賀歲影片徵選競賽'],
            },
            {
                month: '十一月',
                text: ['推出新遊戲「魔女戰役」', '光暈戰記推出「光暈同人陣Alpha」'],
            },
            {
                year: 2013,
                month: '二月',
                text: ['「光暈同人陣」正式上線'],
            },
            {
                month: '八月',
                text: ['光暈同人陣票選活動「同人奧斯卡」'],
            },
            {
                month: '十月',
                text: ['光暈戰記推出新模式「殭屍來襲」'],
            },
            {
                month: '十一月',
                text: ['光暈戰記推出「一元特價」系統'],
            },
            {
                year: 2014,
                month: '一月',
                text: ['光暈戰記新年徵稿活動「新春對聯」'],
            },
            {
                month: '二月',
                text: ['推出新遊戲「公主未成年」'],
            },
            {
                month: '六月',
                text: ['嘎姆擂台入不敷出，初代團隊宣告解散'],
            },
            {
                year: 2015,
                month: '二月',
                text: ['光暈戰記推出新機制「緊急事件」'],
            },
            {
                month: '八月',
                text: ['「嘎姆點數秘境」正式推出，嘎姆擂台轉型為非營利'],
            },
            {
                month: '九月',
                text: ['嘎姆伺服器移轉至小哈片刻的私人機房', '光暈戰記推出「電競場」'],
            },
            {
                month: '十月',
                text: ['光暈戰記推出「路亞修行」(LUA)', '光暈戰記「萬聖節自創角色大賽」'],
            },
            {
                year: 2016,
                month: '一月',
                text: ['嘎姆召喚玩家組成「嘎姆管理團隊」'],
            },
            {
                month: '三月',
                text: ['由幻筆發起，近二十位作者共筆的第一期嘎姆刊物出刊'],
            },
            {
                month: '六月',
                text: ['「電競委員會」成立，由玩家組成委員會代理官方籌辦比賽'],
            },
            {
                month: '七月',
                text: [
                    '電競委員會主辦「試辦生存戰」',
                    '嘎姆伺服器二度移轉至新機房',
                    '伺服器嚴重毀損（<a href="//blog.gamelet.com/2016/08/blog-post_4.html" target="_blank">七日天罰事件</a>）',
                ],
            },
            {
                month: '八月',
                text: ['電競委員會主辦「生存戰正式賽」', '電競委員會主辦「戰鬥籃球聯賽」', '光暈戰記激戰區推出全新「MMO RPG」'],
            },
            {
                year: 2017,
                month: '一月',
                text: ['「<a href="//blog.gamelet.com" target="_blank">嘎姆遊刊</a>」部落格啟用'],
            },
            {
                month: '七月',
                text: ['電競委員會主辦「程式寫手-路亞靈魂」'],
            },
            {
                month: '八月',
                text: ['嘎姆官方電子期刊Vol.2出刊'],
            },
            {
                year: 2018,
                month: '四月',
                text: ['嘎姆官方旗下遊戲製作網站「<a href="//code.gamelet.com" target="_blank">摳的嘎姆</a>」正式推出'],
            },
            {
                month: '八月',
                text: ['嘎姆官方電子期刊Vol.3出刊'],
            },
            {
                year: 2019,
                month: '一月',
                text: ['摳的嘎姆開課，時程三個月的免費網路課程'],
            },
            {
                month: '六月',
                text: ['由幽冥接手主編，嘎姆官方電子期刊Vol.4出刊'],
            },
            {
                month: '十一月',
                text: ['嘎姆伺服器損毀，舊版嘎姆停止運作'],
            },
            {
                month: '十二月',
                text: ['由「嘎姆管理團隊」於Discord成立臨時社群聚集地。', '「嘎姆管理團隊」著手開始新網站的計畫與建置'],
            },
            {
                year: 2020,
                month: '一月',
                text: ['「光暈戰記重製版」Alpha測試機上架於「摳的嘎姆」平台', '光暈戰記重製版推出新年活動「年獸來襲」'],
            },
            {
                month: '二月',
                text: ['新版嘎姆擂台遊戲網站(gamelet.online)簡易版架設完畢'],
            },
            {
                month: '三月',
                text: [
                    '嘎姆團隊於摳的嘎姆平台上共同開發「COVID-19 CommandCentre」',
                    '並參賽 COVID-19 Global Hackathon 1.0 全球肺炎黑客松',
                ],
            },
            {
                month: '六月',
                text: [
                    '新版嘎姆擂台遊戲網站重建工程開始',
                    '嘎姆官方期刊編輯群改名為「嘎姆編輯群」',
                    '光暈戰記重製版的官方任務重建工程開始',
                    '嘎姆官方電子期刊Vol.5出刊',
                ],
            },
            {
                month: '七月',
                text: ['新版光暈戰記之「C.G.同人陣」系統公開'],
            },
            {
                month: '十月',
                text: ['開放「摳的嘎姆」平台上的遊戲發佈至新版嘎姆網站'],
            },
            {
                month: '十二月',
                text: ['新版嘎姆擂台遊戲網站正式架設完畢(gamelet.online)', '嘎姆編輯群重新啟動人員招募與對外徵稿作業'],
            },
            {
                year: 2021,
                month: '二月',
                text: ['嘎姆擂台官方Discord臨時討論區功成身退'],
            },
        ],
    },
    cg: {
        title: '摳的嘎姆',
        btn: {
            goCG: '去看看',
        },
        intro: [
            '<a href="//code.gamelet.com">Code.Gamelet.com</a> (簡稱CG) 是個適合各種程度的夢想家製作遊戲的平台。',
            '借由圖案素材分享、音效分享、甚至是遊戲引擎的分享，遊戲設計師可以跨越程式寫作的障礙，將遊戲點子化為真實，並在這個平台上和其他玩家分享。',
        ],
        compareUser: [
            {
                title: '遊戲製作',
                features: [
                    '適合<b>想製作</b>遊戲，但不知道從何下手的人',
                    '想要用<b>最快速</b>度將想到的點子變成遊戲的人',
                    '使用<b>內建</b>遊戲編輯器',
                ],
            },
            {
                title: '程式設計',
                features: [
                    '想學<b>寫程式</b>，但拿不定主意要學什麼語言的人',
                    '已經會寫程式，但很<b>想觀摩</b>大師以求精進的人',
                    '使用<b>內建</b> Typescript 整合開發環境',
                ],
            },
        ],
        shareFeatures: [
            '<b>共享</b>圖片/音效/素材/各式編輯器',
            'CG提供資料庫/<b>即時連線</b>伺服器/發佈網站',
            '使用CG一鍵轉換<b>手機</b>應用程式',
            '和嘎姆的玩家及設計高手們切搓<b>成長</b>',
        ],
        compareVideo: [
            {
                title: '使用範例',
                video: 'https://www.youtube.com/embed/JYMpMDhBjs0',
            },
            {
                title: '使用範例',
                video: 'https://www.youtube.com/embed/Z3HhDTHtntM?start=2640',
            },
        ],
    },
});

export const aboutUs_translation_zh = 1;
