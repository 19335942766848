const all: ItemStatus[] = [];

export class ItemStatus {
    public static DISABLED = new ItemStatus('disabled');
    public static HIDDEN = new ItemStatus('hidden');
    public static PUB = new ItemStatus('pub');
    public static PUB_TO_OWNER = new ItemStatus('pubToOwner');
    public static DEV = new ItemStatus('dev');

    public static getByCode(code: string): ItemStatus | undefined {
        return all.find((category) => category.code == code);
    }

    constructor(public code: string) {}
}
