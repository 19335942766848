
import { Component, Vue } from 'vue-property-decorator';
import { translate } from '@/filters/translate';
import { timestampToDate, timestampToDateTime } from '@/filters/timestampToString';
import { numberWithCommas } from '@/filters/number-filters';
import { serverService } from '@/services/serverService';
import CssPreloader from '@/components/CssPreloader.vue';
import AreaTitle from '@/components/AreaTitle.vue';
import { ITransaction, IUserWallet } from '@/vo/PayProduct';
import { TransactionCategory } from '../../datas/TransactionCategory';

@Component({
    methods: {
        translate,
        numberWithCommas,
        timestampToDate,
        timestampToDateTime,
    },
    components: {
        CssPreloader,
        AreaTitle,
    },
})
export default class Deposit extends Vue {
    wallet: IUserWallet | null = null;

    gltDollarIcon = serverService.generateServerUrl('/img/store/glt_dollar.png');
    gltPointIcon = serverService.generateServerUrl('/img/store/glt_point.png');

    history: {
        startDate: string;
        loading: boolean;
        list: ITransaction[];
        hasMore: boolean;
    } | null = null;

    mounted(): void {
        this.syncWallet(true);
    }

    syncWallet(force?: boolean): void {
        if (this.wallet || force) {
            this.wallet = null;
            serverService.user.getMyWallet().then((wallet) => {
                this.wallet = wallet;
            });
        }
    }

    amountToString(amount: number): string {
        let output = amount > 0 ? '+' : '';
        output += numberWithCommas(amount);
        return output;
    }

    loadHistory(restart?: boolean): void {
        if (!this.history) {
            this.history = {
                startDate: '',
                loading: false,
                list: [],
                hasMore: true,
            };
        } else if (restart) {
            this.history.list = [];
        }
        const history = this.history!;

        let date: Date | null = null;
        if (history.startDate) {
            date = new Date(history.startDate);
        }
        history.loading = true;
        let countPerLoad = 10;
        if (history.list.length >= 20) {
            countPerLoad = 20;
        } else if (history.list.length >= 50) {
            countPerLoad = 30;
        } else if (history.list.length >= 150) {
            countPerLoad = 50;
        }
        serverService.pay.listGltTransactions(date, true, history.list.length, countPerLoad).then((list) => {
            history.list = history.list.concat(list);
            history.loading = false;
            history.hasMore = list.length >= countPerLoad;
        });
    }

    isTeamItem(trans: ITransaction): boolean {
        const gameItem = trans.gameItem!;
        return (
            gameItem &&
            (gameItem.code.startsWith('GameletOnline.setTeamSize') || gameItem.code.startsWith('GameletOnline.createTeam'))
        );
    }

    getGameItemTooltip(trans: ITransaction): string {
        const gameItem = trans.gameItem!;
        return [gameItem.name, '/ ' + trans.client.name + `(${trans.client.code})`].join('\n');
    }
    getHistoryTooltip(trans: ITransaction): string {
        if (trans.category == TransactionCategory.TRANSFER_GLT_POINT.code) {
            let search = trans.gmNote!.match(/^transfer:(.*)->(.*)$/);
            return [
                translate('pay.history.fromCGUser', { username: (search && search[1]) || '?' }),
                translate('pay.history.transNo', { transNo: (search && search[2]) || '?' }),
            ].join('\n');
        }
        return '';
    }
}
