import { Location } from 'vue-router';
import { DISCUSS_CATEGORY } from '../services/discussService';
import { serverService } from '../services/serverService';
import { translate } from './translate';
import { IClient } from '@/vo/Client';
import { IGameBadge } from '../vo/Badge';

export function getClientPlayUrl(game: IClient): string {
    if (serverService.isLocalServer) {
        return `http://localhost:8090/playgames/page/${game.code}`;
    }
    return 'https://' + game.code.toLowerCase().replace(/_/g, '-') + '.gamelet.online';
}
export function getClientOwnerUrl(game: IClient): string {
    return game && game.owner ? 'https://code.gamelet.com/profile/' + game.owner.username : '';
}
export function getClientOwnerTooltip(game: IClient): string {
    if (game && game.owner) {
        return game.owner.name + '\n( ' + game.owner.username + ' )';
    } else {
        return '';
    }
}
export function getClientForumRoute(game: IClient): Location {
    return {
        name: 'forum_client',
        params: {
            category: DISCUSS_CATEGORY.FORUM.code,
            clientCode: game.code,
        },
    };
}
export function getClientReviewRoute(game: IClient): Location {
    return {
        name: 'review_client',
        params: {
            category: DISCUSS_CATEGORY.REVIEW.code,
            clientCode: game.code,
        },
    };
}
export function getClientReportRoute(game: IClient): Location {
    return {
        name: 'forum_client',
        params: {
            category: DISCUSS_CATEGORY.REPORT.code,
            clientCode: game.code,
        },
    };
}
export function getClientNewsRoute(game: IClient): Location {
    return {
        name: 'forum_client',
        params: {
            category: DISCUSS_CATEGORY.NEWS.code,
            clientCode: game.code,
        },
    };
}
export function getClientRouteByCategory(game: IClient, category: DISCUSS_CATEGORY): Location {
    return {
        name: category.routeName,
        params: {
            category: category.code,
            clientCode: game.code,
        },
    };
}
export function getClientCoverBgImg(game: IClient): string {
    return `url("${game.cover}")`;
}

export function clientCodeToName(clientCode: string, category?: string): string {
    const key = `forum.board.${clientCode}.title.${category || 'forum'}`;
    const name = translate(key);
    return name == key ? '' : name;
}

export function isSiteClient(clientCode: string, category: string): boolean {
    return !!clientCodeToName(clientCode, category);
}

export function isUserTitleClient(client: IClient | undefined): boolean | undefined {
    return client && client.sys == 2;
}

export function getBadgePageRoute(client: IClient | string, badge: IGameBadge): Location {
    return {
        name: 'game_badge_page',
        params: {
            clientCode: typeof client === 'string' ? client : client.code,
            badgeCode: badge.code,
        },
    };
}