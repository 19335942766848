
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import GoogleSearch from '@/components/GoogleSearch.vue';
import UserManage from '@/components/UserManage.vue';
import NotiBell from '@/components/NotiBell.vue';
import ThemeBtn from '@/components/ThemeBtn.vue';
import { Route } from 'vue-router';
import { translate } from '@/filters/translate';
import { serverService } from '@/services/serverService';
import { isMobile } from '@/filters/mobile';
import RightNavbar from '@/components/RightNavbar.vue';

@Component({
    methods: {
        translate,
        isMobile,
    },
    components: {
        GoogleSearch,
        UserManage,
        NotiBell,
        ThemeBtn,
        RightNavbar,
    },
})
export default class Appbar extends Vue {
    @Prop({ default: false })
    noShadow?: boolean;

    shrink = false;

    activeBarX = '-200px';

    openRightNavbar = false;

    public $refs!: Vue['$refs'] & {
        games?: HTMLDivElement;
        forums?: HTMLDivElement;
        deposit?: HTMLDivElement;
        blog?: HTMLDivElement;
        coding: HTMLDivElement;
    };

    mounted(): void {
        this.onScroll();
        this.onScroll = this.onScroll.bind(this);
        window.addEventListener('scroll', this.onScroll);
    }

    beforeDestroy(): void {
        window.removeEventListener('scroll', this.onScroll);
    }

    controlRightNavbar(open: boolean): void {
        this.openRightNavbar = open;
        if (this.openRightNavbar) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    }

    private onScroll(): void {
        this.shrink =
            document.body.scrollTop > 80 ||
            document.documentElement.scrollTop > 80;
    }

    @Watch('$route', { immediate: true, deep: true })
    onRouteChange(newVal: Route): void {
        if (this.openRightNavbar) {
            this.controlRightNavbar(false);
        }
        this.updateActiveBar(newVal.name!);
    }
    private updateActiveBar(routeName: string): void {
        if (this.$refs && this.$refs.games) {
            this.activeBarX = '-200px';
            let el = this.$refs[routeName] as HTMLDivElement;
            if (el) {
                this.activeBarX = `${el.offsetLeft + el.offsetWidth / 2 - 1}px`;
            }
        } else {
            setTimeout(() => this.updateActiveBar(routeName), 30);
        }
    }

    public isDebug(): boolean {
        return serverService.isLocalTesting;
    }
}
