import { importTranslation } from '../translate';

importTranslation('zh-Hant', {
    links: {
        games: '所有遊戲',
        forums: '討論區',
        recharge: '線上儲值',
        gameletBlog: {
            fullName: '嘎姆遊刊',
            tab: '嘎姆遊刊',
        },
        codeGamelet: {
            fullName: '摳的嘎姆',
            tab: '摳的嘎姆',
        },
        aboutUs: '關於我們',
        memorabilia: '重大記事',
        problemReport: '嘎姆支援',
        userTerms: '使用者條款',
        privacyPolicy: '隱私權政策',
    },
});

export const links_translation_zh = 1;
