
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { translate } from '@/filters/translate';
import CssPreloader from '@/components/CssPreloader.vue';
import { serverService } from '../../services/serverService';
import { dialogService } from '@/services/dialogService';
import { errorToString } from '@/filters/content-filters';
import { ArrayUtil } from '@/utils/ArrayUtil';
import { timestampToTimeAgo } from '@/filters/timestampToString';
import AreaTitle from '@/components/AreaTitle.vue';
import { IClient, IClientProgress } from '@/vo/Client';

interface ProgressItem {
    progress: IClientProgress;
    loading: boolean;
    edit: IClientProgress | null;
}

@Component({
    components: {
        CssPreloader,
        AreaTitle,
    },
    methods: {
        translate,
    },
})
export default class GameProgress extends Vue {
    @Prop()
    client!: IClient;

    progressList: ProgressItem[] = [];

    idsInOrder: number[] = [];

    loading = true;

    @Watch('client', { immediate: true })
    onClientChange(): void {
        serverService.client
            .listGameMasters(this.client.code)
            .then(() => listClientProgress(this.client, this.isClientGM()))
            .then((value: IClientProgress[]) => {
                this.idsInOrder = [];
                this.progressList = value.map((cp) => {
                    this.idsInOrder.push(cp.id!);
                    return { progress: cp, edit: null, loading: false };
                });
                this.loading = false;
            })
            .then(() => {
                this.loading = false;
            });
    }

    syncIdsOrder(): void {
        let ids = this.progressList.filter((p) => p.progress.id).map((p) => p.progress.id);
        if (ids.join(',') != this.idsInOrder.join(',')) {
            serverService.client
                .setProgressOrder(
                    this.client,
                    this.progressList.map((p) => p.progress)
                )
                .then((list) => {
                    this.idsInOrder = [];
                    let newList = list.map((progress) => {
                        this.idsInOrder.push(progress.id!);
                        let item: ProgressItem = {
                            progress: progress,
                            edit: null,
                            loading: false,
                        };
                        let old = this.progressList.find((p) => p.progress.id == progress.id);
                        if (old) {
                            item.edit = old.edit;
                            item.loading = old.loading;
                        }
                        return item;
                    });
                    let unsaved = this.progressList.filter((p) => !p.progress.id);
                    newList = unsaved.concat(newList);
                    this.progressList = newList;
                });
        }
    }

    isClientGM(): boolean {
        return serverService.isClientGM(this.client.code);
    }

    addProgress(): void {
        let progress: IClientProgress = {
            title: '',
            desc: '',
            order: 0,
            progress: 0,
            total: 100,
        };
        this.progressList.unshift({
            progress: progress,
            edit: progress,
            loading: false,
        });
    }

    undoProgress(progressItem: ProgressItem): void {
        if (progressItem.progress.id) {
            if (progressItem.edit) {
                progressItem.edit = null;
                let order = this.idsInOrder.indexOf(progressItem.progress.id) || 0;
                let list = this.progressList.filter((p) => p.progress.id && p != progressItem);
                let next = list[order];
                if (next) {
                    this.moveProgressTo(progressItem, this.progressList.indexOf(next));
                } else {
                    this.moveProgressTo(progressItem, this.progressList.length - 1);
                }

                this.moveProgressTo(progressItem, order);
            }
        } else {
            ArrayUtil.removeElement(this.progressList, progressItem);
        }
    }

    moveProgress(progressItem: ProgressItem, dIndex: number): void {
        let index = Math.max(0, this.progressList.indexOf(progressItem) + dIndex);
        this.moveProgressTo(progressItem, index);
    }
    moveProgressTo(progressItem: ProgressItem, index: number): void {
        ArrayUtil.removeElement(this.progressList, progressItem);
        this.progressList.splice(Math.min(this.progressList.length, index), 0, progressItem);
    }

    removeProgress(progressItem: ProgressItem): void {
        dialogService.alert(
            {
                title: translate('progress.label.deleteTitle'),
            },
            {
                okText: translate('btn.confirm'),
                cancelText: translate('btn.cancel'),
                ok: (controller) => {
                    if (!progressItem.progress.id) {
                        ArrayUtil.removeElement(this.progressList, progressItem);
                        controller.dismiss();
                    } else {
                        controller.showLoading();
                        serverService.client
                            .removeProgress(this.client, progressItem.progress)
                            .then(() => {
                                ArrayUtil.removeElement(this.progressList, progressItem);
                                ArrayUtil.removeElement(this.idsInOrder, progressItem.progress.id);
                                controller.dismiss();
                            })
                            .catch((err) => {
                                controller.hideLoading();
                                dialogService.error({
                                    title: errorToString(err),
                                });
                            });
                    }
                },
            }
        );
    }

    checkFormat(progress: IClientProgress): boolean {
        if (progress.total <= 0) {
            this.formalAlert(translate('progress.alert.total'));
            return false;
        }
        if (!progress.title) {
            this.formalAlert(translate('progress.alert.total'));
        }
        return true;
    }

    formalAlert(alert: string): void {
        dialogService.alert(
            {
                title: alert,
            },
            {
                okText: translate('btn.close'),
                cancelText: '',
            }
        );
    }

    uploadProgress(progressItem: ProgressItem): void {
        if (progressItem.edit && this.checkFormat(progressItem.edit)) {
            progressItem.loading = true;
            this._setProgress(progressItem)
                .then((progress: IClientProgress) => {
                    progressItem.progress = progress;
                    progressItem.edit = null;
                    if (this.idsInOrder.indexOf(progress.id!) == -1) {
                        this.idsInOrder.unshift(progress.id!);
                    }
                })
                .catch((err) => {
                    dialogService.error({ title: errorToString(err) });
                })
                .then(() => {
                    progressItem.loading = false;
                    this.syncIdsOrder();
                });
        }
    }

    private _setProgress(progressItem: ProgressItem): Promise<IClientProgress> {
        let origin = progressItem.progress;
        let edit = progressItem.edit;
        if (
            !origin.id ||
            origin.title != edit?.title ||
            origin.desc != edit.desc ||
            origin.progress != edit.progress ||
            origin.total != edit.total
        ) {
            return serverService.client.setProgress(this.client, progressItem.edit!);
        } else {
            return Promise.resolve(progressItem.progress);
        }
    }

    onBtnEdit(progressItem: ProgressItem): void {
        progressItem.edit = Object.assign({}, progressItem.progress);
    }

    showDesc(progress: IClientProgress): void {
        let desc = [
            '<div style="text-align:right;font-size:smaller;margin-bottom:10px;">' +
                translate('progress.editTime', {
                    time: timestampToTimeAgo(progress.modifyTime ? progress.modifyTime * 1000 : Date.now()),
                }) +
                '</div>',
            progress.desc.replace(/\n/g, '<br/>'),
        ].join('<p>');
        dialogService.alert(
            {
                title: progress.title,
                body: desc,
                titleIcon: 'fas fa-info-circle',
            },
            {
                okText: translate('btn.close'),
                cancelText: '',
            }
        );
    }

    calcPercentage(progress: IClientProgress): number {
        if (progress.total <= 0) {
            return 100;
        }
        return Math.round(Math.max(0, Math.min(1, progress.progress / progress.total)) * 100);
    }
    getProgressTooltip(progress: IClientProgress): string {
        return [
            '(' +
                translate('progress.editTime', {
                    time: timestampToTimeAgo(progress.modifyTime ? progress.modifyTime * 1000 : Date.now()),
                }) +
                ')',
            progress.desc,
        ].join('\n');
    }
    getProgressClass(progress: IClientProgress): string {
        let percent = this.calcPercentage(progress);
        if (percent < 20) {
            return 'bg-danger';
        } else if (percent < 50) {
            return 'bg-warning';
        } else if (percent < 85) {
            return 'bg-info';
        } else {
            return 'bg-success';
        }
    }
}

const cachedClientProgress: {
    [key: string]: {
        progress?: IClientProgress[];
        time: number;
        promises: {
            resolve: (items: IClientProgress[]) => void;
            reject: (error: any) => void;
        }[];
    };
} = {};

function listClientProgress(client: IClient, isGM: boolean): Promise<IClientProgress[]> {
    let key = `${client.code}/${isGM ? 'gm' : 'user'}`;
    let cache = cachedClientProgress[key];
    if (cache && cache.time + (isGM ? 1000 : 300000) > Date.now()) {
        if (cache.progress) {
            return Promise.resolve(cache.progress);
        } else {
            return new Promise<IClientProgress[]>((resolve, reject) => {
                cache.promises.push({ resolve: resolve, reject: reject });
            });
        }
    }
    cache = {
        time: Date.now(),
        promises: [],
    };
    cachedClientProgress[key] = cache;
    return serverService.client
        .listProgress(client, isGM)
        .then((value) => {
            cache.progress = value;
            cache.promises.forEach((promise) => promise.resolve(value));
            cache.promises.length = 0;
            return value;
        })
        .catch((err) => {
            cache.promises.forEach((promise) => promise.reject(err));
            cache.promises.length = 0;
            throw err;
        });
}
