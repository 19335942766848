import { importTranslation } from '../translate';

importTranslation('en-US', {
    contributeArts: {
        rules: {
            title: 'Specification for submission:',
            size: 'Size: {width} x {height}',
            tip: '* Picture that voilate good customs or infringe copyright are prohibited *',
            chatCover: 'Topic: any derivative work or meme',
            gamesCover: 'Topic: original or derivative work of games that are on Gamelet',
            galleryCover: 'Topic: original work of yours',
        },
        btn: {
            contribute: 'Contribute the covers',
            addFile: 'Select Image',
            submitFile: 'Submit for review',
            filter: {
                showMine: 'Mine only',
                showAll: 'All work',
            },
        },
        noResource: 'No work for far',
        newsTitle: 'Gamelet Announcement',
        uploadTime: 'Uploaded {time}',
        tableTitle: {
            time: 'Time',
            cover: 'Cover',
            author: 'Author',
            state: 'State',
        },
        coverState: {
            pass: 'On Carousels',
            fail: 'Audit Failed',
            waitting: 'Auditing',
        },
    },
});

export const contributeArts_translation_en = 1;
