import { Prop, Vue } from 'vue-property-decorator';
import { FriendshipStatus, TeamshipSetting } from '../../../datas/FriendshipStatus';
import { ITeam, ITeamship, IUser } from '@/vo/User';

export default class BaseTabComp extends Vue {
    @Prop()
    me?: IUser;

    @Prop()
    user?: IUser;

    @Prop()
    team?: ITeam;

    @Prop()
    teamship?: ITeamship;

    @Prop()
    friendshipStatus!: FriendshipStatus;

    get isMe(): boolean {
        return !(!this.me || !this.user) && this.me.username == this.user.username;
    }
    get isManager(): boolean {
        if (this.team) {
            return TeamshipSetting.checkAuth(this.teamship, TeamshipSetting.MANAGE);
        } else {
            return this.isMe;
        }
    }
}
