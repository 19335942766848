
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getClientForumRoute, getClientRouteByCategory } from '@/filters/game-filters';
import { translate } from '@/filters/translate';
import { DISCUSS_CATEGORY } from '@/services/discussService';
import { ExtraButtonInfo } from '@/datas/ExtraButtonInfo';
import { IClient } from '@/vo/Client';

const allCategories: DISCUSS_CATEGORY[] = [];
function listAllCategories(client?: IClient): DISCUSS_CATEGORY[] {
    if (client && client.sys) {
        return [DISCUSS_CATEGORY.FORUM, DISCUSS_CATEGORY.NEWS, DISCUSS_CATEGORY.REPORT];
    }
    if (allCategories.length == 0) {
        allCategories.push(DISCUSS_CATEGORY.FORUM);
        allCategories.push(DISCUSS_CATEGORY.NEWS);
        allCategories.push(DISCUSS_CATEGORY.REVIEW);
        allCategories.push(DISCUSS_CATEGORY.REPORT);
    }
    return allCategories;
}

@Component({
    methods: {
        getClientForumRoute,
        getClientRouteByCategory,
        translate,
    },
})
export default class GameLinksButton extends Vue {
    @Prop()
    client?: IClient;

    @Prop()
    category?: DISCUSS_CATEGORY;

    @Prop()
    extraButtons!: ExtraButtonInfo[];

    @Prop({ default: 'icon' })
    buttonType!: 'icon' | 'text';

    get allCats(): DISCUSS_CATEGORY[] {
        return listAllCategories(this.client);
    }

    getMainLinkCategory(): DISCUSS_CATEGORY {
        return this.category || DISCUSS_CATEGORY.FORUM;
    }

    get isReport(): boolean {
        return this.category == DISCUSS_CATEGORY.REPORT;
    }
    get isForum(): boolean {
        return this.category == DISCUSS_CATEGORY.FORUM;
    }
    get isNews(): boolean {
        return this.category == DISCUSS_CATEGORY.NEWS;
    }
    get isReview(): boolean {
        return this.category == DISCUSS_CATEGORY.REVIEW;
    }
}
