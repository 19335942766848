import { serverService } from './serverService';
import EventEmitter from 'eventemitter3';
export class AbstractSubService extends EventEmitter {
    constructor(private apiPrefix: string) {
        super();
    }

    protected api(uri: string, data?: unknown): Promise<any> {
        return serverService['api'](this.apiPrefix + uri, data);
    }
    protected cgApi(uri: string, data?: unknown): Promise<any> {
        return serverService['cgApi'](uri, data);
    }
    protected wait(duration: number): Promise<void> {
        return serverService.wait(duration);
    }
}
