
import { Component, Prop, Vue } from 'vue-property-decorator';
import { translate } from '@/filters/translate';
import CssPreloader from '@/components/CssPreloader.vue';
import { timestampToTimeAgo, timestampToDateTime } from '@/filters/timestampToString';
import { getUserRoute, getUserTooltip } from '@/filters/user-filters';
import { DISCUSS_CATEGORY, DISCUSS_STATE } from '../../../services/discussService';
import { Location } from 'vue-router';
import { IArticle, IArticlesWithParent } from '@/vo/Discuss';

@Component({
    components: {
        CssPreloader,
    },
    methods: {
        translate,
        timestampToTimeAgo,
        timestampToDateTime,
        getUserRoute,
        getUserTooltip,
    },
})
export default class UserArticlesMsg extends Vue {
    @Prop()
    data!: IArticlesWithParent;

    @Prop()
    msg!: IArticle;

    @Prop()
    category!: DISCUSS_CATEGORY;

    @Prop()
    isRoot?: boolean;

    @Prop()
    hasNext?: boolean;

    get isUserBoard(): boolean {
        return this.category == DISCUSS_CATEGORY.USER_BOARD;
    }

    get closed(): boolean {
        return this.msg.state == DISCUSS_STATE.CLOSE.code || this.data.parent.state == DISCUSS_STATE.CLOSE.code;
    }

    get rootIsMe(): boolean {
        return this.data.parent.author.username == this.data.articles[0].author.username;
    }

    get articleRoute(): Location {
        const data = this.data;
        if (this.category == DISCUSS_CATEGORY.USER_BOARD) {
            return {
                name: 'user_board_message',
                params: {
                    username: data.username!,
                    messageID: data.parent.id + '',
                },
            };
        }

        if (this.category.isForClient()) {
            return {
                name: 'forum_client_post',
                params: {
                    category: this.category.code,
                    clientCode: data.clientCode!,
                    articleId: this.msg.id + '',
                },
            };
        }

        return {
            name: 'home',
        };
    }
}
