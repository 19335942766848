
import { Component } from 'vue-property-decorator';
import { serverService } from '@/services/serverService';
import { translate } from '@/filters/translate';
import BaseTabComp from '../users/components/BaseTabComp';
import TeamRecordsTable from './components/TeamRecordsTable.vue';
import CssPreloader from '@/components/CssPreloader.vue';
import { ITeamRecord, IUser } from '@/vo/User';
import { ALL_TEAMACTION_SET, TeamActionSet } from '../../filters/team-filters';
import { timestampToInputDate } from '../../filters/timestampToString';

@Component({
    components: {
        CssPreloader,
        TeamRecordsTable,
    },
    methods: {
        translate,
    },
})
export default class MembersTab extends BaseTabComp {
    loading = false;
    date = this.todayDate;
    hasMore = false;
    sizePerLoad = 50;

    actionSets = ALL_TEAMACTION_SET;
    selectActionSet = ALL_TEAMACTION_SET[0];

    records: ITeamRecord[] = [];
    options = {
        operator: '',
        actions: [''],
        user: '',
        time: 0,
    };

    get todayDate(): string {
        return timestampToInputDate(Date.now());
    }

    mounted(): void {
        this.loadMore();
    }

    loadMore(): void {
        this.loading = true;
        let date = new Date(this.date);
        if (this.date == this.todayDate) {
            date = new Date();
        }
        this.options.actions = this.selectActionSet.actions.map((a) => a.code);
        this.options.time = Math.round(date.getTime() / 1000) + 86400;
        serverService.team.listRecords(this.team!, this.options, this.records.length, this.sizePerLoad).then((list) => {
            this.records = this.records.concat(list);
            this.loading = false;
            this.hasMore = list.length >= this.sizePerLoad;
        });
    }
    reload(): void {
        this.records = [];
        this.loadMore();
    }

    changeActionSet(actionSet: TeamActionSet): void {
        this.selectActionSet = actionSet;
    }

    onFilterOper(user: IUser | null): void {
        this.options.operator = user ? user.username : '';
        this.reload();
    }
    onFilterUser(user: IUser | null): void {
        this.options.user = user ? user.username : '';
        this.reload();
    }
}
