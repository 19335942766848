
import { Component, Vue, Watch } from 'vue-property-decorator';
import { translate } from '@/filters/translate';
import managementTeamIcon from '../assets/gamelet_icon.png';
import editorGroupIcon from '../assets/gamelet_icon3.png';
import { serverService } from '../services/serverService';
import { Constant } from '@/datas/Constant';
import { getUserTooltip, getUserRoute } from '@/filters/user-filters';
import { Location, Route } from 'vue-router';
import { ForumCat } from '../datas/ForumCat';
import AreaTitle from '@/components/AreaTitle.vue';
import { IUser } from '@/vo/User';

interface AboutUsNavItem {
    code: string;
    icon: string;
    members: IUser[];
    loading: boolean;
    route: Location;
    clientCode: string;
    boardRoute: Location;
}

@Component({
    components: {
        AreaTitle,
    },
    methods: {
        translate,
        getUserTooltip,
        getUserRoute,
    },
})
export default class AboutUs extends Vue {
    navItems: AboutUsNavItem[] = [
        {
            code: 'gmTeam',
            icon: managementTeamIcon,
            members: [],
            loading: false,
            route: { name: 'about_us' },
            clientCode: Constant.SITE_CLIENT.code,
            boardRoute: {
                name: 'gm',
            },
        },
        {
            code: 'editors',
            icon: editorGroupIcon,
            members: [],
            loading: false,
            route: { name: 'about_tab', params: { tab: 'editors' } },
            clientCode: Constant.GAMELET_PRESS.code,
            boardRoute: ForumCat.GAMELET_PRESS.route,
        },
    ];

    tabIndex = 0;

    isMember = false;

    showContactUs = false;

    mounted(): void {
        document.title = translate('localedTitle') + ':' + translate('aboutUs.title.aboutUs');
    }

    @Watch('$route', { immediate: true })
    onRouteChange(newVal: Route): void {
        this.tabIndex = this.navItems.findIndex((item) => item.code == newVal.params.tab);
        if (this.tabIndex == -1) {
            this.tabIndex = 0;
        }
        this.loadMembers(this.tabIndex);
    }

    loadMembers(navIdx: number): void {
        let item = this.navItems[navIdx];
        if (!item.members.length && !item.loading) {
            this.isMember = false;
            item.loading = true;
            serverService.client.listGameMasters(item.clientCode).then((data) => {
                item.members = data;
                item.loading = false;
                this.isMember = this._isMember(item.members);
            });
        } else {
            this.isMember = this._isMember(item.members);
        }
    }

    private _isMember(members: IUser[]): boolean {
        return !!members.find((m) => serverService.isMe(m));
    }

    get locale(): string {
        return serverService.locale;
    }

    toggleContactUs(): void {
        this.showContactUs = true;
    }
}
