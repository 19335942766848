
import { Component, Vue } from 'vue-property-decorator';
import { translate } from '@/filters/translate';
import { ForumCat } from '@/datas/ForumCat';
import TwitterInfor from '@/components/TwitterInfor.vue';
import AreaTitle from '@/components/AreaTitle.vue';
import AdsResponsive from '@/components/ads/AdsResponsive.vue';
import { isMobile, showSideAds } from '@/filters/mobile';
import { getUserRoute } from '@/filters/user-filters';

@Component({
    components: {
        TwitterInfor,
        AreaTitle,
        AdsResponsive,
    },
    methods: {
        translate,
        isMobile,
        showSideAds,
        getUserRoute,
    },
})
export default class ForumCatList extends Vue {
    forumCats: ForumCat[] = ForumCat.listPublic();

    mounted(): void {
        document.title = translate('localedTitle') + ':' + translate('forum.board.root.title');
    }
}
