
import { Component, Prop, Vue } from 'vue-property-decorator';
import { translate } from '@/filters/translate';
import { isMobile } from '@/filters/mobile';

@Component({
    methods: {
        translate,
        isMobile,
    },
})
export default class LeftMenu extends Vue {
    @Prop({ default: false })
    flat?: boolean;
}
