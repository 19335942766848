import { translate } from '../filters/translate';
import { IGMRecord } from '@/vo/GMRecord';

function userLink(record: IGMRecord): string {
    return '/user/' + record.user.username;
}
function articleLink(record: IGMRecord): string {
    return `/forum/${record.client.code}/post/${record.paramInt}`;
}
function clientLink(record: IGMRecord): string {
    return '/forum/' + record.paramStr;
}
function cgOwnerLink(record: IGMRecord): string {
    return '//code.gamelet.com/profile/' + record.paramStr;
}

const all: GMAction[] = [];

export class GMAction {
    public static SUSPEND_USER = new GMAction('suspendUser', true, userLink);
    public static UNSUSPEND_USER = new GMAction('unsuspendUser', false, userLink);

    public static CLOSE_ARTICLE = new GMAction('closeArticle', true, articleLink);
    public static OPEN_ARTICLE = new GMAction('openArticle', false, articleLink);
    public static LOCK_ARTICLE = new GMAction('lockArticle', true, articleLink);
    public static UNLOCK_ARTICLE = new GMAction('unlockArticle', false, articleLink);
    public static COOL_ARTICLE = new GMAction('coolArticle', true, articleLink);
    public static UNCOOL_ARTICLE = new GMAction('uncoolArticle', false, articleLink);
    public static SET_ARTICLE_TOP = new GMAction('setArticleTop', false, articleLink);
    public static SET_ARTICLE_SUBJECT = new GMAction('setArticleSubject', false, articleLink);
    public static LOCK_IP = new GMAction('lockIP', true, userLink);
    public static UNLOCK_IP = new GMAction('unlockIP', false, userLink);
    public static RESET_USER = new GMAction('resetUser', true, userLink);
    public static ALERT_USER = new GMAction('alertUser', true, userLink);
    public static AWARD_GLT_POINT = new GMAction('awardGltPoint', false, userLink);
    public static BATCH_REMOVE_USER_ARTICLE = new GMAction('batchRemoveUserArticle', true, userLink);
    public static SET_ARTICLE_STATUS = new GMAction('setArticleStatus', true, articleLink);

    public static BLOCK_CLIENT = new GMAction('blockClient', true, clientLink);
    public static UNBLOCK_CLIENT = new GMAction('unblockClient', false, clientLink);
    public static BLOCK_CG_OWNER = new GMAction('blockCgOwner', true, cgOwnerLink);
    public static UNBLOCK_CG_OWNER = new GMAction('unblockCgOwner', false, cgOwnerLink);

    public static getByCode(code: string): GMAction | undefined {
        return all.find((action) => action.code == code);
    }

    public static listAll(): GMAction[] {
        return all.slice();
    }

    constructor(public code: string, public negative: boolean, public getLink: (record: IGMRecord) => string) {
        if (!GMAction.getByCode(code)) {
            all.push(this);
        }
    }

    get name(): string {
        return translate('gm.gmAction.' + this.code);
    }
}
