
import '@/dialog.css';
//import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { isMobile } from '@/filters/mobile';
import AppBar from '@/components/AppBar.vue';
import Footer from '@/components/Footer.vue';
import LeftMenu from '@/components/LeftMenu.vue';
import PushNotiBox from '@/components/PushNotiBox.vue';
import { dialogService } from '@/services/dialogService';
import { common_translation_en } from '@/filters/trans/common.en';
import { common_translation_zh } from '@/filters/trans/common.zh';
import { common_translation_cn } from '@/filters/trans/common.cn';
common_translation_en;
common_translation_zh;
common_translation_cn;
import { forum_translation_en } from '@/filters/trans/forum.en';
import { forum_translation_zh } from '@/filters/trans/forum.zh';
import { forum_translation_cn } from '@/filters/trans/forum.cn';
forum_translation_en;
forum_translation_zh;
forum_translation_cn;
import { game_translation_en } from '@/filters/trans/game.en';
import { game_translation_zh } from '@/filters/trans/game.zh';
import { game_translation_cn } from '@/filters/trans/game.cn';
game_translation_en;
game_translation_zh;
game_translation_cn;
import { links_translation_en } from '@/filters/trans/links.en';
import { links_translation_zh } from '@/filters/trans/links.zh';
import { links_translation_cn } from '@/filters/trans/links.cn';
links_translation_en;
links_translation_zh;
links_translation_cn;
import { gameletQA_translation_en } from '@/filters/trans/gameletQA.en';
import { gameletQA_translation_zh } from '@/filters/trans/gameletQA.zh';
import { gameletQA_translation_cn } from '@/filters/trans/gameletQA.cn';
gameletQA_translation_en;
gameletQA_translation_zh;
gameletQA_translation_cn;
import { aboutUs_translation_en } from '@/filters/trans/aboutUs.en';
import { aboutUs_translation_zh } from '@/filters/trans/aboutUs.zh';
import { aboutUs_translation_cn } from '@/filters/trans/aboutUs.cn';
aboutUs_translation_en;
aboutUs_translation_zh;
aboutUs_translation_cn;
import { blog_translation_en } from '@/filters/trans/blog.en';
import { blog_translation_zh } from '@/filters/trans/blog.zh';
import { blog_translation_cn } from '@/filters/trans/blog.cn';
blog_translation_en;
blog_translation_zh;
blog_translation_cn;
import { contributeArts_translation_en } from '@/filters/trans/contributeArts.en';
import { contributeArts_translation_zh } from '@/filters/trans/contributeArts.zh';
import { contributeArts_translation_cn } from '@/filters/trans/contributeArts.cn';
contributeArts_translation_en;
contributeArts_translation_zh;
contributeArts_translation_cn;
import { pay_translation_en } from '@/filters/trans/pay.en';
import { pay_translation_zh } from '@/filters/trans/pay.zh';
import { pay_translation_cn } from '@/filters/trans/pay.cn';
pay_translation_en;
pay_translation_zh;
pay_translation_cn;

import Notifications from 'vue-notification';
Vue.use(Notifications);

import VuejsDialog from 'vuejs-dialog';
import { Constant } from '@/datas/Constant';
import AlertDialog from '@/components/dialogs/AlertDialog.vue';
import InputDialog from '@/components/dialogs/InputDialog.vue';
import ChooseDialog from '@/components/dialogs/ChooseDialog.vue';
import { serverService } from '@/services/serverService';
import { Route } from 'vue-router';
import ChoosePayByDialog from '@/components/dialogs/ChoosePayByDialog.vue';
import SearchUserDialog from '@/components/dialogs/SearchUserDialog.vue';
Vue.use(VuejsDialog);
Vue['dialog'].registerComponent(Constant.VIEW.ALERT, AlertDialog);
Vue['dialog'].registerComponent(Constant.VIEW.INPUT, InputDialog);
Vue['dialog'].registerComponent(Constant.VIEW.CHOOSE, ChooseDialog);
Vue['dialog'].registerComponent(Constant.VIEW.CHOOSE_PAYBY, ChoosePayByDialog);
Vue['dialog'].registerComponent(Constant.VIEW.SEARCH_USER, SearchUserDialog);

@Component({
    name: 'app',
    methods: {
        isMobile,
    },
    components: {
        AppBar,
        Footer,
        LeftMenu,
        PushNotiBox,
    },
})
export default class AppHome extends Vue {
    mounted(): void {
        dialogService.initialize(this);
        serverService
            .getInitData()
            .then((initData) => {
                if (initData.me && !initData.me.guest) {
                    serverService
                        .wait(300) // wait for a while, we may pause messaging service in the mean time
                        .then(() => serverService.messaging.initialize(initData, serverService.isLocalTesting))
                        .then(() => serverService.messaging.startSyncNotifications());
                }
            })
            .catch((err) => {
                console.error('app init failed: ', err);
            });
    }

    @Watch('$route', { immediate: true })
    onRouteChange(to: Route): void {
        if (to.name == 'deposit') {
            serverService.messaging.pause();
        } else {
            serverService.messaging.resume();
        }
    }

    get mainClasses(): string[] {
        const list: string[] = ['bodymain'];
        if (this.isFlatRoute) {
            list.push('flat');
        }
        return list;
    }

    get isFlatRoute(): boolean {
        if (this.$route.fullPath.match(/^\/(user|team)(\/|$)/)) {
            return true;
        }

        if (['gameletQA', 'history'].includes(this.$route.name!)) {
            return true;
        }

        return false;
    }

    get localTesting(): boolean {
        return serverService.isLocalTesting;
    }
}
