import { render, staticRenderFns } from "./UserRoot.vue?vue&type=template&id=43fc6e37&scoped=true"
import script from "./UserRoot.vue?vue&type=script&lang=ts"
export * from "./UserRoot.vue?vue&type=script&lang=ts"
import style0 from "./UserRoot.vue?vue&type=style&index=0&id=43fc6e37&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43fc6e37",
  null
  
)

export default component.exports