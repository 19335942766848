import { importTranslation } from '../translate';

importTranslation('zh-Hant', {
    contributeArts: {
        rules: {
            title: '稿件必須符合以下條件：',
            size: '尺寸：{width} x {height}',
            tip: '* 禁止任何侵犯版權或違反善良風俗的圖片 *',
            chatCover: '主題：不限主題的二次創作或梗圖',
            gamesCover: '主題：以站內遊戲為主題的原創或二次創作',
            galleryCover: '主題：不限主題的個人原創作品',
        },
        btn: {
            contribute: '我要投稿封面',
            addFile: '選擇圖片',
            submitFile: '提交封面審核',
            filter: {
                showMine: '僅顯示我的投稿',
                showAll: '顯示所有稿件',
            },
        },
        noResource: '找不到稿件',
        newsTitle: '嘎姆公告',
        uploadTime: '{time}上傳',
        tableTitle: {
            time: '時間',
            cover: '封面',
            author: '作者',
            state: '狀態',
        },
        coverState: {
            pass: '審核通過',
            fail: '未通過審核',
            waitting: '審核中',
        },
    },
});

export const contributeArts_translation_zh = 1;
