
import { Component, Vue, Watch } from 'vue-property-decorator';
import Pagination from '@/components/Pagination.vue';
import PostListItem from '@/forum/components/PostListItem.vue';
import ForumCatCover from '@/contributeArts/ForumCatCover.vue';
import { translate } from '@/filters/translate';
import { Location, Route } from 'vue-router';
import { serverService } from '@/services/serverService';
import { DISCUSS_CATEGORY, DISCUSS_STATE } from '@/services/discussService';
import { getClientLastViewId, RouteResolver, setClientUpRoute, UpRouteData } from '@/utils/RouteResolver';
import { ArrayUtil } from '@/utils/ArrayUtil';
import { ForumCat } from '../../datas/ForumCat';
import { DomUtil } from '../../utils/DomUtil';
import AreaTitle from '@/components/AreaTitle.vue';
import AdsResponsive from '@/components/ads/AdsResponsive.vue';
import { showSideAds } from '@/filters/mobile';
import { IArticle } from '@/vo/Discuss';
import { IClient } from '@/vo/Client';

@Component({
    methods: {
        translate,
        showSideAds,
    },
    components: {
        Pagination,
        PostListItem,
        ForumCatCover,
        AreaTitle,
        AdsResponsive,
    },
})
export default class MixGameArticles extends Vue implements RouteResolver {
    public $refs!: Vue['$refs'] & {
        listTop: HTMLDivElement;
    };

    page = 1;

    pageSize = 20;

    maxPage = 1;

    articles: IArticle[] | null = null;

    forumCat = ForumCat.GAMES;

    forumCats = ForumCat.listPublic();

    scrollTo?: string;

    loadArticlesTotal(): void {
        serverService.discuss.getRootClientArticlesTotal([DISCUSS_STATE.OPEN]).then((total) => {
            this.maxPage = Math.ceil(total / this.pageSize);
        });
    }

    mounted(): void {
        document.title = translate('localedTitle') + ':' + translate('forum.board.games.tab');
    }

    @Watch('$route', { immediate: true })
    onRouteChanged(to: Route, from?: Route): void {
        this.scrollTo = undefined;
        this.loadArticles(Number(to.params.page) || 1);
        this.loadArticlesTotal();
        if (from) {
            // if from is defined, we move to the list
            this.$nextTick(() => {
                DomUtil.moveToElement(this.$refs.listTop);
            });
        } else {
            const focus = getClientLastViewId();
            if (focus) {
                this.scrollTo = 'article_' + focus;
            }
        }
    }

    get category(): DISCUSS_CATEGORY {
        return DISCUSS_CATEGORY.FORUM;
    }

    get routeResolver(): RouteResolver {
        return this;
    }

    resolveRoute(params: { [key: string]: any }): Location {
        return {
            name: 'forum_games_page',
            params: {
                page: params.page + '',
            },
        };
    }
    getUpRoute(): UpRouteData | null {
        return null;
    }

    private loadArticles(page: number): void {
        this.page = page;
        const start = this.pageSize * Math.max(0, this.page - 1);
        this.articles = null;

        serverService.discuss
            .listLatestClientsArticles(DISCUSS_CATEGORY.FORUM, [DISCUSS_STATE.OPEN], start, this.pageSize)
            .then((list) => {
                let clientCodes: string[] = [];
                list.forEach((article) => {
                    ArrayUtil.addUniqueElement(clientCodes, article.clientCode);
                });
                serverService.client.queryClients(clientCodes).then((clients) => {
                    const clientsMap: { [key: string]: IClient } = {};
                    clients.forEach((c) => (clientsMap[c.code] = c));
                    list.forEach((article) => (article.client = clientsMap[article.clientCode!]));
                    this.articles = list;
                    setClientUpRoute(this.resolveRoute({ page: this.page + '' }));

                    const scrollTo = this.scrollTo;
                    this.scrollTo = undefined;
                    if (scrollTo) {
                        this.$nextTick(() => {
                            this.scrollToRefId(scrollTo);
                        });
                    }
                });
            });
    }

    get loading(): boolean {
        return !this.articles;
    }

    private scrollToRefId(id: string) {
        let refs = this.$refs[id] as PostListItem[];
        if (refs && refs.length) {
            DomUtil.moveToElement(refs[0].$refs.hashTag);
        }
    }
}
