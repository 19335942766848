
import { Component, Vue } from 'vue-property-decorator';
import { translate } from '@/filters/translate';
import { isDarkMode } from '@/filters/mobile';
import { serverService } from '@/services/serverService';
import AreaTitle from './AreaTitle.vue';

declare const twttr: any;
declare const $: any;

@Component({
    components: {
        AreaTitle,
    },
    methods: {
        translate,
    },
})
export default class TwitterInfor extends Vue {
    theme = 'light';

    mounted(): void {
        this.theme = isDarkMode() ? 'dark' : 'light';
        serverService
            .installScript('https://platform.twitter.com/widgets.js')
            .then(() => {
                twttr.widgets.load();
            });
    }
}
