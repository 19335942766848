
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { translate } from '@/filters/translate';
import { getUserUrl, getUserTooltip } from '@/filters/user-filters';
import { ITeamRecord, IUser } from '@/vo/User';
import { TeamAction, allTeamshipSettings } from '../../../filters/team-filters';
import { TeamshipSetting } from '../../../datas/FriendshipStatus';
import { unescapeUnicode } from '../../../filters/content-filters';

@Component({
    methods: {
        translate,
        getUserUrl,
        getUserTooltip,
    },
})
export default class TeamRecordsTable extends Vue {
    @Prop()
    records!: ITeamRecord[];

    @Emit('filterUser')
    onFilterUser(user: IUser): IUser {
        return user;
    }
    @Emit('filterOper')
    onFilterOper(user: IUser): IUser {
        return user;
    }

    getOpTooltip(user: IUser, settings: number): string {
        let tooltip = getUserTooltip(user);
        let powers =
            settings == TeamshipSetting.LEADER
                ? [translate('label.team.leader')]
                : allTeamshipSettings.filter((s) => s.setting & settings).map((s) => s.label);
        tooltip += `\n[ ${powers.join(', ')} ]`;
        return tooltip;
    }

    getActionName(record: ITeamRecord): string {
        let action = TeamAction.getByCode(record.action);
        return action!.name;
    }
    getActionParams(record: ITeamRecord): string {
        let params: any[] = [];
        record.paramInt !== undefined && params.push(record.paramInt);
        record.paramStr && params.push(modifyParamStr(record.paramStr));
        return `(${params.join(', ')})`;
    }
    timestampToDateTime(time: number): string {
        const date = new Date(time);
        return `<div>${date.toLocaleDateString()}</div>,&nbsp<div>${date.toLocaleTimeString()}</div>`;
    }
}

function modifyParamStr(str: string): string {
    str = unescapeUnicode(str);
    return str.replace(/"\\\/gassets\\\/.*?"/g, '"' + translate('label.newUrl') + '"');
}
