
import { Component, Emit, Prop } from 'vue-property-decorator';
import { serverService } from '@/services/serverService';
import BaseTabComp from '../users/components/BaseTabComp';
import CssPreloader from '@/components/CssPreloader.vue';
import { getUserTooltip, getUserRoute } from '@/filters/user-filters';
import { translate } from '@/filters/translate';
import { ITeamship } from '@/vo/User';
import { FriendshipStatus, TeamshipSetting } from '@/datas/FriendshipStatus';
import { errorToString } from '@/filters/content-filters';
import { dialogService } from '@/services/dialogService';
import { timestampToDate, timestampToTimeAgo } from '@/filters/timestampToString';
import { allTeamshipSettings, SettingData } from '@/filters/team-filters';
import { getClientPlayUrl } from '../../filters/game-filters';

@Component({
    components: {
        CssPreloader,
    },
    methods: {
        getUserRoute,
        getUserTooltip,
        translate,
    },
})
export default class Members extends BaseTabComp {
    @Prop({ default: 'friends' })
    listType?: string;

    @Prop({ default: 0 })
    total!: number;

    @Prop()
    view!: string;

    @Emit('inviteAccepted')
    inviteAcceptedEvent(teamship: ITeamship): ITeamship {
        return teamship;
    }
    @Emit('memberRemoved')
    memberRemovedEvent(teamship: ITeamship): ITeamship {
        this.friendsTotal--;
        return teamship;
    }

    teamships: ITeamship[] = [];

    loading = true;

    hasMore = false;

    countPerLoad = 30;

    friendsTotal = 0;

    settings = allTeamshipSettings;

    editing: {
        teamship: ITeamship;
        saving?: boolean;
        title: string;
        settings: number;
    } | null = null;

    get isManager(): boolean {
        if (this.team) {
            return TeamshipSetting.checkAuth(this.teamship, TeamshipSetting.REMOVE);
        } else {
            return this.isMe;
        }
    }

    isLeader(tship: ITeamship): boolean {
        return tship.ship.settings == TeamshipSetting.LEADER;
    }

    hasInvitePower(teamship: ITeamship): boolean {
        if (teamship.ship.status != FriendshipStatus.ACCEPTED.code) {
            let creator = teamship.ship.creator;
            if (creator) {
                return serverService.isMe(creator);
            } else {
                return TeamshipSetting.checkAuth(this.teamship, TeamshipSetting.INVITE);
            }
        }
        return false;
    }
    isInvitor(teamship: ITeamship): boolean {
        if (teamship.ship.status != FriendshipStatus.ACCEPTED.code) {
            let creator = teamship.ship.creator;
            return !!(creator && serverService.isMe(creator));
        }
        return false;
    }

    hasSetting(shipSettings: number, setting: SettingData): boolean {
        return ((shipSettings || 0) & setting.setting) != 0;
    }

    mounted(): void {
        if (this.total) {
            this.friendsTotal = this.total;
            this.loadMore();
        } else {
            this.loading = true;
            this.getTotal().then((total) => {
                this.loading = false;
                this.friendsTotal = total;
                if (this.friendsTotal) {
                    this.loadMore();
                }
            });
        }
    }
    onInviteAccepted(tship: ITeamship): void {
        if (this.listType == 'friends' && this.teamships) {
            this.teamships.push(tship);
            this.friendsTotal++;
        }
    }

    canRemoveMember(teamship: ITeamship): boolean {
        if (serverService.isMe(teamship.user) || teamship.ship.settings == TeamshipSetting.LEADER) {
            return false;
        }
        return this.isManager;
    }

    getMemberTitle(teamship: ITeamship): string {
        const status = FriendshipStatus.getByCode(teamship.ship.status);
        if (status == FriendshipStatus.ACCEPTED) {
            let meta = teamship.ship.meta || {};
            if (meta && meta.title) {
                return meta.title;
            }

            if (teamship.ship.settings == TeamshipSetting.LEADER) {
                return translate('label.team.leader');
            } else {
                return translate('label.team.member');
            }
        } else if (status == FriendshipStatus.LOADING) {
            return translate('updating');
        } else if (status == FriendshipStatus.REQUESTING) {
            return translate('team.apply-join-at', { date: timestampToDate(teamship.ship.createTime * 1000) });
        }
        return translate('not-in-myteam');
    }

    private getTotal(): Promise<number> {
        if (this.listType == 'invites') {
            return serverService.user.getFriendInvitesTotal(this.user!);
        } else if (this.listType == 'blacklist') {
            return serverService.user.getBlacklistTotal(this.user!);
        } else {
            return serverService.user.getFriendsTotal(this.user!);
        }
    }

    loadMore(): void {
        this.loading = true;
        this.hasMore = false;
        let promise: Promise<ITeamship[]>;
        if (this.listType == 'invites') {
            promise = serverService.team.listMemberInvites(this.team!, this.teamships.length, this.countPerLoad);
        } else if (this.listType == 'blacklist') {
            promise = serverService.user.listBlacklist(this.team!, this.teamships.length, this.countPerLoad).then((users) =>
                users.map((u) => {
                    return {
                        ship: {
                            requester: u,
                            acceptor: this.team!,
                            status: FriendshipStatus.BLACKED.code,
                            createTime: 0,
                            confirmTime: 0,
                        },
                        user: u,
                    };
                })
            );
        } else {
            this.countPerLoad = 1000;
            promise = serverService.team.listMembers(this.team!);
        }
        promise.then((teamships) => {
            teamships.forEach((t) => (t.team = this.team));
            this.teamships = this.teamships.concat(teamships);
            this.loading = false;
            this.hasMore = teamships.length >= this.countPerLoad;

            // if (this.isMe && this.listType != 'invites') {
            //     serverService.messaging.markRead(
            //         friends.map((f) => f.username),
            //         [NotificationType.FRIEND_GRANTED]
            //     );
            // }
        });
    }

    get noFriendsText(): string {
        return translate(this.teamship ? `team.i-have-no-${this.listType}` : `team.he-has-no-${this.listType}`, {
            user: this.team?.nickname,
        });
    }

    get friendsTotalText(): string {
        return translate(this.teamship ? `team.my-${this.listType}-total` : `team.his-${this.listType}-total`, {
            user: this.team?.nickname,
            total: this.friendsTotal,
        });
    }

    btnManage(teamship: ITeamship): void {
        if (this.editing && this.editing.saving) {
            return;
        }
        this.editing = {
            teamship: teamship,
            title: this.getMemberTitle(teamship),
            settings: teamship.ship.settings || 0,
        };
    }
    btnEditSetting(s: SettingData): void {
        if (this.editing!.settings != TeamshipSetting.LEADER) {
            if (this.editing!.settings & s.setting) {
                this.editing!.settings -= s.setting;
            } else {
                this.editing!.settings += s.setting;
            }
        }
    }
    btnCancel(): void {
        this.editing = null;
    }
    async btnSave(): Promise<void> {
        if (this.editing) {
            this.editing.saving = true;
            let teamship = this.editing.teamship;
            teamship.team = this.team;
            try {
                await serverService.team.updateTeamship(teamship, {
                    title: this.editing.title,
                    settings: this.editing.settings,
                });
            } catch (err) {
                dialogService.alert(
                    {
                        title: errorToString(err),
                        titleIcon: 'fas fa-exclamation-triangle',
                    },
                    {
                        customClass: 'danger',
                        okText: '',
                        cancelText: translate('btn.close'),
                    }
                );
            }
        }
        this.editing = null;
    }

    btnRemove(tship: ITeamship): void {
        dialogService.alert(
            {
                //title: 'Make friend?',
                body: translate('confirm-team-kickout'),
            },
            {
                customClass: 'danger',
                okText: translate('btn.team-kickout'),
                cancelText: translate('btn.cancel'),
                ok: (controller) => {
                    controller.dismiss();
                    this.removeTeamship(tship);
                },
            }
        );
    }
    private removeTeamship(tship: ITeamship): void {
        const status = tship.ship.status;
        tship.ship.status == FriendshipStatus.LOADING.code;

        serverService.team
            .setTeamship(tship, FriendshipStatus.NONE)
            .then((teamship) => {
                tship.ship = teamship.ship;
                this.memberRemovedEvent(tship);
            })
            .catch((err) => {
                tship.ship.status = status;
                dialogService.error({ body: errorToString(err) });
                return null;
            });
    }

    getNotInTeamMsg(tship: ITeamship): string {
        if (this.listType == 'invites' && tship.ship.status == FriendshipStatus.NONE.code) {
            return translate('request-is-canceled');
        } else {
            return translate('he-not-in-myteam');
        }
    }

    btnAccept(tship: ITeamship): void {
        const status = tship.ship.status;
        tship.ship.status = FriendshipStatus.LOADING.code;
        serverService.team
            .setTeamship(tship, FriendshipStatus.ACCEPTED)
            .then((teamship) => {
                tship.ship = teamship.ship;
                this.inviteAcceptedEvent(tship);
            })
            .catch((err) => {
                let search = err && err.response && err.response.data && err.response.data.match(/^(.*)\.\(n\d+\)$/);
                if (search) {
                    err = search[1];
                    tship.ship.status = FriendshipStatus.NONE.code;
                    this.memberRemovedEvent(tship);
                } else {
                    tship.ship.status = status;
                }
                dialogService.error({ body: errorToString(err) });
            });
    }
    btnReject(tship: ITeamship): void {
        const status = tship.ship.status;
        tship.ship.status = FriendshipStatus.LOADING.code;
        serverService.team
            .setTeamship(tship, FriendshipStatus.NONE)
            .then((teamship) => {
                tship.ship = teamship.ship;
            })
            .catch((err) => {
                tship.ship.status = status;
                dialogService.error({ body: errorToString(err) });
            });
    }

    openSettingsHelp(tship: ITeamship): void {
        let mySettings = tship.ship.settings || 0;
        let helps = allTeamshipSettings.map((setting) => {
            let help = setting.label + ': ' + setting.description;
            if (mySettings & setting.setting) {
                // do nothing
            } else {
                help = `<span style="color:var(--color-dim)">${help}</span>`;
            }
            return help;
        });
        dialogService.alert(
            {
                titleIcon: 'fas fa-info-circle',
                title: translate('label.team.settingsTitle'),
                body: helps.join('<br/>\n'),
            },
            {
                okText: '',
                cancelText: translate('btn.close'),
            }
        );
    }

    getLastPlay(tship: ITeamship): string {
        let usermeta = tship.user!.meta;
        let gameMeta = usermeta && usermeta['lastGame'];
        if (gameMeta && gameMeta['time'] > 1689448178) {
            let time = timestampToTimeAgo(gameMeta.time * 1000);
            return translate('lastPlay', { time: time, game: gameMeta.name || gameMeta.code });
        } else {
            return translate('lastPlayUnknown');
        }
    }
    getLastPlayLink(tship: ITeamship): string | undefined {
        let usermeta = tship.user!.meta;
        let gameMeta = usermeta && usermeta['lastGame'];
        if (gameMeta) {
            return getClientPlayUrl(gameMeta);
        } else {
            return undefined;
        }
    }
}
