
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { serverService } from '@/services/serverService';
import { getUserTooltip, getUserRoute } from '@/filters/user-filters';
import { translate } from '@/filters/translate';
import AreaTitle from '@/components/AreaTitle.vue';
import { IClient } from '@/vo/Client';
import { IUser } from '@/vo/User';
import { ItemStatus } from '../datas/ItemStatus';
import { isUserTitleClient } from '../filters/game-filters';
import { Constant } from '../datas/Constant';

@Component({
    components: {
        AreaTitle,
    },
    methods: {
        getUserRoute,
        getUserTooltip,
        translate,
        isUserTitleClient,
    },
})
export default class GameManagerList extends Vue {
    @Prop()
    client?: IClient;

    loading = true;
    managerData?: IUser[];

    @Watch('client', { immediate: true })
    onClientChange(): void {
        const client = this.client!;
        if (client && client.name) {
            if (isUserTitleClient(client)) {
                serverService.client
                    .listSysTitleItems(client, Constant.SYS_ITEM_CATEGORY.USER_TITLE, true, true)
                    .then((items) => {
                        let users: IUser[] = [];
                        items.forEach((item) => {
                            if (item.status == ItemStatus.PUB.code && item.priceInGameDollar && item.users) {
                                users = users.concat(item.users);
                            }
                        });
                        this.managerData = users;
                        this.loading = false;
                    });
            } else {
                serverService.client.listGameMasters(client.code).then((data) => {
                    this.managerData = data;
                    this.loading = false;
                });
            }
        }
    }
}
