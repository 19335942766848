import { importTranslation } from '../translate';

importTranslation('zh-Hans', {
    gameletQA: {
        banner: {
            title: '嘎姆 2.0 Q&A',
        },
        tabs: [
            {
                title: '常见问题',
                content: [
                    {
                        q: '游戏出现「您的浏览器不支援WebGL或WebGL未启用」是怎么一回事？ ',
                        a:
                            '大部份高端的网页游戏都需要有WebGL的支援才能玩得顺畅。 启用WebGL需要以下两个浏览器的设定(或其中一个设定):\n1.启用「硬体加速」\n2.启用「WebGL」',
                    },
                    {
                        q: '我的帐号被一个游戏封锁了，请问会影响我在其他游戏的权限吗？ ',
                        a:
                            '各游戏都是由各自游戏的制作者负责组团管理玩家及社群，你的帐号在一个游戏的停权状态不会影响在其他游戏的权限。 ',
                    },
                    {
                        q: '帐号被游戏封锁了，请问我该找谁申诉？ ',
                        a: '你可以在游戏的专属讨论区中找到包含「回报问题」的选单，你可经此连结前往该游戏的回报区进行申诉。 ',
                    },
                ],
            },
            {
                title: '游戏上架问题',
                content: [
                    {
                        q: '我能把自己的游戏免费放上嘎姆？ ',
                        a:
                            '只要是在「抠的嘎姆(CG)」制作的游戏都可以免费在嘎姆官网上架，并能利用官网赠送的免费专属讨论区经营社群。 ',
                    },
                    {
                        q: '在嘎姆上架的游戏有可能被下架吗？ ',
                        a:
                            '嘎姆官方会避免干涉各游戏的社群，但是要请各游戏制作团队善加经营，以免因社群违反嘎姆的善良风俗而让游戏被迫下架。 ',
                    },
                    {
                        q: '要如何管理我的游戏专属讨论区？ ',
                        a:
                            '请先开启CG的你的专案，打开右方「资料库」中的「游戏管理员」。 将你在gamelet.online上的玩家帐号加入管理员列表后，就可以使用这个帐号在讨论区中关闭不适当的文章。 ',
                    },
                ],
            },
            {
                title: '关于旧版嘎姆',
                content: [
                    {
                        q: '旧版发生什么事？ ',
                        a:
                            '嘎姆于2019年10月底有一台伺服器的主机完全报销，因为旧版伺服器无法得到技术支援，所以只能抱憾宣告不治。 ',
                    },
                    {
                        q: '旧版讨论区的文章能移植过来吗？ ',
                        a:
                            '虽然旧版讨论区的文章仍躺在旧版伺服器的资料库中，但因为技术问题，目前无法存取。 站长也希望有朝一日可以让这些旧文章重见天日，但这个目标可能遥遥无期。 ',
                    },
                    {
                        q: '我在旧版的嘎姆擂台储值，现在这些点数怎么办？ ',
                        a:
                            '目前让想要退费的玩家提供帐号及购买点数的相关发票或记录寄信至<a href="mailto:gamelet.online@gmail.com">gamelet.online@gmail.com</a> ，并在信件主题注明"嘎姆退费" ，这样站长就会与你联系。 ',
                    },
                ],
            },
        ],
    },
});

export const gameletQA_translation_cn = 1;