var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"root"},[_c('div',{staticClass:"left-root"},[(_vm.page <= 1)?_c('div',{staticClass:"intro-root"},[_c('AreaTitle',{attrs:{"filters":"hue-rotate(-60deg)","color":"#60d15b","text":_vm.translate('blog.intro.title')}}),_c('div',{staticClass:"box-body intro-body"},[_c('div',{staticClass:"contents"},[_c('img',{staticClass:"intro-logo",attrs:{"src":require("../assets/gamelet_icon3.png")}}),_c('div',[_c('div',{staticClass:"desc",domProps:{"innerHTML":_vm._s(_vm.translate('blog.intro.desc', { wrap: 'p' }))}}),_c('div',{staticClass:"actions"},[_c('a',{staticClass:"btn btn-success",attrs:{"href":"https://blog.gamelet.com/2016/01/contribute-to-the-blog.html"}},[_vm._v(_vm._s(_vm.translate('blog.btn.submitBlog')))]),_c('router-link',{staticClass:"btn btn-success",attrs:{"to":{
                                    name: 'about_tab',
                                    params: { tab: 'editors' },
                                },"href":"https://blog.gamelet.com/2016/01/contribute-to-the-blog.html"}},[_vm._v(" "+_vm._s(_vm.translate('blog.btn.aboutEditors'))+" ")])],1)])])])],1):_vm._e(),_c('div',{staticClass:"blogs-root"},[_c('div',{staticClass:"title"},[_c('AreaTitle',{attrs:{"filters":"hue-rotate(-60deg)","color":"#60d15b","text":_vm.translate('blog.blogs.title')}}),_vm._m(0)],1),_c('div',{staticClass:"box-body blog-article"},[(!_vm.loading && (_vm.page > 1 || _vm.hasMore))?_c('div',{staticClass:"page-controller top"},[(_vm.page > 1)?_c('router-link',{attrs:{"to":_vm.getBlogRoute(_vm.page - 1)}},[_c('i',{staticClass:"fas fa-arrow-left"}),_vm._v(" "+_vm._s(_vm.translate('blog.btn.newer'))+" ")]):_vm._e(),_c('div',{staticClass:"spacer"}),(_vm.hasMore)?_c('router-link',{attrs:{"to":_vm.getBlogRoute(_vm.page + 1)}},[_vm._v(_vm._s(_vm.translate('blog.btn.older'))+" "),_c('i',{staticClass:"fas fa-arrow-right"})]):_vm._e()],1):_vm._e(),(_vm.loading)?_c('CssPreloader',{staticClass:"loading"}):(_vm.feed)?_c('div',{staticClass:"entries"},_vm._l((_vm.feed),function(entry,index){return _c('div',{key:`entry${index}`,staticClass:"entry"},[_c('div',{staticClass:"tags"},[_vm._v(_vm._s(entry.tags.join('/')))]),_c('div',{staticClass:"title",domProps:{"innerHTML":_vm._s(entry.title)}}),_c('div',{staticClass:"author"},[_vm._v(_vm._s(entry.author))]),_c('div',{staticClass:"published"},[_vm._v(" "+_vm._s(entry.published.toLocaleDateString())+" ")]),_c('div',{staticClass:"content"},[(entry.thumb)?_c('img',{staticClass:"thumb",attrs:{"src":entry.thumb}}):_vm._e(),_c('div',{staticClass:"content-body"},[_vm._v(_vm._s(entry.content)+"...")]),_c('div',{staticClass:"content-footer"},[(!_vm.isCommentsLoading())?_c('router-link',{class:{
                                        'comments-total': true,
                                        empty: !_vm.getCommentsTotal(entry.id),
                                    },attrs:{"to":_vm.getBlogReadRoute(entry, 1, '#comments')}},[_c('i',{staticClass:"far fa-comments"}),_vm._v("   "+_vm._s(_vm.getCommentsTotal(entry.id))+" ")]):_vm._e(),_c('div',{staticClass:"spacer"}),_c('router-link',{staticClass:"btn-continue",attrs:{"to":_vm.getBlogReadRoute(entry)}},[_vm._v(" "+_vm._s(_vm.translate('forum.btn.continueReading'))+"  "),_c('i',{staticClass:"fas fa-angle-right"})])],1)]),_c('div',{staticClass:"entry-footer"})])}),0):_vm._e(),(_vm.feed)?_c('div',{staticClass:"page-controller"},[(_vm.page > 1)?_c('router-link',{attrs:{"to":_vm.getBlogRoute(_vm.page - 1)}},[_c('i',{staticClass:"fas fa-arrow-left"}),_vm._v(" "+_vm._s(_vm.translate('blog.btn.newer'))+" ")]):_vm._e(),_c('div',{staticClass:"spacer"}),(_vm.hasMore)?_c('router-link',{attrs:{"to":_vm.getBlogRoute(_vm.page + 1)}},[_vm._v(_vm._s(_vm.translate('blog.btn.older'))+" "),_c('i',{staticClass:"fas fa-arrow-right"})]):_vm._e()],1):_vm._e()],1)])]),_c('div',{staticClass:"right-root"},[_c('div',{staticClass:"news-root"},[_c('GameNews',{staticClass:"game-news",attrs:{"totalPages":1,"client":_vm.client}})],1),_c('Epub',{staticClass:"epub-root"}),(_vm.showSideAds())?_c('AdsResponsive',{staticClass:"ads-right",attrs:{"stickyTop":68}}):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a',{staticClass:"btn-linkout",attrs:{"href":"https://blog.gamelet.com"}},[_c('i',{staticClass:"fas fa-external-link-alt"})])
}]

export { render, staticRenderFns }