import { Vue } from 'vue-property-decorator';
import {
    AlertDialogController,
    AlertDialogOptions,
    ChooseDialogOptions,
    ChoosePayByDialogController,
    ChoosePayByDialogOptions,
    IDialogMessage,
    InputDialogController,
    InputDialogOptions,
    SearchUserDialogController,
    SearchUserDialogOptions,
} from '../components/dialogs/DialogProps';
import { Constant } from '../datas/Constant';
import { PayBy } from '../datas/PackageContentCode';
import { translate } from '../filters/translate';
import { serverService } from './serverService';
import { IUser } from '@/vo/User';
import { IPaymentPackage } from '@/vo/PayProduct';

export class DialogService {
    private app: Vue = null!;

    public initialize(app: Vue): void {
        this.app = app;
    }

    alert(message: IDialogMessage, options?: AlertDialogOptions): Promise<void> {
        return new Promise<void>((resolve) => {
            options = Object.assign(
                {
                    view: Constant.VIEW.ALERT,
                    html: true,
                    backdropClose: true,
                },
                options || {}
            );
            return this.app.$dialog
                .alert(message, options)
                .then(() => resolve())
                .catch(() => resolve());
        });
    }

    loading(options?: AlertDialogOptions): { close: () => void } {
        let needClose = false;
        const close = () => {
            needClose = true;
            if (controller) {
                controller.dismiss();
            }
        };
        let controller: AlertDialogController = null!;
        options = Object.assign(
            {
                view: Constant.VIEW.ALERT,
                html: true,
                backdropClose: false,
                animation: 'fade',
                customClass: 'loading-dialog',
                getController: (con: AlertDialogController) => {
                    controller = con;
                    if (needClose) {
                        controller.dismiss();
                    } else {
                        controller.showLoading();
                    }
                },
            },
            options || {}
        );
        this.app.$dialog.alert({}, options).catch((err) => console.error(err));
        return { close: close };
    }

    notLoginError(routerPath: string): void {
        dialogService.alert(
            {
                titleIcon: 'fas fa-exclamation-triangle',
                title: translate('error.notLogin'),
            },
            {
                okText: translate('btn.login'),
                cancelText: translate('btn.close'),
                ok: () => {
                    window.location.href = serverService.generateLoginUrl(routerPath);
                },
            }
        );
    }

    error(message: IDialogMessage, options?: AlertDialogOptions): Promise<void> {
        message = Object.assign(
            {
                title: 'Error',
                titleIcon: 'fas fa-exclamation-triangle',
            },
            message
        );
        return new Promise<void>((resolve) => {
            options = Object.assign(
                {
                    view: Constant.VIEW.ALERT,
                    html: true,
                    backdropClose: true,
                    cancelText: '',
                    okText: translate('btn.close'),
                },
                options || {}
            );
            return this.app.$dialog
                .alert(message, options)
                .then(() => resolve())
                .catch(() => resolve());
        });
    }

    input(message: IDialogMessage, options?: InputDialogOptions): Promise<string | null> {
        return new Promise<string | null>((resolve) => {
            options = Object.assign(
                {
                    view: Constant.VIEW.INPUT,
                    html: true,
                    backdropClose: true,
                },
                options || {}
            );
            const originGetController = options.getController;
            let controller: InputDialogController;
            options.getController = (con) => {
                controller = con;
                originGetController && originGetController(con);
            };
            return this.app.$dialog
                .alert(message, options)
                .then(() => resolve(controller.getInput()))
                .catch(() => resolve(null));
        });
    }

    choose(message: IDialogMessage, options: ChooseDialogOptions): Promise<void> {
        return new Promise<void>((resolve) => {
            options = Object.assign(
                {
                    view: Constant.VIEW.CHOOSE,
                    html: true,
                    backdropClose: true,
                },
                options || {}
            );
            return this.app.$dialog
                .alert(message, options)
                .then(() => resolve())
                .catch(() => resolve());
        });
    }

    searchUser(message: IDialogMessage, options?: SearchUserDialogOptions): Promise<IUser | null> {
        return new Promise<IUser | null>((resolve) => {
            options = Object.assign(
                {
                    view: Constant.VIEW.SEARCH_USER,
                    html: true,
                    backdropClose: true,
                    customClass: 'large-dialog full-height',
                },
                options || {}
            );
            const originGetController = options.getController;
            let controller: SearchUserDialogController;
            options.getController = (con) => {
                controller = con;
                originGetController && originGetController(con);
            };
            return this.app.$dialog
                .alert(message, options)
                .then(() => resolve(controller.getUser()))
                .catch(() => resolve(null));
        });
    }

    choosePayBy(pkg: IPaymentPackage, payByList: PayBy[], options?: ChoosePayByDialogOptions): Promise<PayBy | null> {
        return new Promise<PayBy | null>((resolve) => {
            options = Object.assign(
                {
                    pkg: pkg,
                    payByList: payByList,
                    view: Constant.VIEW.CHOOSE_PAYBY,
                    customClass: 'auto-dialog',
                },
                options || {}
            );
            const originGetController = options.getController;
            let controller: ChoosePayByDialogController;
            options.getController = (con) => {
                controller = con;
                originGetController && originGetController(con);
            };
            return this.app.$dialog
                .alert(
                    {
                        title: translate('pay.choosePayBy'),
                        titleIcon: ' ',
                    },
                    options
                )
                .then(() => resolve(controller.getPayBy()))
                .catch(() => resolve(null));
        });
    }
}

export const dialogService = new DialogService();
