
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class AdsResponsive extends Vue {
    public $refs!: Vue['$refs'] & {
        adsRoot: HTMLDivElement;
    };

    @Prop({ default: 0 })
    stickyTop!: number;

    sticky = false;

    mounted(): void {
        const win = window as any;
        win.adsbygoogle = win.adsbygoogle || [];
        win.adsbygoogle.push({});

        if (this.stickyTop) {
            this.onScroll = this.onScroll.bind(this);
            window.addEventListener('scroll', this.onScroll);
        }
    }

    beforeDestroy(): void {
        window.removeEventListener('scroll', this.onScroll);
    }

    onScroll(): void {
        let root = this.$refs.adsRoot;
        let offset = root.getBoundingClientRect();
        this.sticky = offset.top < this.stickyTop && offset.width < window.innerWidth / 2;
    }
}
