
import { Component, Vue, Watch } from 'vue-property-decorator';
import { translate } from '@/filters/translate';
import { serverService } from '@/services/serverService';
import { Constant } from '@/datas/Constant';
import { ResourceCategory } from '@/datas/ResourceCategory';
import { timestampToDateTime, timestampToTimeAgo } from '@/filters/timestampToString';
import { getUserUrl, getUserTooltip } from '@/filters/user-filters';
import { dialogService } from '@/services/dialogService';
import { errorToString } from '@/filters/content-filters';
import UploadResources from './UploadResources.vue';
import Pagination from '@/components/Pagination.vue';
import { RouteResolver, UpRouteData } from '../utils/RouteResolver';
import { Location, Route } from 'vue-router';
import { IResource } from '@/vo/Resource';
import { IUser } from '@/vo/User';

@Component({
    methods: {
        translate,
        timestampToDateTime,
        timestampToTimeAgo,
        getUserUrl,
        getUserTooltip,
    },
    components: {
        UploadResources,
        Pagination,
    },
})
export default class ResourcesList extends Vue implements RouteResolver {
    resources: IResource[] = [];

    category?: ResourceCategory;

    loading = true;
    me: IUser | null = null;
    mineOnly = false;
    showInputBox = false;

    page = 1;
    pageSize = 20;
    maxPage = 0;
    maxPageKey = '';

    @Watch('$route', { immediate: true })
    onRouteChange(to: Route): void {
        this.category = ResourceCategory.getByCode(to.params.category);
        if (!this.category) {
            this.$router.replace({ name: 'home' });
            return;
        }
        this.loadResourcesByCategory(Number(to.params.page) || 1);
    }

    private loadPageKey(page: number): string {
        return [this.category?.code, (this.mineOnly && this.me?.username) || '', page].join('/');
    }
    get routeResolver(): RouteResolver {
        return this;
    }
    getUpRoute(): UpRouteData | null {
        return null;
    }

    resolveRoute(params: { [key: string]: any }): Location {
        return {
            name: 'contribute_art_page',
            params: {
                category: this.category!.code,
                page: params.page + '',
            },
        };
    }

    mounted(): void {
        serverService.getInitData().then((initData) => {
            this.me = initData.me;
        });
    }

    toggleInputBox(): void {
        if (this.me) {
            this.setInputBoxVisible(!this.showInputBox);
        } else {
            this.setInputBoxVisible(false);
            dialogService.notLoginError(this.$route.fullPath);
        }
    }

    showCause(resource: IResource): void {
        dialogService.alert(
            {
                title: translate('contributeArts.coverState.fail'),
                titleIcon: 'fas fa-question-circle',
                body: resource.rejected?.note,
            },
            {
                okText: translate('btn.close'),
                cancelText: '',
            }
        );
    }

    setInputBoxVisible(vis: boolean): void {
        this.showInputBox = vis;
    }

    loadMaxPage(): void {
        let loadKey = this.loadPageKey(-1);
        if (this.maxPageKey != loadKey) {
            this.maxPageKey = loadKey;
            let promise: Promise<number>;
            if (this.mineOnly && this.me) {
                promise = serverService.resource.getResourcesTotalByUser(Constant.SITE_CLIENT.code, this.me.username, [
                    this.category!,
                ]);
            } else {
                promise = serverService.resource.getResourcesTotalByCategory(Constant.SITE_CLIENT.code, this.category!);
            }

            promise.then((total) => {
                if (this.maxPageKey == loadKey) {
                    this.maxPage = Math.ceil(total / this.pageSize);
                }
            });
        }
    }

    loadResourcesByUser(page: number): void {
        this.mineOnly = true;
        this.loadResources(page);
        this.loadMaxPage();
    }

    loadResourcesByCategory(page: number): void {
        this.mineOnly = false;
        this.loadResources(page);
        this.loadMaxPage();
    }

    private loadResources(page: number): void {
        this.page = page;
        this.resources = [];
        this.loading = true;
        const start = (page - 1) * this.pageSize;
        let loadPageKey = this.loadPageKey(page);
        let promise: Promise<IResource[]>;

        if (this.mineOnly && this.me) {
            promise = serverService.resource.listResourcesByUser(
                Constant.SITE_CLIENT.code,
                this.me.username,
                [this.category!],
                start,
                this.pageSize
            );
        } else {
            promise = serverService.resource.listResourcesByCategory(
                Constant.SITE_CLIENT.code,
                this.category!,
                start,
                this.pageSize
            );
        }
        promise.then((list) => {
            if (loadPageKey == this.loadPageKey(this.page)) {
                this.resources = list;
                this.loading = false;
            }
        });
    }

    getResourcesUrl(resource: IResource): string {
        return serverService.generateServerUrl('/gassets/' + resource.url);
    }
    setHidden(resource: IResource, hidden: boolean): void {
        this.openNoteDialog(hidden ? 'Hide Image' : 'Show Image', hidden ? 'fas fa-eye-slash' : 'fas fa-eye', () =>
            serverService.resource.setResourceHidden(resource, hidden, '')
        );
    }

    private openNoteDialog(title: string, titleIcon: string, action: (note: string) => Promise<any>): void {
        dialogService.input(
            {
                titleIcon: titleIcon,
                title: title,
            },
            {
                placeholder: translate('forum.gmNote'),
                okText: translate('btn.confirm'),
                cancelText: translate('btn.cancel'),
                ok: (controller) => {
                    controller.showLoading(translate('processing'));
                    action(controller.getInput())
                        .then(() => {
                            controller.dismiss();
                        })
                        .catch((err) => {
                            controller.hideLoading();
                            dialogService.error({ body: errorToString(err) });
                        });
                },
            }
        );
    }
}
