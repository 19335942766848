import { PackageContentType } from '@/datas/PackageContentCode';
import { AbstractSubService } from './abstractSubService';
import { IPaymentPackage, ITransaction } from '@/vo/PayProduct';

export class PayService extends AbstractSubService {
    public listProductPackages(contentType: PackageContentType): Promise<IPaymentPackage[]> {
        return this.api('/list/product_packages/' + contentType.code, {}).then((json: any) => json.packages);
    }

    public listGltTransactions(time: Date | null, backward: boolean, start: number, length: number): Promise<ITransaction[]> {
        return this.api('/list/glt_transactions', {
            time: time ? Math.round(time.getTime() / 1000) : 0,
            backward: backward,
            start: start,
            length: length,
        }).then((json: any) => json.list);
    }
}
