
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { timestampToDate } from '@/filters/timestampToString';
import { translate } from '@/filters/translate';
import { ISubjectFull } from '@/vo/Discuss';

@Component({
    methods: {
        timestampToDate,
        translate,
    },
})
export default class ContributeTime extends Vue {
    @Prop()
    subject?: ISubjectFull;

    @Prop()
    checkCreateTime!: boolean;

    @Prop({ default: false })
    isManager!: boolean;

    @Emit('openSubject')
    onOpenSubject(): void {}

    @Emit('closeSubject')
    onCloseSubject(): void {}

    @Emit('changeView')
    onChangeView(view: string): string {
        return view;
    }
}
