import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import Home from './home/Home.vue';
import UserRoot from './users/UserRoot.vue';
import TeamRoot from './teams/TeamRoot.vue';
import FriendsTab from './users/components/FriendsTab.vue';
import MembersTab from './teams/MembersTab.vue';
import TeamRecords from './teams/TeamRecords.vue';
import Badges from './users/components/Badges.vue';
import About from './users/components/About.vue';
import Board from './users/components/Board.vue';
import UserArticles from './users/components/UserArticles.vue';
import GmRedirect from './users/GmRedirect.vue';
import Games from './games/Games.vue';
import GameEvents from './games/GameEvents.vue';
import Deposit from './deposits/Deposit.vue';
import ForumCatList from './forums/ForumCatList.vue';
import ReviewList from '@/forum/reviews/ReviewList.vue';
import RootArticleList from '@/forum/components/RootArticleList.vue';
import ForumArticle from '@/forum/components/ForumArticle.vue';
import ContributeArtList from '@/contributeArts/ContributeArtList.vue';
import MixGameArticles from './forums/MixGameArticles.vue';
import GoogleSearchResult from './searches/GoogleSearchResult.vue';
import AboutUs from '@/views/AboutUs.vue';
import History from '@/views/History.vue';
import Coding from '@/views/Coding.vue';
import Blog from '@/views/Blog.vue';
import BlogRead from '@/views/BlogRead.vue';
import GameletQA from '@/views/GameletQA.vue';
import { Position } from 'vue-router/types/router';
import GameBadgePage from '../game/GameBadgePage.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
    },
    {
        path: '/gm',
        name: 'gm',
        component: GmRedirect,
    },
    {
        path: '/search',
        name: 'search',
        component: GoogleSearchResult,
    },
    {
        path: '/about/cg',
        name: 'coding',
        component: Coding,
    },
    {
        path: '/about/history',
        name: 'history',
        component: History,
    },
    {
        path: '/about/qanda',
        name: 'gameletQA',
        component: GameletQA,
    },
    {
        path: '/about',
        component: AboutUs,
        children: [
            {
                path: ':tab',
                name: 'about_tab',
            },
            {
                path: '',
                name: 'about_us',
            },
            {
                path: '*',
                redirect: { name: 'about_us' },
            },
        ],
    },
    {
        path: '/blog',
        name: 'blog',
        component: Blog,
    },
    {
        path: '/blog/page/:page',
        name: 'blog_page',
        component: Blog,
    },
    {
        path: '/blog/read/:id',
        name: 'blog_read',
        component: BlogRead,
    },
    {
        path: '/blog/read/:id/page/:page',
        name: 'blog_read_page',
        component: BlogRead,
    },
    {
        path: '/user',
        name: 'profile',
        component: UserRoot,
    },
    {
        path: '/user/:username',
        component: UserRoot,
        children: [
            {
                path: 'board',
                name: 'user_board',
                component: Board,
            },
            {
                path: 'board/page/:page',
                name: 'user_board_page',
                component: Board,
            },
            {
                path: 'board/message/:messageID',
                name: 'user_board_message',
                component: Board,
            },
            {
                path: 'friends',
                name: 'user_friends',
                component: FriendsTab,
            },
            {
                path: 'badges',
                name: 'user_badges',
                component: Badges,
            },
            {
                path: 'posts',
                name: 'user_posts',
                component: UserArticles,
            },
            {
                path: 'posts/page/:page',
                name: 'user_posts_page',
                component: UserArticles,
            },
            {
                path: 'posts/:category',
                name: 'user_posts_category',
                component: UserArticles,
            },
            {
                path: 'posts/:category/page/:page',
                name: 'user_posts_category_page',
                component: UserArticles,
            },
            {
                path: 'about',
                name: 'user_about',
                component: About,
            },
            {
                path: '',
                name: 'user',
                component: About,
            },
            {
                path: '*',
                redirect: { name: 'user_about' },
            },
        ],
    },
    {
        path: '/team',
        name: 'team_profile',
        component: TeamRoot,
    },
    {
        path: '/team/:teamname',
        component: TeamRoot,
        children: [
            {
                path: 'board',
                name: 'team_board',
                component: Board,
            },
            {
                path: 'board/page/:page',
                name: 'team_board_page',
                component: Board,
            },
            {
                path: 'board/message/:messageID',
                name: 'team_board_message',
                component: Board,
            },
            {
                path: 'records',
                name: 'team_records',
                component: TeamRecords,
            },
            {
                path: 'about',
                name: 'team_about',
                component: About,
            },
            {
                path: 'members',
                name: 'team_members',
                component: MembersTab,
            },
            {
                path: '',
                name: 'team',
                component: About,
            },
            {
                path: '*',
                redirect: { name: 'team_about' },
            },
        ],
    },
    {
        path: '/events',
        name: 'events',
        component: GameEvents,
    },
    {
        path: '/events/page/:page',
        name: 'events_page',
        component: GameEvents,
    },
    {
        path: '/games',
        name: 'games',
        component: Games,
    },
    {
        path: '/games/page/:page',
        name: 'games_page',
        component: Games,
    },
    {
        path: '/games/beta',
        name: 'games_beta',
        component: Games,
    },
    {
        path: '/games/beta/:page',
        name: 'games_beta_page',
        component: Games,
    },
    {
        path: '/games/:order/:page',
        name: 'games_order_page',
        component: Games,
    },
    {
        path: '/game/:clientCode/badge/:badgeCode',
        name: 'game_badge_page',
        component: GameBadgePage,
    },
    {
        path: '/forums',
        name: 'forums',
        component: ForumCatList,
    },
    {
        path: '/forum/games',
        name: 'forum_games',
        component: MixGameArticles,
    },
    {
        path: '/forum/games/page/:page',
        name: 'forum_games_page',
        component: MixGameArticles,
    },
    {
        path: '/contribute/art/:category',
        name: 'contribute_art',
        component: ContributeArtList,
    },
    {
        path: '/contribute/art/:category/page/:page',
        name: 'contribute_art_page',
        component: ContributeArtList,
    },
    {
        path: '/review/:clientCode',
        name: 'review_client',
        component: ReviewList,
    },
    {
        path: '/review/:clientCode/page/:page',
        name: 'review_client_page',
        component: ReviewList,
    },
    {
        path: '/:category/:clientCode',
        name: 'forum_client',
        component: RootArticleList,
    },
    {
        path: '/:category/:clientCode/page/:page',
        name: 'forum_client_page',
        component: RootArticleList,
    },
    {
        path: '/:category/:clientCode/post/:articleId',
        name: 'forum_client_post',
        component: ForumArticle,
    },
    {
        path: '/:category/:clientCode/post/:articleId/page/:page',
        name: 'forum_client_post_page',
        component: ForumArticle,
    },
    {
        path: '/deposit',
        name: 'deposit',
        component: Deposit,
    },
    {
        path: '*',
        redirect: { name: 'home' },
    },
];

function getBaseUri(): string {
    const href: string = window.location.href;
    if (href.startsWith('http://localhost:58618')) {
        return '/';
    }
    const search = href.match(/https?:\/\/([^/]+)(\/|\/gltserver\/)([a-zA-Z]+)\/([^/]+)(\/|$|\?)/);
    if (search && search[1].includes('localhost:')) {
        return `/gltserver/`;
    }
    return '/';
}

const keepPositionExcludes = ['team'];

const router = new VueRouter({
    mode: 'history',
    base: getBaseUri(),
    routes,
    scrollBehavior: (to: Route, from: Route, savedPosition: void | Position) => {
        if (from) {
            if (from.path == to.path && to.hash) {
                return {
                    selector: to.hash,
                    behavior: 'smooth',
                };
            }
            if (from.name == to.name && !keepPositionExcludes.includes(from.name!)) {
                return null;
            }
        }
        return savedPosition || { x: 0, y: 0 };
    },
});
export default router;
