var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:['input-root', _vm.selectedReportFormat ? 'formated' : '', _vm.reportFormats ? 'needFormat' : '', _vm.category.code]},[(_vm.isNews)?_c('div',{staticClass:"postTips"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.translate('news.title.postTips')))]),_c('ol',{domProps:{"innerHTML":_vm._s(_vm.translate('news.postTips', { wrap: 'li' }))}})]):_vm._e(),(_vm.reportFormats)?_c('div',{staticClass:"reportTips"},[_c('div',{staticClass:"dropdown"},[_c('button',{staticClass:"btn btn-light dropdown-toggle",attrs:{"type":"button","data-bs-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v(" "+_vm._s(_vm.selectedReportFormat ? _vm.selectedReportFormat.name : _vm.translate('report.selectFormat'))+" ")]),_c('div',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"dropdownMenuButton"}},_vm._l((_vm.reportFormats),function(format,index){return _c('a',{key:`format${index}`,staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setReportFormat(format)}}},[_vm._v(_vm._s(format.name))])}),0)])]):_vm._e(),_c('div',{staticClass:"user-inputs"},[(_vm.isRoot && _vm.titleRequired)?_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.title),expression:"title"}],staticClass:"form-control",attrs:{"type":"text","placeholder":_vm.translate(`${_vm.category.code}.label.title`) + '...',"maxlength":"60"},domProps:{"value":(_vm.title)},on:{"input":function($event){if($event.target.composing)return;_vm.title=$event.target.value}}})]):_vm._e(),_c('Editor',{key:_vm.resetInput,staticClass:"editor",attrs:{"api-key":_vm.tinymceApiKey,"init":{
                setup: (editor) => _vm.markButton(editor),
                branding: false,
                toolbar_mode: 'sliding',
                forced_root_block: 'div',
                target_list: false,
                block_formats:
                    'Div=div;Paragraph=p;Heading 1=h1;Heading 2=h2;Heading 3=h3;Heading 4=h4;Heading 5=h5;Heading 6=h6;',
                min_height: _vm.reportFormats || _vm.isContribute ? 600 : 320,
                menubar: false,
                language: _vm.language,
                language_url: _vm.getEditorLanguageUrl(_vm.language),
                skin: _vm.dark ? 'oxide-dark' : undefined,
                content_css: _vm.dark ? 'dark' : undefined,
                placeholder: _vm.translate(_vm.isNews ? 'news.placeholder.content' : 'forum.placeholder.article'),
                font_formats:
                    '新細明體=PMingLiU;標楷體=DFKai-SB;新宋體=NSimSun;微軟正黑體=Microsoft JhengHei;微軟雅黑體=Microsoft YaHei;Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings,zapf dingbats',
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime table paste code help wordcount noneditable',
                ],
                custom_elements: 'markfloor',
                content_style:
                    _vm.getEditorContentStyles(_vm.dark) +
                    '.mceNonEditable {text-decoration: none;color: #4ebdd0;margin: 0 5px;font-weight: bold;font-size: 16px;font-family: Noto Sans TC, Roboto, sans-serif;background: #8886;padding: 0 5px;}',
                noneditable_noneditable_class: 'mceNonEditable',
                contextmenu: '',
                convert_urls: false, // 不要把插入的網址轉為相對路徑
                toolbar: `undo redo | formatselect | fontselect | fontsizeselect formats forecolor backcolor bold italic underline strikethrough removeformat ${_vm.extraFunctionName} | preview | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent Insert/ link image Table help`,
            }},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}),_c('div',{staticClass:"button-box"},[(_vm.stateOptions)?_c('div',{staticClass:"dropdown"},[_c('button',{staticClass:"btn btn-light btn-state dropdown-toggle",attrs:{"type":"button","data-bs-toggle":"dropdown","title":_vm.getStateDesc(_vm.state)}},[_vm._v(" "+_vm._s(_vm.getStateName(_vm.state))+" ")]),_c('ul',{staticClass:"dropdown-menu"},_vm._l((_vm.stateOptions),function(option){return _c('li',{key:option.code,staticClass:"dropdown-item",attrs:{"href":"#","title":_vm.getStateDesc(option)},on:{"click":function($event){$event.preventDefault();return _vm.selectState(option)}}},[_vm._v(" "+_vm._s(_vm.getStateName(option))+" ")])}),0)]):_vm._e(),_c('div',{staticClass:"send-button",on:{"click":function($event){return _vm.onPost()}}},[_vm._v(" "+_vm._s(_vm.action)+" ")])]),(_vm.showPostReminder)?_c('div',{staticClass:"postReminder"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.translate('forum.board.root.postReminderTitle'))+" ")]),_c('ol',{domProps:{"innerHTML":_vm._s(
                    _vm.translate('forum.board.root.postReminder', {
                        wrap: 'li',
                        url: _vm.rules[_vm.client.code],
                    })
                )}}),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.translate('forum.board.root.postReminderEnd'))+" ")])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }