import { importTranslation } from '../translate';

importTranslation('zh-Hans', {
    links: {
        games: '所有游戏',
        forums: '讨论区',
        recharge: '线上储值',
        gameletBlog: {
            fullName: '嘎姆游刊',
            tab: '嘎姆游刊',
        },
        codeGamelet: {
            fullName: '抠的嘎姆',
            tab: '抠的嘎姆',
        },
        aboutUs: '关于我们',
        memorabilia: '重大记事',
        problemReport: '嘎姆支援',
        userTerms: '使用者条款',
        privacyPolicy: '隐私权政策',
    },
});

export const links_translation_cn = 1;