export const Constant = {
    VIEW: {
        ALERT: 'alertWindow',
        INPUT: 'inputWindow',
        CHOOSE: 'chooseWindow',
        SEARCH_USER: 'searchUser',
        CHOOSE_PAYBY: 'choosePayBy',
    },
    SITE_CLIENT: {
        code: 'GameletOnline',
        name: '',
    },
    GAMELET_PRESS: {
        code: 'GameletPress',
        name: '',
    },
    GAMELET_CHAT: {
        code: 'GameletChat',
        name: '',
    },
    GAMELET_CHAT2: {
        code: 'GameletChat2',
        name: '',
    },
    GAMELET_GALLERY: {
        code: 'GameletGallery',
        name: '',
    },
    EPUB_URL: '/gltblog/epub/html',

    REVIEW_MIN_PLAY_MINS: 3, // 3 minutes
    ADMIN_USERNAME: 'admin@gltserver',

    SYS_ITEM_CATEGORY: {
        USER_TITLE: 'userTitle',
        VIDEO: 'video',
    },
};
