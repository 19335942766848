
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
    getClientPlayUrl,
    getClientOwnerUrl,
    getClientForumRoute,
    getClientReviewRoute,
    isUserTitleClient,
} from '@/filters/game-filters';
import { translate } from '@/filters/translate';
import { DISCUSS_CATEGORY } from '../services/discussService';
import GameManagerList from './GameManagerList.vue';
import RateStars from '@/components/RateStars.vue';
import GameLinksButton from '@/components/GameLinksButton.vue';
import { ExtraButtonInfo } from '../datas/ExtraButtonInfo';
import AreaTitle from '@/components/AreaTitle.vue';
import { IClient } from '@/vo/Client';
import { contentLinksToBlankTarget } from '../filters/content-filters';
import { DomUtil } from '@/utils/DomUtil';

declare const $: any;

@Component({
    methods: {
        translate,
        getClientPlayUrl,
        getClientOwnerUrl,
        getClientForumRoute,
        getClientReviewRoute,
        isUserTitleClient,
    },
    components: {
        GameManagerList,
        RateStars,
        GameLinksButton,
        AreaTitle,
    },
})
export default class GameInfor extends Vue {
    public $refs!: Vue['$refs'] & {
        gmList: HTMLDivElement;
    };

    @Prop()
    client?: IClient;

    @Prop()
    category!: DISCUSS_CATEGORY;

    @Prop()
    siteClient!: boolean;

    @Prop({ default: false })
    isManager!: boolean;

    gmListIsOpen = false;

    extraButtons: ExtraButtonInfo[] = [];

    @Watch('siteClient', { immediate: true })
    onSiteClientChange(): void {
        if (this.siteClient) {
            this.extraButtons = [];
        } else {
            this.extraButtons = [
                {
                    label: this.client?.sys ? 'game.sysTitle.labelOrganizer' : 'game.btn.viewClientGM',
                    icon: 'fas fa-users',
                    onClick: () => this.setGmList(true),
                },
            ];
        }
    }

    getSysDesc(content: string): string {
        return contentLinksToBlankTarget(content);
    }

    get loading(): boolean {
        return !this.client;
    }

    get isReport(): boolean {
        return this.category == DISCUSS_CATEGORY.REPORT;
    }

    get isForum(): boolean {
        return this.category == DISCUSS_CATEGORY.FORUM;
    }
    get isNews(): boolean {
        return this.category == DISCUSS_CATEGORY.NEWS;
    }
    get isReview(): boolean {
        return this.category == DISCUSS_CATEGORY.REVIEW;
    }

    setGmList(open: boolean): void {
        $(this.$refs.gmList).collapse(open ? 'show' : 'hide');
    }

    getGameBoxBgImg(item: IClient): string {
        return `url("${item.cover}")`;
    }

    get trailerID(): string {
        let trailer = this.client && this.client.trailer;
        return (trailer && trailer.id) || '';
    }

    openTrailer(): void {
        const trailer = this.client && this.client.trailer;
        DomUtil.showVideoDialog(trailer!.platform, trailer!.id);
    }
}
