import { importTranslation } from '../translate';

importTranslation('en-US', {
    pay: {
        pageTitle: 'My Deposits',
        choosePayBy: 'Choose a payment method',
        support: {
            creditcard: 'Credit card',
            bank: 'Bank account',
            paypal: 'Paypal credits',
            cvs: 'Convenience store',
            webATM: 'Web ATM',
            ATM: 'ATM',
            linepay: 'LINE pay',
        },
        product: {
            points: {
                title: 'Get <img src="{icon}"><b>{points}</b>',
                desc: 'Get <img src="{icon}">{points} GLT Dollars,<br/>and deposit to your account.',
            },
        },
        mydeposit: {
            title: 'My Deposits',
            gltDollar: 'GLT-Dollar',
            gltPoint: 'GLT-Point',
        },
        history: {
            title: 'Transactions',
            btnRead: 'View History',
            btnMore: 'more',
            noRecord: 'No record',
            category: {
                buyGltDollar: 'Buy GLT-Dollar',
                awardGltPoint: 'Award GLT-Point',
                transferGltPoint: 'From CG creative pts',
            },
            fromCGUser: 'From CG User: {username}',
            transNo: 'Transaction: {transNo}',
        },
        currency: {
            TWD: 'NTD',
            USD: 'USD',
        },
        paymentCompany: {
            ecpay: {
                name: 'Ecpay',
                icon: 'https://gamelet.online/img/store/ecpay_logo.png',
                iconHeight: '20px',
            },
            paypal: {
                name: 'Paypal',
                icon: 'https://gamelet.online/img/store/paypal_logo.png',
                iconHeight: '20px',
            },
            newebpay: {
                name: 'NewebPay',
                icon: 'https://gamelet.online/img/store/newebpay_logo.png',
                iconHeight: '24px',
            },
            steampay: {
                name: 'Steam Wallet',
                icon: 'https://gamelet.online/img/store/steampay_logo.png',
                iconHeight: '24px',
            },
        },
        paymentDisabled: '{company} is currently not available.',
        paymentOnlyOnCG: {
            tip: 'Please go to CG for consumption',
            title: 'Spend money on CG',
            message:
                'In C.G. (code.gamelet.come), you can purchase CG Powers (powers that help you make better games) with <b>{company}</b>, and obtain <b>Creative Points</b>. You can then convert the Creative Points into <b>Gamelet Points</b> that can be used in the games on gamelet.online.',
        },
        price: {
            TWD: 'TWD {price}',
            USD: 'USD {price}',
        },
        btn: {
            buy: 'BUY',
        },
        intro: {
            title: 'Deposit GLT Dollar',
            lines: [
                'Gamelet and all creators need your support!',
                'Purchase GLT-dollars and spend on your favorite games to encourage the creators to build more fun.',
            ],
        },
    },
});

export const pay_translation_en = 1;
