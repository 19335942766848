import { translate } from '../filters/translate';

const all: ReportFormat[] = [];

export class ReportFormat {
    static ARTICLE_ABUSE = new ReportFormat('articleAbuse', ['targetID', 'abuseTime', 'clientOrUrl', 'abuseDesc']);
    static PLAYER_ABUSE = new ReportFormat('playerAbuse', ['targetID', 'abuseTime', 'client', 'abuseDesc', 'abuseVideo']);
    static GAME_BUG = new ReportFormat('gameBug', [
        'playerID',
        'bugTime',
        'client',
        'bugTiming',
        'bugDesc',
        'bugRepeatable',
        'bugError',
    ]);
    static GM_ABUSE = new ReportFormat('gmAbuse', ['gmID', 'gmTime', 'gmTargetID', 'clientOrUrl', 'gmDesc']);
    static GAME_ABUSE = new ReportFormat('gameAbuse', ['client', 'abuseTime', 'abuseReason']);
    static SITE_BUG = new ReportFormat('siteBug', ['siteUrl', 'bugTiming', 'bugDesc', 'bugRepeatable', 'bugError']);

    constructor(public code: string, public labels: string[]) {
        all.push(this);
    }

    get name(): string {
        return translate(`report.name.${this.code}`);
    }

    generateHtmlTemplate(contents?: { [key: string]: string }): string {
        return this.labels
            .map((label) => {
                const content = (contents && contents[label]) || '';
                return generateReportTemplate(translate(`report.label.${label}`), content);
            })
            .join('');
    }
}

function generateReportTemplate(title: string, content: string): string {
    return [
        `<div style="margin-bottom:1em;">`,
        `<div style="color:#666">${title}</div>`,
        `<div style="border:1px solid #999;border-radius:5px;padding:10px;margin:10px 15px;min-height:1.5em;"><div>${content}</div></div>`,
        `</div>`,
    ].join('');
}
