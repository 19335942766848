import { importTranslation } from '../translate';

importTranslation('zh-Hant', {
    time: {
        aMinute: '一分鐘',
        minutes: '{minutes}分鐘',
        hours: '{hours}小時',
        days: '{days}天',
        years: '{years}年',
    },
    timeShort: {
        aMinute: '一分鐘',
        minutes: '{minutes}分鐘',
        hours: '{hours}小時',
        days: '{days}天',
        years: '{years}年',
    },
    timeAgo: {
        sec: '一秒前',
        secs: '{sec}秒前',
        min: '一分鐘前',
        mins: '{min}分鐘前',
        hour: '一小時前',
        hours: '{hour}小時前',
        day: '昨天',
        days: '{day}天前',
        month: '上個月',
        months: '{month}個月前',
        year: '去年',
        years: '{year}年前',
    },
    reply: '{reply}回覆',
    replies: '{reply}回覆',
    update: '{count}則更新',
    updates: '{count}則更新',
    to: '至',
    btn: {
        login: '登入',
        logout: '登出',
        myProfile: '我的檔案',
        myTeam: '我的盟會',
        more: '更多',
        confirm: '確定',
        cancel: '取消',
        close: '關閉',
        gotit: '知道了',
        depositBeforeBuy: '充值(資金不足)',
    },
    googleSearch: {
        input: 'Google搜尋',
        result: '搜尋結果',
    },
    twitter: {
        title: '推特消息',
    },
    leftMenu: {
        gameletQA: '嘎姆2.0<br />Q&A專區',
        gameletChannel: '官方頻道',
        moreFunction: 'COMING<br />SOON',
    },
    localedTitle: '嘎姆擂台',
    notLogin: '未登入',
    processing: '處理中...',
    gmHome: '管理員的家',
    loading: '讀取資料中...',
    updating: '更新資料中...',
    error: {
        notLogin: '請先登入帳號！',
        fileOverSized: '檔案太大了!',
    },
    locked: {
        reasonTitle: '凍結原因',
        showHowLong: '您的帳號暫時處於凍結狀態，還有 <span style="color: #fff09e">{time}</span> 解鎖。',
    },
    gm: {
        alert: {
            searchResourceUser: {
                selected: '已選擇',
                btnSelect: '選擇',
            },
        },
    },
});

export const common_translation_zh = 1;
