
import { Component, Prop, Vue } from 'vue-property-decorator';
import { translate } from '@/filters/translate';
import { getUserUrl, getUserTooltip } from '@/filters/user-filters';
import { serverService } from '../../../services/serverService';
import { ITeam } from '@/vo/User';
import CssPreloader from '@/components/CssPreloader.vue';

@Component({
    components: {
        CssPreloader,
    },
    methods: {
        translate,
        getUserUrl,
        getUserTooltip,
    },
})
export default class TeamList extends Vue {
    @Prop({ default: 0 })
    initLoad!: number;

    sizePerLoad = 20;

    searchTerm = '';

    teams: ITeam[] = [];

    loading = false;
    hasMore = true;

    searched = false;

    mounted(): void {
        if (this.initLoad) {
            this.reload(this.initLoad);
        } else {
            this.hasMore = false;
        }
    }

    reload(len?: number): void {
        this.searched = true;
        this.teams = [];
        this.loadMore(len);
    }

    loadMore(len?: number): void {
        this.loading = true;
        len = len || this.sizePerLoad;
        serverService.team.searchTeams(this.searchTerm, this.teams.length, len).then((list) => {
            this.teams = this.teams.concat(list);
            this.hasMore = list.length >= len!;
            this.loading = false;
        });
    }

    getTeamTooltip(team: ITeam): string {
        return `${team.nickname}\n(${team.teamname})\n${translate('team.membersAndSize', {
            members: team.members,
            size: team.size,
        })}`;
    }
}
