import { importTranslation } from '../translate';

importTranslation('zh-Hant', {
    forum: {
        board: {
            root: {
                title: '討論區',
                latestArticles: '討論區最新文章',
                latestArticlesShort: '最新文章',
                postReminderTitle: '什麼樣的文章會被管理員砍掉呢？',
                postReminderEnd: '請大家潔身自愛，給大家一個乾淨的討論區！',
                postReminder: [
                    '講粗話、做人身攻擊或包含各種不雅字眼',
                    '照抄遊戲所提供的內容',
                    '毫無內容可言，如「無聊」等兩三個字的文章',
                    '錯字或注音文太多，讓人無法理解內容的文章',
                    '張貼或宣傳作弊方法的文章',
                    '違反<a href="/terms_of_service" target="_blank">使用者條款</a>或<a href="{url}" target="_blank">版規</a>的文章',
                ],
            },
            cover: {
                author: '封面作者',
            },
            GameletChat: {
                tab: '哈拉聊天區',
                title: {
                    forum: '哈拉聊天區',
                },
                desc: '天南地北隨便聊，坡文把酒話桑麻，保持風度相尊重，挑燈夜戰望朝霞。',
            },
            GameletChat2: {
                tab: '哈拉聊天二區',
                title: {
                    forum: '哈拉聊天二區',
                },
                desc: '天南地北隨便聊，坡文把酒話桑麻，保持風度相尊重，挑燈夜戰望朝霞。',
            },
            games: {
                tab: '遊戲討論區',
                title: {
                    forum: '遊戲討論區',
                },
                desc: '討論遊戲中遭遇的疑惑、傳播遊戲知識、或分享遊戲中遇到的大小趣事。',
            },
            GameletGallery: {
                tab: '嘎姆藝廊',
                title: {
                    forum: '嘎姆藝廊',
                },
                desc: '繪畫、影片、音樂，嘎姆粉絲們可以愛現自己創作的園地。',
            },
            GameletOnline: {
                title: {
                    forum: '嘎姆GM討論',
                    report: '嘎姆支援',
                    news: '嘎姆擂台',
                },
            },
            GameletPress: {
                title: {
                    forum: '嘎姆編輯群',
                    report: '徵稿發文區',
                    news: '遊刊公告',
                    contribute: '嘎姆投稿專區',
                },
            },
        },
        alert: {
            emptyTitle: '標題是空的喔！',
            emptyContent: '還沒寫內文呢！',
            membersOnly: '這個討論版只限成員瀏覽。',
            uploadDialog: {
                title: '投稿至{category}',
            },
            loadingDialog: {
                text: '上傳中',
            },
        },
        btn: {
            enterForum: '進入討論區',
            enterGossip: '回哈拉聊天主區',
            enterGossip2: '又一個哈拉聊天區',
            post: '發表文章',
            determinePost: '發佈',
            cancel: '取消',
            reply: '回覆',
            submitPost: '發表文章',
            submitReply: '回覆',
            continueReading: '繼續閱讀',
            leaveMessage: '我要留言',
            confirmLeaveMessage: '送出留言',
            view: '展開',
            loadPrev: '載入之前的對話',
            manage: '管理',
            to_cool: '冷卻',
            to_warm: '回溫',
            to_open: '設為開放',
            to_close: '設為私密',
            to_dialog: '設為對話',
            to_removed: '移除',
            to_open_2: '復原文章',
            to_close_2: '關閉文章',
            to_markTop: '設為置頂',
            to_cancelTop: '取消置頂',
            to_markLock: '鎖回覆',
            to_cancelLock: '解鎖回覆',
            to_gossip2: '移至二板',
            to_gossip: '移回主板',
            enterBeta: 'Beta遊戲區',
            enterAllGames: '進入所有遊戲區',
            seeMore: '查看更多',
            seeLess: '看少點',
            backTo: '回 {name}',
        },
        top: '置頂',
        boardTopDesc: '將文章置頂，原置頂文章會取消置頂。',
        boardRemoveTopDesc: '取消文章的置頂設定。',
        locked: '回覆功能已上鎖',
        moveUp: '往上移',
        moveDown: '往下移',
        elevator: '升降梯',
        floor: '幾樓',
        markFloor: '標記樓層',
        closed: '已關閉',
        postClosedTitle: '貼文已關閉',
        rootPostClosed: '樓主已被關閉',
        postNotViewableLabel: '非公開',
        postNotViewable: '內文非公開',
        noMessageLeft: '還沒有人找到這裏...',
        gmCloseArticle: '關閉這則貼文',
        gmOpenArticle: '復原這則貼文',
        gmMoveTo_GameletChat: '移動至哈拉主板',
        gmMoveTo_GameletChat2: '移動至哈拉二板',
        gmReportStatus: '改變處理狀態 => {status}',
        gmNote: '註記',
        thisIsPrivateMsg: '這是一則私密的留言。',
        thisIsRemovedMsg: '這則留言已被刪除。',
        lastReply: '最新回覆',
        label: {
            title: '文章標題',
            noData: '這裡還沒有貼文...',
            wrong: '唔～載入文章時出了點問題...',
            poster: '樓主',
            track: {
                category: '文章類別',
                forum: '遊戲討論',
                report: '問題回報',
                contribute: '投稿',
                userBoard: '留言板',
                friendship: '朋友圈',
                teamship: '盟會',
            },
            more: {
                forum: '這串討論有更新的文章',
                report: '這則回報有更新的回應',
                userBoard: '有更新的留言回覆',
            },
        },
        placeholder: {
            article: '內文...',
            reply: '回覆...',
        },
        replyConfirm: {
            title: '準備要回覆了',
            btnConfirm: '確認回覆',
            btnCancel: '等等',
            posting: '回覆中...',
        },
        postConfirm: {
            title: '準備要貼文了',
            btnConfirm: '確認貼文',
            btnCancel: '等等',
            posting: '貼文中...',
        },
        states: {
            open: {
                option: '公開',
                title: '公開留言',
                desc: '公開留言可讓其他玩家亂入聊天。',
            },
            dialog: {
                option: '對話',
                title: '公開對話',
                desc: '公開對話可讓其他玩家看到對話內容，但僅限留言者與版主兩人回覆留言。',
            },
            close: {
                option: '私密',
                title: '私密留言',
                desc: '私密留言僅限留言者及版主兩人閱讀內文與回覆留言。',
            },
            removed: {
                option: '已刪除',
                title: '已刪除的留言',
                desc: '刪除的留言無法復原，將永遠被世人遺忘。',
            },
        },
        userAction: {
            open: '設為開放留言',
            close: '設為私密對話',
            dialog: '設為公開對話',
            removed: '刪除對話',
        },
        watch: {
            follow: '追蹤文章',
            re_follow: '恢復追蹤',
            un_follow: '取消追蹤',
            following: '正在追蹤文章',
            not_following: '未追蹤文章',
            stopped_following: '已停止追蹤',
        },
    },
    news: {
        title: {
            allGames: '遊戲新聞',
            aGame: '遊戲公告',
            postTips: '注意事項',
            dates: '周報日期',
        },
        btn: {
            post: '發佈公告/新聞',
            submitPost: '發佈公告',
        },
        label: {
            title: '新聞標題',
            noData: '還沒有更新或公告',
        },
        placeholder: {
            content: '公告細節...',
        },
        postTips: [
            '<b>新聞標題</b>是大部分玩家會看到的部分，請簡短明瞭地幫這個更新下個容易明白的標題。',
            '請<b>一次只發佈一則</b>更新或公告，系統會幫遊戲將一個星期內的所有新聞匯總至一則新聞總集給玩家看。',
        ],
    },
    progress: {
        title: '開發進度',
        editTime: '{time}更新',
        inputDesc: {
            title: '項目標題',
            desc: '項目描述',
            progress: '目前進度',
            total: '總進度',
        },
        btn: {
            addProgress: '新增進度',
        },
        label: {
            deleteTitle: '刪除進度',
        },
        alert: {
            total: '總進度需大於0',
            progress: '目前進度需大於0',
            progressAndTotal: '目前進度需小於總進度',
            noTitle: '項目標題不可空白',
        },
    },
    report: {
        selectFormat: '請先選擇一種回報格式',
        name: {
            articleAbuse: '言論與資料違規檢舉(謾罵、洗板、個資外洩等內容)',
            playerAbuse: '遊戲違規檢舉(加速器、洗分)',
            gameBug: '登入障礙、遊戲BUG',
            gmAbuse: '遊戲管理員不當處置的申訴',
            siteBug: '官方網站BUG',
            gameAbuse: '檢舉遊戲',
        },
        btn: {
            post: '回報問題',
            submitPost: '回報問題',
        },
        label: {
            title: '回報主題',
            targetID: '違規帳號(ID)',
            abuseTime: '違規時間(日期/時間)',
            clientOrUrl: '在哪個地方(請貼連結網址)',
            client: '是哪個遊戲',
            siteUrl: '在哪個網頁(請貼連結網址)',
            abuseDesc: '具體違規行為',
            abuseVideo: '影片證據連結',
            abuseReason: '違規原因',
            playerID: '玩家帳號(ID)',
            bugTime: '出現異常的時間',
            bugTiming: '出現異常的時機/模式/畫面',
            bugDesc: '異常的狀況',
            bugRepeatable: '重新整理網頁後會不會重複出現',
            bugError: '是否有看到錯誤訊息(如有看到請將錯誤訊息複製貼上)',
            gmID: '申訴的對象(管理員帳號ID/暱稱)',
            gmTime: '行為時間(日期/時間)',
            gmTargetID: '被處置的玩家(帳號ID/暱稱)',
            gmDesc: '詳情',
            noData: '這裡沒有人回報問題',
        },
        states: {
            open: {
                option: '公開',
                title: '公開回報',
                desc: '',
            },
            dialog: {
                option: '非公開',
                title: '非公開回報',
                desc: '',
            },
        },
        status: {
            open: {
                option: '待辦',
                desc: '',
            },
            done: {
                option: '已結案',
                desc: '',
            },
            passover: {
                option: '無法處理',
                desc: '',
            },
        },
    },
    contribute: {
        btn: {
            post: '撰寫文章',
            postImg: '投稿封面',
            submitPost: '投稿',
            closeSubject: '關閉投稿',
            openSubject: '開放投稿',
        },
        label: {
            title: '文章主題',
            noData: '還沒有人投稿文章...',
            postImg: '投稿封面',
            viewAll: '看全部',
            viewThisPeriod: '看本期',
        },
        message: {
            subjectClosed: '投稿已於 {date} 截止',
        },
        states: {
            open: {
                option: '公開',
                title: '公開文章',
                desc: '',
            },
            dialog: {
                option: '非公開',
                title: '非公開文章',
                desc: '',
            },
        },
    },
    review: {
        btn: {
            post: '撰寫評論',
            submitPost: '發佈評論',
            viewHistory: '看他先前的評論',
            loadMore: '看更多',
        },
        label: {
            title: '評論摘要',
            noData: '還沒有專家來發表評論...',
            cantReview: '還不能評論喔',
            rate: '評分: {rate}',
            rateAvg: '評分: {rate}, 投票數: {votes}',
            history: '先前的評論',
        },
        message: {
            played: '玩了',
            needPlayToReview: '你需要遊玩這個遊戲{mins}分鐘以上才能發表評論',
            havePlayedOneMinute: '你已經玩了一分鐘左右',
            havePlayedMinutes: '你已經玩了{mins}分鐘左右了',
            havePlayedAlmostMinutes: '你已經玩了接近{mins}分鐘了',
            rate_0: '* 請謹慎地選擇你對這個遊戲的評分',
            rate_1: '一顆星：遊戲無法正常進行/想玩都玩不了',
            rate_2: '二顆星：很難理解這個遊戲怎麼玩/找不到好玩的點',
            rate_3: '三顆星：雖有瑕疵，但玩法/故事/概念有可取之處',
            rate_4: '四顆星：完成度高/耐玩/有用心的遊戲',
            rate_5: '五顆星：好玩度爆表/我是粉絲',
        },
        alert: {
            emptyRate: '請先選擇你對這個遊戲的評分',
        },
        forceEditTrue: {
            title: '準備更新您的評論',
            message: {
                lessOneDay: '上次評論的時間離現在不到一天，所以您現在即將覆蓋上一次評論的內容。',
                sameContent: '因為評論的內容和上次的評論相同，所以您現在即將覆蓋上一次評論的內容。',
            },
            btnConfirm: '確定更新',
        },
        forceEditFalse: {
            title: '準備發表新的評論',
            message: {
                any: '您即將發表一則新的評論。',
            },
            btnConfirm: '確定發表',
        },
        edit: {
            title: '更新還是發表新的評論',
            message: '您即將發表的評論內容和上次的評論內容有點像。請問您想要覆蓋上一次評論的內容，還是發表一則新的評論？',
            btn: {
                new: '發表新的評論',
                edit: '覆蓋上次評論',
            },
        },
    },
    userBoard: {
        states: {
            open: {
                option: '公開',
                title: '公開留言',
                desc: '公開留言可讓其他玩家亂入聊天。',
            },
            dialog: {
                option: '對話',
                title: '公開對話',
                desc: '公開對話可讓其他玩家看到對話內容，但僅限留言者與版主兩人回覆留言。',
            },
            close: {
                option: '私密',
                title: '私密留言',
                desc: '私密留言僅限留言者及版主兩人閱讀內文與回覆留言。',
            },
            removed: {
                option: '已刪除',
                title: '已刪除的留言',
                desc: '刪除的留言無法復原，將永遠被世人遺忘。',
            },
        },
        label: {
            boardSetting: '留言板設定',
        },
        btn: {
            showBlacklist: '列出我的黑名單',
        },
        rootState: {
            player: {
                title: '公開',
                btn: '公開',
            },
            friend: {
                title: '僅限朋友',
                btn: '僅限朋友',
            },
            me: {
                title: '留言板關閉',
                btn: '關閉留言板',
            },
        },
    },
    teamBoard: {
        states: {
            open: {
                option: '公開留言',
                title: '公開留言',
                desc: '公開留言可讓其他玩家亂入聊天。',
            },
            dialog: {
                option: '與盟對話',
                title: '與盟對話',
                desc: '與盟對話可讓其他玩家看到對話內容，但僅限留言者與盟會成員回覆留言。',
            },
            close: {
                option: '盟內私訊',
                title: '盟內私訊',
                desc: '盟內私訊僅限留言者及盟會成員閱讀內文與回覆留言。',
                thisIsPrivateMsg: '這是一則盟內私訊。',
            },
            removed: {
                option: '已刪除',
                title: '已刪除的留言',
                desc: '刪除的留言無法復原，將永遠被世人遺忘。',
            },
        },
        label: {
            boardSetting: '留言板設定',
        },
        btn: {
            showBlacklist: '列出盟會的黑名單',
        },
        rootState: {
            player: {
                title: '公開',
                btn: '公開',
            },
            friend: {
                title: '僅限成員',
                btn: '僅限成員',
            },
            me: {
                title: '留言板關閉',
                btn: '關閉留言板',
            },
        },
    },
    blogBoard: {
        comments: '讀者留言',
        states: {
            open: {
                option: '公開',
                title: '公開留言',
                desc: '公開留言可讓其他玩家亂入聊天。',
            },
            removed: {
                option: '已刪除',
                title: '已刪除的留言',
                desc: '刪除的留言無法復原，將永遠被世人遺忘。',
            },
        },
    },
    noti: {
        btn: {
            markAllRead: '全部標示已讀',
            markOneRead: '標示已讀',
        },
        label: {
            noti: '通知',
            noNoti: '沒有新通知',
            read: '已讀',
        },
        subject: {
            userReport: '回報了一個問題',
            userJoinedDiscuss: '加入討論 {rootTitle}',
            userMessage: '留了言給你',
            userMessageReply: '回應了你的留言',
            friendRequest: '{requester} 想和你成為朋友',
            friendGranted: '{acceptor} 接受了你的好友邀請',
            toTeamRequest: '{requester} 想加入你的盟會',
            toTeamGranted: '{acceptor} 接受了你的入盟申請',
            toTeamRejected: '{acceptor} 謝絕了你的入盟申請',
            teamInviteRequest: '{requester} 邀請你加入盟會',
            teamInviteGranted: '{acceptor} 加入了你的盟會',
            teamInviteRejected: '{acceptor} 謝絕加入你的盟會',
        },
    },
});

export const forum_translation_zh = 1;
