import { render, staticRenderFns } from "./GameManagerList.vue?vue&type=template&id=6c20f8f1&scoped=true"
import script from "./GameManagerList.vue?vue&type=script&lang=ts"
export * from "./GameManagerList.vue?vue&type=script&lang=ts"
import style0 from "./GameManagerList.vue?vue&type=style&index=0&id=6c20f8f1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c20f8f1",
  null
  
)

export default component.exports