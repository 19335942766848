import { importTranslation } from '../translate';

importTranslation('zh-Hans', {
    forum: {
        board: {
            root: {
                title: '讨论区',
                latestArticles: '讨论区最新文章',
                latestArticlesShort: '最新文章',
                postReminderTitle: '什么样的文章会被管理员砍掉呢？ ',
                postReminderEnd: '请大家洁身自爱，给大家一个干净的讨论区！ ',
                postReminder: [
                    '讲粗话、做人身攻击或包含各种不雅字眼',
                    '照抄游戏所提供的内容',
                    '毫无内容可言，如「无聊」等两三个字的文章',
                    '错字或注音文太多，让人无法理解内容的文章',
                    '张贴或宣传作弊方法的文章',
                    '违反<a href="/terms_of_service" target="_blank">使用者条款</a>或<a href="{url}" target="_blank">版规</a>的文章',
                ],
            },
            cover: {
                author: '封面作者',
            },
            GameletChat: {
                tab: '哈拉聊天区',
                title: {
                    forum: '哈拉聊天区',
                },
                desc: '天南地北随便聊，坡文把酒话桑麻，保持风度相尊重，挑灯夜战望朝霞。 ',
            },
            GameletChat2: {
                tab: '哈拉聊天二区',
                title: {
                    forum: '哈拉聊天二区',
                },
                desc: '天南地北随便聊，坡文把酒话桑麻，保持风度相尊重，挑灯夜战望朝霞。 ',
            },
            games: {
                tab: '游戏讨论区',
                title: {
                    forum: '游戏讨论区',
                },
                desc: '讨论游戏中遭遇的疑惑、传播游戏知识、或分享游戏中遇到的大小趣事。 ',
            },
            GameletGallery: {
                tab: '嘎姆艺廊',
                title: {
                    forum: '嘎姆艺廊',
                },
                desc: '绘画、影片、音乐，嘎姆粉丝们可以爱现自己创作的园地。 ',
            },
            GameletOnline: {
                title: {
                    forum: '嘎姆GM讨论',
                    report: '嘎姆支援',
                    news: '嘎姆擂台',
                },
            },
            GameletPress: {
                title: {
                    forum: '嘎姆编辑群',
                    report: '征稿发文区',
                    news: '游刊公告',
                    contribute: '嘎姆投稿专区',
                },
            },
        },
        alert: {
            emptyTitle: '标题是空的喔！ ',
            emptyContent: '还没写内文呢！ ',
            membersOnly: '这个讨论版只限成员浏览。 ',
            uploadDialog: {
                title: '投稿至{category}',
            },
            loadingDialog: {
                text: '上传中',
            },
        },
        btn: {
            enterForum: '进入讨论区',
            enterGossip: '回哈拉聊天主区',
            enterGossip2: '又一个哈拉聊天区',
            post: '发表文章',
            determinePost: '发布',
            cancel: '取消',
            reply: '回覆',
            submitPost: '发表文章',
            submitReply: '回覆',
            continueReading: '继续阅读',
            leaveMessage: '我要留言',
            confirmLeaveMessage: '送出留言',
            view: '展开',
            loadPrev: '载入之前的对话',
            manage: '管理',
            to_cool: '冷却',
            to_warm: '回温',
            to_open: '设为开放',
            to_close: '设为私密',
            to_dialog: '设为对话',
            to_removed: '移除',
            to_open_2: '复原文章',
            to_close_2: '关闭文章',
            to_markTop: '设为置顶',
            to_cancelTop: '取消置顶',
            to_markLock: '锁回覆',
            to_cancelLock: '解锁回覆',
            to_gossip2: '移至二板',
            to_gossip: '移回主板',
            enterBeta: 'Beta游戏区',
            enterAllGames: '进入所有游戏区',
            seeMore: '查看更多',
            seeLess: '看少点',
            backTo: '回 {name}',
        },
        top: '置顶',
        boardTopDesc: '将文章置顶，原置顶文章会取消置顶。 ',
        boardRemoveTopDesc: '取消文章的置顶设定。 ',
        locked: '回覆功能已上锁',
        moveUp: '往上移',
        moveDown: '往下移',
        elevator: '升降梯',
        floor: '几楼',
        markFloor: '标记楼层',
        closed: '已关闭',
        postClosedTitle: '贴文已关闭',
        rootPostClosed: '楼主已被关闭',
        postNotViewableLabel: '非公开',
        postNotViewable: '内文非公开',
        noMessageLeft: '还没有人找到这里...',
        gmCloseArticle: '关闭这则贴文',
        gmOpenArticle: '复原这则贴文',
        gmMoveTo_GameletChat: '移动至哈拉主板',
        gmMoveTo_GameletChat2: '移动至哈拉二板',
        gmReportStatus: '改变处理状态 => {status}',
        gmNote: '注记',
        thisIsPrivateMsg: '这是一则私密的留言。 ',
        thisIsRemovedMsg: '这则留言已被删除。 ',
        lastReply: '最新回覆',
        label: {
            title: '文章标题',
            noData: '这里还没有贴文...',
            wrong: '唔～载入文章时出了点问题...',
            poster: '楼主',
            track: {
                category: '文章类别',
                forum: '游戏讨论',
                report: '问题回报',
                contribute: '投稿',
                userBoard: '留言板',
                friendship: '朋友圈',
                teamship: '盟会',
            },
            more: {
                forum: '这串讨论有更新的文章',
                report: '这则回报有更新的回应',
                userBoard: '有更新的留言回覆',
            },
        },
        placeholder: {
            article: '内文...',
            reply: '回覆...',
        },
        replyConfirm: {
            title: '准备要回覆了',
            btnConfirm: '确认回复',
            btnCancel: '等等',
            posting: '回覆中...',
        },
        postConfirm: {
            title: '准备要贴文了',
            btnConfirm: '确认贴文',
            btnCancel: '等等',
            posting: '贴文中...',
        },
        states: {
            open: {
                option: '公开',
                title: '公开留言',
                desc: '公开留言可让其他玩家乱入聊天。 ',
            },
            dialog: {
                option: '对话',
                title: '公开对话',
                desc: '公开对话可让其他玩家看到对话内容，但仅限留言者与版主两人回覆留言。 ',
            },
            close: {
                option: '私密',
                title: '私密留言',
                desc: '私密留言仅限留言者及版主两人阅读内文与回覆留言。 ',
            },
            removed: {
                option: '已删除',
                title: '已删除的留言',
                desc: '删除的留言无法复原，将永远被世人遗忘。 ',
            },
        },
        userAction: {
            open: '设为开放留言',
            close: '设为私密对话',
            dialog: '设为公开对话',
            removed: '删除对话',
        },
        watch: {
            follow: '追踪文章',
            re_follow: '恢复追踪',
            un_follow: '取消追踪',
            following: '正在追踪文章',
            not_following: '未追踪文章',
            stopped_following: '已停止追踪',
        },
    },
    news: {
        title: {
            allGames: '游戏新闻',
            aGame: '游戏公告',
            postTips: '注意事项',
            dates: '周报日期',
        },
        btn: {
            post: '发布公告/新闻',
            submitPost: '发布公告',
        },
        label: {
            title: '新闻标题',
            noData: '还没有更新或公告',
        },
        placeholder: {
            content: '公告细节...',
        },
        postTips: [
            '<b>新闻标题</b>是大部分玩家会看到的部分，请简短明了地帮这个更新下个容易明白的标题。 ',
            '请<b>一次只发布一则</b>更新或公告，系统会帮游戏将一个星期内的所有新闻汇总至一则新闻总集给玩家看。 ',
        ],
    },
    progress: {
        title: '开发进度',
        editTime: '{time}更新',
        inputDesc: {
            title: '项目标题',
            desc: '项目描述',
            progress: '目前进度',
            total: '总进度',
        },
        btn: {
            addProgress: '新增进度',
        },
        label: {
            deleteTitle: '删除进度',
        },
        alert: {
            total: '总进度需大于0',
            progress: '目前进度需大于0',
            progressAndTotal: '目前进度需小于总进度',
            noTitle: '项目标题不可空白',
        },
    },
    report: {
        selectFormat: '请先选择一种回报格式',
        name: {
            articleAbuse: '言论与资料违规检举(谩骂、洗板、个资外泄等内容)',
            playerAbuse: '游戏违规检举(加速器、洗分)',
            gameBug: '登入障碍、游戏BUG',
            gmAbuse: '游戏管理员不当处置的申诉',
            siteBug: '官方网站BUG',
            gameAbuse: '检举游戏',
        },
        btn: {
            post: '回报问题',
            submitPost: '回报问题',
        },
        label: {
            title: '回报主题',
            targetID: '违规帐号(ID)',
            abuseTime: '违规时间(日期/时间)',
            clientOrUrl: '在哪个地方(请贴连结网址)',
            client: '是哪个游戏',
            siteUrl: '在哪个网页(请贴连结网址)',
            abuseDesc: '具体违规行为',
            abuseVideo: '影片证据连结',
            abuseReason: '违规原因',
            playerID: '玩家帐号(ID)',
            bugTime: '出现异常的时间',
            bugTiming: '出现异常的时机/模式/画面',
            bugDesc: '异常的状况',
            bugRepeatable: '重新整理网页后会不会重复出现',
            bugError: '是否有看到错误讯息(如有看到请将错误讯息复制贴上)',
            gmID: '申诉的对象(管理员帐号ID/昵称)',
            gmTime: '行为时间(日期/时间)',
            gmTargetID: '被处置的玩家(帐号ID/昵称)',
            gmDesc: '详情',
            noData: '这里没有人回报问题',
        },
        states: {
            open: {
                option: '公开',
                title: '公开回报',
                desc: '',
            },
            dialog: {
                option: '非公开',
                title: '非公开回报',
                desc: '',
            },
        },
        status: {
            open: {
                option: '待办',
                desc: '',
            },
            done: {
                option: '已结案',
                desc: '',
            },
            passover: {
                option: '无法处理',
                desc: '',
            },
        },
    },
    contribute: {
        btn: {
            post: '撰写文章',
            postImg: '投稿封面',
            submitPost: '投稿',
            closeSubject: '关闭投稿',
            openSubject: '开放投稿',
        },
        label: {
            title: '文章主题',
            noData: '还没有人投稿文章...',
            postImg: '投稿封面',
            viewAll: '看全部',
            viewThisPeriod: '看本期',
        },
        message: {
            subjectClosed: '投稿已于 {date} 截止',
        },
        states: {
            open: {
                option: '公开',
                title: '公开文章',
                desc: '',
            },
            dialog: {
                option: '非公开',
                title: '非公开文章',
                desc: '',
            },
        },
    },
    review: {
        btn: {
            post: '撰写评论',
            submitPost: '发布评论',
            viewHistory: '看他先前的评论',
            loadMore: '看更多',
        },
        label: {
            title: '评论摘要',
            noData: '还没有专家来发表评论...',
            cantReview: '还不能评论喔',
            rate: '评分: {rate}',
            rateAvg: '评分: {rate}, 投票数: {votes}',
            history: '先前的评论',
        },
        message: {
            played: '玩了',
            needPlayToReview: '你需要游玩这个游戏{mins}分钟以上才能发表评论',
            havePlayedOneMinute: '你已经玩了一分钟左右',
            havePlayedMinutes: '你已经玩了{mins}分钟左右了',
            havePlayedAlmostMinutes: '你已经玩了接近{mins}分钟了',
            rate_0: '* 请谨慎地选择你对这个游戏的评分',
            rate_1: '一颗星：游戏无法正常进行/想玩都玩不了',
            rate_2: '二颗星：很难理解这个游戏怎么玩/找不到好玩的点',
            rate_3: '三颗星：虽有瑕疵，但玩法/故事/概念有可取之处',
            rate_4: '四颗星：完成度高/耐玩/有用心的游戏',
            rate_5: '五颗星：好玩度爆表/我是粉丝',
        },
        alert: {
            emptyRate: '请先选择你对这个游戏的评分',
        },
        forceEditTrue: {
            title: '准备更新您的评论',
            message: {
                lessOneDay: '上次评论的时间离现在不到一天，所以您现在即将覆盖上一次评论的内容。 ',
                sameContent: '因为评论的内容和上次的评论相同，所以您现在即将覆盖上一次评论的内容。 ',
            },
            btnConfirm: '确定更新',
        },
        forceEditFalse: {
            title: '准备发表新的评论',
            message: {
                any: '您即将发表一则新的评论。 ',
            },
            btnConfirm: '确定发表',
        },
        edit: {
            title: '更新还是发表新的评论',
            message: '您即将发表的评论内容和上次的评论内容有点像。 请问您想要覆盖上一次评论的内容，还是发表一则新的评论？ ',
            btn: {
                new: '发表新的评论',
                edit: '覆盖上次评论',
            },
        },
    },
    userBoard: {
        states: {
            open: {
                option: '公开',
                title: '公开留言',
                desc: '公开留言可让其他玩家乱入聊天。 ',
            },
            dialog: {
                option: '对话',
                title: '公开对话',
                desc: '公开对话可让其他玩家看到对话内容，但仅限留言者与版主两人回覆留言。 ',
            },
            close: {
                option: '私密',
                title: '私密留言',
                desc: '私密留言仅限留言者及版主两人阅读内文与回覆留言。 ',
            },
            removed: {
                option: '已删除',
                title: '已删除的留言',
                desc: '删除的留言无法复原，将永远被世人遗忘。 ',
            },
        },
        label: {
            boardSetting: '留言板设定',
        },
        btn: {
            showBlacklist: '列出我的黑名单',
        },
        rootState: {
            player: {
                title: '公开',
                btn: '公开',
            },
            friend: {
                title: '仅限朋友',
                btn: '仅限朋友',
            },
            me: {
                title: '留言板关闭',
                btn: '关闭留言板',
            },
        },
    },
    teamBoard: {
        states: {
            open: {
                option: '公开留言',
                title: '公开留言',
                desc: '公开留言可让其他玩家乱入聊天。 ',
            },
            dialog: {
                option: '与盟对话',
                title: '与盟对话',
                desc: '与盟对话可让其他玩家看到对话内容，但仅限留言者与盟会成员回覆留言。 ',
            },
            close: {
                option: '盟内私讯',
                title: '盟内私讯',
                desc: '盟内私讯仅限留言者及盟会成员阅读内文与回覆留言。 ',
                thisIsPrivateMsg: '这是一则盟内私讯。 ',
            },
            removed: {
                option: '已删除',
                title: '已删除的留言',
                desc: '删除的留言无法复原，将永远被世人遗忘。 ',
            },
        },
        label: {
            boardSetting: '留言板设定',
        },
        btn: {
            showBlacklist: '列出盟会的黑名单',
        },
        rootState: {
            player: {
                title: '公开',
                btn: '公开',
            },
            friend: {
                title: '仅限成员',
                btn: '仅限成员',
            },
            me: {
                title: '留言板关闭',
                btn: '关闭留言板',
            },
        },
    },
    blogBoard: {
        comments: '读者留言',
        states: {
            open: {
                option: '公开',
                title: '公开留言',
                desc: '公开留言可让其他玩家乱入聊天。 ',
            },
            removed: {
                option: '已删除',
                title: '已删除的留言',
                desc: '删除的留言无法复原，将永远被世人遗忘。 ',
            },
        },
    },
    noti: {
        btn: {
            markAllRead: '全部标示已读',
            markOneRead: '标示已读',
        },
        label: {
            noti: '通知',
            noNoti: '没有新通知',
            read: '已读',
        },
        subject: {
            userReport: '回报了一个问题',
            userJoinedDiscuss: '加入讨论 {rootTitle}',
            userMessage: '留了言给你',
            userMessageReply: '回应了你的留言',
            friendRequest: '{requester} 想和你成为朋友',
            friendGranted: '{acceptor} 接受了你的好友邀请',
            toTeamRequest: '{requester} 想加入你的盟会',
            toTeamGranted: '{acceptor} 接受了你的入盟申请',
            toTeamRejected: '{acceptor} 谢绝了你的入盟申请',
            teamInviteRequest: '{requester} 邀请你加入盟会',
            teamInviteGranted: '{acceptor} 加入了你的盟会',
            teamInviteRejected: '{acceptor} 谢绝加入你的盟会',
        },
    },
});

export const forum_translation_cn = 1;