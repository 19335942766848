
import { Component, Vue, Watch } from 'vue-property-decorator';
import RootTabs from './components/RootTabs.vue';
import Friendship from './components/Friendship.vue';
import Teamship from './components/Teamship.vue';
import CssPreloader from '@/components/CssPreloader.vue';
import AdsResponsive from '@/components/ads/AdsResponsive.vue';
import { FileUtil } from '@/utils/FileUtil';
import { serverService } from '@/services/serverService';
import { translate } from '@/filters/translate';
import { errorToString } from '@/filters/content-filters';
import { appUser_translation_en } from '@/filters/trans/app-user.en';
import { appUser_translation_zh } from '@/filters/trans/app-user.zh';
import { dialogService } from '@/services/dialogService';
import { showSideAds } from '@/filters/mobile';
import { Route } from 'vue-router';
appUser_translation_en;
appUser_translation_zh;
import { Constant } from '@/datas/Constant';
import { FriendshipStatus } from '../../datas/FriendshipStatus';
import { IUser } from '@/vo/User';

declare const $: any;

@Component({
    name: 'UserRoot',
    components: {
        RootTabs,
        Friendship,
        Teamship,
        AdsResponsive,
        CssPreloader,
    },
    methods: {
        translate,
        showSideAds,
    },
})
export default class UserRoot extends Vue {
    public $refs!: Vue['$refs'] & {
        username: HTMLDivElement;
    };

    editFields = [
        /* {
            key: 'nickname',
            allowEmpty: false,
            default: '',
        },*/
        {
            key: 'mood',
            allowEmpty: true,
            default: translate('i-have-no-mood'),
        },
    ];

    editIcon: {
        base64: string;
        loading: boolean;
    } | null = null;

    loading = false;
    tabs: string[] = [];
    currentTab = '';

    me: IUser | null = null;
    user: IUser | null = null;
    isGM = false;
    friendshipStatus = FriendshipStatus.LOADING;

    editUser: {
        key: string;
        value: string;
        allowEmpty: boolean;
        loading: boolean;
    } | null = null;

    mounted(): void {
        this.refresh();
    }

    @Watch('$route')
    onRouterChange(to: Route, from: Route): void {
        if (to.params.username != from.params.username) {
            this.refresh();
        } else {
            this.refreshTabs(this.user!);
        }
    }

    onFriendshipChanged(friendshipStatus: FriendshipStatus): void {
        this.friendshipStatus = friendshipStatus;
    }
    onTeamshipChanged(): void {
        // do nothing
    }

    impersonate(): void {
        window.location.href = this.impersonateUrl;
    }

    get editProfileUrl(): string {
        return serverService.generateServerUrl('/profile/edit?from=' + window.location.href);
    }

    get impersonateUrl(): string {
        return '/?_switch_user=' + this.user?.username;
    }

    get userIconUrl(): string {
        if (this.user) {
            if (this.editIcon) {
                return this.editIcon.base64;
            }
            return this.user.iconUrl;
        } else {
            return '';
        }
    }

    get mine(): boolean {
        return !!(this.me && this.user && this.me.username == this.user.username);
    }

    get meAdmin(): boolean {
        return !!(this.me && this.me.admin);
    }

    startEditUser(key: string, allowEmpty: boolean): void {
        if (!this.editUser && this.mine) {
            this.editUser = {
                key: key,
                value: this.user![key],
                allowEmpty: allowEmpty,
                loading: false,
            };
        }
    }
    doneEditUser(save: boolean): void {
        let editUser = this.editUser;
        if (editUser && save && this.mine) {
            editUser.value = editUser.value.trim();
            if (editUser.allowEmpty || editUser.value) {
                let data: any = {};
                data[editUser.key] = editUser.value;
                editUser.loading = true;
                serverService.user
                    .saveUser(this.user!, data)
                    .then(() => {
                        this.editUser = null;
                    })
                    .catch((err) => {
                        editUser!.loading = false;
                        this.editUser = editUser;
                        dialogService.error({ body: errorToString(err) });
                    });
            }
        } else {
            this.editUser = null;
        }
    }

    private refresh(): void {
        this.loading = true;
        if (this.user && this.$route.params.username.toLowerCase() != this.user.username.toLowerCase()) {
            this.user = null;
        }

        serverService.getInitData().then((initData) => {
            this.me = initData.me;
            let username = this.$route.params.username;

            if (username) {
                this.loadUser(username)
                    .then((user) => {
                        if (user) {
                            const teamname = user['teamname'];
                            if (teamname) {
                                this.$router.replace({ name: 'team', params: { teamname: teamname } });
                            } else {
                                this.user = user;
                                this.refreshIsGM();
                                this.refreshTabs(user!);
                            }
                        } else {
                            dialogService.error({ body: translate('error.found-no-user') }).then(() => {
                                this.$router.replace({ name: 'home' });
                            });
                        }
                    })
                    .catch((err) => {
                        dialogService.error({ body: errorToString(err) });
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                this.loading = false;

                if (this.me) {
                    this.$router.replace({
                        name: 'user',
                        params: { username: this.me.username },
                    });
                } else {
                    this.$router.replace({
                        name: 'home',
                    });
                }
            }
        });
    }

    private refreshTabs(user?: IUser): void {
        let defaultTab = 'user_about';
        if (user) {
            if (!user.guest) {
                this.tabs = ['user_board', 'user_about', 'user_badges', 'user_friends'];
                if (serverService.isMe(user) || serverService.isGM()) {
                    this.tabs.splice(1, 0, 'user_posts');
                }
            } else {
                this.tabs = [defaultTab];
            }
            let initTab = this.$router.currentRoute.name;
            if (initTab?.startsWith('user_board')) {
                initTab = 'user_board';
            } else if (initTab?.startsWith('user_posts')) {
                initTab = 'user_posts';
            }

            if (initTab && this.tabs.includes(initTab)) {
                this.currentTab = initTab;
            } else {
                this.currentTab = defaultTab;
            }
            document.title = user.nickname + ':' + translate('tab.' + this.currentTab);
        } else {
            this.$router.replace({
                name: 'home',
            });
        }
    }

    private loadUser(username: string): Promise<IUser | null> {
        return serverService.user.getUser(username);
    }

    private copyUsername() {
        if (window.getSelection()) {
            const range = document.createRange();
            range.selectNode(this.$refs.username);
            window.getSelection()!.removeAllRanges();
            window.getSelection()!.addRange(range);
            document.execCommand('Copy');
            window.getSelection()!.removeAllRanges();

            $(this.$refs.username).tooltip('show');
            setTimeout(() => {
                $(this.$refs.username).tooltip('hide');
            }, 1000);
        }
    }

    //
    startEditIcon(): void {
        let input = document.createElement('input');
        input.type = 'file';
        input.accept = '.gif,.png,.jpg';
        input.click();

        input.onchange = (e) => {
            const el = e.target as HTMLInputElement;
            if (el.files && el.files.length) {
                FileUtil.convertFileToBase64(el.files[0])
                    .then((content) => FileUtil.squareDataUrl(content, 120))
                    .then((dataUrl) => {
                        this.editIcon = {
                            base64: dataUrl,
                            loading: false,
                        };
                    });
            }
        };
    }

    refreshIsGM(): void {
        this.isGM = false;
        serverService.client.listGameMasters(Constant.SITE_CLIENT.code).then((data) => {
            data.forEach((element) => {
                if (element.username == this.user!.username) this.isGM = true;
            });
        });
    }

    doneEditIcon(save: boolean): void {
        if (save && this.editIcon) {
            let editIcon = this.editIcon!;
            editIcon.loading = true;
            serverService.user
                .saveUser(this.user!, {
                    icon: editIcon.base64,
                })
                .then(() => {
                    this.editIcon = null;
                })
                .catch((err) => {
                    this.editIcon = null;
                    dialogService.error({ body: errorToString(err) });
                });
        } else {
            this.editIcon = null;
        }
    }
}
