export class ArrayUtil {
    /**
     * Swaps the position of the elements.
     * @param {Array<unknown>} array the array to swap the position of the elements.
     * @param {number} index1 the first element.
     * @param {number} index2 the second element.
     */
    public static swapElements(array: Array<unknown>, element1: unknown, element2: unknown): void {
        ArrayUtil.swapElementsAt(array, array.indexOf(element1), array.indexOf(element2));
    }
    /**
     * Swaps the position of the elements in the specified indexes.
     * @param {Array[]} array the array to swap the position of the elements.
     * @param {number} index1 the index of the first element.
     * @param {number} index2 the index of the second element.
     */
    public static swapElementsAt(array: Array<unknown>, index1: number, index2: number): void {
        const el: unknown = array[index1];
        array[index1] = array[index2];
        array[index2] = el;
    }

    /**
     * Remove an element from the array
     * @param {Array<unknown>} array
     * @param {unknown} element
     */
    public static removeElement(array: Array<unknown>, element: unknown): boolean {
        const index = array.indexOf(element);
        if (index == -1) return false;
        array.splice(index, 1);
        return true;
    }

    /**
     * Add an element to an array if the specified element is not in the array.
     * @param {Array<unknown>} array
     * @param {unknown} element
     */
    public static addUniqueElement(array: Array<unknown>, element: unknown): boolean {
        if (array.indexOf(element) != -1) return false;

        array.push(element);
        return true;
    }

    /**
     * Sort an array of number objects
     * @param {Array<unknown>} array
     * @param {boolean} ascending
     */
    public static sortNumeric(array: Array<never>, ascending?: boolean): Array<never> {
        if (ascending) {
            array.sort((a, b) => a - b);
        } else {
            array.sort((a, b) => b - a);
        }
        return array;
    }

    /**
     * Sort an array of objects by a field of the objects.
     * @param {Array<unknown>} array
     * @param {string} key the property key used to sort
     * @param {boolean} ascending
     */
    public static sortNumericOn(arr: Array<any>, key: string, ascending?: boolean): Array<any> {
        if (ascending) {
            arr.sort((a, b) => a[key] - b[key]);
        } else {
            arr.sort((a, b) => b[key] - a[key]);
        }
        return arr;
    }

    /**
     * Sort an array of objects by a field of the objects.
     * @param {Array<unknown>} array
     * @param {string} key the property key used to sort
     * @param {boolean} ascending
     */
    public static sortOn(arr: Array<never>, key: string, ascending?: boolean): Array<any> {
        if (ascending) {
            arr.sort((a, b) => (a[key] == b[key] ? 0 : a[key] > b[key] ? 1 : -1));
        } else {
            arr.sort((a, b) => (a[key] == b[key] ? 0 : a[key] < b[key] ? 1 : -1));
        }
        return arr;
    }

    /**
     * Sort an array of objects by a list of keys in order.
     * @param {Array<unknown>} array
     * @param {Array<unknown>} sort keys, each item includes a key:string, ascending:boolean, numberic:boolean
     */
    public static sortOnKeys(
        arr: Array<never>,
        sorts: {
            key: string;
            ascending?: boolean;
            numberic?: boolean;
        }[]
    ): Array<any> {
        const maxIndex = sorts.length - 1;
        const compareFn = (a, b, index: number) => {
            if (index == maxIndex) {
                return 0;
            } else {
                const sort = sorts[index];

                if (a[sort.key] == b[sort.key]) {
                    return compareFn(a, b, index + 1);
                }
                if (sort.numberic) {
                    if (sort.ascending) {
                        return a[sort.key] - b[sort.key];
                    } else {
                        return b[sort.key] - a[sort.key];
                    }
                } else {
                    if (sort.ascending) {
                        return a[sort.key] > b[sort.key] ? 1 : -1;
                    } else {
                        return a[sort.key] < b[sort.key] ? 1 : -1;
                    }
                }
            }
        };
        arr.sort((a, b) => compareFn(a, b, 0));
        return arr;
    }

    /**
     * Set an element in an array to a specified index.
     * @param {Array<unknown>} array
     * @param {unknown} element
     * @param {number} index
     * @return {number} returns the index number of the element
     */
    public static setElementIndex(array: Array<never>, element: never, index: number): number {
        const order: number = array.indexOf(element);
        if (order == -1) return -1;

        index = Math.max(0, Math.min(array.length - 1, index));
        if (index != order) {
            array.splice(order, 1);
            array.splice(index, 0, element);
        }
        return index;
    }

    public static listKeysOfObject(obj: { [key: string]: never }): Array<string> {
        const keys: Array<string> = [];
        for (const key in obj) {
            keys.push(key);
        }
        return keys;
    }

    public static listValuesOfObject(obj: { [key: string]: unknown }): Array<unknown> {
        const values: Array<unknown> = [];
        for (const key in obj) {
            values.push(obj[key]);
        }
        return values;
    }

    public static getRandomElement<T>(list: T[], remove?: boolean): T | null {
        if (!list || !list.length) {
            return null;
        }
        const index = Math.floor(Math.random() * list.length);
        const item = list[index];
        if (remove) {
            list.splice(index, 1);
        }
        return item;
    }
}
