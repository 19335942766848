
import { Component, Vue } from 'vue-property-decorator';

declare const $: any;

@Component({})
export default class ThemeBtn extends Vue {
    darkMode = false;

    mounted(): void {
        this.darkMode = localStorage.getItem('theme') == 'dark';
        this.onSet();
    }

    toggle(): void {
        this.darkMode = !this.darkMode;
        this.onSet();
        localStorage.setItem('theme', this.darkMode ? 'dark' : 'light');
    }

    onSet(): void {
        $(document.body).removeClass('theme-light theme-dark');
        $(document.body).removeClass('theme-light');
        $(document.body).addClass(this.darkMode ? 'theme-dark' : 'theme-light');
    }
}
