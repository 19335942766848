import { Location } from 'vue-router';
import { contentStripHTML } from './content-filters';
import { IFeedEntry } from '@/vo/Blog';

export function getBlogRoute(page: number, hash?: string): Location {
    if (page > 1) {
        return {
            name: 'blog_page',
            params: {
                page: page + '',
            },
            hash: hash,
        };
    } else {
        return {
            name: 'blog',
            hash: hash,
        };
    }
}
export function getBlogReadRoute(entry: IFeedEntry, page?: number, hash?: string): Location {
    if (page && page > 1) {
        return {
            name: 'blog_read_page',
            params: {
                id: 'b' + entry.id,
                page: page + '',
            },
            hash: hash,
        };
    } else {
        return {
            name: 'blog_read',
            params: {
                id: 'b' + entry.id,
            },
            hash: hash,
        };
    }
}

export function convertEntryToFeedEntry(_entry: unknown): IFeedEntry {
    const entry = _entry as any;
    let content: string = entry.content.$t;
    let thumb = '';
    const searchThumb = content.match(/<img\s[^>]*src="([^"]+)"/);
    if (searchThumb) {
        thumb = searchThumb[1];
    } else if (entry.media$thumbnail) {
        thumb = entry.media$thumbnail.url;
    }
    const searchAuthor = content.match(/(《.*?》)/);
    content = content.replace(/(《.*?》)/, '').replace(/<div class="post-page-container">.*?<\/div>/, '');
    const cutIndex = content.indexOf(`<a name='more'></a>`);
    if (cutIndex != -1) {
        content = content.substr(0, cutIndex);
    }
    content = contentStripHTML(
        content
            .replace(/[\n\r]/g, ' ')
            .trim()
            .replace(/<a[^>]+?>[^><]+<\/a>$/, '')
    );
    return {
        id: entry.id.$t.match(/post-([0-9]+)$/)[1],
        author: searchAuthor ? contentStripHTML(searchAuthor[1]) : '',
        content: content,
        title: entry.title.$t,
        tags: entry.category.map((cat) => cat.term),
        link: entry.link.find((link) => link.rel == 'alternate').href,
        thumb: thumb,
        published: new Date(entry.published.$t),
    };
}
