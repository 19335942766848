
import { Component, Vue } from 'vue-property-decorator';
import { serverService } from '@/services/serverService';
import { getClientPlayUrl, getClientOwnerUrl, getClientForumRoute } from '@/filters/game-filters';
import { translate } from '@/filters/translate';
import GameLinksButton from '@/components/GameLinksButton.vue';
import RateStars from '@/components/RateStars.vue';
import CssPreloader from '@/components/CssPreloader.vue';
import { isMobile } from '@/filters/mobile';
import AreaTitle from '@/components/AreaTitle.vue';
import { IClient } from '@/vo/Client';
import { DomUtil } from '@/utils/DomUtil';

declare const $: any;

@Component({
    components: {
        GameLinksButton,
        RateStars,
        CssPreloader,
        AreaTitle,
    },
    methods: {
        translate,
        getClientForumRoute,
        getClientPlayUrl,
        getClientOwnerUrl,
        isMobile,
    },
})
export default class GameSuggest extends Vue {
    gameData: IClient[] = [];
    idx = 0;
    contextAnimation = 'main-suggest-context';
    loading = true;
    isMounted = true;
    onlinePpls: { [key: string]: number } = {};
    onlinePplsLoader = {
        id: 0,
        nexttime: 0,
        interval: 30000,
    };

    private autoScrollId = 0;
    private autoScrollX = 1;
    private autoScrollStuck = 0;

    mounted(): void {
        serverService.client.listFeaturedClients(6, true).then((clients) => {
            this.gameData = clients;
            this.loading = false;
            if (isMobile()) {
                this.startAutoScroll();
            }
            this.reloadOnlinePpls = this.reloadOnlinePpls.bind(this);
            this.reloadOnlinePpls();
        });
    }
    beforeDestroy(): void {
        this.isMounted = false;
    }

    getClientRate(client: IClient): number {
        return (client.rate && client.rate.avg) || 0;
    }
    getClientVotes(client: IClient): number {
        return (client.rate && client.rate.votes) || 0;
    }

    reloadOnlinePpls(): void {
        if (this.isMounted) {
            const loader = this.onlinePplsLoader;
            this.loadOnlinePpl(this.gameData);
            setTimeout(this.reloadOnlinePpls, loader.interval);
            loader.interval += 5000;
        }
    }
    loadOnlinePpl(clients: IClient[]): Promise<void> {
        const loader = this.onlinePplsLoader;
        if (clients.length && Date.now() > loader.nexttime) {
            let loadId = ++loader.id;
            loader.nexttime = Date.now() + 30000;
            return serverService.client.listMsgPlayersCount(clients).then((data) => {
                for (let client of clients) {
                    this.onlinePpls[client.code] = data[client.code] || 0;
                }
                if (loadId == loader.id) {
                    this.$forceUpdate();
                }
            });
        } else {
            return Promise.resolve();
        }
    }
    getOnlinePpl(clientCode: string): number {
        return this.onlinePpls[clientCode] || 0;
    }

    changeGame(newIdx: number): void {
        if (this.idx == newIdx) {
            // navigate to game forum if it is already showing the game
            let game = this.gameData[newIdx];
            if (game) {
                this.$router.push(getClientForumRoute(game));
            }
        } else {
            this.idx = newIdx;
            this.contextAnimation = 'main-suggest-context0';
            setTimeout(() => {
                this.contextAnimation = 'main-suggest-context';
            }, 0);
            DomUtil.moveToElement($('.suggest-title')[0]);
        }
    }

    getGameBoxBgImg(item: IClient): string {
        return `url("${item.cover}")`;
    }

    hideGameDropdowns(): void {
        $('.bottom-box .btn-more-info').dropdown('hide');
    }

    startAutoScroll(): void {
        this.stopAutoScroll();
        this.autoScrollId = setTimeout(this.autoScroll, 2000, this);
    }

    private autoScroll = (thisArg: GameSuggest) => {
        thisArg.autoScrollId = setTimeout(thisArg.autoScroll, 30, thisArg);
        let node = $('.games-list');
        let currentScroll = node.scrollLeft();
        node.scrollLeft(node.scrollLeft() + this.autoScrollX);
        if (currentScroll == node.scrollLeft()) {
            this.autoScrollStuck++;
            if (this.autoScrollStuck > 30) {
                this.autoScrollStuck = 0;
                this.autoScrollX *= -1;
            }
        }
    };

    stopAutoScroll(): void {
        if (this.autoScrollId) {
            clearTimeout(this.autoScrollId);
            this.autoScrollId = 0;
        }
    }
}
