
import { Component, Vue } from 'vue-property-decorator';
import DialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js';
import { AlertDialogController, AlertDialogOptions, IDialog, IDialogMessage } from '@/components/dialogs/DialogProps';

@Component({
    name: 'alertDialog',
    mixins: [DialogMixin],
})
export default class AlertDialog extends Vue implements AlertDialogController {
    private loading?: {
        message?: string;
    } = null!;

    get dialog(): IDialog {
        return this as any;
    }

    get ops(): AlertDialogOptions {
        return this.dialog.options as AlertDialogOptions;
    }

    private attemptClose = false;

    private backdropClose: true | undefined;

    mounted(): void {
        if (this.ops.getController) {
            this.ops.getController(this);
        }
        this.backdropClose = this.ops.backdropClose;
    }

    showLoading(message?: string): void {
        this.loading = {
            message: message,
        };
        this.attemptClose = false;
        this.dialog.options.backdropClose = undefined;
    }
    hideLoading(): void {
        this.loading = undefined;
        this.attemptClose = false;
        this.dialog.options.backdropClose = this.backdropClose;
    }

    updateContent(message?: IDialogMessage): void {
        this.ops.message = message;
        this.attemptClose = false;
    }

    dismiss(): void {
        this.dialog.cancel();
    }

    private onBtnNo(): void {
        this.dialog.cancel();
    }

    private onBtnOk(): void {
        if (this.ops.ok) {
            this.attemptClose = true;
            this.ops.ok(this);
            if (this.attemptClose) {
                this.dialog.proceed(null);
            }
        } else {
            this.dialog.proceed(null);
        }
    }
}
