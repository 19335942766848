
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { timestampToTimeAgo, timestampToDateTime } from '@/filters/timestampToString';
import { translate } from '@/filters/translate';
import { DISCUSS_STATE, DISCUSS_TOP_MOVEMENT } from '@/services/discussService';
import { serverService } from '@/services/serverService';
import { contentLinksToBlankTarget, errorToString } from '@/filters/content-filters';
import { dialogService } from '@/services/dialogService';
import CssPreloader from '@/components/CssPreloader.vue';
import { AlertDialogController } from '@/components/dialogs/DialogProps';
import { IArticle, IArticleAndReplies } from '@/vo/Discuss';
import { IUser } from '@/vo/User';

@Component({
    methods: {
        timestampToTimeAgo,
        timestampToDateTime,
        translate,
    },
    components: { CssPreloader },
})
export default class BoardMessage extends Vue {
    @Prop()
    article!: IArticle;

    @Prop()
    user!: IUser;

    loading = false;

    @Emit('stateChange')
    onStateChange(article: IArticle): IArticleAndReplies {
        return { article: article, replies: [] };
    }

    @Emit('read')
    onRead(article: IArticle): IArticleAndReplies {
        return { article: article, replies: [] };
    }

    public $refs!: Vue['$refs'] & {
        hashTag: HTMLAnchorElement;
    };

    get closed(): boolean {
        return this.article.state == DISCUSS_STATE.CLOSE.code;
    }
    get removed(): boolean {
        return this.article.state == DISCUSS_STATE.REMOVED.code;
    }

    get content(): string {
        if (this.removed || this.isPrivateToMe()) {
            return '';
        }
        return contentLinksToBlankTarget(this.article.content);
    }

    isPrivateToMe(): boolean {
        if (this.closed) {
            if (serverService.isMe(this.article.author) || serverService.isMe(this.user)) {
                return false;
            }
            return true;
        }
        return false;
    }

    readAlert(): void {
        this.loading = true;
        serverService.discuss
            .setArticleTop(this.article, DISCUSS_TOP_MOVEMENT.REMOVE)
            .then((article) => {
                this.onRead(article);
            })
            .catch((err) => {
                dialogService.error({ body: errorToString(err) });
            })
            .finally(() => {
                this.loading = false;
            });
    }

    onBtnRemove(): void {
        dialogService.alert(
            {
                titleIcon: 'fas fa-trash-alt',
                title: translate('forum.userAction.' + DISCUSS_STATE.REMOVED.code),
            },
            {
                okText: translate('btn.confirm'),
                cancelText: translate('btn.cancel'),
                ok: (controller: AlertDialogController) => {
                    let promise: Promise<any> = serverService.discuss.removeArticle(this.article);

                    if (promise) {
                        controller.showLoading();
                        promise
                            .then((article) => {
                                this.onStateChange(article);
                            })
                            .catch((err) => {
                                dialogService.error({
                                    body: errorToString(err),
                                });
                            })
                            .finally(() => controller.dismiss());
                    }
                },
            }
        );
    }
}
