import { Constant } from '../datas/Constant';
import { GMAction } from '../datas/GMAction';
import { Currency, PaySite, TradeResult } from '../datas/PackageContentCode';
import { IArticle, IArticleShort } from '@/vo/Discuss';
import { IGMRecord } from '@/vo/GMRecord';
import { ITradeRecord, ITradeSummary, ITransaction, IUserWallet } from '@/vo/PayProduct';
import { IClientLockIp, IUser, IUserIpRecord } from '@/vo/User';
import { AbstractSubService } from './abstractSubService';

export class GMService extends AbstractSubService {
    private get clientCode(): string {
        return Constant.SITE_CLIENT.code;
    }

    private callGMService(method: string, args: any[]): Promise<any[]> {
        return this.api('/service/' + method, {
            args: args,
        }).then((json) => json.result);
    }

    public listGmRecords(
        clientCodes: string[],
        user: IUser | null,
        gm: IUser | null,
        actions: GMAction[],
        time: Date,
        backward: boolean,
        start: number,
        length: number
    ): Promise<IGMRecord[]> {
        if (!actions || !actions.length) {
            actions = GMAction.listAll();
        }
        return this.api('/list/gmrecords', {
            clients: clientCodes,
            user: user ? user.username : '',
            gm: gm ? gm.username : '',
            actions: actions.map((action) => action.code),
            timestamp: Math.round(time.getTime() / 1000),
            backward: backward ? 1 : 0,
            start: start,
            length: length,
        }).then((result) => result.list);
    }

    public listGameMasters(): Promise<IUser[]> {
        return this.callGMService('listGameMasters', [this.clientCode]).then((result) => result[0]);
    }

    public listUsersWithSameIPByUser(user: IUser, start: number, length: number): Promise<IUser[]> {
        return this.api('/list/users_with_same_ip/user/' + user.username, {
            start: start,
            length: length,
        }).then((json) => json.list);
    }

    public listUsersWithSameIP(ipV4c: string, ipV6c: string, start: number, length: number): Promise<IUser[]> {
        return this.api('/list/users_with_same_ip/ips', {
            ipV4c: ipV4c,
            ipV6c: ipV6c,
            start: start,
            length: length,
        }).then((json) => json.list);
    }

    public listUserIPRecords(user: IUser, start: number, length: number): Promise<IUserIpRecord[]> {
        return this.api('/list/users_ip_records/' + user.username, {
            start: start,
            length: length,
        }).then((json) => json.list);
    }
    // lock ips
    public addLockIP(user: IUser, record: IUserIpRecord, gmNote: string): Promise<IClientLockIp> {
        return this.api('/add/lock_ip/' + user.username, {
            ipV4c: record.v4c,
            ipV6c: record.v6c,
            note: gmNote || '',
        }).then((json) => {
            record.locked = true;
            return json.result;
        });
    }
    public removeLockIP(user: IUser, ipV4c?: string, ipV6c?: string, gmNote?: string): Promise<void> {
        return this.api('/remove/locked_ip', {
            username: user.username,
            ipV4c: ipV4c || '',
            ipV6c: ipV6c || '',
            note: gmNote || '',
        });
    }
    public listLockedIPsByIPs(records: IUserIpRecord[]): Promise<IClientLockIp[]> {
        const ipV4cs: string[] = [];
        const ipV6cs: string[] = [];
        records.forEach((record) => {
            record.v4c && ipV4cs.push(record.v4c);
            record.v6c && ipV6cs.push(record.v6c);
        });
        return this.api('/list/locked_ips/ips', {
            ipV4cs: ipV4cs,
            ipV6cs: ipV6cs,
        }).then((json) => json.list);
    }

    public addAdminAlert(user: IUser, message: string): Promise<IArticle> {
        return this.api('/add/admin_message/user/' + user.username, {
            message: message,
        }).then((json) => json.article);
    }

    public addGltPoint(
        user: IUser,
        points: number,
        note: string
    ): Promise<{
        record: ITransaction;
        gltPoints: number;
    }> {
        return this.api('/add/glt_point/user/' + user.username, {
            points: points,
            note: note,
        }).then((json) => json);
    }

    public getGltPointsByUser(user: IUser): Promise<IUserWallet> {
        return this.api('/get/glt_points/user/' + user.username, {}).then((json) => json);
    }

    public listGltPointTransactions(user: IUser, time: Date | null, start: number, length: number): Promise<ITransaction[]> {
        return this.api('/list/glt_point_transactions/user/' + user.username, {
            time: time ? Math.round(time.getTime() / 1000) : 0,
            start: start,
            length: length,
        }).then((json) => json.list);
    }
    // discuss ------------------------------------
    public removeArticlesByUserAction(
        user: IUser,
        time: Date,
        start: number,
        length: number,
        note: string,
        dry: boolean
    ): Promise<{
        removed: number;
        ids: number[];
        list: IArticleShort[];
    }> {
        return this.api('/remove/articles/user/' + user.username, {
            startTime: time ? Math.round(time.getTime() / 1000) : 0,
            start: start,
            length: length,
            note: note || '',
            dry: dry ? 1 : 0,
        });
    }

    // super admin only -------------------------
    public listGltTransactions(time: Date | null, start: number, length: number): Promise<ITransaction[]> {
        return this.api('/list/glt_transactions', {
            time: time ? Math.round(time.getTime() / 1000) : 0,
            start: start,
            length: length,
        }).then((json) => json.list);
    }
    public listTrades(
        site: PaySite,
        currency: Currency,
        tradeResult: TradeResult,
        startTime: Date | null,
        days?: number,
        start?: number,
        length?: number
    ): Promise<{
        list: ITradeRecord[];
        summary?: ITradeSummary;
    }> {
        return this.api('/list/trades', {
            site: site.code,
            currency: currency.code,
            tradeResult: tradeResult.code,
            time: startTime ? Math.round(startTime.getTime() / 1000) : 0,
            days: days || 0,
            start: start || 0,
            length: length || 30,
        }).then((json) => json);
    }
    // ------------------------------------------
}
