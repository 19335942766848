import { importTranslation } from '../translate';

importTranslation('zh-Hant', {
    label: {
        friendship: '友誼狀態',
        sns: '社群',
        location: '棲息地',
        languages: '使用語言',
        'create-team': '創建你的盟會',
        'create-team-notopen': '尚未開放創建盟會',
        myTeam: '我的盟會',
        memberOfTeam: '<b>{team}</b> 的盟會成員',
        'my-teammate': '我的盟友',
        newUrl: '新網址',
        titleItem: '頭銜',
        user: {
            intro: '自我介紹',
            my_home: '我的網站',
            'edit-about': '編輯關於我',
        },
        team: {
            intro: '盟會介紹',
            my_home: '盟會網站',
            'edit-about': '編輯關於盟會',
            leader: '盟主',
            member: '成員',
            memberTitle: '稱號',
            time: '時間',
            operator: '執行者',
            oper: '執行者',
            user: '對象',
            action: '動作',
            search: '搜盟會',
            atPosting: '留言時的狀態:',
            settingsTitle: '盟權說明',
            view: {
                settings: '查看盟權',
                lastPlay: '最後遊玩遊戲',
            },
            setting: {
                invite: '召募人',
                remove: '監察官',
                editor: '編輯師',
                manage: '管理員',
                invest: '盟金主',
            },
            settingTip: {
                invite: '負責召募新人',
                remove: '負責更新盟員稱號與權限',
                editor: '負責編修盟會名稱、介紹與社交網站',
                manage: '負責管理盟會留言板',
                invest: '可以投資盟會擴大可容納人數',
            },
        },
    },
    btn: {
        'accept-friend': '接受',
        'reject-friend': '拒絕',
        'remove-friend': '解除好友',
        cancel: '算了',
        'cancel-friend-request': '收回交友邀請',
        'be-friend': '想和 {target} 交個朋友嗎?',
        'be-friend2': '改變主意，成為好友',
        'send-friend-request': '送出邀請',
        black: '拉黑',
        unblack: '洗白',
        more: '更多',
        edit: '編輯',
        save: '儲存',
        discard: '取消',
        copyUsername: '複製帳號ID',
        viewWebmaster: '網站管理員',
        edit_nickname: '更新你的暱稱',
        edit_mood: '更新你的心情',
        edit_icon: '更換你的照片',
        'create-team': '創建盟會',
        'team-invite': '邀請 {target} 進入你的盟會?',
        'send-team-invite': '送出邀請',
        'cancel-team-invite': '收回邀請',
        'cancel-team-apply': '收回申請',
        'leave-team': '離開盟會',
        edit_team_nickname: '更新盟會名稱',
        edit_team_icon: '更新盟會旗幟（標準尺寸 200x120）',
        'apply-team': '申請加入 {target}',
        'send-team-apply': '申請入盟',
        'team-invite-accept2': '改變主意，加入盟會',
        'teamship-edit': '編輯盟權',
        'team-kickout': '成員退盟',
        'retake-myteam': '回舊盟會重新掌權',
        'retake-myteam-short': '重掌盟會',
    },
    tab: {
        user_board: '留言板',
        user_posts: '文章追蹤',
        user_badges: '勳章',
        user_friends: '好友',
        user_about: '關於我',
        team_about: '關於本盟',
        team_board: '留言板',
        team_members: '成員',
        team_records: '記錄',
    },
    badge: {
        getDate: '於 {date} 取得',
        firstGetDate: '{date} 第一次取得',
        lastGetDate: '{date} 最近一次取得',
        getCount: '共獲頒 {count} 次',
    },
    placeholder: {
        location: '你主要出沒的地方...',
        languages: '可以跟你交流的語言...',
        nickname: '你的暱稱',
        mood: '你的心情',
    },
    error: {
        'invalid-sns-url': '這不是個有效的{name}網址',
        'found-no-user': '找不到這位玩家的資料',
    },
    noRecord: '找不到記錄',
    usernameCopied: '已複製到剪貼簿',
    teamnameCopied: '已複製到剪貼簿',
    'i-have-no-mood': '我心如止水...',
    'i-have-no-friends': '我暫時還沒交到任何朋友。',
    'he-has-no-friends': '這傢伙沒朋友。',
    'i-have-no-invites': '你的信箱裏沒有交友邀請。',
    'he-has-no-invites': '{user}的信箱裏沒有交友邀請。',
    'i-have-no-blacklist': '我的黑名單是空的。',
    'he-has-no-blacklist': '黑名單是空的。',
    'my-friends-total': '我在嘎姆有 {total} 位好友',
    'his-friends-total': '{user} 在嘎姆有 {total} 位好友',
    'my-invites-total': '{total} 則交友邀請',
    'his-invites-total': '{total} 則交友邀請',
    'my-blacklist-total': '我的黑名單有{total}個人',
    'i-have-no-badge': '什麼! 我一枚勳章都沒有?',
    'he-has-no-badge': '這裏找不到什麼勳章',
    'my-intro-empty': '我還沒想到要寫什麼...',
    'his-intro-empty': '{user}沒什麼要說的...',
    'user-blocked-byme': '{user} 已被你拉黑',
    'me-blocked-byuser': '你已被列在{user}的黑名單',
    'reject-friend-byme': '你已拒絕了{user}的好友邀請',
    'reject-friend-byuser': '{user}已拒絕了你的好友邀請',
    team: {
        'i-have-no-mood': '我心如止水...',
        'i-have-no-friends': '這個盟會只是一個空殼。',
        'he-has-no-friends': '這個盟會只是一個空殼。',
        'i-have-no-invites': '盟會信箱裏沒有人申請入盟。',
        'he-has-no-invites': '盟會信箱裏沒有人申請入盟。',
        'i-have-no-blacklist': '黑名單是空的。',
        'he-has-no-blacklist': '黑名單是空的。',
        'my-friends-total': '盟會內有 {total} 位成員',
        'his-friends-total': '盟會內有 {total} 位成員',
        'my-invites-total': '{total} 位玩家申請入盟',
        'his-invites-total': '{total} 位玩家申請入盟',
        'my-blacklist-total': '黑名單有{total}個人',
        'i-have-no-badge': '什麼! 一枚勳章都沒有?',
        'he-has-no-badge': '這裏找不到什麼勳章',
        'my-intro-empty': '還沒想到要寫什麼...',
        'his-intro-empty': '{user}沒什麼要說的...',
        'user-blocked-byme': '{user} 已被你拉黑',
        'me-blocked-byuser': '你已被列在{user}的黑名單',
        'reject-friend-byme': '你已拒絕了{user}的入盟邀請',
        'reject-friend-byuser': '你的盟會已挽拒了{user}的入盟申請',
        'apply-join-at': '{date} 申請入盟',
        membersAndSize: '目前 {members} 名成員（最多可容納 {size} 人）',
        action: {
            createTeam: '建立盟會',
            retake: '重掌盟會',
            acceptInvite: '接受入盟邀請',
            apply: '申請入盟',
            acceptApply: '接受入盟申請',
            invite: '邀請入盟',
            rejectInvite: '駁回入盟申請',
            rejectApply: '挽拒入盟邀請',
            leaveTeam: '離開盟會',
            cancelApply: '取消入盟申請',
            removeMember: '退盟成員',
            cancelInvite: '取消入盟邀請',
            saveTeam: '編輯盟會介紹',
            setTeamSize: '提高盟會容納人數',
            updateTeamship: '編輯盟權',
            setBoardAuthType: '留言板設定',
            removeArticle: '移除文章',
            setArticleStatus: '文章可見度',
            setArticleState: '文章狀態',
            setArticleLocked: '鎖文章',
            setArticleOrder: '文章順序',
        },
        actionSet: {
            all: '全部記錄',
            team: '盟會屬性',
            invite: '盟員召募',
            remove: '盟員管理',
            board: '留言板',
        },
    },
    'friend-request-expire-alert': '交友邀請只會保存{days}天。',
    'friend-request-received': '{user}邀請您成為他的好友',
    'friend-request-accepted': '你們已經成為好友了 (從{date}開始)',
    'friend-request-sent': '你的交友邀請已於 {date} 送給了 {target}',
    'confirm-send-friend-request': '確定要送給 {target} 好友邀請嗎?',
    'confirm-remove-friend': '您確定要和{target}解除好友關係嗎?',
    'game.badges.total': '共 {total} 枚',
    'not-in-team': '您尚未加入任何盟會',
    'he-not-in-myteam': '這位朋友不在你的盟會裡。',
    'request-is-canceled': '申請已被取消。',
    'not-in-myteam': '不在盟內',
    'input-teamname': '請輸入一個盟會代碼',
    'teamname-rules': '僅能使用 英數字 / 減號 / 底線',
    'confirm-send-team-invite-request': '確定要邀請 {target} 進入你的盟會嗎?',
    'team-invite-sent': '入盟邀請已於 {date} 送給了 {target}',
    'team-apply-sent': '入盟申請已於 {date} 送給了 {target}',
    'team-invite-received': '這個盟會想邀你加入他們',
    'team-invite-accepted': '{date} 入盟',
    'apply-team-received': '{user}想要加入你的盟會',
    'reject-teamship-byme': '你已拒絕了這個盟會的入盟邀請',
    'confirm-leave-team': '您確定要離開你的盟會嗎?',
    'confirm-team-kickout': '您確定要讓這位成員退出盟會嗎?',
    'confirm-send-team-apply-request': '你想要申請加入盟會《{target}》嗎?',
    'confirm-send-team-retake-request': '你想要重回舊盟會《{target}》，再次成為盟主嗎?',
    'team-testing-warning': '盟會系統仍在開發中，若發現任何BUG，請回報小哈！',
    lastPlay: '{time} 在<br/>{game}</br>有看到這位玩家',
    lastPlayUnknown: '不確定他最後玩的是什麼',
    'guest-intro': '<p>這是個訪客帳號。</p><p>以訪客身分玩遊戲將無法儲存遊戲進度也不會獲得勳章與分數。</p>',
    'create-team-free-during-test': '測試期間首次創立盟會不需要資金。',
    'create-team-already-have-item': '你先前已購買了創建盟會的道具，因此現在可以直接使用並創建你的盟會。',
    'create-team-need-fund': '創建盟會需要資金：',
    'your-fund': '您的錢包裏目前有：',
    'enlarge-team-already-have-item': '你先前已購買了提升盟會容納人數的道具，因此現在可以直接使用並盟會容納人數至 {size} 人。',
    'enlarge-team-need-fund': '提升盟會容納人數至 {size} 人，需要資金：',
    'enlarge-team': '提高盟會可容納的人數',
});
export const appUser_translation_zh = 1;
