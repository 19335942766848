var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"root"},[_c('ul',{class:['nav', 'nav-pills', _vm.locale],attrs:{"role":"tablist"}},_vm._l((_vm.navItems),function(item,index){return _c('li',{key:item.code,staticClass:"nav-item",attrs:{"role":"presentation"}},[_c('router-link',{class:{ 'nav-link': true, active: index == _vm.tabIndex },attrs:{"role":"tab","aria-controls":`pills-${item.code}`,"to":item.route}},[_c('div',{staticClass:"nav-active"}),_c('div',{staticClass:"nav-label"},[_vm._v(" "+_vm._s(_vm.translate(`aboutUs.${item.code}.tab`))+" ")])])],1)}),0),_c('div',{staticClass:"tab-content"},_vm._l((_vm.navItems),function(item,index){return _c('div',{key:item.code,class:{
                'tab-pane': true,
                fade: true,
                show: index == _vm.tabIndex,
                active: index == _vm.tabIndex,
            },attrs:{"role":"tabpanel","aria-labelledby":`pills-${item.code}-tab`}},[_c('div',{class:['mainbox', item.code]},[_c('AreaTitle',{attrs:{"text":_vm.translate('aboutUs.title.aboutUs')}}),_c('div',{staticClass:"info-box"},[_c('div',{staticClass:"block-gap"}),_c('div',{staticClass:"team-logo"},[_c('img',{attrs:{"src":item.icon}})]),_c('div',{staticClass:"content",domProps:{"innerHTML":_vm._s(
                            _vm.translate(`aboutUs.${item.code}.content`, {
                                wrap: 'p',
                            })
                        )}})]),_c('div',{staticClass:"index"}),_c('div',{staticClass:"members-box"},[_c('div',{staticClass:"team-header"},[_c('div',{staticClass:"team-title"},[_vm._v(" "+_vm._s(_vm.translate(`aboutUs.title.teamMember`))+" ")]),_c('div',{staticClass:"spacer"}),(_vm.isMember)?_c('router-link',{staticClass:"team-board btn btn-info",attrs:{"to":item.boardRoute}},[_vm._v(_vm._s(_vm.translate(`aboutUs.title.teamBoard`)))]):_vm._e(),(_vm.tabIndex == 0)?[_c('button',{staticClass:"btn btn-info",attrs:{"data-bs-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"},on:{"click":function($event){return _vm.toggleContactUs()}}},[_vm._v(" "+_vm._s(_vm.translate(`aboutUs.title.contact`))+" ")]),_c('div',{staticClass:"dropdown-menu dropdown-menu-right",attrs:{"aria-labelledby":"menuTrigger"}},[(_vm.showContactUs)?_c('table',[_c('tr',[_c('th',[_vm._v(" "+_vm._s(_vm.translate('aboutUs.title.email'))+" ")]),_vm._m(0,true)]),_c('tr',[_c('th',[_vm._v(" "+_vm._s(_vm.translate('aboutUs.title.adminFacebook'))+" ")]),_vm._m(1,true)]),_c('tr',[_c('th',[_vm._v(" "+_vm._s(_vm.translate('aboutUs.title.teamFacebook'))+" ")]),_vm._m(2,true)])]):_vm._e()])]:_vm._e()],2),(item.members.length)?_c('div',{staticClass:"members"},_vm._l((item.members),function(member){return _c('div',{key:member.username,staticClass:"member"},[_c('router-link',{attrs:{"to":_vm.getUserRoute(member),"title":_vm.getUserTooltip(member)}},[_c('div',{staticClass:"user-img"},[_c('img',{attrs:{"src":member.iconUrl}})]),_c('div',{staticClass:"user-name"},[_vm._v(" "+_vm._s(member.nickname)+" ")])])],1)}),0):_c('div',{staticClass:"spinner-border text-info loading",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])])],1)])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('a',{attrs:{"target":"_blank","href":"mailto:haskasu@gmail.com"}},[_vm._v("haskasu@gmail.com")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('a',{attrs:{"target":"_blank","href":"https://www.facebook.com/haska"}},[_vm._v("https://www.facebook.com/haska")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('a',{attrs:{"target":"_blank","href":"https://www.facebook.com/twilightwars.tw"}},[_vm._v("https://www.facebook.com/twilightwars.tw")])])
}]

export { render, staticRenderFns }