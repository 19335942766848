
import { Component, Vue } from 'vue-property-decorator';
import DialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js';
import {
    ChoosePayByDialogController,
    ChoosePayByDialogOptions,
    IDialog,
    IDialogMessage,
} from '@/components/dialogs/DialogProps';
import { PayBy } from '../../datas/PackageContentCode';
import { serverService } from '../../services/serverService';
import { translate } from '@/filters/translate';
import { IPaymentPackage } from '@/vo/PayProduct';
import { dialogService } from '@/services/dialogService';

@Component({
    name: 'alertDialog',
    mixins: [DialogMixin],
    methods: {
        translate,
    },
})
export default class ChoosePayByDialog extends Vue implements ChoosePayByDialogController {
    get dialog(): IDialog {
        return this as any;
    }

    get ops(): ChoosePayByDialogOptions {
        return this.dialog.options as ChoosePayByDialogOptions;
    }

    get payByList(): PayBy[] {
        return this.ops.payByList!;
    }
    get pkg(): IPaymentPackage {
        return this.ops.pkg!;
    }

    private attemptClose = false;

    choice: PayBy | null = null;

    mounted(): void {
        if (this.ops.getController) {
            this.ops.getController(this);
        }
    }

    getPayBy(): PayBy | null {
        return this.choice;
    }
    getPayUrl(payBy: PayBy): string {
        return serverService.generateServerUrl(payBy.getPayUri(this.pkg));
    }

    showLoading(message?: string): void {
        console.log('not implemented: ' + message);
    }
    hideLoading(): void {}

    updateContent(message?: IDialogMessage): void {
        this.ops.message = message;
        this.attemptClose = false;
    }

    dismiss(): void {
        this.dialog.cancel();
    }

    getPayByImage(payBy: PayBy): string {
        return serverService.generateServerUrl(`/img/store/${payBy.image}`);
    }

    private onBtnPayOnCG(payBy: PayBy): void {
        dialogService.alert(
            {
                title: translate('pay.paymentOnlyOnCG.title'),
                titleIcon: 'fas fa-info-circle',
                body: translate('pay.paymentOnlyOnCG.message', { company: translate(`pay.paymentCompany.${payBy.code}.name`) }),
            },
            {
                html: true,
                okText: translate('btn.gotit'),
                cancelText: '',
                customClass: 'medium-dialog',
            }
        );
    }

    private onBtnNo(): void {
        this.dialog.cancel();
    }

    private onBtnOk(choice: PayBy): void {
        this.choice = choice;
        if (this.ops.ok) {
            this.attemptClose = true;
            this.ops.ok(this);
            if (this.attemptClose) {
                this.dialog.proceed(null);
            }
        } else {
            this.dialog.proceed(null);
        }
    }
}
