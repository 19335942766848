import { DomUtil } from '../utils/DomUtil';

declare const $: any;

export function contentLinksToBlankTarget(content: string): string {
    return (
        content &&
        content
            .replace(/<a(\s[^>]*?href="[^#][^>]*?)>/g, '<a target="_blank"$1>')
            .replace(/<a(\s[^>]*?href="#([0-9]+)"[^>]*?)>/g, '<a onclick="return _anchorScroll($2)"$1>')
    );
}

export function contentStripHTML(content: string): string {
    return content && content.replace(/<\/?[^>]+>/g, ' ');
}
export function unescapeUnicode(str: string): string {
    return str.replace(/\\u([a-fA-F0-9]{4})/g, function (_g, m1) {
        return String.fromCharCode(parseInt(m1, 16));
    });
}
export function errorToString(error: string | any): string {
    if (error && error.response) {
        return error.response.data;
    }
    return error + '';
}

export function isContentEmpty(content: string): boolean {
    content = content.replace(/<img\s[^>]+>/g, 'img');
    const div = document.createElement('div');
    div.innerHTML = content;
    let text = div.textContent || div.innerText || '';
    text = text.trim();
    return !text;
}

export function containsBadWords(contents: string[]): boolean {
    return !!contents.find((content) => content.match(/(溝鼠|噬糞|穢鼠|63e6337f0a0f19c0a353eea329eb6842)/));
}

window['_anchorScroll'] = function (id: string): boolean {
    const anchor = $(`a.hashtag[name="${id}"]`);
    if (anchor.length) {
        // smooth scroll is handled in router.vue scrollBehavior
        DomUtil.moveToElement(anchor[0]);
        return false;
    }
    return true;
};

export function getYoutubeID(youtubeUrl: string): string {
    let search = youtubeUrl.match(/https:\/\/youtu.be\/(\w+)/);
    if (search) {
        return search[1];
    }
    search = youtubeUrl.match(/https:\/\/www.youtube.com\/.*(\/v\/|\?v=|\/embed\/|\/live\/)(\w+)/);
    if (search) {
        return search[2];
    }
    return '';
}
export function getYoutubeThumb(youtubeUrl: string, size = 'mq3'): string {
    const id = getYoutubeID(youtubeUrl);
    return id && `https://i.ytimg.com/vi_webp/${id}/${size}.webp`;
}
