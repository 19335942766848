
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { translate } from '@/filters/translate';
import CssPreloader from '@/components/CssPreloader.vue';
import AreaTitle from '@/components/AreaTitle.vue';
import { IClient } from '@/vo/Client';
import { IGameBadgeWithUser } from '@/vo/Badge';
import { serverService } from '@/services/serverService';
import { ArrayUtil } from '@/utils/ArrayUtil';
import { IUser } from '@/vo/User';
import { getBadgePageRoute } from '@/filters/game-filters';

@Component({
    components: {
        CssPreloader,
        AreaTitle,
    },
    methods: {
        translate,
        getBadgePageRoute,
    },
})
export default class GameBadges extends Vue {
    @Prop()
    client!: IClient;

    badgeList: IGameBadgeWithUser[] = [];
    ownBadges: IGameBadgeWithUser[] = [];
    missBadges: IGameBadgeWithUser[] = [];
    missCount = 0;

    @Watch('client', { immediate: true })
    onClientChange(): void {
        serverService
            .getInitData()
            .then((initData) => listGameBadges(this.client, initData.me))
            .then((list) => {
                this.badgeList = list;
                this.ownBadges = list.filter((badge) => badge.count);
                this.missBadges = list.filter((badge) => {
                    if (!badge.count) {
                        badge.lastTime = badge.status == 'pub' ? 1 : 0;
                        return true;
                    }
                    return false;
                });
                ArrayUtil.sortNumericOn(this.ownBadges, 'lastTime', true);
                ArrayUtil.sortNumericOn(this.missBadges, 'lastTime', false);
                this.missCount = this.missBadges.length;
                if (this.missCount > 10) {
                    this.missBadges.length = 10;
                }
            });
    }

    getBadgeImageTitle(badge: IGameBadgeWithUser): string {
        if (badge.count) {
            let title = badge.name;
            if (badge.repeatable) {
                title += ' x ' + badge.count;
            }
            title += '\n' + new Date(badge.lastTime! * 1000).toLocaleDateString();
            title += '\n' + badge.desc;
            return title;
        } else if (badge.status == 'pub') {
            return badge.name + '\n' + badge.desc;
        } else {
            return '???';
        }
    }
}

const cachedGameBadges: {
    [key: string]: {
        badges?: IGameBadgeWithUser[];
        time: number;
        promises: {
            resolve: (items: IGameBadgeWithUser[]) => void;
            reject: (error: any) => void;
        }[];
    };
} = {};

function listGameBadges(client: IClient, user: IUser): Promise<IGameBadgeWithUser[]> {
    let key = `${client.code}/${user ? user.username : 'guest'}`;
    let cache = cachedGameBadges[key];
    if (cache && cache.time + 300000 > Date.now()) {
        if (cache.badges) {
            return Promise.resolve(cache.badges);
        } else {
            return new Promise<IGameBadgeWithUser[]>((resolve, reject) => {
                cache.promises.push({ resolve: resolve, reject: reject });
            });
        }
    }
    cache = {
        time: Date.now(),
        promises: [],
    };
    cachedGameBadges[key] = cache;
    return serverService.client
        .listGameBadges(client, user)
        .then((value) => {
            cache.badges = value;
            cache.promises.forEach((promise) => promise.resolve(value));
            cache.promises.length = 0;
            return value;
        })
        .catch((err) => {
            cache.promises.forEach((promise) => promise.reject(err));
            cache.promises.length = 0;
            throw err;
        });
}
