
import { Component, Vue, Watch } from 'vue-property-decorator';
import { DISCUSS_CATEGORY, DISCUSS_STATE } from '@/services/discussService';
import PostListItem from '@/forum/components/PostListItem.vue';
import CssPreloader from '@/components/CssPreloader.vue';
import { translate } from '@/filters/translate';
import { ForumCat } from '@/datas/ForumCat';
import { isMobile } from '@/filters/mobile';
import AreaTitle from '@/components/AreaTitle.vue';
import { serverService } from '../../services/serverService';
import { setClientUpRoute } from '../../utils/RouteResolver';
import { Route } from 'vue-router';
import { IArticle } from '@/vo/Discuss';

interface NavItem {
    articles: IArticle[];
    loading: boolean;
    expire: number;
}

@Component({
    methods: {
        translate,
        isMobile,
    },
    components: {
        PostListItem,
        CssPreloader,
        AreaTitle,
    },
})
export default class ForumDashboard extends Vue {
    length = 10;
    navItems = ForumCat.listPublic();
    postsMap: { [key: string]: NavItem } = {};
    updateKey = 0;
    navIndex = -1;
    isAdmin = serverService.isSuperAdmin() || serverService.isLocalTesting;

    mounted(): void {
        document.title = translate('localedTitle');
    }

    @Watch('$route', { immediate: true })
    onRouteChanged(to: Route): void {
        let forumCat = ForumCat.getByCode(to.query.forum as string);
        if (!forumCat || !this.navItems.includes(forumCat)) {
            forumCat = ForumCat.GOSSIP;
        }
        this.setNavIndex(this.navItems.indexOf(forumCat));
    }

    setNavIndex(index: number): void {
        this.navIndex = index;
        this.loadPosts(this.navItems[index]);
    }

    get category(): DISCUSS_CATEGORY {
        return DISCUSS_CATEGORY.FORUM;
    }

    private loadPosts(item: ForumCat): void {
        const now = Date.now();
        const navItem = this.postsMap[item.code];
        if (!navItem || now > navItem.expire) {
            let navItem: NavItem = (this.postsMap[item.code] = {
                articles: [],
                loading: true,
                expire: now + 60000,
            });
            item.listRootArticles([DISCUSS_STATE.OPEN], 0, this.length)
                .then((data) => {
                    navItem.articles = data;
                    navItem.loading = false;
                    this.updateKey++;
                })
                .catch((err) => {
                    console.error(err);
                });
        }
        setClientUpRoute(item.route);
    }
}
