var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
        root: true,
        isRoot: _vm.isRoot,
        closed: _vm.closed,
        isNotUserBoard: !_vm.isUserBoard,
        isUserBoard: _vm.isUserBoard,
    }},[_c('div',{staticClass:"header"},[_c('router-link',{attrs:{"title":_vm.getUserTooltip(_vm.msg.author),"to":_vm.getUserRoute(_vm.msg.author)}},[_c('img',{class:{ 'author-icon': true, isRoot: _vm.isRoot },attrs:{"src":_vm.msg.author.iconUrl}})]),(_vm.hasNext)?_c('div',{staticClass:"straight-line"}):_vm._e(),(!_vm.isRoot)?_c('div',{staticClass:"fillet-line"}):_vm._e()],1),_c('div',{staticClass:"content"},[_c('router-link',{staticClass:"content-link",attrs:{"to":_vm.articleRoute}}),_c('div',{staticClass:"title"},[(_vm.isRoot && !_vm.isUserBoard)?_c('span',[(!_vm.closed || _vm.rootIsMe)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.msg.title)}}):_c('span',{staticClass:"closed-title"},[_vm._v(" "+_vm._s(_vm.translate('forum.closed'))+" ")])]):_c('span',{domProps:{"innerHTML":_vm._s(_vm.msg.content)}})]),_c('div',{staticClass:"footer"},[_c('router-link',{staticClass:"author-name",attrs:{"title":_vm.getUserTooltip(_vm.msg.author),"to":_vm.getUserRoute(_vm.msg.author)}},[_vm._v(_vm._s(_vm.msg.author.nickname))]),_c('span',{staticClass:"split-line"}),_c('span',{staticClass:"dates",attrs:{"title":_vm.timestampToDateTime(_vm.msg.createTime * 1000)}},[_vm._v(" "+_vm._s(_vm.timestampToTimeAgo(_vm.msg.createTime * 1000))+" ")]),(_vm.isRoot && _vm.isUserBoard)?_c('span',{staticClass:"state-text"},[_c('span',{staticClass:"split-line"}),_vm._v(" "+_vm._s(_vm.translate(`forum.states.${_vm.msg.state}.option`))+" ")]):_vm._e(),(_vm.isRoot)?_c('span',[_c('span',{staticClass:"split-line"}),_vm._v(" "+_vm._s(_vm.translate(_vm.msg.replies - 1 > 1 ? 'replies' : 'reply', { reply: _vm.msg.replies - 1, }))+" ")]):_vm._e(),(_vm.closed && !_vm.isUserBoard)?_c('span',{staticClass:"closed-text"},[(_vm.rootIsMe || !_vm.isRoot)?_c('span',[_c('span',{staticClass:"split-line"}),_vm._v(" "+_vm._s(_vm.translate('forum.closed'))+" ")]):_vm._e()]):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }