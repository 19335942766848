
import { Component, Prop, Vue } from 'vue-property-decorator';
import AreaTitle from '@/components/AreaTitle.vue';
import { translate } from '@/filters/translate';
import { getUserTooltip } from '@/filters/user-filters';
import { getClientForumRoute } from '@/filters/game-filters';
import { IClient } from '@/vo/Client';
import { serverService } from '@/services/serverService';
import { dialogService } from '@/services/dialogService';
import { errorToString, getYoutubeID } from '@/filters/content-filters';
import { inputDateToDate, timestampToInputDate } from '../filters/timestampToString';
import { IGameItem } from '@/vo/PayProduct';
import { ArrayUtil } from '@/utils/ArrayUtil';
import { ItemStatus } from '@/datas/ItemStatus';
import { IUser } from '@/vo/User';
import { isDarkMode } from '@/filters/mobile';
import { getEditorContentStyles, getEditorLanguageUrl } from '@/filters/editors';
import Editor from '@tinymce/tinymce-vue';
import CssPreloader from '@/components/CssPreloader.vue';
import { Constant } from '../datas/Constant';

@Component({
    methods: {
        translate,
        getUserTooltip,
        getClientForumRoute,
        getEditorContentStyles,
        getEditorLanguageUrl,
    },
    components: {
        AreaTitle,
        Editor,
        CssPreloader,
    },
})
export default class TitleClient extends Vue {
    @Prop()
    client!: IClient;

    gameName = '';

    gameTime = '';

    gameDesc = '';

    gamePpls = 0;

    originGameTime = '';
    originGamePpls = 0;

    newItemName = '';
    newVideoUrl = 'https://www.youtube.com/';

    items: IGameItem[] = [];
    videos: IGameItem[] = [];

    loading = false;

    dark = false;

    language = serverService.tinymceLocale;

    mounted(): void {
        this.dark = isDarkMode();
        const client = this.client;
        this.gameName = client.name!;
        this.gameTime = timestampToInputDate(client.rate ? client.rate.start * 1000 : 0);
        this.gameDesc = client.desc || '';
        this.gamePpls = client.rate ? client.rate.votes : 0;
        this.originGameTime = this.gameTime;
        this.originGamePpls = this.gamePpls;

        this.loading = true;
        Promise.all([
            serverService.client.listSysTitleItems(client, Constant.SYS_ITEM_CATEGORY.USER_TITLE, true, false),
            serverService.client.listSysTitleItems(client, Constant.SYS_ITEM_CATEGORY.VIDEO, false, false),
        ])
            .then(([titleList, videolist]) => {
                this.items = titleList.map((item) => {
                    backupItem(item);
                    return item;
                });
                this.videos = videolist.map((item) => {
                    backupItem(item);
                    return item;
                });
            })
            .finally(() => {
                this.loading = false;
            });
    }
    validateNewVideoUrl(): boolean {
        return !!getYoutubeID(this.newVideoUrl);
    }
    hasItemChange(item: IGameItem): boolean {
        const backup: IGameItem = item['backup'];
        return (
            backup.order != item.order ||
            backup.name != item.name ||
            backup.desc != item.desc ||
            backup.status != item.status ||
            backup.priceInGameDollar != item.priceInGameDollar
        );
    }

    hasChanges(): boolean {
        if (
            this.gameName != this.client.name ||
            this.originGameTime != this.gameTime ||
            this.gameDesc != this.client.desc ||
            this.gamePpls != this.originGamePpls
        ) {
            return true;
        }
        return false;
    }

    btnSave(): void {
        let loading = dialogService.loading();
        serverService.client
            .setSysTitleData(this.client, {
                gameName: this.gameName,
                gameTime: Math.round(inputDateToDate(this.gameTime).getTime() / 1000),
                gameDesc: this.gameDesc,
                gamePpls: Math.min(1000, Math.max(Math.round(this.gamePpls))),
            })
            .then(() => {
                this.$forceUpdate();
            })
            .catch((err) => {
                dialogService.error({ body: errorToString(err) });
            })
            .finally(() => {
                loading.close();
            });
    }

    btnAddItem(): void {
        let loading = dialogService.loading();
        serverService.client
            .addSysTitleItem(this.client, Constant.SYS_ITEM_CATEGORY.USER_TITLE, { name: this.newItemName })
            .then((item) => {
                backupItem(item);
                this.items.push(item);
                sortItems(this.items);
                this.newItemName = '';
                this.$forceUpdate();
            })
            .catch((err) => {
                dialogService.error({ body: errorToString(err) });
            })
            .finally(() => {
                loading.close();
            });
    }
    btnAddVideo(): void {
        let loading = dialogService.loading();
        serverService.client
            .addSysTitleItem(this.client, Constant.SYS_ITEM_CATEGORY.VIDEO, { name: 'Live', desc: this.newVideoUrl })
            .then((item) => {
                backupItem(item);
                this.videos.push(item);
                sortItems(this.videos);
                this.newVideoUrl = 'https://www.youtube.com/';
                this.$forceUpdate();
            })
            .catch((err) => {
                dialogService.error({ body: errorToString(err) });
            })
            .finally(() => {
                loading.close();
            });
    }
    toggleItemStatus(item: IGameItem): void {
        item.status = item.status == ItemStatus.PUB.code ? ItemStatus.HIDDEN.code : ItemStatus.PUB.code;
    }
    toggleItemSponsor(item: IGameItem): void {
        item.priceInGameDollar = item.priceInGameDollar ? 0 : 1;
    }
    btnSaveItem(item: IGameItem, list: IGameItem[]): void {
        item['loading'] = true;
        this.$forceUpdate();
        serverService.client
            .setSysTitleItem(this.client, item)
            .then(() => {
                backupItem(item);
                sortItems(list);
            })
            .catch((err) => {
                dialogService.error({ body: errorToString(err) });
            })
            .finally(() => {
                item['loading'] = false;
                this.$forceUpdate();
            });
    }
    btnAddUser(item: IGameItem): void {
        item['loading'] = true;
        this.$forceUpdate();
        dialogService
            .searchUser(
                {
                    title: translate('game.sysTitle.addUserItem'),
                    titleIcon: 'fas fa-search',
                },
                {
                    okText: translate('btn.confirm'),
                    cancelText: translate('btn.cancel'),
                }
            )
            .then((user) => {
                if (user) {
                    return serverService.client.addSysTitleUserItem(this.client, item, user).then((userItem) => {
                        item.users.push(userItem.user);
                    });
                }
            })
            .catch((err) => {
                dialogService.error({ body: errorToString(err) });
            })
            .finally(() => {
                item['loading'] = false;
                this.$forceUpdate();
            });
    }
    btnRemoveUser(item: IGameItem, user: IUser): void {
        item['loading'] = true;
        this.$forceUpdate();
        serverService.client
            .removeSysTitleUserItem(this.client, item, user)
            .then(() => {
                ArrayUtil.removeElement(item.users, user);
            })
            .catch((err) => {
                dialogService.error({ body: errorToString(err) });
            })
            .finally(() => {
                item['loading'] = false;
                this.$forceUpdate();
            });
    }
}
function backupItem(item: IGameItem): void {
    item['backup'] = {
        name: item.name,
        desc: item.desc,
        order: item.order,
        status: item.status,
        priceInGameDollar: item.priceInGameDollar,
    };
}
function sortItems(items: IGameItem[]): void {
    ArrayUtil.sortNumericOn(items, 'order', true);
}
