
import { Component } from 'vue-property-decorator';
import Editor from '@tinymce/tinymce-vue';
import { serverService } from '@/services/serverService';
import CssPreloader from '@/components/CssPreloader.vue';
import BaseTabComp from './BaseTabComp';
import { translate } from '@/filters/translate';
import { isDarkMode } from '@/filters/mobile';
import { getEditorContentStyles, getEditorLanguageUrl } from '@/filters/editors';
import { contentLinksToBlankTarget } from '@/filters/content-filters';
import { IUserAbout } from '@/vo/User';
import { TeamshipSetting } from '@/datas/FriendshipStatus';
import { ITitleUserItem } from '@/vo/PayProduct';
import UserTitleBox from '@/forum/components/UserTitleBox.vue';

@Component({
    components: {
        CssPreloader,
        Editor,
        UserTitleBox,
    },
    methods: {
        translate,
        contentLinksToBlankTarget,
        getEditorContentStyles,
        getEditorLanguageUrl,
    },
})
export default class About extends BaseTabComp {
    loading = false;

    editing = false;

    about: IUserAbout = null!;

    editAbout: IUserAbout = null!;

    editAboutIntro = '';

    preservedSnsUrl: { [key: string]: string } = {};

    errors: { [key: string]: string } = {};

    dark = isDarkMode();

    language = serverService.tinymceLocale;

    get transCat(): string {
        return this.team ? 'team' : 'user';
    }

    snsList: SNS[] = [
        new SNS('sns_facebook', 'Facebook', /^https?:\/\/(www\.)?facebook\.com\//, false),
        new SNS('sns_instagram', 'Instagram', /^https?:\/\/(www\.)?instagram\.com\//, false),
        new SNS('sns_twitter', 'Twitter', /^https?:\/\/(www\.)?twitter\.com\//, false),
        new SNS('sns_youtube', 'Youtube', /^https?:\/\/(www\.)?(youtube\.com|youtu.be)\//, false),
        new SNS('sns_twitch', 'Twitch', /^https?:\/\/(www\.)?twitch.tv\//, false),
        new SNS('sns_reddit', 'Reddit', /^https?:\/\/(www\.)?reddit\.com\//, false),
        new SNS('sns_pinterest', 'Pinterest', /^https?:\/\/(www\.)?pinterest\.(com|ca)\//, false),
        new SNS('sns_linkedin', 'LinkedIn', /^https?:\/\/(www\.)?linkedin\.com\//, false),
        new SNS('sns_github', 'Github', /^https?:\/\/(www\.)?github\.com\//, true),
        new SNS('sns_website', 'label.my_home', /^https?:\/\//, true),
    ];

    extraKeys: string[] = ['location', 'languages'];

    titleItems: ITitleUserItem[] = [];

    mounted(): void {
        this.dark = isDarkMode();

        if (this.user!.guest) {
            this.about = {
                intro: translate('guest-intro'),
            };
        } else {
            this.loading = true;
            serverService.user.getUserAbout(this.user!).then((about) => {
                this.loading = false;
                this.about = about;
            });
            const userMeta = this.user!.meta;
            if (userMeta && userMeta.utitles) {
                serverService.user.listSysTitleByUser(this.user!).then((list) => {
                    this.titleItems = list;
                });
            }
        }
    }

    get isManager(): boolean {
        if (this.team) {
            return TeamshipSetting.checkAuth(this.teamship, TeamshipSetting.EDITOR);
        } else {
            return this.isMe;
        }
    }

    edit(): void {
        this.editing = true;
        this.editAbout = Object.assign({}, this.about);
        this.editAboutIntro = this.about.intro || '';
        this.errors = {};
        this.snsList.forEach((sns) => {
            sns.visible = this.about[sns.code];
            if (sns.code == 'sns_website') {
                sns.setNameKey(`label.${this.transCat}.my_home`);
            }
        });
    }

    cancelEdit(): void {
        this.editing = false;
    }

    saveEdit(): void {
        if (this.hasError()) {
            return;
        }
        this.loading = true;
        this.editAboutIntro = this.editAboutIntro.trim();
        this.editAbout.intro = this.editAboutIntro;
        serverService.user
            .saveUserAbout(this.team || this.user!, this.editAbout)
            .then((about) => {
                Object.assign(this.about, about);
                this.about = about;
                this.editing = false;
            })
            .catch(() => {
                this.editing = true;
            })
            .then(() => {
                this.loading = false;
            });
    }

    editRemoveSns(sns: SNS): void {
        if (this.editAbout[sns.code]) {
            this.preservedSnsUrl[sns.code] = this.editAbout[sns.code];
            this.editAbout[sns.code] = '';
        }
        this.onSnsUrlChanged(sns);
        sns.visible = false;
    }

    get emptyIntroKey(): string {
        return this.isManager ? 'my-intro-empty' : 'his-intro-empty';
    }

    get filteredSnsList(): SNS[] {
        return this.snsList.filter((sns) => this.about[sns.code]);
    }

    get filteredExtraKeys(): string[] {
        return this.extraKeys.filter((k) => this.about[k]);
    }

    get hasSns(): boolean {
        for (const key in this.about) {
            if (key.startsWith('sns_')) {
                return true;
            }
        }
        return false;
    }

    getSnsIcon(sns: SNS): string {
        return serverService.generateServerUrl(`/img/socicon/${sns.code}.svg`);
    }

    toggleSnsEdit(sns: SNS): void {
        if (sns.visible) {
            this.editRemoveSns(sns);
        } else {
            sns.visible = true;
            if (this.preservedSnsUrl[sns.code]) {
                this.editAbout[sns.code] = this.preservedSnsUrl[sns.code];
                this.onSnsUrlChanged(sns);
            }
        }
    }

    onSnsUrlChanged(sns: SNS): void {
        let value: string = this.editAbout[sns.code];
        console.log('onSnsUrlChanged ' + sns.code, value);
        if (value) {
            if (value.startsWith('//')) {
                value = (sns.code == 'sns_website' ? 'http:' : 'https:') + value;
                this.editAbout[sns.code] = value;
            } else if (!value.includes('//') && value.match(/^[a-zA-Z]/)) {
                value = (sns.code == 'sns_website' ? 'http://' : 'https://') + value;
                this.editAbout[sns.code] = value;
            }

            if (sns.validateUrl(value)) {
                delete this.errors[sns.code];
            } else {
                this.errors[sns.code] = translate('error.invalid-sns-url', {
                    name: sns.name,
                });
            }

            this.$forceUpdate();
        } else {
            delete this.errors[sns.code];
        }
    }

    hasError(): boolean {
        for (const key in this.errors) {
            return true;
        }
        return false;
    }
}

class SNS {
    visible = true;

    constructor(public code: string, private _name: string, private varifySchema: RegExp, public black: boolean) {}

    public setNameKey(key: string): void {
        this._name = key;
    }

    get name(): string {
        return translate(this._name);
    }

    validateUrl(url: string): boolean {
        return !!url.match(this.varifySchema);
    }
}
