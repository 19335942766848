import { UserResetKey } from '../datas/UserResetKey';
import { ArrayUtil } from '../utils/ArrayUtil';
import { IUserBadge, IUserBadgePack } from '@/vo/Badge';
import { IClient } from '@/vo/Client';
import { ITitleUserItem, IUserWallet } from '@/vo/PayProduct';
import { IFriednship, IUser, IUserAbout } from '@/vo/User';
import { AbstractSubService } from './abstractSubService';
import { serverService } from './serverService';

const cachedUsers: { [key: string]: { user: IUser; expire: number } } = {};
function cacheUser(user: IUser): IUser {
    user &&
        (cachedUsers[user.username.toLowerCase()] = {
            user: user,
            expire: Date.now() + 300000, // 5 minutes
        });
    return user;
}

export class UserService extends AbstractSubService {
    searchUsers(term: string, start: number, length: number): Promise<IUser[]> {
        return this.api('/search/users', {
            term: term,
            start: start,
            length: length,
        }).then((json: any) => json.users);
    }

    getUser(username: string): Promise<IUser | null> {
        if (!username) {
            return Promise.resolve(null);
        } else {
            const cachedUser = cachedUsers[username.toLowerCase()];
            if (cachedUser && Date.now() < cachedUser.expire) {
                return Promise.resolve(cachedUser.user);
            }
        }
        return this.api('/get/user/' + username, {}).then((json: any) => cacheUser(json.user) || null);
    }
    listFriends(user: IUser, start: number, length: number): Promise<IUser[]> {
        return this.api('/list/friends/' + user.username, {
            start: start,
            length: length,
        }).then((json: any) => json.list);
    }
    getFriendsTotal(user: IUser): Promise<number> {
        return this.api('/get/friends_total/' + user.username).then((json: any) => json.total);
    }

    listBlacklist(user: IUser, start: number, length: number): Promise<IUser[]> {
        return this.api('/list/blacklist/' + user.username, {
            start: start,
            length: length,
        }).then((json: any) => json.list);
    }
    getBlacklistTotal(user: IUser): Promise<number> {
        return this.api('/get/blacklist_total/' + user.username).then((json: any) => json.total);
    }

    listFriendInvites(user: IUser, start: number, length: number): Promise<IUser[]> {
        return this.api('/list/friend_invites/' + user.username, {
            start: start,
            length: length,
        }).then((json: any) => json.list);
    }
    getFriendInvitesTotal(user: IUser): Promise<number> {
        return this.api('/get/friend_invites_total/' + user.username).then((json: any) => json.total);
    }

    getFriendship(user: IUser, target: IUser): Promise<IFriednship | null> {
        return this.api(`/get/friendship/${user.username}/${target.username}`).then((json: any) => json.friendship);
    }

    setFriendship(friendship: IFriednship, status: string): Promise<IFriednship | null> {
        return this.api(`/set/friendship/${friendship.requester.username}/${friendship.acceptor.username}/${status}`).then(
            (json: any) => {
                Object.assign(friendship, json.friendship);
                return json.friendship;
            }
        );
    }

    listRecentBadgesByUser(user: IUser, length: number): Promise<IUserBadgePack[]> {
        return this.api(`/list/recent_badges/${user.username}/${length}`).then((packs: IUserBadgePack[]) => {
            return serverService.client.queryClients(packs.map((p) => p.gameCode)).then((clients: IClient[]) => {
                packs.forEach((pack) => {
                    pack.game = clients.find((c) => c.code == pack.gameCode) || {
                        code: pack.gameCode,
                        name: pack.gameCode,
                    };
                });
                return packs;
            });
        });
    }

    listUserBadgesByGame(user: IUser, game: IClient, start: number, length: number): Promise<IUserBadge[]> {
        return this.api(`/list/user_badges/${user.username}/game/${game.code}`, {
            start: start,
            length: length,
        }).then((list: any) => list);
    }

    getUserAbout(user: IUser): Promise<IUserAbout> {
        return this.api(`/get/user_about/${user.username}`).then((json: any) => json.about);
    }
    saveUserAbout(user: IUser, about: IUserAbout): Promise<IUserAbout> {
        const data: any = {};
        for (const key in about) {
            if (!key.startsWith('_')) {
                data[key] = about[key];
            }
        }
        return this.api(`/save/user_about/${user.username}`, {
            about: data,
        }).then((json: any) => json.about);
    }

    saveUser(
        user: IUser,
        data: {
            nickname?: string;
            mood?: string;
            icon?: string;
        }
    ): Promise<IUser> {
        return this.api(`/save/user/${user.username}`, {
            data: data,
        }).then((json: any) => {
            user.mood = json.user.mood;
            user = Object.assign(user, json.user);
            serverService.updateMe(user);
            return cacheUser(user);
        });
    }

    listByUsernames(usernames: string[]): Promise<IUser[]> {
        const users: IUser[] = [];
        const queryUsernames: string[] = [];
        usernames.forEach((uname) => {
            const cachedUser = cachedUsers[uname.toLowerCase()];
            if (cachedUser) {
                users.push(cachedUser.user);
            } else {
                ArrayUtil.addUniqueElement(queryUsernames, uname);
            }
        });
        if (queryUsernames.length) {
            return this.api('/list/by_usernames', {
                usernames: usernames,
            }).then((json: any) => {
                (json.list as IUser[]).forEach((user) => {
                    cacheUser(user);
                    users.push(user);
                });
                return users;
            });
        } else {
            return Promise.resolve(users);
        }
    }

    setUserSuspended(user: IUser, days: number, note: string): Promise<IUser> {
        return this.api(`/set/user/suspended/${user.username}`, {
            days: days,
            note: note,
        }).then((json: any) => {
            //user.suspendTime = json.user.suspendTime;
            return cacheUser(json.user);
        });
    }

    resetUser(user: IUser, key: UserResetKey, note?: string): Promise<IUser> {
        return this.api(`/reset_user/${user.username}`, {
            key: key.code,
            note: note || '',
        }).then((json: any) => {
            const newUser = json.user as IUser;
            Object.assign(user, newUser);
            user.mood = newUser.mood;
            return cacheUser(json.user);
        });
    }

    getMyWallet(): Promise<IUserWallet> {
        return this.api(`/get/wallet`, {}).then((json: any) => json.wallet);
    }

    listSysTitleByUser(user: IUser): Promise<ITitleUserItem[]> {
        const cache = userSysTitlesCache[user.username];
        if (cache && Date.now() < cache.expire) {
            return Promise.resolve(cache.items);
        }
        return this.api(`/list/sys_titles/${user.username}`, {}).then((json: any) => {
            const items = json.list;
            userSysTitlesCache[user.username] = {
                expire: Date.now() + 60000 * 60, // one hour
                items: items,
            };
            return items;
        });
    }
}

const userSysTitlesCache: {
    [key: string]: {
        expire: number,
        items: ITitleUserItem[],
    }
} = {};