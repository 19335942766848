
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { serverService } from '@/services/serverService';
import { DISCUSS_CATEGORY, DISCUSS_ORDER, DISCUSS_STATE } from '@/services/discussService';
import { timestampToTimeAgo } from '@/filters/timestampToString';
import { translate } from '@/filters/translate';
import { contentLinksToBlankTarget, errorToString, isContentEmpty } from '@/filters/content-filters';
import { getUserRoute, getUserTooltip } from '@/filters/user-filters';
import { isDarkMode } from '@/filters/mobile';
import { getEditorContentStyles, getEditorLanguageUrl } from '@/filters/editors';
import { dialogService } from '@/services/dialogService';
import Editor from '@tinymce/tinymce-vue';
import BoardReply from './BoardReply.vue';
import BoardFooter from './BoardFooter.vue';
import CssPreloader from '@/components/CssPreloader.vue';
import { NotificationType } from '../../../datas/NotificationType';
import { IArticle, IArticleAndReplies } from '@/vo/Discuss';
import { ITeam, ITeamship, IUser } from '@/vo/User';

export class BoardMessageData {
    replies: IArticle[] = [];

    showReplies = false;

    hasMore = false;

    constructor(public article: IArticle) {}
}

@Component({
    methods: {
        timestampToTimeAgo,
        translate,
        getUserRoute,
        getUserTooltip,
        getEditorContentStyles,
        getEditorLanguageUrl,
    },
    components: {
        Editor,
        BoardReply,
        BoardFooter,
        CssPreloader,
    },
})
export default class BoardMessage extends Vue {
    @Prop()
    article!: IArticle;

    @Prop()
    replies!: IArticle[];

    @Prop()
    user?: IUser;

    @Prop()
    team?: ITeam;

    @Prop()
    teamship?: ITeamship;

    @Prop()
    allowPost!: boolean;

    @Prop()
    category!: DISCUSS_CATEGORY;

    dark = false;

    language = serverService.tinymceLocale;

    mounted(): void {
        this.dark = isDarkMode();

        if (!this.isPrivateToMe() && this.$route.hash) {
            let searchHash = this.$route.hash.match(/^#([0-9]+)$/);
            if (searchHash && Number(searchHash[1]) == this.article.id) {
                this.loadMoreReplies(10);
            }
        }
    }

    @Emit('refresh')
    refresh(): void {}

    @Emit('stateChange')
    onStateChange(article: IArticle, replies: IArticle[]): IArticleAndReplies {
        return {
            article: article,
            replies: replies,
        };
    }

    @Watch('replies', { immediate: true })
    onDataChange(): void {
        this.replyInput = '';
        this.showReplies = this.replies.length > 0;
        this.hasMore = this.replies.length < this.article.replies - 1;
    }

    onReplyChange(reply: IArticle, index: number): void {
        let replies = this.replies.slice();
        replies[index] = reply;
        this.onStateChange(this.article, replies);
    }

    getMainClasses(): string[] {
        let list = ['main', this.category.code];
        this.team && list.push('team');
        this.closed && list.push('closed');
        this.removed && list.push('removed');
        return list;
    }

    replyInput = '';
    showReplies = false;
    hasMore = false;
    sendingReply = false;
    loading = false;

    isTeamMember(): boolean {
        return !!this.teamship;
    }

    get allowReply(): boolean {
        if (this.allowPost) {
            let state = this.articleState;
            if (state == DISCUSS_STATE.OPEN) {
                return true;
            }
            if (state == DISCUSS_STATE.REMOVED) {
                return false;
            }
            return serverService.isMe(this.article.author) || serverService.isMe(this.user) || this.isTeamMember();
        } else {
            return false;
        }
    }

    public $refs!: Vue['$refs'] & {
        hashTag: HTMLAnchorElement;
    };

    get articleState(): DISCUSS_STATE {
        return DISCUSS_STATE.getByCode(this.article.state);
    }

    get closed(): boolean {
        return this.article.state == DISCUSS_STATE.CLOSE.code;
    }
    get removed(): boolean {
        return this.article.state == DISCUSS_STATE.REMOVED.code;
    }

    isPrivateToMe(): boolean {
        if (this.closed) {
            if (serverService.isMe(this.article.author) || serverService.isMe(this.user) || this.isTeamMember()) {
                return false;
            }
            return true;
        }
        return false;
    }

    get content(): string {
        if (this.removed || this.isPrivateToMe()) {
            return '';
        }
        return contentLinksToBlankTarget(this.article.content);
    }

    loadMoreReplies(count = 2): void {
        this.showReplies = true;
        this.hasMore = false;

        if (this.article.replies > 1) {
            this.loading = true;
            serverService.discuss
                .listReplies(this.article.id, DISCUSS_ORDER.TIME_DESC, this.replies.length, count)
                .then((list) => {
                    this.onStateChange(this.article, list.reverse().concat(this.replies));
                    this.hasMore = list.length >= count;
                    serverService.messaging.markArticlesRead([this.article], [NotificationType.USER_MESSAGE_REPLY]);
                })
                .catch((err) => {
                    dialogService.error({ body: errorToString(err) });
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }

    onBtnSendReply(): void {
        let content = this.replyInput.trim();
        if (isContentEmpty(content)) {
            dialogService.error({
                body: translate('forum.alert.emptyContent'),
            });
        } else {
            this.sendingReply = true;
            serverService.discuss
                .addReply(this.article.id, '', content)
                .then((result) => {
                    this.replyInput = '';
                    this.onStateChange(result.article, this.replies.concat([result.reply]));
                    this.$forceUpdate();
                })
                .catch((err) => {
                    dialogService.error({ body: errorToString(err) });
                })
                .finally(() => {
                    this.sendingReply = false;
                });
        }
    }
}
