const all: { [key: string]: NotificationType } = {};

export class NotificationType {
    static USER_REPORT = new NotificationType('userReport');
    static USER_JOINED_DISCUSS = new NotificationType('userJoinedDiscuss');
    static USER_MESSAGE = new NotificationType('userMessage');
    static USER_MESSAGE_REPLY = new NotificationType('userMessageReply');

    static FRIEND_REQUEST = new NotificationType('friendRequest');
    static FRIEND_GRANTED = new NotificationType('friendGranted');

    static TO_TEAM_REQUEST = new NotificationType('toTeamRequest');
    static TO_TEAM_GRANTED = new NotificationType('toTeamGranted');
    static TO_TEAM_REJECTED = new NotificationType('toTeamRejected');
    static TEAM_INVITE_REQUEST = new NotificationType('teamInviteRequest');
    static TEAM_INVITE_GRANTED = new NotificationType('teamInviteGranted');
    static TEAM_INVITE_REJECTED = new NotificationType('teamInviteRejected');

    static getByCode(code: string): NotificationType {
        return all[code];
    }
    static listAll(): NotificationType[] {
        return Object.values(all);
    }

    constructor(public code: string) {
        all[code] = this;
    }
}
