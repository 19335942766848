
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { translate } from '@/filters/translate';
import { serverService } from '../services/serverService';
import { isMobile } from '@/filters/mobile';

declare const $: any;

interface LocaleInfo {
    code: string;
    name: string;
}

function getCurrLocale(locales: LocaleInfo[]): LocaleInfo {
    let lcCode = serverService.locale;
    return locales.find((lc) => lc.code == lcCode) || locales[0];
}

@Component({
    methods: {
        translate,
        isMobile,
    },
})
export default class LocaleMenuItem extends Vue {
    @Prop({ default: 0 })
    hide!: number;

    isOpen = false;

    @Watch('hide')
    onHideChange(): void {
        let menu = this.menu;
        menu.css('display', 'none');
    }

    locales: LocaleInfo[] = [
        {
            code: 'zh-Hant',
            name: '繁體中文',
        },
        {
            code: 'zh-Hans',
            name: '简体中文',
        },
        {
            code: 'en-US',
            name: 'English',
        },
    ];

    currLocale: LocaleInfo = getCurrLocale(this.locales);

    onClick(e: MouseEvent): void {
        if (isMobile()) {
            this.isOpen = !this.isOpen;
        } else {
            e.preventDefault();
            e.stopImmediatePropagation();
            this.setVisible(!this.isVisible());
        }
    }

    isVisible(): boolean {
        let menu = this.menu;
        return menu.css('display') != 'block';
    }

    setVisible(vis: boolean): void {
        let menu = this.menu;
        menu.css('display', vis ? 'block' : 'none');
    }

    get menu(): any {
        return $('#locale-selector .dropdown-menu');
    }

    getHref(locale: string): string {
        let url = window.location.href;
        if (url.match(/(&|\?)locale=.*?(&|$)/)) {
            url = url.replace(/(&|\?)locale=.*?(&|$)/, '$1locale=' + locale + '$2');
        } else {
            var qIndex = url.indexOf('?');
            if (qIndex == -1) {
                url += '?';
            } else if (qIndex < url.length - 1) {
                url += '&';
            }
            url += 'locale=' + locale;
        }
        return url;
    }
}
