
import { Component, Vue, Watch } from 'vue-property-decorator';
import GameEventBox from './GameEventBox.vue';
import Pagination from '@/components/Pagination.vue';
import { RouteResolver, UpRouteData } from '@/utils/RouteResolver';
import { Location, Route } from 'vue-router';
import { serverService } from '@/services/serverService';
import { translate } from '@/filters/translate';
import { showSideAds } from '@/filters/mobile';
import AreaTitle from '@/components/AreaTitle.vue';
import AdsResponsive from '@/components/ads/AdsResponsive.vue';
import { IClient } from '@/vo/Client';

@Component({
    name: 'Games',
    components: {
        GameEventBox,
        Pagination,
        AreaTitle,
        AdsResponsive,
    },
    methods: {
        translate,
        showSideAds,
    },
})
export default class Games extends Vue implements RouteResolver {
    loading = false;
    gameData: IClient[] = [];
    maxPage = 0;
    page = -1;
    isMounted = true;

    @Watch('$route', { immediate: true })
    onRouteChanged(to: Route): void {
        this.loadPage(Number(to.params.page || 1));
        this.loadTotal();
    }

    mounted(): void {
        document.title = translate('localedTitle') + ':' + translate('game.sysTitle.eventsTitle');
    }

    beforeDestroy(): void {
        this.isMounted = false;
    }

    get pageSize(): number {
        return 30;
    }

    loadTotal(): void {
        serverService.client.getSysTitleClientsTotal().then((total) => {
            this.maxPage = Math.ceil(total / this.pageSize);
        });
    }

    get routeResolver(): RouteResolver {
        return this;
    }
    getUpRoute(): UpRouteData | null {
        return null;
    }

    resolveRoute(params: { [key: string]: any }): Location {
        return {
            name: 'events_page',
            params: {
                page: (params.page || 1) + '',
            },
        };
    }

    loadPage(page: number): void {
        this.gameData = [];
        this.page = page;
        this.loading = true;

        this.listClients().then((clients) => {
            this.loading = false;
            this.gameData = clients;
        });
    }

    private listClients(): Promise<IClient[]> {
        return serverService.client.listSysTitleClients((this.page - 1) * this.pageSize, this.pageSize);
    }
}
