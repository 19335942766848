declare const $: any;
declare const bootstrap: any;

export class DomUtil {
    static moveToElement(el: HTMLElement, paddingTop = 0): void {
        DomUtil.moveToTop(DomUtil.getElementTop(el), paddingTop);
    }
    static moveToTop(top: number, paddingTop = 0): void {
        window.scrollTo({
            top: top - 80 - paddingTop, //appBar's height,
            behavior: 'smooth',
        });
    }
    static getElementTop(el: HTMLElement): number {
        const rect = el.getBoundingClientRect();
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        return rect.top + scrollTop;
    }

    static SharpSvg = `<svg
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    style="transform:scale(0.2, 0.1)"
    width="78"
    height="200"
    id="svg1307">
   <defs
      id="defs1309" />
   <g
      id="layer1">
     <path
        d="M 52.984422,75.138892 L 25.888974,83.986385 L 25.888974,125.18252 L 52.984422,116.33503 L 52.984422,75.138892 M 5.9821143,61.867652 L 17.870933,57.996874 L 17.870933,12.929955 L 25.888974,12.929955 L 25.888974,55.232032 L 52.984422,46.384539 L 52.984422,6.0178512 L 61.002462,6.0178512 L 61.002462,43.896181 L 71.785345,40.301887 L 71.785345,69.05624 L 61.002462,72.650534 L 61.002462,113.57019 L 71.785345,110.25238 L 71.785345,138.73025 L 61.002462,142.32454 L 61.002462,187.39146 L 52.984422,187.39146 L 52.984422,144.8129 L 25.888974,153.66039 L 25.888974,194.58005 L 17.870933,194.58005 L 17.870933,156.42523 L 5.9821143,160.29601 L 5.9821143,131.54166 L 17.870933,127.67088 L 17.870933,86.474742 L 5.9821143,90.34552 L 5.9821143,61.867652"
        transform="scale(1.00299,0.997019)"
        style="font-size:276.48416138px;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;text-align:start;line-height:100%;writing-mode:lr-tb;text-anchor:start;fill:#000000;fill-opacity:1;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;font-family:Bitstream Vera Sans"
        id="text1326" />
   </g>
 </svg>`;

    static showVideoDialog(platform: string, id: string): void {
        const url = `https://www.youtube.com/embed/${id}?autoplay=1`;
        $(document.body).append(
            `<div class="video-dialog" onclick="closeVideoDialogs()">
                <div class="video-panel">
                    <iframe src="${url}" frameborder="0"
                        allow="autoplay;encrypted-media; picture-in-picture; web-share"
                        allowfullscreen></iframe>
                    <div class="btn-close-dialog" onclick="closeDialogs()"><i class="fas fa-times"></i></div>
                </div>
            </div>`
        );
    }

    static enableRarityTooltip(comp: Vue): void {
        comp.$nextTick().then(() => {
            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            [...tooltipTriggerList].forEach((el) => {
                if (!el['tooltipInstalled']) {
                    el['tooltipInstalled'] = 1;
                    new bootstrap.Tooltip(el);
                }
            });
        });
    }
}

window['closeVideoDialogs'] = () => {
    $('.video-dialog').remove();
};
