const ALL: { [key: string]: TransactionCategory } = {};

export class TransactionCategory {

    static GAME_DOLLAR = new TransactionCategory('gameDollar');
    static GAMELET_DOLLAR = new TransactionCategory('gameletDollar');
    static GAMELET_POINT = new TransactionCategory('gameletPoint');
    static BUY_GLT_DOLLAR = new TransactionCategory('buyGltDollar');
    static AWARD_GLT_POINT = new TransactionCategory('awardGltPoint');
    static TRANSFER_GLT_POINT = new TransactionCategory('transferGltPoint');
    static BUY_ITEM = new TransactionCategory('buyItem');

    static getByCode(code: string): TransactionCategory {
        return ALL[code];
    }

    constructor(public code: string) {
        ALL[code] = this;
    }
}