
import { Component, Vue } from 'vue-property-decorator';
import DialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js';
import { SearchUserDialogController, SearchUserDialogOptions, IDialog, IDialogMessage } from '@/components/dialogs/DialogProps';
import { serverService } from '@/services/serverService';
import { errorToString } from '@/filters/content-filters';
import { dialogService } from '@/services/dialogService';
import { timestampToTimeMore } from '@/filters/timestampToString';
import { getUserUrl } from '@/filters/user-filters';
import { translate } from '@/filters/translate';
import { IUser } from '@/vo/User';

@Component({
    name: 'alertDialog',
    mixins: [DialogMixin],
    methods: {
        translate,
        getUserUrl,
        timestampToTimeMore,
    },
})
export default class SearchUserDialog extends Vue implements SearchUserDialogController {
    private loading?: {
        message?: string;
    } = null!;

    get dialog(): IDialog {
        return this as any;
    }

    get ops(): SearchUserDialogOptions {
        return this.dialog.options as SearchUserDialogOptions;
    }

    private attemptClose = false;

    searchTerm = '';

    selectedUser: IUser | null = null;

    users: IUser[] = [];

    searching = false;

    hasMore = false;

    private backdropClose: true | undefined;

    onButtonSelect(user: IUser): void {
        this.selectedUser = user;
    }

    mounted(): void {
        if (this.ops.getController) {
            this.ops.getController(this);
        }
        this.backdropClose = this.ops.backdropClose;
    }

    onButtonSearch(): void {
        this.users = [];
        this.search();
    }

    onButtonMore(): void {
        this.search();
    }

    private search(): void {
        this.searching = true;
        this.hasMore = false;
        const length = 20;
        serverService.user
            .searchUsers(this.searchTerm, this.users.length, length)
            .then((list) => {
                this.users = this.users.concat(list);
                this.hasMore = list.length >= length;
            })
            .catch((err) => {
                dialogService.error({ body: errorToString(err) });
            })
            .finally(() => {
                this.searching = false;
            });
    }
    getUser(): IUser | null {
        return this.selectedUser;
    }

    showLoading(message?: string): void {
        this.loading = {
            message: message,
        };
        this.attemptClose = false;
        this.dialog.options.backdropClose = undefined;
    }
    hideLoading(): void {
        this.loading = undefined;
        this.attemptClose = false;
        this.dialog.options.backdropClose = this.backdropClose;
    }

    updateContent(message?: IDialogMessage): void {
        this.ops.message = message;
        this.attemptClose = false;
    }

    dismiss(): void {
        this.dialog.cancel();
    }

    private onBtnNo(): void {
        this.dialog.cancel();
    }

    private onBtnOk(): void {
        if (this.ops.ok) {
            this.attemptClose = true;
            this.ops.ok(this);
            if (this.attemptClose) {
                this.dialog.proceed(null);
            }
        } else {
            this.dialog.proceed(null);
        }
    }
}
