import { AuthType } from '../datas/AuthType';
import { translate } from '../filters/translate';
import { ArrayUtil } from '../utils/ArrayUtil';
import { NetUtil } from '../utils/NetUtil';
import { IFeedEntry } from '@/vo/Blog';
import { IClient } from '@/vo/Client';
import {
    IArticle,
    IArticleAndReplies,
    IArticleUsersMeta,
    IArticlesWithParent,
    IReviewMeta,
    ISubject,
    ISubjectFull,
} from '@/vo/Discuss';
import { IUser } from '@/vo/User';
import { AbstractSubService } from './abstractSubService';

export class DiscussService extends AbstractSubService {
    // client articles
    getReviewMeta(
        client: IClient
    ): Promise<{
        meta: IReviewMeta;
        review: IArticle;
    }> {
        return NetUtil.getIPs()
            .then((ips) => {
                const record = NetUtil.encryptJson(ips || { v4: '', v6: '' });
                return this.api(`/get/review_meta/${client.code}`, {
                    record: record,
                });
            })
            .then((json: any) => json);
    }

    public addReview(
        client: IClient,
        title: string,
        content: string,
        rate: number,
        meta: IReviewMeta,
        edit: boolean
    ): Promise<IArticle> {
        return getIpRecord().then((record) => {
            return this.api('/add/review/client/' + client.code, {
                title: title,
                content: content,
                rate: rate,
                meta: NetUtil.encryptJson(meta),
                edit: edit ? 1 : 0,
                record: record,
            }).then((json) => {
                Object.assign(client, json.client);
                return json.article;
            });
        });
    }
    public addRootArticle(
        client: IClient,
        category: DISCUSS_CATEGORY,
        title: string,
        content: string,
        state?: DISCUSS_STATE,
        checkCreateTime?: boolean,
        postQuota?: number
    ): Promise<IArticle> {
        return getIpRecord().then((record) => {
            return this.api('/add/root_article/client/' + client.code, {
                category: category.code,
                title: title,
                content: content,
                state: (state || DISCUSS_STATE.OPEN).code,
                checkCreateTime: checkCreateTime,
                record: record,
                quota: postQuota,
            }).then((json) => json.article);
        });
    }

    public getArticleById(articleId: number): Promise<IArticle> {
        return this.api('/get/article/' + articleId, {}).then((json) => json.article);
    }

    public getRootArticleIndex(articleId: number, states: DISCUSS_STATE[]): Promise<{ index: number; parent: number }> {
        return this.api('/get/root_article_index/' + articleId, {
            states: states.map((s) => s.code),
        }).then((json) => json);
    }

    public listRootArticlesByClients(
        clients: IClient[],
        category: DISCUSS_CATEGORY,
        states: DISCUSS_STATE[],
        start: number,
        length: number
    ): Promise<IArticle[]> {
        return this.api('/list/root_articles/clients', {
            clients: clients.map((c) => c.code),
            category: category.code,
            states: states.map((s) => s.code),
            start: start,
            length: length,
        }).then((json) => json.articles);
    }

    public listRootArticles(
        client: IClient,
        category: DISCUSS_CATEGORY,
        states: DISCUSS_STATE[],
        statusList: DISCUSS_STATUS[],
        ignoreTopOrder: boolean,
        includeContent: boolean,
        checkCreateTime: boolean,
        excludeCooled: boolean,
        includeLastReply: boolean,
        start: number,
        length: number
    ): Promise<IArticle[]> {
        return this.api('/list/root_articles/client/' + client.code, {
            category: category.code,
            states: states.map((s) => s.code),
            status: statusList.map((s) => s.code),
            start: start,
            length: length,
            ignoreTop: ignoreTopOrder,
            includeContent: includeContent,
            checkCreateTime: checkCreateTime,
            excludeCooled: excludeCooled,
            includeLastReply: includeLastReply,
        }).then((json) => json.articles);
    }

    public getRootArticlesTotal(
        client: IClient,
        category: DISCUSS_CATEGORY,
        states: DISCUSS_STATE[]
    ): Promise<{ total: number; subject: ISubjectFull }> {
        return this.api('/get/root_articles_total/client/' + client.code, {
            category: category.code,
            states: states.map((s) => s.code),
        }).then((json) => json);
    }

    public listLatestClientsRootArticles(
        category: DISCUSS_CATEGORY,
        states: DISCUSS_STATE[],
        start: number,
        length: number
    ): Promise<IArticle[]> {
        return this.api('/list/latest_clients_root_articles', {
            category: category.code,
            states: states.map((s) => s.code),
            start: start,
            length: length,
        }).then((json) => json.articles);
    }

    public listLatestClientsArticles(
        category: DISCUSS_CATEGORY,
        states: DISCUSS_STATE[],
        start: number,
        length: number
    ): Promise<IArticle[]> {
        return this.api('/list/latest_clients_articles', {
            category: category.code,
            states: states.map((s) => s.code),
            start: start,
            length: length,
        }).then((json) => json.articles);
    }

    public getRootClientArticlesTotal(states: DISCUSS_STATE[]): Promise<number> {
        return this.api('/get/root_clients_articles_total', {
            states: states.map((s) => s.code),
        }).then((json) => json.total);
    }

    // replies

    public getArticleAndReplies(
        clientCode: string,
        category: DISCUSS_CATEGORY,
        articleId: number,
        orderBy: DISCUSS_ORDER,
        start: number,
        length: number
    ): Promise<IArticleAndReplies> {
        return this.api('/get/article_replies/' + clientCode + '/' + articleId, {
            category: category ? category.code : '',
            orderBy: orderBy.code,
            start: start,
            length: length,
        });
    }

    public addReply(
        articleId: number,
        title: string,
        content: string
    ): Promise<{
        article: IArticle;
        reply: IArticle;
    }> {
        return getIpRecord().then((record) => {
            return this.api('/add/reply/' + articleId, {
                title: title,
                content: content,
                record: record,
            });
        });
    }

    public listReplies(articleId: number, orderBy: DISCUSS_ORDER, start: number, length: number): Promise<IArticle[]> {
        return this.api('/list/replies/' + articleId, {
            orderBy: orderBy.code,
            start: start,
            length: length,
        }).then((json) => json.list);
    }

    // gm
    public closeArticle(article: IArticle, note: string): Promise<IArticle> {
        return this.api('/close/article/' + article.id, {
            note: note || '',
        }).then((json) => {
            Object.assign(article, json.article);
            return json.article;
        });
    }
    public openArticle(article: IArticle, note: string, state?: DISCUSS_STATE): Promise<IArticle> {
        return this.api('/open/article/' + article.id, {
            note: note || '',
            state: (state || DISCUSS_STATE.OPEN).code,
        }).then((json) => {
            Object.assign(article, json.article);
            return json.article;
        });
    }
    public setArticleLocked(article: IArticle, locked: boolean, note: string): Promise<IArticle> {
        return this.api('/set/article_locked/' + article.id, {
            locked: locked ? 1 : 0,
            note: note || '',
        }).then((json) => {
            Object.assign(article, json.article);
            article.locked = json.article.locked;
            return json.article;
        });
    }

    public setArticleCooled(article: IArticle, cooled: boolean, note: string): Promise<IArticle> {
        return this.api('/set/article_cooled/' + article.id, {
            cooled: cooled ? 1 : 0,
            note: note || '',
        }).then((json) => {
            Object.assign(article, json.article);
            article.cooled = json.article.cooled;
            return json.article;
        });
    }

    public setArticleSubject(article: IArticle, subject: ISubject, note: string): Promise<IArticle> {
        return this.api('/set/article_subject/' + article.id, {
            subject: subject,
            note: note || '',
        }).then((json) => {
            Object.assign(article, json.article);
            return json.article;
        });
    }
    public setArticleStatus(article: IArticle, status: DISCUSS_STATUS, note: string): Promise<IArticle> {
        return this.api('/set/article_status/' + article.id, {
            status: status.code,
            note: note || '',
        }).then((json) => {
            Object.assign(article, json.article);
            return json.article;
        });
    }
    // blog board
    public addBlogArticle(entry: IFeedEntry, content: string): Promise<IArticle> {
        return getIpRecord().then((record) => {
            return this.api('/add/root_article/blog/' + entry.id, {
                category: DISCUSS_CATEGORY.BLOG_BOARD.code,
                title: entry.title,
                content: content,
                state: DISCUSS_STATE.OPEN.code,
                record: record,
            }).then((json) => json.article);
        });
    }
    public listBlogRootArticles(
        entry: IFeedEntry,
        states: DISCUSS_STATE[],
        start: number,
        length: number
    ): Promise<IArticle[]> {
        return this.api('/list/root_articles/blog/' + entry.id, {
            title: entry.title,
            category: DISCUSS_CATEGORY.BLOG_BOARD.code,
            states: states.map((s) => s.code),
            start: start,
            length: length,
        }).then((json) => json.articles);
    }
    public getBlogRootArticlesTotal(
        entry: IFeedEntry,
        states: DISCUSS_STATE[]
    ): Promise<{ total: number; subject: ISubjectFull }> {
        return this.api('/get/root_articles_total/blog/' + entry.id, {
            title: entry.title,
            category: DISCUSS_CATEGORY.BLOG_BOARD.code,
            states: states.map((s) => s.code),
        }).then((json) => json);
    }

    public listBlogRootArticlesTotal(entrys: IFeedEntry[], states: DISCUSS_STATE[]): Promise<{ [key: string]: number }> {
        return this.api('/list/root_articles_total/blog', {
            ids: entrys.map((entry) => entry.id),
            category: DISCUSS_CATEGORY.BLOG_BOARD.code,
            states: states.map((s) => s.code),
        }).then((json) => json);
    }

    // userboard
    public addUserArticle(
        user: IUser,
        category: DISCUSS_CATEGORY,
        title: string,
        content: string,
        state?: DISCUSS_STATE
    ): Promise<IArticle> {
        return getIpRecord().then((record) => {
            return this.api('/add/root_article/user/' + user.username, {
                category: category.code,
                title: title,
                content: content,
                state: (state || DISCUSS_STATE.OPEN).code,
                record: record,
            }).then((json) => json.article);
        });
    }

    public listUserRootArticles(
        user: IUser,
        category: DISCUSS_CATEGORY,
        states: DISCUSS_STATE[],
        start: number,
        length: number
    ): Promise<IArticle[]> {
        return this.api('/list/root_articles/user/' + user.username, {
            category: category.code,
            states: states.map((s) => s.code),
            start: start,
            length: length,
        }).then((json) => json.articles);
    }

    public getUserRootArticlesTotal(
        user: IUser,
        category: DISCUSS_CATEGORY,
        states: DISCUSS_STATE[]
    ): Promise<{ total: number; subject: ISubjectFull }> {
        return this.api('/get/root_articles_total/user/' + user.username, {
            category: category.code,
            states: states.map((s) => s.code),
        }).then((json) => json);
    }

    /**
     * only support user_board category
     * @param article
     */
    public removeArticle(article: IArticle): Promise<IArticle> {
        return this.api('/remove/article/' + article.id, {}).then((json) => {
            Object.assign(article, json.article);
            return json.article;
        });
    }

    // list by user
    public listArticlesByUser(
        user: IUser,
        category: DISCUSS_CATEGORY,
        start: number,
        length: number
    ): Promise<IArticlesWithParent[]> {
        return this.api('/list/user_articles/' + user.username + '/' + category.code, {
            start: start,
            length: length,
        }).then((json) => json.list);
    }
    public getArticlesByUserTotal(user: IUser, category: DISCUSS_CATEGORY): Promise<number> {
        return this.api('/get/user_articles_total/' + user.username + '/' + category.code, {}).then((json) => json.total);
    }

    //
    public setArticleTop(article: IArticle, movement: DISCUSS_TOP_MOVEMENT): Promise<IArticle> {
        return this.api('/set/article_top/' + article.id + '/' + movement.code, {}).then((json) => {
            Object.assign(article, json.article);
            article.top = json.article.top;
            return json.article;
        });
    }

    //
    public setUserBoardAuthType(user: IUser, category: DISCUSS_CATEGORY, authType: AuthType): Promise<ISubjectFull> {
        return this.api('/set/userboard_authtype/' + user.username + '/' + category.code, {
            authType: authType.code,
        }).then((json) => json.subject);
    }

    //
    public openContributeSubject(client: IClient): Promise<ISubjectFull> {
        return this.api('/open/contribute_subject/client/' + client.code, {}).then((json) => json.subject);
    }
    public closeContributeSubject(client: IClient): Promise<ISubjectFull> {
        return this.api('/close/contribute_subject/client/' + client.code, {}).then((json) => json.subject);
    }
    public getContributeSubject(client: IClient): Promise<ISubjectFull> {
        return this.api('/get/contribute_subject/client/' + client.code, {}).then((json) => json.subject);
    }

    //
    public watchArticle(parentArticle: IArticle): Promise<IArticleUsersMeta> {
        return this.api('/watch/article/' + parentArticle.id, {}).then((json) => {
            if (json.aid == parentArticle.id) {
                parentArticle.meta = json.meta;
            }
            return json.meta;
        });
    }
    public stopWatchArticle(parentArticle: IArticle): Promise<IArticleUsersMeta> {
        return this.api('/stop_watch/article/' + parentArticle.id, {}).then((json) => {
            if (json.aid == parentArticle.id) {
                parentArticle.meta = json.meta;
            }
            return json.meta;
        });
    }
}

class Locker {
    static instance = new Locker();
}
const allStates: { [key: string]: DISCUSS_STATE } = {};
export class DISCUSS_STATE {
    static OPEN = new DISCUSS_STATE(Locker.instance, 'open');
    static DIALOG = new DISCUSS_STATE(Locker.instance, 'dialog');
    static CLOSE = new DISCUSS_STATE(Locker.instance, 'close');
    static REMOVED = new DISCUSS_STATE(Locker.instance, 'removed');

    static isCloseOrRemoved(stateCode: string): boolean {
        return stateCode == DISCUSS_STATE.CLOSE.code || stateCode == DISCUSS_STATE.REMOVED.code;
    }

    static getByCode(code: string): DISCUSS_STATE {
        return allStates[code];
    }

    constructor(locker: Locker, public code: string) {
        allStates[code] = this;
    }

    private transKey(category: string, key: string): string {
        return translate(`${category}.states.${this.code}.${key}`);
    }

    getTitle(category: string): string {
        return this.transKey(category, 'title');
    }
    getOptionName(category: string): string {
        return this.transKey(category, 'option');
    }
    getDescription(category: string): string {
        return this.transKey(category, 'desc');
    }
}

const allStatus: { [key: string]: DISCUSS_STATUS } = {};
export class DISCUSS_STATUS {
    static OPEN = new DISCUSS_STATUS(Locker.instance, 'open');
    static DONE = new DISCUSS_STATUS(Locker.instance, 'done');
    static PASSOVER = new DISCUSS_STATUS(Locker.instance, 'passover');

    static listAll(): DISCUSS_STATUS[] {
        return ArrayUtil.listValuesOfObject(allStatus) as DISCUSS_STATUS[];
    }

    static getByCode(code: string): DISCUSS_STATUS {
        return allStatus[code];
    }

    constructor(locker: Locker, public code: string) {
        allStatus[code] = this;
    }

    private transKey(category: string, key: string): string {
        return translate(`${category}.status.${this.code}.${key}`);
    }

    getOptionName(category: string): string {
        return this.transKey(category, 'option');
    }
    getDescription(category: string): string {
        return this.transKey(category, 'desc');
    }
}

export class DISCUSS_ORDER {
    static TIME_DESC = new DISCUSS_ORDER(Locker.instance, 'timeDesc');
    static TIME_ASC = new DISCUSS_ORDER(Locker.instance, 'timeAsc');

    constructor(locker: Locker, public code: string) {}
}

const allCategories: { [key: string]: DISCUSS_CATEGORY } = {};

export class DISCUSS_CATEGORY {
    static FORUM = new DISCUSS_CATEGORY(Locker.instance, 'forum', 'fas fa-comments', 'forum_client');
    static REPORT = new DISCUSS_CATEGORY(Locker.instance, 'report', 'fas fa-question-circle', 'forum_client');
    static NEWS = new DISCUSS_CATEGORY(Locker.instance, 'news', 'fas fa-newspaper', 'forum_client');
    static CONTRIBUTE = new DISCUSS_CATEGORY(Locker.instance, 'contribute', 'fas fa-comments', 'forum_client');
    static REVIEW = new DISCUSS_CATEGORY(Locker.instance, 'review', 'fas fa-pen-nib', 'review_client');
    static USER_BOARD = new DISCUSS_CATEGORY(Locker.instance, 'userBoard', 'fas fa-comments', 'forum_client');
    static BLOG_BOARD = new DISCUSS_CATEGORY(Locker.instance, 'blogBoard', 'fas fa-comments', 'forum_client');

    static getByCode(code: string): DISCUSS_CATEGORY {
        return allCategories[code];
    }

    constructor(locker: Locker, public code: string, public icon: string, public routeName: string) {
        allCategories[code] = this;
    }

    isForClient(): boolean {
        return clientCategories.includes(this);
    }
    isForUser(): boolean {
        return userCategories.includes(this);
    }

    get name(): string {
        return translate('game.btn.' + this.code);
    }
}

function getIpRecord(): Promise<string> {
    return NetUtil.getIPs().then((ips) => {
        return NetUtil.encryptJson(ips || { err: 'not found' });
    });
}

export class DISCUSS_TOP_MOVEMENT {
    static UP = new DISCUSS_TOP_MOVEMENT('up');
    static DOWN = new DISCUSS_TOP_MOVEMENT('down');
    static TOP = new DISCUSS_TOP_MOVEMENT('top');
    static BOTTOM = new DISCUSS_TOP_MOVEMENT('bottom');
    static REMOVE = new DISCUSS_TOP_MOVEMENT('remove');

    constructor(public code: string) {}
}

const clientCategories = [DISCUSS_CATEGORY.FORUM, DISCUSS_CATEGORY.REPORT, DISCUSS_CATEGORY.NEWS, DISCUSS_CATEGORY.CONTRIBUTE];
const userCategories = [DISCUSS_CATEGORY.USER_BOARD];
