import { translate } from '@/filters/translate';

const all: { [key: string]: AuthType } = {};

export class AuthType {
    static GM = new AuthType('gm', 'fa-user-secret');
    static CLIENT_GM = new AuthType('clientGm', 'fa-user-secret');
    static PLAYER = new AuthType('player', 'fas fa-globe-asia');
    static FRIEND = new AuthType('friend', 'fas fa-user-friends');
    static ME = new AuthType('me', 'fas fa-door-closed');

    static getByCode(code: string): AuthType {
        return all[code];
    }

    constructor(public code: string, public icon: string) {
        all[code] = this;
    }

    getTitle(prefix: string): string {
        return translate(`${prefix}.rootState.${this.code}.title`);
    }

    getBtnLabel(prefix: string): string {
        return translate(`${prefix}.rootState.${this.code}.btn`);
    }
}
