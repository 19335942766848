
import { Component, Watch } from 'vue-property-decorator';
import { serverService } from '@/services/serverService';
import { translate } from '@/filters/translate';
import BaseTabComp from '../users/components/BaseTabComp';
import Members from './Members.vue';
import Vue from 'vue';
import { ITeamship } from '@/vo/User';
import { TeamMemberView, TEAM_MEMBER_VIEWS } from '@/datas/FriendshipStatus';
import { ArrayUtil } from '../../utils/ArrayUtil';

@Component({
    components: {
        Members,
    },
    methods: {
        translate,
    },
})
export default class MembersTab extends BaseTabComp {
    invitesTotal = 0;
    showInvites = false;

    view = TEAM_MEMBER_VIEWS.SETTINGS;
    views = ArrayUtil.listValuesOfObject(TEAM_MEMBER_VIEWS);

    public $refs!: Vue['$refs'] & {
        members?: Members;
    };

    get mine(): boolean {
        return !!this.teamship;
    }

    mounted(): void {
        this.refreshInvitesTotal();
    }

    refreshInvitesTotal(): void {
        if (this.mine) {
            serverService.team.getMemberInvitesTotal(this.team!).then((total) => {
                this.invitesTotal = total;
            });
        }
    }

    @Watch('$route', { immediate: true })
    onRouteChange(): void {
        if (this.isMe && this.$route.hash == '#invites') {
            this.btnInvites(true);
        }
    }

    btnInvites(show: boolean): void {
        this.showInvites = show;
    }

    onInviteAccepted(tship: ITeamship): void {
        this.invitesTotal--;
        this.$refs.members!.onInviteAccepted(tship);
    }
    onInviteRemoved(): void {
        this.invitesTotal--;
    }
    btnView(view: TeamMemberView): void {
        this.view = view;
    }
}
