import { render, staticRenderFns } from "./MyDeposit.vue?vue&type=template&id=ec744064&scoped=true"
import script from "./MyDeposit.vue?vue&type=script&lang=ts"
export * from "./MyDeposit.vue?vue&type=script&lang=ts"
import style0 from "./MyDeposit.vue?vue&type=style&index=0&id=ec744064&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec744064",
  null
  
)

export default component.exports