
import { Component, Prop, Vue } from 'vue-property-decorator';
import { translate } from '@/filters/translate';

const tabToIconMap = {
    user_board: 'fas fa-comments',
    user_about: 'fas fa-address-card',
    user_badges: 'fas fa-trophy',
    user_friends: 'fas fa-users',
    user_posts: 'fas fa-history',
    team_board: 'fas fa-comments',
    team_records: 'fas fa-history',
    team_about: 'fas fa-address-card',
    team_members: 'fas fa-users',
};
function tabToIcon(tab: string): string {
    return tabToIconMap[tab] || '';
}

@Component({
    methods: {
        translate,
        tabToIcon,
    },
})
export default class RootTabs extends Vue {
    @Prop()
    tabs?: string[];
    @Prop()
    currentTab?: string;
}
