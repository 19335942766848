
import { Component, Prop, Vue } from 'vue-property-decorator';
import { translate } from '@/filters/translate';
import { isMobile } from '@/filters/mobile';

@Component({
    methods: {
        translate,
        isMobile,
    },
})
export default class AreaTitle extends Vue {
    @Prop({})
    text!: string;

    @Prop({})
    subtitle?: string;

    @Prop({})
    filters?: string;

    @Prop({})
    color?: string;

    @Prop({})
    hoverEffect?: boolean;

    @Prop({})
    link?: any;
}
