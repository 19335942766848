
import { Component, Vue } from 'vue-property-decorator';
import { serverService } from '@/services/serverService';
import PushNotiBox from '@/components/PushNotiBox.vue';
import { translate } from '@/filters/translate';
import { INotification } from '@/vo/Noti';
import { IUser } from '@/vo/User';

declare const $: any;

@Component({
    components: {
        PushNotiBox,
    },
    methods: {
        translate,
    },
})
export default class NotiBell extends Vue {
    public $refs!: Vue['$refs'] & {
        root?: HTMLDivElement;
    };

    me: IUser | null = null;

    notis: INotification[] = [];

    showNotis = false;

    mounted(): void {
        serverService.getInitData().then((data) => {
            this.me = data.me;
            if (this.me) {
                serverService.messaging.on('notis', this.onNotisChange, this);
                this.$nextTick(() => {
                    $(this.$refs.root).on('show.bs.dropdown', () => {
                        this.showNotis = true;
                    });
                });
            }

            if (serverService.isLocalTesting) {
                // setTimeout(() => {
                //     while (this.notis.length < 10) {
                //         this.notis.push(serverService.messaging.getTestNoti());
                //     }
                //     this.showNotis = true;
                // }, 1000);
            }
        });
    }

    private onNotisChange(notis: INotification[]): void {
        this.notis = notis;
    }

    markAllRead(): void {
        $('.btn-bell').dropdown('hide');
        serverService.messaging.markAllRead();
    }
}
