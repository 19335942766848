
import { Component, Vue, Watch } from 'vue-property-decorator';
import GameBox from './GameBox.vue';
import Pagination from '@/components/Pagination.vue';
import { RouteResolver, UpRouteData } from '@/utils/RouteResolver';
import { Location, Route } from 'vue-router';
import { serverService } from '@/services/serverService';
import { CLIENT_ORDER } from '@/services/clientService';
import { translate } from '@/filters/translate';
import { Constant } from '../../datas/Constant';
import LiteGames from './LiteGames.vue';
import { showSideAds } from '@/filters/mobile';
import AreaTitle from '@/components/AreaTitle.vue';
import AdsResponsive from '@/components/ads/AdsResponsive.vue';
import { IClient } from '@/vo/Client';

@Component({
    name: 'Games',
    components: {
        GameBox,
        Pagination,
        LiteGames,
        AreaTitle,
        AdsResponsive,
    },
    methods: {
        translate,
        showSideAds,
    },
})
export default class Games extends Vue implements RouteResolver {
    loading = false;
    gameData: IClient[] = [];
    maxPage = 0;
    page = -1;

    orderOptions = [CLIENT_ORDER.NEW, CLIENT_ORDER.RATE, CLIENT_ORDER.PPL];
    order = CLIENT_ORDER.NEW;
    beta = false;
    totalKey = '';
    showSideBeta = false;
    onlinePpls: { [key: string]: number } = {};
    onlinePplsLoader = {
        id: 0,
        nexttime: 0,
        interval: 30000,
    };
    isMounted = true;

    @Watch('$route', { immediate: true })
    onRouteChanged(to: Route): void {
        this.beta = !!to.name?.includes('_beta');
        this.order = CLIENT_ORDER.getByCode(to.params.order) || CLIENT_ORDER.NEW;
        this.loadPage(Number(to.params.page || 1));
        this.loadTotal();
        document.title = translate('localedTitle') + ':' + translate(this.beta ? 'game.betaGames' : 'game.allGames');
    }

    mounted(): void {
        const loader = this.onlinePplsLoader;
        this.reloadOnlinePpls = this.reloadOnlinePpls.bind(this);
        setTimeout(this.reloadOnlinePpls, loader.interval);
    }

    beforeDestroy(): void {
        this.isMounted = false;
    }

    reloadOnlinePpls(): void {
        if (this.isMounted) {
            const loader = this.onlinePplsLoader;
            this.loadOnlinePpl(this.gameData);
            setTimeout(this.reloadOnlinePpls, loader.interval);
            loader.interval += 5000;
        }
    }

    get pageSize(): number {
        return this.beta || this.order.mixBeta ? 30 : 28;
    }

    loadTotal(): void {
        if (this.order == CLIENT_ORDER.PPL) {
            this.totalKey = '';
            this.maxPage = 1;
        } else {
            let totalKey = 'beta' + this.beta;
            if (totalKey != this.totalKey) {
                this.totalKey = totalKey;
                serverService.client.getClientsTotal(this.beta).then((total) => {
                    if (totalKey == this.totalKey) {
                        this.maxPage = Math.ceil(total / this.pageSize);
                    }
                });
            }
        }
    }

    get minsPlayToReview(): number {
        return Constant.REVIEW_MIN_PLAY_MINS;
    }

    get routeResolver(): RouteResolver {
        return this;
    }
    getUpRoute(): UpRouteData | null {
        return null;
    }

    resolveRoute(params: { [key: string]: any }): Location {
        return {
            name: this.beta ? 'games_beta_page' : 'games_order_page',
            params: {
                page: (params.page || 1) + '',
                order: params.order || this.order.code,
            },
        };
    }

    loadPage(page: number): void {
        this.gameData = [];
        this.page = page;
        this.loading = true;
        const loader = this.onlinePplsLoader;
        loader.nexttime = 0;
        loader.id = 0;
        loader.interval = 30000;

        this.listClients().then((clients) => {
            this.loading = false;
            this.gameData = clients;
            this.loadOnlinePpl(clients);
        });
    }

    loadOnlinePpl(clients: IClient[]): Promise<void> {
        const loader = this.onlinePplsLoader;
        if (clients.length && Date.now() > loader.nexttime) {
            let loadId = ++loader.id;
            loader.nexttime = Date.now() + 30000;
            return serverService.client.listMsgPlayersCount(clients).then((data) => {
                for (let client of clients) {
                    this.onlinePpls[client.code] = data[client.code] || 0;
                }
                if (loadId == loader.id) {
                    this.$forceUpdate();
                }
            });
        } else {
            return Promise.resolve();
        }
    }

    getOnlinePpl(clientCode: string): number {
        return this.onlinePpls[clientCode] || 0;
    }

    private listClients(): Promise<IClient[]> {
        if (this.order == CLIENT_ORDER.PPL) {
            this.beta = false;
            return serverService.client.listMsgClients((this.page - 1) * this.pageSize, this.pageSize).then((data) => {
                const loader = this.onlinePplsLoader;
                loader.nexttime = Date.now() + 30000;

                if (this.page == 1 && !data.length) {
                    this.maxPage = 1;
                    return this._listClients();
                } else {
                    if (!data.length && this.page > 1) {
                        this.maxPage = Math.min(this.maxPage, this.page - 1);
                    } else if (data.length < this.pageSize) {
                        this.maxPage = this.page;
                    } else {
                        this.maxPage = this.page + 1;
                    }
                    return data
                        .filter((d) => {
                            let client = d.client;
                            return client.owner && client.pub && !client.blocked;
                        })
                        .map((d) => {
                            this.onlinePpls[d.client.code] = d.ppl;
                            return d.client;
                        });
                }
            });
        } else {
            return this._listClients();
        }
    }

    private _listClients(): Promise<IClient[]> {
        if (this.beta) {
            return serverService.client.listClientsByOrder(
                this.beta,
                this.order,
                (this.page - 1) * this.pageSize,
                this.pageSize
            );
        } else {
            return serverService.client.listClientsWithBetaByOrder(
                2,
                this.order,
                (this.page - 1) * this.pageSize,
                this.pageSize
            );
        }
    }
}
