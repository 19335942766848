
import { Component, Vue } from 'vue-property-decorator';
import { serverService } from '../../services/serverService';

@Component({})
export default class GmRedirect extends Vue {
    mounted(): void {
        if (serverService.isLocalTesting) {
            return;
        }
        window.location.href = serverService.generateServerUrl('/gm');
    }
}
