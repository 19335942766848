
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { serverService } from '../services/serverService';
import { translate } from '@/filters/translate';
import { getUserRoute, getUserUrl } from '@/filters/user-filters';
import noneLoginPhoto from '../assets/none_login_photo.png';
import LocaleMenuItem from '@/components/LocaleMenuItem.vue';
import { timestampToTimeMore } from '@/filters/timestampToString';
import { dialogService } from '@/services/dialogService';
import { IUser, InitData } from '@/vo/User';

@Component({
    components: {
        LocaleMenuItem,
    },
    methods: {
        translate,
        getUserRoute,
        getUserUrl,
        timestampToTimeMore,
    },
})
export default class RightNavBarUserManage extends Vue {
    me: IUser | null = null;

    data: InitData | null = null;

    @Prop({ default: false })
    appGM?: boolean; // if this is on app-gm (app-home otherwise)

    loginUrl = '/login';

    openSub = false;

    @Watch('$route', { immediate: true, deep: true })
    onRouteChange(): void {
        this.openSub = false;
    }

    toggleOpenSub(open: boolean): void {
        this.openSub = open;
    }

    @Emit('closeNavbar')
    closeNavbar(): void {}

    mounted(): void {
        this.loginUrl = serverService.generateServerUrl('/login');
        serverService.getInitData().then((data) => {
            this.me = data.me;
            this.data = data;
        });
    }

    showLockedReason(): void {
        dialogService.alert(
            {
                title: translate('locked.reasonTitle'),
                titleIcon: 'fas fa-lock',
                body: this.data!.suspendedReason,
            },
            {
                okText: translate('btn.close'),
                cancelText: '',
            }
        );
    }

    get isSiteGM(): boolean {
        return serverService.isGM();
    }

    get userIconUrl(): string {
        return this.me ? this.me.iconUrl : noneLoginPhoto;
    }

    get gmUrl(): string {
        return serverService.generateServerUrl('/gm');
    }

    get logoutUrl(): string {
        return serverService.generateServerUrl('/logout_options');
    }

    onBtnLogin(): void {
        this.loginUrl = serverService.generateLoginUrl(this.$route.fullPath);
    }
}
