import { render, staticRenderFns } from "./InputBox.vue?vue&type=template&id=78d2b684&scoped=true"
import script from "./InputBox.vue?vue&type=script&lang=ts"
export * from "./InputBox.vue?vue&type=script&lang=ts"
import style0 from "./InputBox.vue?vue&type=style&index=0&id=78d2b684&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78d2b684",
  null
  
)

export default component.exports