
import { Component, Vue, Watch } from 'vue-property-decorator';
import CssPreloader from '@/components/CssPreloader.vue';
import RootTabs from '../users/components/RootTabs.vue';
import AdsResponsive from '@/components/ads/AdsResponsive.vue';
import Teamship from '../users/components/Teamship.vue';
import TeamList from './components/TeamList.vue';
import { serverService } from '@/services/serverService';
import { translate } from '@/filters/translate';
import { errorToString } from '@/filters/content-filters';
import { appUser_translation_en } from '@/filters/trans/app-user.en';
import { appUser_translation_zh } from '@/filters/trans/app-user.zh';
import { dialogService } from '@/services/dialogService';
import { showSideAds } from '@/filters/mobile';
import { Route } from 'vue-router';
import { ITeam, ITeamship, IUser } from '@/vo/User';
import { FileUtil } from '../../utils/FileUtil';
import { FriendshipStatus, TeamshipSetting } from '../../datas/FriendshipStatus';
import { numberWithCommas } from '../../filters/number-filters';
import { IPurchaseTeamItem } from '@/vo/PayProduct';
appUser_translation_en;
appUser_translation_zh;

declare const $: any;

@Component({
    name: 'TeamRoot',
    components: {
        AdsResponsive,
        CssPreloader,
        Teamship,
        RootTabs,
        TeamList,
    },
    methods: {
        translate,
        showSideAds,
    },
})
export default class TeamRoot extends Vue {
    public $refs!: Vue['$refs'] & {
        teamname: HTMLDivElement;
    };

    allowCreateTeam = true;

    gltDollarIcon = serverService.generateServerUrl('/img/store/glt_dollar.png');
    gltPointIcon = serverService.generateServerUrl('/img/store/glt_point.png');

    loading = false;
    teamship: ITeamship | null = null;

    me: IUser | undefined;
    myTeamship: ITeamship | undefined;

    editIcon: {
        base64: string;
        loading: boolean;
    } | null = null;

    editTeam: {
        key: string;
        value: string;
        allowEmpty: boolean;
        loading: boolean;
    } | null = null;

    editFields = [
        {
            key: 'nickname',
            allowEmpty: false,
            default: '',
        },
    ];

    tabs: string[] = [];
    currentTab = '';

    get team(): ITeam {
        return (this.teamship && this.teamship.team)!;
    }
    isMine(): boolean {
        const myTeamship = this.myTeamship;
        return !!(myTeamship && this.teamship && myTeamship.team?.username == this.teamship.team?.username);
    }
    get teamIconUrl(): string {
        if (this.team) {
            if (this.editIcon) {
                return this.editIcon.base64;
            }
            return this.team.iconUrl;
        } else {
            return '';
        }
    }
    isEditor(): boolean {
        return this.isMine() && !!(this.myTeamship!.ship.settings! & TeamshipSetting.EDITOR);
    }
    isLeader(): boolean {
        return this.isMine() && this.myTeamship?.ship.settings == TeamshipSetting.LEADER;
    }
    isInvestor(): boolean {
        return this.isMine() && !!(this.myTeamship!.ship.settings! & TeamshipSetting.INVEST);
    }

    mounted(): void {
        this.refresh();
    }

    onTeamshipChanged(): void {
        this.refresh();
    }

    @Watch('$route')
    onRouterChange(to: Route, from: Route): void {
        if (to.params.teamname != from.params.teamname) {
            this.refresh();
        } else {
            this.refreshTabs(this.team!);
        }
    }

    private refresh(): void {
        this.loading = true;
        let paramTeamname = this.$route.params.teamname || '';
        if (this.teamship && paramTeamname.toLowerCase() != this.teamship.team?.teamname.toLowerCase()) {
            this.teamship = null;
        }

        serverService.getInitData().then((initData) => {
            this.me = initData.me;
            let teamname = this.$route.params.teamname;

            if (teamname) {
                Promise.all([serverService.team.getMyOwnTeamship(this.me), this.loadTeam(teamname)])
                    .then(([myteamship, teamship]) => {
                        if (myteamship && myteamship.ship.status == FriendshipStatus.ACCEPTED.code) {
                            this.myTeamship = myteamship;
                        } else {
                            this.myTeamship = undefined;
                        }
                        this.loading = false;
                        this.teamship = teamship;
                        this.refreshTabs(this.team!);
                    })
                    .catch(() => {
                        if (this.myTeamship && teamname == this.myTeamship.team?.teamname) {
                            // some error on my team, go to personal profile
                            this.$router.replace({
                                name: 'profile',
                            });
                        } else if (serverService.hasLoggedIn()) {
                            this.$router.replace({
                                name: 'team_profile',
                            });
                        } else {
                            this.$router.replace({
                                name: 'home',
                            });
                        }
                    });
            } else {
                serverService.team.getMyOwnTeamship(this.me).then((myteamship) => {
                    this.loading = false;

                    if (myteamship && myteamship.ship.status == FriendshipStatus.ACCEPTED.code) {
                        this.myTeamship = myteamship;
                    } else {
                        this.myTeamship = undefined;
                    }
                    if (this.myTeamship) {
                        this.$router.replace({
                            name: 'team',
                            params: { teamname: this.myTeamship.team!.teamname },
                        });
                    } else if (!serverService.hasLoggedIn()) {
                        this.$router.replace({
                            name: 'home',
                        });
                    } else {
                        document.title = translate('localedTitle');
                    }
                });
            }
        });
    }

    private refreshTabs(user?: IUser): void {
        let defaultTab = 'team_about';
        if (user) {
            if (!user.guest) {
                this.tabs = ['team_board', 'team_about', 'team_members'];
                if (this.isMine()) {
                    this.tabs.splice(1, 0, 'team_records');
                }
            } else {
                this.tabs = [defaultTab];
            }
            let initTab = this.$router.currentRoute.name;
            if (initTab?.startsWith('team_board')) {
                initTab = 'team_board';
            }

            if (initTab && this.tabs.includes(initTab)) {
                this.currentTab = initTab;
            } else {
                this.currentTab = defaultTab;
            }
            document.title = user.nickname + ':' + translate('tab.' + this.currentTab);
        } else {
            this.$router.replace({
                name: 'home',
            });
        }
    }

    private loadTeam(teamname: string): Promise<ITeamship> {
        return serverService.team.getMyTeamship(teamname).then((result) => {
            let teamship = result.teamship;
            if (!teamship || teamship.team?.teamname != result.team.teamname) {
                teamship = {
                    ship: {
                        acceptor: result.team,
                        requester: this.me || { username: '', nickname: '', guest: true, iconUrl: '' },
                        status: FriendshipStatus.NONE.code,
                        createTime: 0,
                        confirmTime: 0,
                    },
                    team: result.team,
                };
            }
            return teamship;
        });
    }

    btnCreateTeam(cachedResult?: { teamname: string; result: IPurchaseTeamItem; expire: number }): void {
        let loading = dialogService.loading();
        let promise =
            cachedResult && Date.now() < cachedResult.expire
                ? Promise.resolve(cachedResult.result)
                : serverService.team.getCreateTeamItem();
        promise
            .then((result) => {
                loading.close();

                let lines: string[] = [];
                let usePoints = 0;
                let useDollars = 0;
                let needDeposit = false;
                /*if (!result.abandonedTeam) {
                    lines.push(translate('create-team-free-during-test'));
                } else */
                if (result.uitem) {
                    lines.push(translate('create-team-already-have-item'));
                } else if (result.wallet) {
                    lines.push(
                        translate('create-team-need-fund'),
                        `<div class="dollarAmount"><img src="${this.gltDollarIcon}"/> ${numberWithCommas(
                            result.item.priceInGltDollar
                        )}</div>`,
                        '',
                        translate('your-fund'),
                        `<div class="dollarAmount"><img src="${this.gltDollarIcon}"/> ${numberWithCommas(
                            result.wallet.dollars
                        )}</div>`,
                        `<div class="dollarAmount"><img src="${this.gltPointIcon}"/> ${numberWithCommas(
                            result.wallet.points
                        )}</div>`
                    );
                    needDeposit = result.item.priceInGltDollar > result.wallet.dollars + result.wallet.points;
                    if (!needDeposit) {
                        let cost = result.item.priceInGltDollar;
                        usePoints = Math.min(result.wallet.points, cost);
                        cost -= usePoints;
                        useDollars = cost;
                    }
                } else {
                    throw new Error('Something went wrong.');
                }

                dialogService
                    .input(
                        {
                            titleIcon: 'fas fa-flag',
                            title: translate('label.create-team'),
                            body: translate('input-teamname'),
                        },
                        {
                            defaultValue: cachedResult && cachedResult.teamname,
                            customClass: needDeposit ? 'danger' : 'normal',
                            inputTip: '(' + translate('teamname-rules') + ')',
                            okText: translate(needDeposit ? 'btn.depositBeforeBuy' : 'btn.create-team'),
                            cancelText: translate('btn.cancel'),
                            body2: lines.join('<br />'),
                            ok: () => {
                                if (needDeposit) {
                                    this.$router.push({ name: 'deposit' });
                                }
                            },
                        }
                    )
                    .then((teamname) => {
                        if (teamname && !needDeposit) {
                            if (teamname.match(/^[\w_-]+$/) || 1 > 0) {
                                loading = dialogService.loading();
                                serverService.team
                                    .createTeam(teamname, {
                                        dollars: useDollars,
                                        points: usePoints,
                                    })
                                    .then((teamship) => {
                                        this.$router.push({ name: 'team', params: { teamname: teamship.team!.teamname } });
                                    })
                                    .catch((err) => {
                                        dialogService.error({ body: errorToString(err) }).then(() => {
                                            this.btnCreateTeam({
                                                teamname: teamname,
                                                result: result,
                                                expire: Date.now() + 60000,
                                            });
                                        });
                                    })
                                    .finally(() => {
                                        loading.close();
                                    });
                            } else {
                                dialogService
                                    .error({
                                        body: translate('teamname-rules'),
                                    })
                                    .then(() => {
                                        this.btnCreateTeam({
                                            teamname: '',
                                            result: result,
                                            expire: Date.now() + 60000,
                                        });
                                    });
                            }
                        }
                    });
            })
            .catch((err) => {
                loading.close();
                dialogService.error({ body: errorToString(err) });
            });
    }
    //
    startEditIcon(): void {
        let input = document.createElement('input');
        input.type = 'file';
        input.accept = '.gif,.png,.jpg';
        input.click();

        input.onchange = (e) => {
            const el = e.target as HTMLInputElement;
            if (el.files && el.files.length) {
                FileUtil.convertFileToBase64(el.files[0])
                    .then((content) => FileUtil.ratioDataUrl(content, 300, 0.6, 'image/png'))
                    .then((dataUrl) => {
                        this.editIcon = {
                            base64: dataUrl,
                            loading: false,
                        };
                    });
            }
        };
    }

    doneEditIcon(save: boolean): void {
        if (save && this.editIcon) {
            let editIcon = this.editIcon!;
            editIcon.loading = true;
            serverService.team
                .saveTeam(this.team!, {
                    icon: editIcon.base64,
                })
                .then(() => {
                    this.editIcon = null;
                })
                .catch((err) => {
                    this.editIcon = null;
                    dialogService.error({ body: errorToString(err) });
                });
        } else {
            this.editIcon = null;
        }
    }
    startEditTeam(key: string, allowEmpty: boolean): void {
        if (!this.editTeam && this.isMine()) {
            this.editTeam = {
                key: key,
                value: this.team![key],
                allowEmpty: allowEmpty,
                loading: false,
            };
        }
    }
    doneEditTeam(save: boolean): void {
        let editTeam = this.editTeam;
        if (editTeam && save && this.isMine()) {
            editTeam.value = editTeam.value.trim();
            if (editTeam.allowEmpty || editTeam.value) {
                let data: any = {};
                data[editTeam.key] = editTeam.value;
                editTeam.loading = true;
                serverService.team
                    .saveTeam(this.team!, data)
                    .then(() => {
                        this.editTeam = null;
                    })
                    .catch((err) => {
                        editTeam!.loading = false;
                        this.editTeam = editTeam;
                        dialogService.error({ body: errorToString(err) });
                    });
            }
        } else {
            this.editTeam = null;
        }
    }

    copyTeamname(): void {
        if (window.getSelection()) {
            const range = document.createRange();
            range.selectNode(this.$refs.teamname);
            window.getSelection()!.removeAllRanges();
            window.getSelection()!.addRange(range);
            document.execCommand('Copy');
            window.getSelection()!.removeAllRanges();

            $(this.$refs.teamname).tooltip('show');
            setTimeout(() => {
                $(this.$refs.teamname).tooltip('hide');
            }, 1000);
        }
    }

    btnTeamSize(): void {
        let loading = dialogService.loading();
        serverService.team
            .setTeamSizeDry(this.team)
            .then((result) => {
                loading.close();
                let search = result.item.code.match(/(\d+)$/);
                let size = search ? Number(search[1]) : 0;
                if (size < this.team.size) {
                    throw new Error('bad team size');
                }
                let lines: string[] = [];
                let usePoints = 0;
                let useDollars = 0;
                let needDeposit = false;
                if (result.uitem) {
                    lines.push(translate('enlarge-team-already-have-item', { size: size }));
                } else if (result.wallet) {
                    lines.push(
                        translate('enlarge-team-need-fund', { size: size }),
                        `<div class="dollarAmount"><img src="${this.gltDollarIcon}"/> ${numberWithCommas(
                            result.item.priceInGltDollar
                        )}</div>`,
                        '',
                        translate('your-fund'),
                        `<div class="dollarAmount"><img src="${this.gltDollarIcon}"/> ${numberWithCommas(
                            result.wallet.dollars
                        )}</div>`,
                        `<div class="dollarAmount"><img src="${this.gltPointIcon}"/> ${numberWithCommas(
                            result.wallet.points
                        )}</div>`
                    );
                    needDeposit = result.item.priceInGltDollar > result.wallet.dollars + result.wallet.points;
                    if (!needDeposit) {
                        let cost = result.item.priceInGltDollar;
                        usePoints = Math.min(result.wallet.points, cost);
                        cost -= usePoints;
                        useDollars = cost;
                    }
                } else {
                    throw new Error('Something went wrong.');
                }
                dialogService.alert(
                    {
                        titleIcon: 'fas fa-flag',
                        title: translate('enlarge-team'),
                        body: lines.join('<br/>\n'),
                    },
                    {
                        html: true,
                        okText: translate(needDeposit ? 'btn.depositBeforeBuy' : 'btn.confirm'),
                        cancelText: translate('btn.cancel'),
                        customClass: needDeposit ? 'danger' : 'normal',
                        ok: () => {
                            if (needDeposit) {
                                this.$router.push({ name: 'deposit' });
                            } else {
                                loading = dialogService.loading();
                                serverService.team
                                    .setTeamSize(this.team, size, {
                                        points: usePoints,
                                        dollars: useDollars,
                                    })
                                    .then(() => {
                                        loading.close();
                                    })
                                    .catch((err) => {
                                        loading.close();
                                        dialogService.error({ body: errorToString(err) });
                                    });
                            }
                        },
                    }
                );
            })
            .catch((err) => {
                loading.close();
                dialogService.error({ body: errorToString(err) });
            });
    }
}
