var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{ closed: !_vm.isOpen, 'rightNavbar-root': true }},[_c('div',{staticClass:"mask",on:{"click":function($event){return _vm.toggleNavBar(false)}}}),_c('div',{staticClass:"navbar-root"},[_c('div',{staticClass:"menu"},[_c('div',{class:{ menuTab: true, userTab: true }},[_c('RightNavBarUserManage',{on:{"closeNavbar":function($event){return _vm.toggleNavBar(false)}}})],1),_c('div',{staticClass:"divider"}),_c('div',{class:{ menuTab: true, currentTab: _vm.routeName == 'games' }},[_c('router-link',{attrs:{"to":{ name: 'games' }}},[_c('i',{staticClass:"fas fa-gamepad"}),_vm._v(" "+_vm._s(_vm.translate('links.games'))+" ")])],1),_c('div',{class:{
                    menuTab: true,
                    currentTab: _vm.routeName == 'forums',
                }},[_c('router-link',{attrs:{"to":{ name: 'forums' }}},[_c('i',{staticClass:"fas fa-comments"}),_vm._v(" "+_vm._s(_vm.translate('links.forums'))+" ")])],1),_c('div',{class:{
                    menuTab: true,
                    currentTab: _vm.routeName == 'deposit',
                }},[_c('router-link',{attrs:{"to":{ name: 'deposit' }}},[_c('i',{staticClass:"fas fa-bolt"}),_vm._v(" "+_vm._s(_vm.translate('links.recharge'))+" ")])],1),_c('div',{class:{ menuTab: true, currentTab: _vm.routeName == 'blog' }},[_c('router-link',{attrs:{"to":{ name: 'blog' }}},[_c('i',{staticClass:"fab fa-blogger-b"}),_vm._v(" "+_vm._s(_vm.translate('links.gameletBlog.tab'))+" ")])],1),_c('div',{class:{
                    menuTab: true,
                    currentTab: _vm.routeName == 'coding',
                }},[_c('router-link',{attrs:{"to":{ name: 'coding' }}},[_c('i',{staticClass:"fas fa-code-branch"}),_vm._v(" "+_vm._s(_vm.translate('links.codeGamelet.tab'))+" ")])],1),_c('div',{staticClass:"divider"}),_c('div',{class:{
                    menuTab: true,
                    currentTab: _vm.routeName == 'about_us',
                }},[_c('router-link',{attrs:{"to":{ name: 'about_us' }}},[_c('i',{staticClass:"fas fa-users"}),_vm._v(" "+_vm._s(_vm.translate('links.aboutUs'))+" ")])],1),_c('div',{class:{
                    menuTab: true,
                    currentTab: _vm.routeName == 'history',
                }},[_c('router-link',{attrs:{"to":{ name: 'history' }}},[_c('i',{staticClass:"fas fa-book-reader"}),_vm._v(" "+_vm._s(_vm.translate('links.memorabilia'))+" ")])],1),_c('div',{class:{
                    menuTab: true,
                    currentTab: _vm.routeName == _vm.getSiteReportForumRouteName(),
                }},[_c('router-link',{attrs:{"to":_vm.getSiteReportForumRoute()}},[_c('i',{staticClass:"fas fa-hands-helping"}),_vm._v(" "+_vm._s(_vm.translate('links.problemReport'))+" ")])],1)]),_c('div',{staticClass:"spacer"}),_c('div',{staticClass:"rules-row"},[_c('a',{attrs:{"href":"/terms_of_service"}},[_vm._v(" "+_vm._s(_vm.translate('links.userTerms')))]),_c('br'),_c('a',{attrs:{"href":"/privacy_policy"}},[_vm._v(" "+_vm._s(_vm.translate('links.privacyPolicy')))])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }