
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getClientCoverBgImg, getClientForumRoute } from '@/filters/game-filters';
import { translate } from '@/filters/translate';
import GameLinksButton from '@/components/GameLinksButton.vue';
import RateStars from '@/components/RateStars.vue';
import { IClient } from '@/vo/Client';
import { timestampToDate } from '../../filters/timestampToString';

@Component({
    components: {
        GameLinksButton,
        RateStars,
    },
    methods: {
        timestampToDate,
        getClientForumRoute,
        getClientCoverBgImg,
        translate,
    },
})
export default class GameEventBox extends Vue {
    @Prop()
    item!: IClient;

    getClientRate(client: IClient): number {
        return (client.rate && client.rate.avg) || 0;
    }
    getClientVotes(client: IClient): number {
        return (client.rate && client.rate.votes) || 0;
    }
    get ppls(): number {
        const rate = this.item.rate!;
        return rate.votes;
    }
    get date(): string {
        const rate = this.item.rate!;
        return timestampToDate(rate.start * 1000);
    }
}
