
import { Component, Vue } from 'vue-property-decorator';
import ForumDashboard from './ForumDashboard.vue';
import GameSuggest from './GameSuggest.vue';
import GameNews from '@/forum/news/GameNews.vue';
import AdsResponsive from '@/components/ads/AdsResponsive.vue';
import { showSideAds } from '@/filters/mobile';

@Component({
    methods: {
        showSideAds,
    },
    components: {
        GameNews,
        GameSuggest,
        ForumDashboard,
        AdsResponsive,
    },
})
export default class Main extends Vue {}
