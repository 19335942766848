import { ResourceCategory } from '../datas/ResourceCategory';
import { IResource } from '@/vo/Resource';
import { IUser } from '@/vo/User';
import { AbstractSubService } from './abstractSubService';

export class ResourceService extends AbstractSubService {
    public addResource(user: IUser, category: ResourceCategory, base64: string): Promise<IResource> {
        return this.api('/add/resource', {
            user: user.username,
            category: category.code,
            base64: base64,
        }).then((json) => json.resource);
    }

    public setResourceHidden(resource: IResource, hidden: boolean, note: string): Promise<IResource> {
        return this.api('/set/resource/hidden', {
            rid: resource.id,
            hidden: !!hidden,
            note: note || '',
        }).then((json) => {
            resource.hidden = json.resource;
            return json.resource;
        });
    }

    public getResourcesTotalByUser(clientCode: string, username: string, categories: ResourceCategory[]): Promise<number> {
        return this.api('/get/resources_total/user/' + username + '/' + clientCode, {
            categories: categories.map((cat) => cat.code),
        }).then((json) => json.total);
    }

    public listResourcesByUser(
        clientCode: string,
        username: string,
        categories: ResourceCategory[],
        start: number,
        length: number
    ): Promise<IResource[]> {
        return this.api('/list/resources/user/' + username + '/' + clientCode, {
            categories: categories.map((cat) => cat.code),
            start: start,
            length: length,
        }).then((json) => json.list);
    }

    public getResourcesTotalByCategory(clientCode: string, category: ResourceCategory): Promise<number> {
        return this.api('/get/resources_total/category/' + category.code + '/' + clientCode, {}).then((json) => json.total);
    }

    public listResourcesByCategory(
        clientCode: string,
        category: ResourceCategory,
        start: number,
        length: number
    ): Promise<IResource[]> {
        return this.api('/list/resources/category/' + category.code + '/' + clientCode, {
            start: start,
            length: length,
        }).then((json) => json.list);
    }

    public getCoverImageResource(clientCode: string, category: ResourceCategory): Promise<IResource> {
        return this.api('/get/cover_image/' + clientCode + '/' + category.code, {}).then((json) => json.data);
    }

    // for gm
    public getResourcesTotalByClient(clientCode: string, includeApproved: boolean, includeRejected: boolean): Promise<number> {
        return this.api('/get/resources_total/client/' + clientCode, {
            includeApproved: includeApproved ? 1 : 0,
            includeRejected: includeRejected ? 1 : 0,
        }).then((json) => json.total);
    }

    public listResourcesByClient(
        clientCode: string,
        includeApproved: boolean,
        includeRejected: boolean,
        start: number,
        length: number
    ): Promise<IResource[]> {
        return this.api('/list/resources/client/' + clientCode, {
            includeApproved: includeApproved ? 1 : 0,
            includeRejected: includeRejected ? 1 : 0,
            start: start,
            length: length,
        }).then((json) => json.list);
    }

    public rejectResource(resource: IResource, note: string): Promise<IResource> {
        return this.api('/reject/resource', {
            rid: resource.id,
            note: note || '',
        }).then((json) => {
            Object.assign(resource, json.resource);
            resource.hidden = json.resource.hidden;
            return json.resource;
        });
    }
    public unrejectResource(resource: IResource, note: string): Promise<IResource> {
        return this.api('/unreject/resource', {
            rid: resource.id,
            note: note || '',
        }).then((json) => {
            resource.rejected = json.resource.rejected;
            resource.hidden = json.resource.hidden;
            return json.resource;
        });
    }
}
