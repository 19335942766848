import { importTranslation } from '../translate';

importTranslation('en-US', {
    forum: {
        board: {
            root: {
                title: 'Forums',
                latestArticles: 'Latest Articles',
                latestArticlesShort: 'Latest',
                postReminderTitle: 'Posts that will be removed by a moderator:',
                postReminderEnd: 'Please help us to keep unsuitable content out of the forums.',
                postReminder: [
                    'Contains “offensive” posts, links or images',
                    'Contains copyright-infringing material',
                    'Contains only scrap contents',
                    'Contains too many typos and content that is hardly understandable',
                    'Contains articles that teach how to cheat in games',
                    'Contains anything that is against <a href="/terms_of_service" target="_blank">term of use</a> or <a href="{url}" target="_blank">Forum Rules</a>',
                ],
            },
            cover: {
                author: 'Author',
            },
            GameletChat: {
                tab: 'Gossip',
                title: {
                    forum: 'Gossip Square',
                },
                desc: 'A place to chat in any topic and interest. All are welcome.',
            },
            GameletChat2: {
                tab: 'Gossip2',
                title: {
                    forum: 'Gossip Second',
                },
                desc: 'A place to chat in any topic and interest. All are welcome.',
            },
            games: {
                tab: 'Games',
                title: {
                    forum: 'Games Discussion',
                },
                desc: 'Sharing knowledge, funny pictures, anything about the games you love.',
            },
            GameletGallery: {
                tab: 'Gallery',
                title: {
                    forum: 'Gamelet Gallery',
                },
                desc: 'Sharing drawings, videos, music of your own to light up your beloved games.',
            },
            GameletOnline: {
                title: {
                    forum: 'Gamelet GM Discuss',
                    report: 'Gamelet Support',
                    news: 'Announcements',
                },
            },
            GameletPress: {
                title: {
                    forum: 'Gamelet Press',
                    report: 'Write for Gamelet',
                    news: 'Bulletin',
                    contribute: 'Gamelet Contribute',
                },
            },
        },
        alert: {
            emptyTitle: 'The title is empty!',
            emptyContent: 'The content is empty!',
            membersOnly: 'This is a private forum. Members only.',
            uploadDialog: {
                title: 'Contribute to {category}',
            },
            loadingDialog: {
                text: 'Uploading',
            },
        },
        btn: {
            enterForum: 'Enter',
            enterGossip: 'Back to Main Stream',
            enterGossip2: 'Another Gossip Square',
            post: 'Post Article',
            determinePost: 'Send',
            cancel: 'Cancel',
            reply: 'Reply',
            submitPost: 'Submit',
            submitReply: 'Submit',
            continueReading: 'Continue reading',
            leaveMessage: 'Leave Message',
            confirmLeaveMessage: 'Submit Message',
            view: 'VIEW',
            loadPrev: 'Load earlier messages',
            manage: 'Manage',
            to_cool: 'Cool Down',
            to_warm: 'Warm Up',
            to_open: 'Mark Open',
            to_close: 'Mark Private',
            to_dialog: 'Mark Dialog',
            to_removed: 'Remove',
            to_open_2: 'Open Article',
            to_close_2: 'Close Article',
            to_markTop: 'Mark Sticky',
            to_cancelTop: 'Remove Sticky',
            to_markLock: 'Lock Reply',
            to_cancelLock: 'Unlock Reply',
            to_gossip2: 'Move to Gossip2',
            to_gossip: 'Move back to Gossip',
            enterBeta: 'Games in Beta',
            enterAllGames: 'Enter All Games',
            seeMore: 'More',
            seeLess: 'Less',
            backTo: 'Back to {name}',
        },
        top: 'Sticky',
        boardTopDesc: 'Stick the message on top of the board. Current top-message will be back to normal.',
        boardRemoveTopDesc: 'Unmark the Sticky from the message.',
        locked: 'Reply to this article is locked',
        moveUp: 'Move Up',
        moveDown: 'Move Down',
        elevator: 'Elevator',
        floor: 'floor',
        markFloor: 'Mark Floor',
        closed: 'Closed',
        postClosedTitle: 'This post has been closed',
        rootPostClosed: 'Original post is closed',
        postNotViewableLabel: 'private',
        postNotViewable: 'The content is private',
        noMessageLeft: 'No one has found this place...',
        gmCloseArticle: 'Close this post',
        gmOpenArticle: 'Open this post',
        gmMoveTo_GameletChat: 'Move to Gossip Main',
        gmMoveTo_GameletChat2: 'Move to Gossip 2nd',
        gmReportStatus: 'Set Report Status => {status}',
        gmNote: 'Notes',
        thisIsPrivateMsg: 'This is a private message.',
        thisIsRemovedMsg: 'This message is removed.',
        lastReply: 'last replied',
        label: {
            title: 'Title',
            noData: 'There are no posts yet...',
            wrong: 'Hmm~ Something went wrong while loading the article...',
            poster: 'Poster',
            track: {
                category: 'Category',
                forum: 'Game Forum',
                report: 'Game Report',
                contribute: 'Contribute',
                userBoard: 'User Board',
                friendship: 'Friendship',
                teamship: 'Guildship',
            },
            more: {
                forum: 'The article got a new reply',
                report: 'The report got new response',
                userBoard: 'There is new response',
            },
        },
        placeholder: {
            article: 'Content...',
            reply: 'Reply the message...',
        },
        replyConfirm: {
            title: 'About to reply',
            btnConfirm: 'Confirm',
            btnCancel: 'Cancel',
            posting: 'Replying...',
        },
        postConfirm: {
            title: 'About to post',
            btnConfirm: 'Confirm',
            btnCancel: 'Cancel',
            posting: 'Posting...',
        },
        states: {
            open: {
                option: 'Open',
                title: 'Open Message',
                desc: 'An Open Message allows all players to view and reply the content。',
            },
            dialog: {
                option: 'Dialog',
                title: 'Dialog Message',
                desc:
                    'A Dialog Message can be viewed by other players, but only the two users of the dialog can reply the content.',
            },
            close: {
                option: 'Private',
                title: 'Private Message',
                desc: 'A Private Message can only be viewed and replied by the two users of the dialog.',
            },
            removed: {
                option: 'Removed',
                title: 'Removed Message',
                desc: 'A Removed Message will never be seen again.',
            },
        },
        userAction: {
            open: 'Mark Open',
            close: 'Mark Private',
            dialog: 'Mark Dialog',
            removed: 'Remove the message',
        },
        watch: {
            follow: 'Follow',
            re_follow: 'Follow',
            un_follow: 'Stop following',
            following: 'Following',
            not_following: 'Not following',
            stopped_following: 'Stopped following',
        },
    },
    news: {
        noNews: 'No News or Update',
        title: {
            allGames: 'Game News',
            aGame: 'News',
            postTips: 'ATTENTION',
            dates: 'Week of',
        },
        btn: {
            post: 'Add News/Updates',
            submitPost: 'Submit News',
        },
        label: {
            title: 'News Title',
            noData: 'There are no news or updates yet',
        },
        placeholder: {
            content: 'News details...',
        },
        postTips: [
            'The <b>News Title</b> is what most players see. Please make it short and clear.',
            'All news and updates within the week will be compiled into one News article. Please post only <b>ONE update at a time</b>.',
        ],
    },
    progress: {
        title: 'Progress',
        editTime: 'Updated {time}',
        inputDesc: {
            title: 'Item Title',
            desc: 'Item Desc',
            progress: 'Current Progress',
            total: 'Total Progress',
        },
        btn: {
            addProgress: 'Add Progress',
        },
        label: {
            deleteTitle: 'Delete Progress',
        },
        alert: {
            total: 'Total progress must be greater than 0',
            progress: 'Current progress must be greater than 0',
            progressAndTotal: 'Current progress needs to be less than Total progress',
            noTitle: 'The title cannot be blank.',
        },
    },
    report: {
        selectFormat: 'Please select a category to report',
        name: {
            articleAbuse: 'Report of violations of speech or article',
            playerAbuse: 'Game violation report (accelerator, swiping scores)',
            gameBug: 'Login barriers, game bugs',
            gmAbuse: 'Appeals of improper handling by game administrators',
            siteBug: 'Website bugs',
            gameAbuse: 'Report Game Abuse',
        },
        btn: {
            post: 'Report Issue',
            submitPost: 'Submit',
        },
        label: {
            title: 'Title',
            targetID: 'Account ID',
            abuseTime: 'Date/Time',
            clientOrUrl: 'Game URL or Forum URL',
            client: 'Game URL',
            siteUrl: 'Which page(please leave the URL)',
            abuseDesc: 'Violations description',
            abuseVideo: 'Video evidence link',
            abuseReason: 'Reason',
            playerID: 'Player ID',
            bugTime: 'Date/Time',
            bugTiming: 'The timing/mode/screen when the bug happened',
            bugDesc: 'Bug description',
            bugRepeatable: 'Did the bug repeat after refreshing the game page',
            bugError: 'Paste the Error message if any',
            gmID: 'GM account(ID/Name or Unknown)',
            gmTime: 'Date/Time',
            gmTargetID: 'The player id that was handled by the GM',
            gmDesc: 'Detail',
            noData: 'There are no reports',
        },
        states: {
            open: {
                option: 'Public',
                title: 'Public Report',
                desc: '',
            },
            dialog: {
                option: 'Private',
                title: 'Private Report',
                desc: '',
            },
        },
        status: {
            open: {
                option: 'Open',
                desc: '',
            },
            done: {
                option: 'Done',
                desc: '',
            },
            passover: {
                option: 'Pass over',
                desc: '',
            },
        },
    },
    contribute: {
        btn: {
            post: 'Write Article',
            postImg: 'Submit Cover',
            submitPost: 'Submit',
            closeSubject: 'Close',
            openSubject: 'Open',
        },
        label: {
            title: 'Title',
            noData: 'There are no articles yet...',
            postImg: 'Contribute',
            viewAll: 'View all',
            viewThisPeriod: 'View this period',
        },
        message: {
            subjectClosed: 'Contribution closed on {date}',
        },
        states: {
            open: {
                option: 'Public',
                title: 'Public Article',
                desc: '',
            },
            dialog: {
                option: 'Private',
                title: 'Private Article',
                desc: '',
            },
        },
    },
    review: {
        btn: {
            post: 'Write a review',
            submitPost: 'Submit',
            viewHistory: 'view earlier reviews',
            loadMore: 'load more',
        },
        label: {
            title: 'One line summary',
            noData: 'No review yet...',
            cantReview: 'Not able to write a review',
            rate: 'Rate: {rate}',
            rateAvg: 'Rate: {rate}, Votes: {votes}',
            history: 'Earlier Reviews',
        },
        message: {
            played: 'Played',
            needPlayToReview: 'You need to play this game for {mins} minutes or more in order to write a review.',
            havePlayedOneMinute: 'You have played about one minute',
            havePlayedMinutes: 'You have played {mins} minutes so far',
            havePlayedAlmostMinutes: 'You have played almost {mins} minutes',
            rate_0: '* Rate the game carefully',
            rate_1: '1 star : Not playable / too buggy',
            rate_2: '2 stars: Difficult to understand how to play or what the fun is',
            rate_3: '3 stars: Despite some glitches, there are nice concept/story/controls in the game.',
            rate_4: '4 stars: Well implemented, an addictive little game.',
            rate_5: '5 stars: One of the best games! I am a fan!',
        },
        alert: {
            emptyRate: 'Please select a rating for this game.',
        },
        forceEditTrue: {
            title: 'Update your review',
            message: {
                lessOneDay:
                    'The last review of yours is less than a day away, so you are about to overwrite the content of your last review.',
                sameContent: 'The content is the same as your last review，so you are about to overwrite your last review.',
            },
            btnConfirm: 'Confirm Update',
        },
        forceEditFalse: {
            title: 'Post a new review',
            message: {
                any: 'You are about to post a new review.',
            },
            btnConfirm: 'Confirm',
        },
        edit: {
            title: 'Edit or New',
            message:
                'The content is a bit similar to the content of your last review. Do you want to overwrite your last review or post a new one?',
            btn: {
                new: 'New review',
                edit: 'Edit my last review',
            },
        },
    },
    userBoard: {
        states: {
            open: {
                option: 'Public',
                title: 'Public Message',
                desc: 'A public message allow anybody to join the chat.',
            },
            dialog: {
                option: 'Dialog',
                title: 'Dialog Message',
                desc: 'A dialog message is viewable for everyone, but only the two people of the dialog can reply.',
            },
            close: {
                option: 'Private',
                title: 'Private Message',
                desc: 'A private message is only viewable to the two people of the dialog.',
            },
            removed: {
                option: 'Removed',
                title: 'Removed Message',
                desc: 'A removed message can never be read.',
            },
        },
        label: {
            boardSetting: 'Board Setting',
        },
        btn: {
            showBlacklist: 'Show my blacklist',
        },
        rootState: {
            player: {
                title: 'Public',
                btn: 'Public',
            },
            friend: {
                title: 'Friends Only',
                btn: 'Friends Only',
            },
            me: {
                title: 'Closed',
                btn: 'Close',
            },
        },
    },
    teamBoard: {
        states: {
            open: {
                option: 'Public',
                title: 'Public Message',
                desc: 'A public message allow anybody to join the chat.',
            },
            dialog: {
                option: 'Dialog',
                title: 'Dialog Message',
                desc: 'A dialog message is viewable for everyone, but only the members of this guild can reply.',
            },
            close: {
                option: 'Private',
                title: 'Private Message',
                desc: 'A private message is only viewable to the members of this guild.',
                thisIsPrivateMsg: '',
            },
            removed: {
                option: 'Removed',
                title: 'Removed Message',
                desc: 'A removed message can never be read.',
            },
        },
        label: {
            boardSetting: 'Board Setting',
        },
        btn: {
            showBlacklist: 'Show my blacklist',
        },
        rootState: {
            player: {
                title: 'Public',
                btn: 'Public',
            },
            friend: {
                title: 'Friends Only',
                btn: 'Friends Only',
            },
            me: {
                title: 'Closed',
                btn: 'Close',
            },
        },
    },
    blogBoard: {
        comments: 'Comments',
        states: {
            open: {
                option: 'Open',
                title: 'Open Message',
                desc: 'An Open Message allows all players to view and reply the content。',
            },
            removed: {
                option: 'Removed',
                title: 'Removed Message',
                desc: 'A Removed Message will never be seen again.',
            },
        },
    },
    noti: {
        btn: {
            markAllRead: 'Mark all read',
            markOneRead: 'Mark read',
        },
        label: {
            noti: 'Notice',
            noNoti: 'No new Notices',
            read: 'Read',
        },
        subject: {
            userReport: 'reported an issue',
            userJoinedDiscuss: 'joined the discuss: {rootTitle}',
            userMessage: 'left you a message',
            userMessageReply: 'replied your message',
            friendRequest: '{requester} sent you a friend request',
            friendGranted: '{acceptor} accepted your friendship',
            toTeamRequest: '{requester} wants to join your guild',
            toTeamGranted: '{acceptor} accepted your apply to join the guild',
            toTeamRejected: '{acceptor} rejected your apply to join the guild',
            teamInviteRequest: '{requester} invites you to join their guild',
            teamInviteGranted: '{acceptor} has joined your guild',
            teamInviteRejected: '{acceptor} refused to join your guild',
        },
    },
});

export const forum_translation_en = 1;
