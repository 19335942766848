import { importTranslation } from '../translate';

importTranslation('zh-Hans', {
    contributeArts: {
        rules: {
            title: '稿件必须符合以下条件：',
            size: '尺寸：{width} x {height}',
            tip: '* 禁止任何侵犯版权或违反善良风俗的图片 *',
            chatCover: '主题：不限主题的二次创作或梗图',
            gamesCover: '主题：以站内游戏为主题的原创或二次创作',
            galleryCover: '主题：不限主题的个人原创作品',
        },
        btn: {
            contribute: '我要投稿封面',
            addFile: '选择图片',
            submitFile: '提交封面审核',
            filter: {
                showMine: '仅显示我的投稿',
                showAll: '显示所有稿件',
            },
        },
        noResource: '找不到稿件',
        newsTitle: '嘎姆公告',
        uploadTime: '{time}上传',
        tableTitle: {
            time: '时间',
            cover: '封面',
            author: '作者',
            state: '状态',
        },
        coverState: {
            pass: '审核通过',
            fail: '未通过审核',
            waitting: '审核中',
        },
    },
});

export const contributeArts_translation_cn = 1;