import { importTranslation } from '../translate';

importTranslation('zh-Hans', {
    pay: {
        pageTitle: '我的存款',
        choosePayBy: '选择一个付款方式',
        support: {
            creditcard: '信用卡',
            bank: '银行帐号',
            paypal: 'Paypal余额',
            cvs: '超商缴费',
            webATM: 'Web ATM',
            ATM: 'ATM转帐',
            linepay: 'LINE pay',
        },
        product: {
            points: {
                title: '购买 <img src="{icon}"><b>{points}</b>',
                desc: '购买嘎姆币<img src="{icon}">{points}<br/>并存入您的帐号',
            },
        },
        mydeposit: {
            title: '我的存款',
            gltDollar: '嘎姆币',
            gltPoint: '嘎姆点数',
        },
        history: {
            title: '交易记录',
            btnRead: '检视记录',
            btnMore: '更多',
            noRecord: '没有记录',
            category: {
                buyGltDollar: '购买嘎姆币',
                awardGltPoint: '获赠嘎姆点数',
                transferGltPoint: '转移自CG创作抠力',
            },
            fromCGUser: '转移自CG帐号: {username}',
            transNo: '交易号码: {transNo}',
        },
        currency: {
            TWD: '台币',
            USD: '美金',
        },
        paymentCompany: {
            ecpay: {
                name: '绿界科技',
                icon: 'https://gamelet.online/img/store/ecpay_logo.png',
                iconHeight: '20px',
            },
            paypal: {
                name: 'Paypal',
                icon: 'https://gamelet.online/img/store/paypal_logo.png',
                iconHeight: '20px',
            },
            newebpay: {
                name: '蓝新金流',
                icon: 'https://gamelet.online/img/store/newebpay_logo.png',
                iconHeight: '24px',
            },
            steampay: {
                name: 'Steam钱包',
                icon: 'https://gamelet.online/img/store/steampay_logo.png',
                iconHeight: '24px',
            },
        },
        paymentDisabled: '暂不提供 {company} 的付费方式',
        paymentOnlyOnCG: {
            tip: '请转至CG上消费',
            title: '在抠的嘎姆(CG)上消费',
            message:
                '在抠的嘎姆(code.gamelet.come)，您能以<b>{company}</b>消费，并在<b>购买嘎姆抠力</b>(各种帮助你制作好游戏的力量)后获得<b>创作抠力</b>，然后再将创作抠力转换至游戏内使用的<b>嘎姆币</b>。 ',
        },
        price: {
            TWD: '台币 ${price}',
            USD: '美金 ${price}',
        },
        btn: {
            buy: '购买',
        },
        intro: {
            title: '线上储值',
            lines: [
                '你的支持就是推动嘎姆创作平台以及所有创作者的最强动力。 ',
                '购买嘎姆币之后，在您喜爱的游戏中使用嘎姆币购买各种武器道具，就可以支援游戏创作者开发更多好玩的游戏与关卡。 ',
            ],
        },
    },
});

export const pay_translation_cn = 1;