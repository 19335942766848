import { ITeamship } from '@/vo/User';

const all: { [key: string]: FriendshipStatus } = {};

export class FriendshipStatus {
    static LOADING = new FriendshipStatus('loading');

    static NONE = new FriendshipStatus('none');
    static ACCEPTED = new FriendshipStatus('accepted');
    static REJECTED = new FriendshipStatus('rejected');
    static BLACKED = new FriendshipStatus('blacked');
    static BLACKED_BOTH = new FriendshipStatus('blackedBoth');
    static REQUESTING = new FriendshipStatus('requesting');

    static getByCode(code?: string): FriendshipStatus {
        return all[code!] || FriendshipStatus.NONE;
    }

    constructor(public code: string) {
        all[code] = this;
    }
}

export const TeamshipSetting = {
    LEADER: 0xffffff,

    INVITE: 1,
    REMOVE: 2,
    EDITOR: 4,
    INVEST: 8,
    MANAGE: 16,

    checkAuth(teamship: ITeamship | undefined | null, setting: number): boolean {
        const theSetting = (teamship && teamship.ship.settings) || 0;
        return (theSetting & setting) != 0;
    },
};

export interface TeamMemberView {
    code: string;
    icon: string;
}

export const TEAM_MEMBER_VIEWS: { [key: string]: TeamMemberView } = {
    SETTINGS: {
        code: 'settings',
        icon: 'fas fa-id-card',
    },
    LAST_PLAY: {
        code: 'lastPlay',
        icon: 'fas fa-gamepad',
    },
};
