
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CssPreloader from '@/components/CssPreloader.vue';
import AreaTitle from '@/components/AreaTitle.vue';
import UserTitleBox from '@/forum/components/UserTitleBox.vue';
import { translate } from '@/filters/translate';
import { IClient } from '@/vo/Client';
import { serverService } from '../../services/serverService';
import { ITitleUserItem } from '@/vo/PayProduct';
import { isMobile } from '../../filters/mobile';
import { getUserRoute, getUserTooltip } from '../../filters/user-filters';
import { ArrayUtil } from '../../utils/ArrayUtil';
import { ItemStatus } from '../../datas/ItemStatus';
import { numberWithCommas } from '../../filters/number-filters';
import { Constant } from '../../datas/Constant';

@Component({
    components: {
        CssPreloader,
        AreaTitle,
        UserTitleBox,
    },
    methods: {
        translate,
        isMobile,
        getUserTooltip,
        getUserRoute,
        numberWithCommas,
    },
})
export default class UserTitleList extends Vue {
    @Prop()
    client!: IClient;

    clientCode = '-';

    titleItems: ITitleUserItem[] = [];

    @Watch('client', { immediate: true })
    onClientChange(): void {
        let clientCode = this.client ? this.client.code : '';
        if (this.clientCode != clientCode) {
            this.clientCode = clientCode;
            this.refresh();
        }
    }

    getTotalPpls(): number {
        const client = this.client;
        return client.rate ? client.rate.votes : 0;
    }

    private refresh(): void {
        serverService.client.listSysTitleItems(this.client, Constant.SYS_ITEM_CATEGORY.USER_TITLE, true, true).then((items) => {
            ArrayUtil.sortNumericOn(items, 'order', true);
            this.titleItems = items
                .filter((i) => i.users.length && i.status == ItemStatus.PUB.code)
                .map((i) => {
                    return {
                        game: this.client,
                        item: i,
                    };
                });
        });
    }
}
