import { importTranslation } from '../translate';

importTranslation('en-US', {
    label: {
        friendship: 'Friendship',
        sns: 'SNS',
        location: 'Location',
        languages: 'Languages',
        'create-team': 'Create your guild',
        'create-team-notopen': 'Not open to create new guild',
        myTeam: 'My Guild',
        memberOfTeam: 'Member of <b>{team}</b>',
        'my-teammate': 'My guild-mate',
        newUrl: 'New URL',
        titleItem: 'Honor',
        user: {
            intro: 'About Me',
            my_home: 'My website',
            'edit-about': 'Edit About Me',
        },
        team: {
            intro: 'About the guild',
            my_home: 'Guild Site',
            'edit-about': 'Edit about guild',
            leader: 'Leader',
            member: 'Member',
            memberTitle: 'Title',
            time: 'Time',
            operator: 'Operator',
            oper: 'Oper',
            user: 'Target',
            action: 'Action',
            search: 'Search Team',
            atPosting: 'Status when posting:',
            settingsTitle: 'Guildship Help',
            view: {
                settings: 'View Guildship',
                lastPlay: 'Last Game Played',
            },
            setting: {
                invite: 'Inviter',
                remove: 'Prosecutor',
                editor: 'Editor',
                manage: 'Manager',
                invest: 'Investor',
            },
            settingTip: {
                invite: 'In charge of recruiting members.',
                remove: 'Update title and settings of members.',
                editor: 'In charge of editing name/about/sites of the team.',
                manage: 'Manage message board.',
                invest: 'Who can increase team size.',
            },
        },
    },
    btn: {
        'accept-friend': 'Accept',
        'reject-friend': 'Reject',
        'remove-friend': 'Unfriend',
        cancel: 'Cancel',
        'cancel-friend-request': 'Cancel Request',
        'be-friend': "Wanna be {target}'s friend?",
        'be-friend2': 'Change my mind, be a friend',
        'send-friend-request': 'Send Request',
        black: 'Block',
        unblack: 'Unblock',
        more: 'More',
        edit: 'Edit',
        save: 'Save',
        discard: 'Discard',
        copyUsername: 'Copy Username',
        viewWebmaster: 'Webmaster',
        edit_nickname: 'Edit nickname',
        edit_mood: 'Edit your mood',
        edit_icon: 'Change your photo',
        'create-team': 'Create a guild',
        'team-invite': 'Invite {target} to your guild?',
        'send-team-invite': 'Send Invitation',
        'cancel-team-invite': 'Cancel',
        'cancel-team-apply': 'Cancel',
        'leave-team': 'Leave',
        edit_team_nickname: 'Edit Guild Name',
        edit_team_icon: 'Change Guild Flag (200x120)',
        'apply-team': 'Apply to join {target}',
        'send-team-apply': 'Send Application',
        'team-invite-accept2': 'Change my mind, join the guild',
        'teamship-edit': 'Edit guildship',
        'team-kickout': 'Out the guild',
        'retake-myteam': 'Retake the abandoned guild of mine',
        'retake-myteam-short': 'Retake Guild',
    },
    tab: {
        user_board: 'Board',
        user_posts: 'Track',
        user_badges: 'Badges',
        user_friends: 'Friends',
        user_about: 'About',
        team_about: 'About',
        team_board: 'Board',
        team_members: 'Members',
        team_records: 'History',
    },
    badge: {
        getDate: 'Aquired on {date}',
        firstGetDate: '{date} aquired for the first time',
        lastGetDate: '{date} last aquired',
        getCount: 'Aquired {count} times',
    },
    placeholder: {
        location: 'Where you habitat...',
        languages: 'Languages you speak...',
        nickname: 'Nickname',
        mood: 'Your mood',
    },
    error: {
        'invalid-sns-url': 'The url is not valid for {name}',
        'found-no-user': 'The user doesnot seem to exist in gamelet.',
    },
    noRecord: 'No Record',
    usernameCopied: 'Copied to the clipboard',
    teamnameCopied: 'Copied to the clipboard',
    'i-have-no-mood': 'I am at peace with myself...',
    'i-have-no-friends': 'I have not made any friend... for now.',
    'he-has-no-friends': '{user} has no friend.',
    'i-have-no-invites': 'There is no friendship request in my box.',
    'he-has-no-invites': "There is no friendship request invite in {user}'s box.",
    'i-have-no-blacklist': 'There is no one in my blacklist.',
    'my-friends-total': 'I have <b>{total}</b> friends on Gamelet.',
    'his-friends-total': '{user} has <b>{total}</b> friends on Gamelet.',
    'my-invites-total': 'Friendship requests: {total}',
    'his-invites-total': 'Friendship requests: {total}',
    'my-blacklist-total': 'Blacklist length: {total}',
    'i-have-no-badge': 'I have not earned any badge.',
    'he-has-no-badge': 'The user has not earned any badge.',
    'he-has-no-blacklist': 'No user is in the blacklist',
    'my-intro-empty': "I haven't thought of what to write... ",
    'his-intro-empty': '{user} has nothing to say...',
    'user-blocked-byme': '{user} is in your blacklist.',
    'me-blocked-byuser': "You are in {user}'s blacklist.",
    'reject-friend-byme': "You have rejected {user}'s friendship.",
    'reject-friend-byuser': "{user}'s rejected your friendship.",
    team: {
        'i-have-no-mood': 'The guild has nothing to say...',
        'i-have-no-friends': 'The guild is just an empty shell...',
        'he-has-no-friends': 'The guild is just an empty shell...',
        'i-have-no-invites': 'No application to join.',
        'he-has-no-invites': 'No application to join.',
        'i-have-no-blacklist': 'There is no one in the blacklist.',
        'my-friends-total': '<b>{total}</b> members in the guild.',
        'his-friends-total': '<b>{total}</b> members in the guild.',
        'my-invites-total': 'Application to join: {total}',
        'his-invites-total': 'Application to join: {total}',
        'my-blacklist-total': 'Blacklist length: {total}',
        'i-have-no-badge': 'I have not earned any badge.',
        'he-has-no-badge': 'The user has not earned any badge.',
        'he-has-no-blacklist': 'No user is in the blacklist',
        'my-intro-empty': 'The guild has nothing to say...',
        'his-intro-empty': 'The guild has nothing to say...',
        'user-blocked-byme': '{user} is in the blacklist.',
        'me-blocked-byuser': "You are in {user}'s blacklist.",
        'reject-friend-byme': 'You have rejected the invitation from {user}.',
        'reject-friend-byuser': "Your guild rejected {user}'s application to join.",
        'apply-join-at': 'Applied on {date}',
        membersAndSize: 'Currently {members} members (max. {size})',
        action: {
            createTeam: 'Create Guild',
            retake: 'Retake Guild',
            acceptInvite: 'Accept Invite',
            apply: 'Apply Join',
            acceptApply: 'Accept Apply',
            invite: 'Invite Join',
            rejectInvite: 'Reject Apply',
            rejectApply: 'Reject Invite',
            leaveTeam: 'Leave Team',
            cancelApply: 'Cancel Apply',
            removeMember: 'Off the Guild',
            cancelInvite: 'Cancel Invite',
            saveTeam: 'Edit Team',
            setTeamSize: 'Incrase Team Size',
            updateTeamship: 'Edit Auth',
            setBoardAuthType: 'Board Setting',
            removeArticle: 'Remove Article',
            setArticleStatus: 'Article Status',
            setArticleState: 'Article State',
            setArticleLocked: 'Lock Article',
            setArticleOrder: 'Order Article',
        },
        actionSet: {
            all: 'All',
            team: 'Team',
            invite: 'Recruit',
            remove: 'Members',
            board: 'Board',
        },
    },
    'friend-request-expire-alert': 'Each friendship request is only preserved for {days} days.',
    'friend-request-received': '{user} would like to be your friend.',
    'friend-request-accepted': 'You are friends since {date}',
    'friend-request-sent': 'You have sent a Friend Request to {target} on {date}',
    'confirm-send-friend-request': 'Are you sure to send {target} a friend request?',
    'confirm-remove-friend': 'Are you sure you want to unfriend with {target}',
    'game.badges.total': '{total} total',
    'not-in-team': 'You have not joined any guild.',
    'he-not-in-myteam': 'This guy is not in your guild.',
    'request-is-canceled': 'The request has been canceled.',
    'not-in-myteam': 'Not in guild',
    'input-teamname': 'Please choose a unique guild-code (Cannot be changed)',
    'teamname-rules': 'Alphabet / digits / dash / underline only',
    'confirm-send-team-invite-request': 'Are you sure to invite {target} to your guild?',
    'team-invite-sent': 'Guild invitation has been sent to {target} at {date}',
    'team-apply-sent': 'Guild application has been sent to {target} at {date}',
    'team-invite-received': 'The guild would like to have you with them',
    'team-invite-accepted': 'joined {date}',
    'apply-team-received': '{user} wants to join your guild',
    'reject-teamship-byme': 'You have rejected the invitation to join the guild.',
    'confirm-leave-team': 'Are you sure you want to leave from your guild?',
    'confirm-team-kickout': 'Are you sure you want to have this member out of your guild?',
    'confirm-send-team-apply-request': 'Do you want to apply to join the guild, {target} ?',
    'confirm-send-team-retake-request': 'Do you want to retake the abandoned guild, {target}, and be the leader again?',
    'team-testing-warning': 'The Guild system is still in developing. Report if you find any bug.',
    lastPlay: 'Last seen in<br>{game}<br>{time}',
    lastPlayUnknown: 'Not sure what he played recently.',
    'guest-intro':
        '<p>This is a guest account.</p><p>Playing as guest do not save progress, nor receive badges and scores.</p>',
    'create-team-free-during-test': 'Creat team does not require fund during testing period.',
    'create-team-already-have-item': 'You have purchased the item to create team before, therefore, you can now use the item to create team without more funds.',
    'create-team-need-fund': 'Create team require funds:',
    'your-fund': 'Your wallet:',
    'enlarge-team-already-have-item': 'You have purchased the item to increase team capacity, therefore, you can now use the item to increase the team capacity to {size}.',
    'enlarge-team-need-fund': 'Incrase team capacity to {size}, Funds required:',
    'enlarge-team': 'Incrase team capacity',
});
export const appUser_translation_en = 1;
