import { importTranslation } from '../translate';

importTranslation('en-US', {
    time: {
        aMinute: '1 min',
        minutes: '{minutes} mins',
        hours: '{hours} hours',
        days: '{days} days',
        years: '{years} years',
    },
    timeShort: {
        aMinute: '1 min',
        minutes: '{minutes} mins',
        hours: '{hours} hrs',
        days: '{days} days',
        years: '{years} yrs',
    },
    timeAgo: {
        sec: 'a second ago',
        secs: '{sec} seconds ago',
        min: 'a minute ago',
        mins: '{min} minutes ago',
        hour: 'an hour ago',
        hours: '{hour} hours ago',
        day: 'yesterday',
        days: '{day} days ago',
        month: 'last month',
        months: '{month} months ago',
        year: 'last year',
        years: '{year} years ago',
    },
    reply: '{reply} reply',
    replies: '{reply} replies',
    update: '{count} update',
    updates: '{count} updates',
    to: 'to',
    btn: {
        login: 'Sign In',
        logout: 'Sign Out',
        myProfile: 'My Profile',
        myTeam: 'My Guild',
        more: 'More',
        confirm: 'Confirm',
        cancel: 'Cancel',
        close: 'Close',
        gotit: 'Got it',
        depositBeforeBuy: 'Deposit (Insufficient fund)',
    },
    googleSearch: {
        input: 'Google Search',
        result: 'Search result',
    },
    twitter: {
        title: 'Twitter',
    },
    leftMenu: {
        gameletQA: 'Gamelet 2.0<br />Q&A',
        gameletChannel: 'Official<br />Channel',
        moreFunction: 'COMING<br />SOON',
    },
    localedTitle: 'Gamelet',
    notLogin: 'Not signed in',
    processing: 'processing...',
    gmHome: 'GM Home',
    loading: 'Loading...',
    updating: 'Updating...',
    error: {
        notLogin: 'Please sign in first!',
        fileOverSized: 'The file size exceeds the limit.',
    },
    locked: {
        reasonTitle: 'Cause',
        showHowLong: 'Your account is temporarily locked, <span style="color: #fff09e">{time}</span> to unlock.',
    },
    gm: {
        alert: {
            searchResourceUser: {
                selected: 'Selected',
                btnSelect: 'SELECT',
            },
        },
    },
});

export const common_translation_en = 1;
