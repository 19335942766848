import { Location } from 'vue-router';

export interface RouteResolver {
    resolveRoute(params: { [key: string]: any }): Location;
    getUpRoute(): UpRouteData | null;
}
export interface UpRouteData {
    name: string;
    route: Location;
}

let clientUpRoute: Location | null = null;
export function setClientUpRoute(route: Location): void {
    clientUpRoute = route;
}
export function getClientUpRoute(clientCode: string): Location | null {
    if (clientUpRoute) {
        if (
            clientUpRoute.name == 'forum_games_page' ||
            clientUpRoute.name == 'forum_games' ||
            clientUpRoute.params?.clientCode == clientCode
        ) {
            return clientUpRoute;
        }
    }
    return null;
}

const clientLastView = {
    id: '',
};
export function setClientLastViewId(id: string): void {
    clientLastView.id = id;
}
export function getClientLastViewId(): string {
    const id = clientLastView.id;
    clientLastView.id = '';
    return id;
}
