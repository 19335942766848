
import { Component, Vue } from 'vue-property-decorator';
import GameBox from './GameBox.vue';
import Pagination from '@/components/Pagination.vue';
import { serverService } from '@/services/serverService';
import { CLIENT_ORDER } from '@/services/clientService';
import { translate } from '@/filters/translate';
import { IClient } from '@/vo/Client';

@Component({
    name: 'Games',
    components: {
        GameBox,
        Pagination,
    },
    methods: {
        translate,
    },
})
export default class LiteGames extends Vue {
    loading = false;
    gameData: IClient[] = [];
    length = 6;
    maxPage = 0;
    page = -1;

    order = CLIENT_ORDER.NEW;

    mounted(): void {
        this.loadPage(1);
    }

    loadPage(page: number): void {
        this.gameData = [];
        this.page = page;
        this.loading = true;

        serverService.client
            .listClientsByOrder(true, this.order, (this.page - 1) * this.length, this.length)
            .then((clients) => {
                this.loading = false;
                this.gameData = clients;
            });
    }
}
