
import { Component, Watch } from 'vue-property-decorator';
import { serverService } from '@/services/serverService';
import { translate } from '@/filters/translate';
import BaseTabComp from './BaseTabComp';
import Friends from './Friends.vue';

@Component({
    components: {
        Friends,
    },
    methods: {
        translate,
    },
})
export default class FriendsTab extends BaseTabComp {
    invitesTotal = 0;
    showInvites = false;

    mounted(): void {
        if (this.isMe) {
            serverService.user.getFriendInvitesTotal(this.user!).then((total) => {
                this.invitesTotal = total;
            });
        }
    }

    @Watch('$route', { immediate: true })
    onRouteChange(): void {
        if (this.isMe && this.$route.hash == '#invites') {
            this.btnInvites(true);
        }
    }

    btnInvites(show: boolean): void {
        this.showInvites = show;
    }
}
