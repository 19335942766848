
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
import { translate } from '@/filters/translate';
import { Route } from 'vue-router';
import { ForumCat } from '@/datas/ForumCat';
import { Location } from 'vue-router';
import { getUserRoute } from '@/filters/user-filters';
import RightNavBarUserManage from './RightNavBarUserManage.vue';

@Component({
    methods: {
        translate,
        getUserRoute,
    },
    components: {
        RightNavBarUserManage,
    },
})
export default class RightNavbar extends Vue {
    @Prop({})
    isOpen?: boolean;

    routeName = '';

    @Prop({ default: false })
    appGM?: boolean; // if this is on app-gm (app-home otherwise)

    @Watch('$route', { immediate: true, deep: true })
    onRouteChange(newVal: Route): void {
        this.routeName = newVal.name!;
        this.toggleNavBar(false);
    }

    @Emit('toggleNavBar')
    toggleNavBar(open: boolean): boolean {
        return open;
    }

    getSiteReportForumRoute(): Location {
        return ForumCat.REPORT.route;
    }
    getSiteReportForumRouteName(): string | undefined {
        return ForumCat.REPORT.route.name;
    }
}
