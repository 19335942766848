import { importTranslation } from '../translate';

importTranslation('zh-Hant', {
    pay: {
        pageTitle: '我的存款',
        choosePayBy: '選擇一個付款方式',
        support: {
            creditcard: '信用卡',
            bank: '銀行帳號',
            paypal: 'Paypal餘額',
            cvs: '超商繳費',
            webATM: 'Web ATM',
            ATM: 'ATM轉帳',
            linepay: 'LINE pay',
        },
        product: {
            points: {
                title: '購買 <img src="{icon}"><b>{points}</b>',
                desc: '購買嘎姆幣<img src="{icon}">{points}<br/>並存入您的帳號',
            },
        },
        mydeposit: {
            title: '我的存款',
            gltDollar: '嘎姆幣',
            gltPoint: '嘎姆點數',
        },
        history: {
            title: '交易記錄',
            btnRead: '檢視記錄',
            btnMore: '更多',
            noRecord: '沒有記錄',
            category: {
                buyGltDollar: '購買嘎姆幣',
                awardGltPoint: '獲贈嘎姆點數',
                transferGltPoint: '轉移自CG創作摳力',
            },
            fromCGUser: '轉移自CG帳號: {username}',
            transNo: '交易號碼: {transNo}',
        },
        currency: {
            TWD: '台幣',
            USD: '美金',
        },
        paymentCompany: {
            ecpay: {
                name: '綠界科技',
                icon: 'https://gamelet.online/img/store/ecpay_logo.png',
                iconHeight: '20px',
            },
            paypal: {
                name: 'Paypal',
                icon: 'https://gamelet.online/img/store/paypal_logo.png',
                iconHeight: '20px',
            },
            newebpay: {
                name: '藍新金流',
                icon: 'https://gamelet.online/img/store/newebpay_logo.png',
                iconHeight: '24px',
            },
            steampay: {
                name: 'Steam錢包',
                icon: 'https://gamelet.online/img/store/steampay_logo.png',
                iconHeight: '24px',
            },
        },
        paymentDisabled: '暫不提供 {company} 的付費方式',
        paymentOnlyOnCG: {
            tip: '請轉至CG上消費',
            title: '在摳的嘎姆(CG)上消費',
            message:
                '在摳的嘎姆(code.gamelet.come)，您能以<b>{company}</b>消費，並在<b>購買嘎姆摳力</b>(各種幫助你製作好遊戲的力量)後獲得<b>創作摳力</b>，然後再將創作摳力轉換至遊戲內使用的<b>嘎姆幣</b>。',
        },
        price: {
            TWD: '台幣 ${price}',
            USD: '美金 ${price}',
        },
        btn: {
            buy: '購買',
        },
        intro: {
            title: '線上儲值',
            lines: [
                '你的支持就是推動嘎姆創作平台以及所有創作者的最強動力。',
                '購買嘎姆幣之後，在您喜愛的遊戲中使用嘎姆幣購買各種武器道具，就可以支援遊戲創作者開發更多好玩的遊戲與關卡。',
            ],
        },
    },
});

export const pay_translation_zh = 1;
