import { Location } from 'vue-router';

const all: ResourceCategory[] = [];

export class ResourceCategory {
    public static CHAT_COVER = new ResourceCategory('chatCover');
    public static GAMES_COVER = new ResourceCategory('gamesCover');
    public static GALLERY_COVER = new ResourceCategory('galleryCover');

    public static getByCode(code: string): ResourceCategory | undefined {
        return all.find((category) => category.code == code);
    }

    constructor(public code: string) {
        all.push(this);
    }

    get contributeRoute(): Location {
        return {
            name: 'contribute_art',
            params: {
                category: this.code,
            },
        };
    }
}
