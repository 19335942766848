import { importTranslation } from '../translate';

importTranslation('zh-Hans', {
    aboutUs: {
        gmTeam: {
            tab: '网站管理团队',
            content: [
                '我们是嘎姆团队，负责嘎姆官网的建置、协助创作者将游戏在官网上架，并监督各游戏的社群管理。 ',
                '嘎姆团队始于2015年，由站长亲自邀请在嘎姆社群间较有名望的玩家组成，旨在调解玩家之间的纷争，帮助站长维持嘎姆的善良风气。 ',
                '2019年，网站管理团队的成员在长期合作的过程中建立了互信与强大的羁绊，并参与了嘎姆的重建。 ',
            ],
        },
        editors: {
            tab: '刊物编辑群',
            content: [
                '「嘎姆编辑群」成立于2016年，由一群喜爱编辑与写作的嘎姆玩家组成的爱好会，以搜文、访谈、创作等方式定期在社群间发行电子刊物，分享嘎姆趣事及 小说共赏。 ',
                '2019年在新任总编的带领下，为编辑群建立了完整的分工制度，定期审查与召募新血，帮助动荡的嘎姆留下历史的文字。 ',
            ],
        },
        title: {
            aboutUs: '关于我们',
            teamMember: '团队成员',
            teamBoard: '团队讨论区',
            contact: '联络我们',
            email: 'Email',
            facebook: '脸书',
            website: '网站',
            adminFacebook: '站长脸书',
            teamFacebook: '团队脸书',
        },
    },
    history: {
        events: [
            {
                year: 2007,
                month: '三月',
                text: ['力可股份有限公司成立'],
            },
            {
                year: 2008,
                month: '八月',
                text: ['嘎姆擂台游戏网站问世(tw.gamelet.com)'],
            },
            {
                month: '九月',
                text: [
                    '推出新游戏「黑森林杰克」',
                    '推出新游戏「冲出大气层」',
                    '推出新游戏「无差别五子棋」',
                    '推出新游戏「槌哥大乱斗」',
                ],
            },
            {
                month: '十月',
                text: ['推出新游戏「物换星移」', '推出新游戏「坦克大决战Online」', '推出新游戏「嘎姆宠物连连看」'],
            },
            {
                year: 2009,
                month: '一月',
                text: ['推出新游戏「光晕战记」', '推出新游戏「暗棋无双」', '推出新游戏「猜数字」', '「槌哥大乱斗」下架'],
            },
            {
                month: '四月',
                text: ['推出新游戏「水晶挖挖哇」', '推出新游戏「坦克战队」', '推出新游戏「蛇来也」'],
            },
            {
                month: '七月',
                text: ['推出新游戏「童话99」', '「水晶挖挖哇」于脸书上架', '「暗棋无双」于脸书上架', '「蛇来也」于脸书上架'],
            },
            {
                month: '八月',
                text: ['「冲出大气层」于脸书上架', '「童话99」于脸书上架', '「光晕战记」于脸书上架'],
            },
            {
                month: '十月',
                text: ['推出脸书限定「海贼大老二」', '推出脸书限定「麻将小窝」'],
            },
            {
                year: 2010,
                month: '二月',
                text: ['推出嘎姆脸书双平台游戏「怪朋友」'],
            },
            {
                month: '五月',
                text: ['「怪朋友」改名为「宠物秘境」'],
            },
            {
                month: '八月',
                text: ['推出脸书独占游戏「Pizza世界」'],
            },
            {
                year: 2011,
                month: '一月',
                text: ['推出新游戏「光之继承」'],
            },
            {
                month: '八月',
                text: ['「宠物秘境」改名为「怪朋友Online」'],
            },
            {
                month: '十月',
                text: ['光晕战记推出「玩家人物造型」及「爱恨情仇」系统'],
            },
            {
                year: 2012,
                month: '一月',
                text: ['光晕战记推出第二季内容「屠魔英雄」', '嘎姆擂台贺岁影片征选竞赛'],
            },
            {
                month: '十一月',
                text: ['推出新游戏「魔女战役」', '光晕战记推出「光晕同人阵Alpha」'],
            },
            {
                year: 2013,
                month: '二月',
                text: ['「光晕同人阵」正式上线'],
            },
            {
                month: '八月',
                text: ['光晕同人阵票选活动「同人奥斯卡」'],
            },
            {
                month: '十月',
                text: ['光晕战记推出新模式「僵尸来袭」'],
            },
            {
                month: '十一月',
                text: ['光晕战记推出「一元特价」系统'],
            },
            {
                year: 2014,
                month: '一月',
                text: ['光晕战记新年征稿活动「新春对联」'],
            },
            {
                month: '二月',
                text: ['推出新游戏「公主未成年」'],
            },
            {
                month: '六月',
                text: ['嘎姆擂台入不敷出，初代团队宣告解散'],
            },
            {
                year: 2015,
                month: '二月',
                text: ['光晕战记推出新机制「紧急事件」'],
            },
            {
                month: '八月',
                text: ['「嘎姆点数秘境」正式推出，嘎姆擂台转型为非营利'],
            },
            {
                month: '九月',
                text: ['嘎姆伺服器移转至小哈片刻的私人机房', '光晕战记推出「电竞场」'],
            },
            {
                month: '十月',
                text: ['光晕战记推出「路亚修行」(LUA)', '光晕战记「万圣节自创角色大赛」'],
            },
            {
                year: 2016,
                month: '一月',
                text: ['嘎姆召唤玩家组成「嘎姆管理团队」'],
            },
            {
                month: '三月',
                text: ['由幻笔发起，近二十位作者共笔的第一期嘎姆刊物出刊'],
            },
            {
                month: '六月',
                text: ['「电竞委员会」成立，由玩家组成委员会代理官方筹办比赛'],
            },
            {
                month: '七月',
                text: [
                    '电竞委员会主办「试办生存战」',
                    '嘎姆伺服器二度移转至新机房',
                    '伺服器严重毁损（<a href="//blog.gamelet.com/2016/08/blog-post_4.html" target="_blank">七日天罚事件</a>）',
                ],
            },
            {
                month: '八月',
                text: ['电竞委员会主办「生存战正式赛」', '电竞委员会主办「战斗篮球联赛」', '光晕战记激战区推出全新「MMO RPG」'],
            },
            {
                year: 2017,
                month: '一月',
                text: ['「<a href="//blog.gamelet.com" target="_blank">嘎姆游刊</a>」部落格启用'],
            },
            {
                month: '七月',
                text: ['电竞委员会主办「程式写手-路亚灵魂」'],
            },
            {
                month: '八月',
                text: ['嘎姆官方电子期刊Vol.2出刊'],
            },
            {
                year: 2018,
                month: '四月',
                text: ['嘎姆官方旗下游戏制作网站「<a href="//code.gamelet.com" target="_blank">抠的嘎姆</a>」正式推出'],
            },
            {
                month: '八月',
                text: ['嘎姆官方电子期刊Vol.3出刊'],
            },
            {
                year: 2019,
                month: '一月',
                text: ['抠的嘎姆开课，时程三个月的免费网路课程'],
            },
            {
                month: '六月',
                text: ['由幽冥接手主编，嘎姆官方电子期刊Vol.4出刊'],
            },
            {
                month: '十一月',
                text: ['嘎姆伺服器损毁，旧版嘎姆停止运作'],
            },
            {
                month: '十二月',
                text: ['由「嘎姆管理团队」于Discord成立临时社群聚集地。 ', '「嘎姆管理团队」着手开始新网站的计画与建置'],
            },
            {
                year: 2020,
                month: '一月',
                text: ['「光晕战记重制版」Alpha测试机上架于「抠的嘎姆」平台', '光晕战记重制版推出新年活动「年兽来袭」'],
            },
            {
                month: '二月',
                text: ['新版嘎姆擂台游戏网站(gamelet.online)简易版架设完毕'],
            },
            {
                month: '三月',
                text: [
                    '嘎姆团队于抠的嘎姆平台上共同开发「COVID-19 CommandCentre」',
                    '并参赛 COVID-19 Global Hackathon 1.0 全球肺炎黑客松',
                ],
            },
            {
                month: '六月',
                text: [
                    '新版嘎姆擂台游戏网站重建工程开始',
                    '嘎姆官方期刊编辑群改名为「嘎姆编辑群」',
                    '光晕战记重制版的官方任务重建工程开始',
                    '嘎姆官方电子期刊Vol.5出刊',
                ],
            },
            {
                month: '七月',
                text: ['新版光晕战记之「C.G.同人阵」系统公开'],
            },
            {
                month: '十月',
                text: ['开放「抠的嘎姆」平台上的游戏发布至新版嘎姆网站'],
            },
            {
                month: '十二月',
                text: ['新版嘎姆擂台游戏网站正式架设完毕(gamelet.online)', '嘎姆编辑群重新启动人员招募与对外征稿作业'],
            },
            {
                year: 2021,
                month: '二月',
                text: ['嘎姆擂台官方Discord临时讨论区功成身退'],
            },
        ],
    },
    cg: {
        title: '抠的嘎姆',
        btn: {
            goCG: '去看看',
        },
        intro: [
            '<a href="//code.gamelet.com">Code.Gamelet.com</a> (简称CG) 是个适合各种程度的梦想家制作游戏的平台。 ',
            '借由图案素材分享、音效分享、甚至是游戏引擎的分享，游戏设计师可以跨越程式写作的障碍，将游戏点子化为真实，并在这个平台上和其他玩家分享。 ',
        ],
        compareUser: [
            {
                title: '游戏制作',
                features: [
                    '适合<b>想制作</b>游戏，但不知道从何下手的人',
                    '想要用<b>最快速</b>度将想到的点子变成游戏的人',
                    '使用<b>内建</b>游戏编辑器',
                ],
            },
            {
                title: '程式设计',
                features: [
                    '想学<b>写程式</b>，但拿不定主意要学什么语言的人',
                    '已经会写程式，但很<b>想观摩</b>大师以求精进的人',
                    '使用<b>内建</b> Typescript 整合开发环境',
                ],
            },
        ],
        shareFeatures: [
            '<b>共享</b>图片/音效/素材/各式编辑器',
            'CG提供资料库/<b>即时连线</b>伺服器/发布网站',
            '使用CG一键转换<b>手机</b>应用程式',
            '和嘎姆的玩家及设计高手们切搓<b>成长</b>',
        ],
        compareVideo: [
            {
                title: '使用范例',
                video: 'https://www.youtube.com/embed/JYMpMDhBjs0',
            },
            {
                title: '使用范例',
                video: 'https://www.youtube.com/embed/Z3HhDTHtntM?start=2640',
            },
        ],
    },
});

export const aboutUs_translation_cn = 1;