var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
        'all-root': 1,
        client: _vm.client,
        'one-page': _vm.totalPages == 1,
        'mul-pages': _vm.totalPages > 1,
    }},[(!_vm.client)?_c('AreaTitle',{attrs:{"text":_vm.title}}):_c('router-link',{staticClass:"title",attrs:{"to":_vm.getClientNewsRoute(_vm.client)}},[_c('AreaTitle',{attrs:{"text":_vm.title}})],1),_c('div',{staticClass:"main-root"},[(!_vm.isMobile())?_c('div',{staticClass:"block-gap"}):_vm._e(),_c('div',{staticClass:"main-body",on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[(!_vm.isMobile())?_c('div',{staticClass:"page-dots"},_vm._l((_vm.pageDataList),function(p){return _c('div',{key:`page${p.index}`,class:{
                        'page-dot': 1,
                        selected: p.index == _vm.currPageData.index,
                    },on:{"click":function($event){return _vm.setPage(p.index)}}},[_c('div',{staticClass:"dot"})])}),0):_vm._e(),_c('div',{class:['news-list', 'fading-' + _vm.currPageData.fading]},[(_vm.currPageData.loading)?_c('CssPreloader',{staticClass:"loading"}):_vm._e(),_vm._l((_vm.currPageData.articles),function(news,index){return _c('div',{key:`news${index}`,staticClass:"news-box"},[_c('router-link',{staticClass:"news-link",attrs:{"to":_vm.getNewsLink(news)}},[_c('div',{staticClass:"game-cover",style:({
                                backgroundImage: _vm.getClientCoverBgImg(news.client),
                            })})]),_c('div',{staticClass:"game-header dropdown dropright"},[(!_vm.client)?_c('a',{staticClass:"game-title dropdown-toggle",attrs:{"href":_vm.getClientPlayUrl(news.client),"data-bs-toggle":"dropdown"}},[_vm._v("#"+_vm._s(news.client.name))]):_c('div',{staticClass:"news-icon"},[_c('i',{staticClass:"fas fa-newspaper"}),_c('div',{staticClass:"weekly-title"},[_vm._v(" "+_vm._s(_vm.newsWeeklyTitle(news))+" ")])]),_c('div',{staticClass:"news-time"},[_vm._v(" "+_vm._s(_vm.timestampToTimeAgo(news.modifyTime * 1000))+" ")]),_c('div',{class:{ 'dropdown-menu': 1, utitle: _vm.isUserTitleClient(news.client) }},[_c('a',{staticClass:"dropdown-item btn-play",attrs:{"href":_vm.getClientPlayUrl(news.client)}},[_c('i',{staticClass:"fas fa-play play-icon"}),_vm._v(" "+_vm._s(_vm.translate('game.btn.play'))+" ")]),_vm._l((_vm.allCats),function(cat){return _c('router-link',{key:cat.code,class:['dropdown-item', cat.code],attrs:{"to":_vm.getClientRouteByCategory(news.client, cat)}},[_c('i',{class:cat.icon}),_vm._v(" "+_vm._s(_vm.getClientLinkName(news.client, cat))+" ")])})],2)]),_c('div',{staticClass:"news-content",domProps:{"innerHTML":_vm._s(news.content)}})],1)}),(!_vm.currPageData.articles.length && !_vm.currPageData.loading)?_c('div',{staticClass:"no-news"},[_vm._v(" "+_vm._s(_vm.translate('news.label.noData'))+" ")]):_vm._e()],2),(_vm.isMobile())?_c('div',{staticClass:"page-dots"},_vm._l((_vm.pageDataList),function(p){return _c('div',{key:`page${p.index}`,class:{
                        'page-dot': 1,
                        selected: p.index == _vm.currPageData.index,
                    },on:{"click":function($event){return _vm.setPage(p.index)}}},[_c('div',{staticClass:"dot"})])}),0):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }