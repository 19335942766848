import { importTranslation } from '../translate';

importTranslation('zh-Hant', {
    gameletQA: {
        banner: {
            title: '嘎姆 2.0 Q&A',
        },
        tabs: [
            {
                title: '常見問題',
                content: [
                    {
                        q: '遊戲出現「您的瀏覽器不支援WebGL或WebGL未啟用」是怎麼一回事？',
                        a:
                            '大部份高端的網頁遊戲都需要有WebGL的支援才能玩得順暢。啟用WebGL需要以下兩個瀏覽器的設定(或其中一個設定):\n1.啟用「硬體加速」\n2.啟用「WebGL」',
                    },
                    {
                        q: '我的帳號被一個遊戲封鎖了，請問會影響我在其他遊戲的權限嗎？',
                        a:
                            '各遊戲都是由各自遊戲的製作者負責組團管理玩家及社群，你的帳號在一個遊戲的停權狀態不會影響在其他遊戲的權限。',
                    },
                    {
                        q: '帳號被遊戲封鎖了，請問我該找誰申訴？',
                        a: '你可以在遊戲的專屬討論區中找到包含「回報問題」的選單，你可經此連結前往該遊戲的回報區進行申訴。',
                    },
                ],
            },
            {
                title: '遊戲上架問題',
                content: [
                    {
                        q: '我能把自己的遊戲免費放上嘎姆？',
                        a:
                            '只要是在「摳的嘎姆(CG)」製作的遊戲都可以免費在嘎姆官網上架，並能利用官網贈送的免費專屬討論區經營社群。',
                    },
                    {
                        q: '在嘎姆上架的遊戲有可能被下架嗎？',
                        a:
                            '嘎姆官方會避免干涉各遊戲的社群，但是要請各遊戲製作團隊善加經營，以免因社群違反嘎姆的善良風俗而讓遊戲被迫下架。',
                    },
                    {
                        q: '要如何管理我的遊戲專屬討論區？',
                        a:
                            '請先開啟CG的你的專案，打開右方「資料庫」中的「遊戲管理員」。將你在gamelet.online上的玩家帳號加入管理員列表後，就可以使用這個帳號在討論區中關閉不適當的文章。',
                    },
                ],
            },
            {
                title: '關於舊版嘎姆',
                content: [
                    {
                        q: '舊版發生什麼事？',
                        a:
                            '嘎姆於2019年10月底有一台伺服器的主機完全報銷，因為舊版伺服器無法得到技術支援，所以只能抱憾宣告不治。',
                    },
                    {
                        q: '舊版討論區的文章能移植過來嗎？',
                        a:
                            '雖然舊版討論區的文章仍躺在舊版伺服器的資料庫中，但因為技術問題，目前無法存取。站長也希望有朝一日可以讓這些舊文章重見天日，但這個目標可能遙遙無期。',
                    },
                    {
                        q: '我在舊版的嘎姆擂台儲值，現在這些點數怎麼辦？',
                        a:
                            '目前讓想要退費的玩家提供帳號及購買點數的相關發票或記錄寄信至 <a href="mailto:gamelet.online@gmail.com">gamelet.online@gmail.com</a>，並在信件主題註明 "嘎姆退費" ，這樣站長就會與你聯繫。',
                    },
                ],
            },
        ],
    },
});

export const gameletQA_translation_zh = 1;
