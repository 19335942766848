import firebase from 'firebase/app';
import 'firebase/messaging';
import store from 'store2';
import { AbstractSubService } from './abstractSubService';
import Vue from 'vue';
import { NotificationType } from '../datas/NotificationType';
import { translate } from '../filters/translate';
import { serverService } from './serverService';
import { ArrayUtil } from '../utils/ArrayUtil';
import { INotification, IPushNoti, IPushNotiData } from '@/vo/Noti';
import { InitData } from '@/vo/User';
import { IArticle } from '@/vo/Discuss';

const oneSecond = 1000;
const oneMinute = oneSecond * 60;

interface PushInfo {
    username?: string;
    token?: string;
}

export class MessagingService extends AbstractSubService {
    private messaging!: firebase.messaging.Messaging;

    private pushInfo: PushInfo = {};

    private notifications: INotification[] | null = null;

    private timeToSync = 0;

    private paused = false;

    pause(): void {
        this.paused = true;
    }
    resume(): void {
        this.paused = false;
    }

    initialize(initData: InitData, localTesting: boolean): Promise<void> {
        if (this.messaging) {
            return Promise.reject('messaging service already exists.');
        }
        if (this.paused) {
            return serverService.wait(1000).then(() => this.initialize(initData, localTesting));
        }
        this.pushInfo = JSON.parse(store('pushInfo') || '{}');

        return navigator.serviceWorker
            .register('/firebase-messaging-sw.js', {
                scope: 'firebase-cloud-messaging-push-scope',
            })
            .then(() => {
                firebase.apps.length ||
                    firebase.initializeApp({
                        apiKey: 'AIzaSyD5AYYZzctyVGazKMFIOybjE6y403FyygY',
                        authDomain: 'codegamelet.firebaseapp.com',
                        databaseURL: 'https://codegamelet.firebaseio.com',
                        projectId: 'codegamelet',
                        storageBucket: 'codegamelet.appspot.com',
                        messagingSenderId: '443719242562',
                        appId: '1:443719242562:web:f3a0de4b08b61f3bd22edb',
                    });
                this.messaging = firebase.messaging();
                return this.getPermission(initData);
            })
            .then(() => {
                this.receiveMessage();
            })
            .catch((err) => {
                console.log('messaing init error: ', err);
            });
    }

    private getPermission(initData: InitData): Promise<void> {
        if (this.messaging == null) {
            return Promise.reject('messaging has no ServiceWorker');
        }
        return this.messaging
            .getToken({
                vapidKey: 'BOkAnQowJi7YcwUM5iPHreGTuRVTqxzcOpAWTPsV1jmuX2Tk7TnLD1VOvT8h-Cw1pv8J9Duc8Ti1G6B1FKVcvlM',
            })
            .then((token) => this.registerUserDevice(token, initData));
    }

    private receiveMessage() {
        this.messaging.onMessage((payload) => {
            console.log('Message received.', payload);
            this.notify(this.mergePushNoti(payload.notification, payload.data));
            this.syncNotis();
        });
    }

    private mergePushNoti(pushNoti: IPushNoti, data: IPushNotiData): IPushNoti {
        data.link = pushNoti.click_action;
        data.title = pushNoti.title;
        data.body = pushNoti.body;
        pushNoti.data = data;
        return pushNoti;
    }

    private notify(pushNoti: IPushNoti): IPushNotiData {
        Vue['notify']({
            group: 'noti',
            title: pushNoti.title,
            text: pushNoti.body,
            data: pushNoti.data,
        });
        return pushNoti.data!;
    }

    public registerUserDevice(token: string, initData: InitData): Promise<void> {
        if (this.pushInfo.token == token && this.pushInfo.username == initData.me.username) {
            return Promise.resolve();
        }
        return this.api('/register/user_device', {
            token: token,
            device: navigator.userAgent,
        })
            .then((json) => {
                console.log('register/user_device: ' + json.result);
                this.pushInfo.username = initData.me.username;
                this.pushInfo.token = token;
                store('pushInfo', JSON.stringify(this.pushInfo));
            })
            .catch((error) => console.error('register/user_device: ' + error));
    }

    public testPush(): void {
        const data = this.getTestPushNotiData(NotificationType.USER_REPORT);
        this.notify(
            this.mergePushNoti(
                {
                    title: data.title,
                    body: data.body,
                    click_action: data.link,
                    icon: data.icon!,
                    tag: '/',
                },
                data
            )
        );
    }

    public getTestPushNotiData(type: NotificationType): IPushNotiData {
        return {
            title: '小哈片刻 加入討論 誰有辦法打敗彩虹七星上將？',
            body: `${type.code}:要打敗不難，重點是你要先拿到傳說中的聖劍之類的東西...`,
            link: '/forum/TwilightWars/post/100',
            icon: 'https://lh5.googleusercontent.com/-Kg9XwCErKTI/AAAAAAAAAAI/AAAAAAAAhew/gytoafsw85g/photo.jpg',
            category: type == NotificationType.USER_REPORT ? 'report' : 'forum',
            time: 1605501010,
            subKey: '',
        };
    }

    public getTestNoti(): INotification {
        const notiType = ArrayUtil.getRandomElement(NotificationType.listAll())!;
        return {
            id: 0,
            type: notiType.code,
            key: 'testkey' + Math.random(),
            meta: Object.assign(
                {
                    author: 'someone',
                    rootTitle: 'cool title',
                },
                this.getTestPushNotiData(notiType)
            ),
            time: Math.floor(Date.now() / 1000),
        };
    }

    private emitNotisChange(): IPushNotiData[] {
        if (!this.notifications) {
            return [];
        }
        const list = this.notifications.map((notification) => {
            const meta = notification.meta;
            meta.title = meta.title || translate('noti.subject.' + notification.type, meta);
            meta.time = meta.time || notification.time;
            return meta;
        });
        this.emit('notis', this.notifications);
        return list;
    }

    public startSyncNotifications(): void {
        this.scheduleNextSync(1);
    }

    private scheduleNextSync(time: number): void {
        this.wait(time)
            .then(() => {
                if (Date.now() > this.timeToSync && !this.paused) {
                    return this.syncNotis();
                } else {
                    return Promise.resolve([]);
                }
            })
            .then(() => this.scheduleNextSync(oneMinute));
    }

    private syncNotis(): Promise<IPushNotiData[]> {
        this.timeToSync = Date.now() + oneMinute * 10;
        return this.listNotifications();
    }

    public listNotifications(): Promise<IPushNotiData[]> {
        this.notifications = null;
        return this.api('/noti/list_mine').then((json) => {
            this.notifications = json.list;
            return this.emitNotisChange();
        });
    }

    public markAllRead(): Promise<number> {
        return this.api('/noti/mark_all_read').then((json) => {
            if (this.notifications) {
                this.notifications = [];
                this.emitNotisChange();
            }
            return json.result;
        });
    }

    public markRead(keys: string[], types: NotificationType[]): Promise<number> {
        if (this.notifications) {
            const typeCodes = types.map((t) => t.code);

            const filtered = this.notifications.filter((n) => !keys.includes(n.key) || !typeCodes.includes(n.type));

            if (filtered.length != this.notifications.length) {
                this.notifications = filtered;
                this.emitNotisChange();

                return this.api('/noti/mark_read', {
                    keys: keys,
                    types: typeCodes,
                }).then((json) => json.result);
            } else {
                return Promise.resolve(0);
            }
        } else {
            return this.wait(1000).then(() => this.markRead(keys, types));
        }
    }

    public markNotificationRead(notification: INotification): Promise<number> {
        return this.markRead([notification.key], [NotificationType.getByCode(notification.type)]);
    }

    public markArticlesRead(articles: IArticle[], types: NotificationType[]): Promise<number> {
        const keys = articles.map((article) => article.id + '');
        return this.markRead(keys, types);
    }
}
