
import { Component, Vue } from 'vue-property-decorator';
import { translate } from '@/filters/translate';

interface HistoryEvent {
    date: number;
    text: string[];
}

@Component({
    methods: {
        translate,
    },
})
export default class History extends Vue {
    events: HistoryEvent[] = translate('history.events', { raw: true }) as any;

    mounted(): void {
        document.title = translate('localedTitle') + ':' + translate('links.memorabilia');
    }
}
