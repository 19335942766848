
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getClientPlayUrl, getClientCoverBgImg, getClientForumRoute, getClientOwnerUrl } from '@/filters/game-filters';
import { getUserRoute } from '@/filters/user-filters';
import { translate } from '@/filters/translate';
import GameLinksButton from '@/components/GameLinksButton.vue';
import RateStars from '@/components/RateStars.vue';
import { IClient } from '@/vo/Client';
import { DomUtil } from '@/utils/DomUtil';

declare const $: any;

let focusingBox: GameBox | null = null;

@Component({
    components: {
        GameLinksButton,
        RateStars,
    },
    methods: {
        getClientForumRoute,
        getClientPlayUrl,
        getClientCoverBgImg,
        getClientOwnerUrl,
        getUserRoute,
        translate,
    },
})
export default class GameBox extends Vue {
    @Prop()
    item!: IClient;

    @Prop()
    liteInfo!: boolean;

    @Prop()
    ppl!: number;

    showTrailer = false;

    elementTop = 0;

    destroyed(): void {
        focusingBox = null;
    }

    mounted(): void {
        this.elementTop = this.getTop();
    }

    private getTop(): number {
        return DomUtil.getElementTop($('#heading_' + this.item.code)[0]);
    }

    getClientRate(client: IClient): number {
        return (client.rate && client.rate.avg) || 0;
    }
    getClientVotes(client: IClient): number {
        return (client.rate && client.rate.votes) || 0;
    }

    private cardOnClick() {
        if (this.liteInfo) {
            this.$router.push(getClientForumRoute(this.item));
        } else {
            focusingBox = this;
            setTimeout(() => this.moveToHeader(this), 350);
            DomUtil.moveToTop(this.elementTop);
        }
    }

    private moveToHeader(comp: any) {
        if (focusingBox == comp) {
            let top = this.getTop();
            if (Math.abs(top - this.elementTop) > 64) {
                this.elementTop = top;
                const header = $('#heading_' + this.item.code);
                if (header.length && !header.hasClass('collapsed')) {
                    DomUtil.moveToTop(this.elementTop);
                }
            }
        }
    }

    toggleTrailer(show: boolean): void {
        this.showTrailer = !!(show && this.trailerID);
    }

    get trailerID(): string {
        let trailer = this.item && this.item.trailer;
        return (trailer && trailer.id) || '';
    }

    get trailerAutoURL(): string {
        return `https://www.youtube-nocookie.com/embed/${this.trailerID}?&autoplay=1&mute=1&controls=0`;
    }

    openTrailer(): void {
        const trailer = this.item && this.item.trailer;
        DomUtil.showVideoDialog(trailer!.platform, trailer!.id);
    }
}
