var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{ root: 1, beta: _vm.beta }},[_c('div',{staticClass:"block"},[_c('div',{staticClass:"title-bar"},[_c('router-link',{staticClass:"title",attrs:{"to":{ name: 'games' }}},[_c('AreaTitle',{attrs:{"hoverEffect":_vm.beta,"text":_vm.translate('game.allGames'),"subtitle":_vm.beta ? 'Beta' : ''}})],1),(!_vm.beta && !_vm.showSideBeta)?_c('router-link',{staticClass:"btn-enter-beta",attrs:{"to":{ name: 'games_beta' }}},[_vm._v(" "+_vm._s(_vm.translate('forum.btn.enterBeta'))+" "),_c('i',{staticClass:"fas fa-arrow-right"})]):_vm._e()],1),_c('div',{staticClass:"games-root"},[(_vm.beta)?_c('div',{staticClass:"betabox"},[_c('i',{staticClass:"fas fa-hand-holding-heart"}),_c('div',{staticClass:"betamsg",domProps:{"innerHTML":_vm._s(
                        _vm.translate('game.betaMessages', {
                            wrap: 'div',
                            min: _vm.minsPlayToReview,
                        })
                    )}})]):_vm._e(),_c('div',{staticClass:"accordion",attrs:{"id":"accordion"}},[_c('div',{staticClass:"btns-order"},_vm._l((_vm.orderOptions),function(op){return _c('router-link',{key:op.code,class:{
                            btn: 1,
                            'btn-info': 1,
                            inactive: op != _vm.order,
                        },attrs:{"to":_vm.resolveRoute({ order: op.code })}},[_c('i',{class:op.icon}),_vm._v(" "+_vm._s(op.name)+" ")])}),1),_c('div',{staticClass:"game-list"},[(_vm.loading)?_c('div',{staticClass:"spinner-border text-info loading",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])]):_vm._e(),_vm._l((_vm.gameData),function(item,index){return _c('GameBox',{key:index,attrs:{"item":item,"liteInfo":false,"ppl":_vm.getOnlinePpl(item.code)}})})],2),(_vm.gameData.length)?_c('Pagination',{attrs:{"maxPage":_vm.maxPage,"page":_vm.page,"routeResolver":_vm.routeResolver}}):_vm._e()],1)])]),(!_vm.beta && _vm.showSideBeta)?_c('LiteGames'):_vm._e(),(_vm.showSideAds())?_c('AdsResponsive',{staticClass:"ads-right",attrs:{"stickyTop":68}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }