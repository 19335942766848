import { importTranslation } from '../translate';

importTranslation('zh-Hant', {
    blog: {
        btn: {
            submitBlog: '我要投稿',
            aboutEditors: '關於編輯群',
            newer: '較新文章',
            older: '較舊文章',
        },
        label: {
            read: '閱讀',
            moreArticles: '更多文章',
        },
        intro: {
            title: '嘎姆遊刊',
            desc: [
                '嘎姆遊刊是玩家們分享點子、抒發心情、回憶考古的專屬園地。我們期待您將平常在嘎姆得到的靈感化成文字，發表在這兒感動與啟發所有的玩家們。',
                '「嘎姆擂台電子期刊」則是由官方組成的「嘎姆編輯群」以搜文、訪談、創作等方式製作的電子刊物，裏頭刊登了許多原創的文章與小說，定期出刊與讀者們分享。',
            ],
        },
        blogs: {
            title: '最新文章',
        },
        epub: {
            title: '嘎姆期刊電子書',
            volTitle: '嘎姆期刊 Vol.{vol}',
        },
    },
});

export const blog_translation_zh = 1;
